import { Inject, Injectable, Optional } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ReleaseNotesApiService } from '@core/modules/release-notes/services/release-notes-api.service';
import { RollbarService } from '@core/modules/rollbar/rollbar.module';
import { NotificationService } from '@core/modules/snack-bar';
import { StatusService } from '@core/services/status.service';
import { ThemeService } from '@core/services/theme/theme.service';
import * as ProjectActions from '@features/workspace-manager/store/projects/projects.action';
import {
  Actions,
  ROOT_EFFECTS_INIT,
  createEffect,
  ofType,
} from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppActions, AuthActions } from '@store/.';
import * as RouterActions from '@store/router/router.actions';
import { AppSelectors } from '@store/selectors';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import Rollbar from 'rollbar';
import { concatMap, first, map, mergeMap, switchMap, tap } from 'rxjs';
import { getErrorMessage } from './app.effects.helper';

@Injectable()
export class AppEffects {
  constructor(
    private _actions$: Actions,
    private _notify: NotificationService,
    private _store: Store,
    private _dialog: MatDialog,
    private _statusService: StatusService,
    private _themeService: ThemeService,
    private _releaseNotesApiService: ReleaseNotesApiService,
    @Inject(RollbarService) @Optional() private _rollbar?: Rollbar
  ) {}

  login$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.loginSuccess),
      switchMap(() => this._releaseNotesApiService.showReleaseNotesBanner()),
      map(seen => AppActions.checkReleaseNotesSeen({ seen }))
    );
  });

  newReleaseNotesSeen$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AppActions.seenReleaseNote),
      switchMap(() => this._releaseNotesApiService.setRecentReleaseSeen()),
      map(() => AppActions.seenReleaseNoteSuccess())
    );
  });

  showBasicErrorMsg$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(AppActions.showBasicErrorMsg),
        tap(({ message }) => this._notify.showError({ message }))
      );
    },
    { dispatch: false }
  );

  showBasicWarningMsg$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(AppActions.showBasicWarningMsg),
        tap(({ message }) => this._notify.showWarning({ message }))
      );
    },
    { dispatch: false }
  );

  showBasicSuccessMsg$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(AppActions.showBasicSuccessMsg),
        tap(({ message }) => this._notify.showSuccess({ message }))
      );
    },
    { dispatch: false }
  );

  showErrorMsg$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(AppActions.showErrorMsg),
        tap(({ config }) => this._notify.showError(config))
      );
    },
    { dispatch: false }
  );

  showDisconnectMsg$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AppActions.showDisconnectMsg),
      switchMap(() =>
        this._store
          .select(WorkspaceSelectors.selectCurrentWorkspaceAvailability)
          .pipe(
            first(),
            map(state =>
              AppActions.showErrorMsg({
                config: { message: getErrorMessage(state) },
              })
            )
          )
      )
    );
  });

  disconnectFromWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AppActions.disconnectFromWorkspace),
      mergeMap(() => [
        RouterActions.gotoWorkspaceManager(),
        AppActions.showDisconnectMsg(),
      ])
    );
  });

  closeAllOverlays$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(AppActions.closeAllOverlays),
        tap(() => {
          this._dialog.closeAll();
        })
      );
    },
    { dispatch: false }
  );

  getAppVersion$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.loginSuccess, AppActions.getAppVersion),
      switchMap(() => this._statusService.getCoreVersion()),
      tap(code_version => this._rollbar?.configure({ code_version })),
      map(version => AppActions.getAppVersionSuccess({ version }))
    );
  });

  initTheme$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      concatMap(() => this._store.select(AppSelectors.selectAppTheme)),
      map(theme => AppActions.updateTheme({ theme }))
    );
  });

  setTheme$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(AppActions.updateTheme, AppActions.toggleTheme),
        concatMap(() => this._store.select(AppSelectors.selectAppTheme)),
        tap(theme => this._themeService.setTheme(theme))
      );
    },
    { dispatch: false }
  );

  togglePresenting$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AppActions.togglePresenting),
      mergeMap(() => [
        WorkspaceActions.getDomainModels(),
        WorkspaceActions.refreshProjectsCache(),
        WorkspaceActions.loadWorkspaces(),
        ProjectActions.loadProjects(),
      ])
    );
  });
}
