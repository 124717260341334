import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { Store } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { saveAs } from 'file-saver';
import { first, switchMap, throwError } from 'rxjs';

import { DownloadGenerateResult } from '../../models/task.model';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  constructor(
    private _http: HttpClient,
    private _store: Store,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  onDownloadFinished({ downloadPath, downloadName }: DownloadGenerateResult) {
    this._store
      .select(WorkspaceSelectors.selectWorkspaceId)
      .pipe(
        first(),
        switchMap(workspaceId =>
          this._http.get(
            `${this._config.resourcePaths.workspace}/${workspaceId.name}/downloadPrepared//${downloadPath}`,
            { responseType: 'blob' }
          )
        )
      )
      .subscribe(
        data => saveAs(data, downloadName),
        () => throwError(() => 'Error downloading daml')
      );
  }
}
