import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { getDialogOptions, IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { WorkspaceApiService } from '@core/services';
import { FileTypeService } from '@features/workspace/components/create-namespace-dialog/file-type.service';
import {
  DialogComponent,
  FileType,
  FileTypeEnum,
  NamespaceForm,
  NamespaceFormRequest,
  WorkspaceItem,
} from '@models';
import { Store } from '@ngrx/store';
import * as WorkspaceActions from '@store/workspace/actions';
import { createWorkspaceItemState } from '@store/workspace/reducers/workspace.reducers.helper';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { DirtyFieldErrorMatcher, fistEnumValue } from '@utils';
import {
  BehaviorSubject,
  filter,
  first,
  map,
  merge,
  Observable,
  startWith,
  Subscription,
  switchMap,
  takeWhile,
} from 'rxjs';

const PREVIEW_PLACEHOLDER = '[ namespace ]';

@Component({
  selector: 'app-create-namespace-dialog',
  templateUrl: './create-namespace-dialog.component.html',
  styleUrls: ['./create-namespace-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FileTypeService],
})
export class CreateNamespaceDialogComponent
  implements DialogComponent, OnInit, OnDestroy
{
  constructor(
    private _store: Store,
    private _fb: FormBuilder,
    private _dialogRef: MatDialogRef<ChangeDetectionStrategy>,
    private _workspaceApiService: WorkspaceApiService,
    private _fileTypeService: FileTypeService,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  form!: NamespaceForm;
  fileTypes!: FileType[];
  namespace$!: Observable<string>;

  errorMatcher = new DirtyFieldErrorMatcher();

  isSubmitting$ = new BehaviorSubject<boolean>(false);
  isFailed$ = new BehaviorSubject<string | null>(null);
  modelShortname$ = this._store
    .select(WorkspaceSelectors.selectWorkspaceInfo)
    .pipe(
      first(),
      map(info => info.modelShortname.toLowerCase())
    );

  private _sub = new Subscription();

  static options(): MatDialogConfig {
    return getDialogOptions('sm', {
      disableClose: true,
    });
  }

  ngOnInit() {
    this.fileTypes = this._fileTypeService.fileType;
    this._customCloseListeners();
    this._initForm();
    this.namespace$ = this.form.valueChanges.pipe(
      startWith({}),
      switchMap(changes =>
        this.modelShortname$.pipe(
          map(shortname => this._getNamespace(changes, shortname))
        )
      )
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  createNamespace() {
    if (this.form.invalid) {
      return;
    }

    this.form.disable({ emitEvent: false });
    this.isSubmitting$.next(true);

    this._createNamespaceRequest()
      .pipe(
        switchMap(payload =>
          this._workspaceApiService.createNewNamespaceFile(payload)
        ),
        switchMap(item => this._createWorkspaceItemState(item))
      )
      .subscribe({
        next: workspaceItem => {
          this._store.dispatch(
            WorkspaceActions.addWorkspaceItem({ workspaceItem })
          );

          this.isSubmitting$.next(false);
          this._dialogRef.close();
        },
        error: err => {
          this.isFailed$.next(err.error.message);
          this.isSubmitting$.next(false);
          this.form.enable({ emitEvent: false });
          return err;
        },
      });
  }

  private _createWorkspaceItemState(item: WorkspaceItem) {
    return this.modelShortname$.pipe(
      map(modelShortname => createWorkspaceItemState(item, modelShortname))
    );
  }

  private _createNamespaceRequest(): Observable<NamespaceFormRequest> {
    const namespace = this.form.get('namespace')?.value;
    const fileType = this.form.get('fileType')?.value;

    if (!namespace || !fileType) {
      throw new Error('');
    }

    return this.modelShortname$.pipe(
      map(modelShortname => ({
        namespace: namespace.replace(modelShortname + '.', ''),
        fileType,
      }))
    );
  }

  private _initForm() {
    this.form = this._fb.group({
      namespace: this._fb.control('', [
        Validators.required,
        Validators.pattern(this._config.regex.newNamespace),
      ]),
      fileType: this._fb.nonNullable.control(
        fistEnumValue(FileTypeEnum),
        Validators.required
      ),
    });

    this._sub.add(
      this.form.valueChanges.subscribe(() => this.isFailed$.next(null))
    );
  }

  private _customCloseListeners() {
    const mouseClick$ = this._dialogRef.backdropClick();
    const escapeClick$ = this._dialogRef
      .keydownEvents()
      .pipe(filter(event => event.key === 'Escape'));

    this._sub.add(
      merge(mouseClick$, escapeClick$)
        .pipe(takeWhile(() => this.isSubmitting$.value))
        .subscribe(() => this._dialogRef.close())
    );
  }

  private _getNamespace(
    changes: Partial<{ namespace: string | null; fileType: string }>,
    shortname: string
  ): string {
    const namespace = changes.namespace || PREVIEW_PLACEHOLDER;
    return namespace && namespace.startsWith(shortname + '.')
      ? namespace
      : `${shortname}.${namespace}`;
  }
}
