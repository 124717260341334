import { IMdStepOption } from 'ngx-ui-tour-md-menu/lib/step-option.interface';

export const transformTourSteps: IMdStepOption[] = [
  {
    stepId: 'transform-1',
    anchorId: 'transform.add-sample',
    title: 'Adding Samples to a Test Pack',
    content: `Samples will be added to the selected Pipeline and Test Pack,
              appearing in all connected ones. Deleting a sample removes it
              from all connected Pipelines and Test Packs.`,
    isAsync: true,
  },
];
