import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModelLogoPipeModule } from '../model-logo-pipe/model-logo.pipe.module';
import { ProjectLogoComponent } from './project-logo.component';

@NgModule({
  declarations: [ProjectLogoComponent],
  imports: [CommonModule, ModelLogoPipeModule],
  exports: [ProjectLogoComponent],
})
export class ProjectLogoModule {}
