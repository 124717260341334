import { Inject, Injectable } from '@angular/core';
import { LoadingState } from '@app/models';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { NotificationService } from '@core/modules/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppActions, WebsocketActions } from '@store/.';
import { TaskSelectors } from '@store/selectors';
import {
  TranslateActions,
  TranslateSelectors,
} from '@workspace-engine/translate/store';
import { concatMap, first, map, mergeMap, switchMap, tap } from 'rxjs';
import { TranslateApiService } from '../../services/translate-api.service';
import { TranslateRunService } from '../../services/translate-run.service';
import { TranslateResultsStorageService } from '../../services/translate-storage';
import { normalizeSampleFileFactory } from './effects.helper';

@Injectable()
export class TranslateEffects {
  constructor(
    private _store: Store,
    private _actions$: Actions,
    private _translateRunService: TranslateRunService,
    private _translateApiService: TranslateApiService,
    private _resultsStorage: TranslateResultsStorageService,
    private _notify: NotificationService,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  // API
  getSampleFiles$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(TranslateActions.panelOpen),
      switchMap(() =>
        this._store
          .select(TranslateSelectors.selectState)
          .pipe(first(state => state !== LoadingState.LOADED))
      ),
      mergeMap(() => [
        TranslateActions.loadEnvironmentConfig(),
        TranslateActions.getAllSampleFiles(),
      ])
    );
  });

  loadSampleFiles$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(TranslateActions.getAllSampleFiles),
      switchMap(() =>
        this._translateApiService.get().pipe(
          first(),
          map(responseData =>
            TranslateActions.sampleFilesLoaded({
              payload: normalizeSampleFileFactory(responseData),
            })
          )
        )
      )
    );
  });

  // Websocket Messages
  runSampleFile$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(TranslateActions.runSampleFiles),
      concatMap(nodeIds =>
        this._store.select(TaskSelectors.hasErrors).pipe(
          first(),
          map(hasErrors => {
            if (hasErrors) {
              return AppActions.showBasicErrorMsg({
                message: this._config.text.ingestionCannotRun,
              });
            }
            return TranslateActions.startSampleFiles(nodeIds);
          })
        )
      )
    );
  });

  startSampleFiles$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(TranslateActions.startSampleFiles),
        concatMap(({ nodeIds }) =>
          this._store
            .select(
              TranslateSelectors.getTranslateIngestionFilesForRun(nodeIds)
            )
            .pipe(
              first(),
              concatMap(translateIngestionFiles =>
                this._translateRunService.requestRun(translateIngestionFiles)
              )
            )
        )
      );
    },
    { dispatch: false }
  );

  deleteSampleFile$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(TranslateActions.deleteSampleFile),
      switchMap(({ node }) =>
        this._translateApiService
          .delete(node)
          .pipe(
            map(({ id }) => TranslateActions.deleteSampleFileSuccess({ id }))
          )
      )
    );
  });

  ingestionTestRunFinished$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WebsocketActions.ingestionTestRunFinished),
      concatMap(({ payload }) => this._resultsStorage.set(payload)),
      map(payload => WebsocketActions.ingestionTestRunResultsSaved({ payload }))
    );
  });

  translateShowErrors$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(
          WebsocketActions.translateIngestionRunFinished,
          WebsocketActions.translateIngestionRunErrored,
          WebsocketActions.translateGenerateAndCompileFinished,
          WebsocketActions.ingestionTestRunFinished
        ),
        tap(({ payload }) => {
          if (!payload.errors) {
            return;
          }
          this._notify.showError({ message: payload.errors.userMessage });
        })
      );
    },
    { dispatch: false }
  );

  handleFileUploadError$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(TranslateActions.sampleFileUploadError),
        tap(({ message }) => {
          this._notify.showError({ message });
        })
      );
    },
    { dispatch: false }
  );

  getEnvironmentMap$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(TranslateActions.loadEnvironmentConfig),
      switchMap(() =>
        this._translateApiService.getEnvironmentConfig().pipe(
          map(environmentConfig =>
            TranslateActions.loadEnvironmentConfigSuccess({
              environmentConfig,
            })
          )
        )
      )
    );
  });
}
