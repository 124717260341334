import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DaysUntilDatePipe } from '@shared/pipes/days-until-date.pipe';
import { RosettaTitleCasePipe } from '@shared/pipes/rosetta-title-case.pipe';
import { RosettaMenuComponent } from '../rosetta-menu.component';
import { RosettaOverlayModule } from '../rosetta-overlay/rosetta-overlay.modules';
import { TextBadgeModule } from '../text-badge/text-badge.module';
import { LogoComponent } from './logo.component';

@NgModule({
  declarations: [LogoComponent],
  imports: [
    CommonModule,
    TextBadgeModule,
    RosettaOverlayModule,
    RosettaMenuComponent,
    RosettaTitleCasePipe,
    DaysUntilDatePipe,
  ],
  exports: [LogoComponent],
})
export class LogoModule {}
