<div class="feature-logo__wrapper">
  <ng-content select="[feature-logo]" />
</div>

<nav mat-tab-nav-bar [tabPanel]="tabPanel">
  <ng-container *ngFor="let key of tabKeys">
    <div class="tab-group" *ngIf="getTabs(key) as tabs">
      <ng-container *ngFor="let tab of tabs">
        <ng-template #tabTemplate>
          <div
            mat-tab-link
            [disabled]="
              allTabsDisabled || (isTabDisabled(tab, $contribution) | async)
            "
            [active]="activeTabName === tab.name"
            (click)="onClickTab(tab)"
            (keyup.enter)="onClickTab(tab)"
            (keyup.space)="onClickTab(tab)"
          >
            <a class="tab-content">
              <fa-icon [icon]="tab.icon" />
              <span>{{ tab.label }}</span>
            </a>
          </div>
        </ng-template>

        <ng-template #tabLabs>
          <ng-container *ngIf="tab.params?.labsFeature; else tabTemplate">
            <ng-container
              *appLabsFeature="tab.params.labsFeature"
              [ngTemplateOutlet]="tabTemplate"
            />
          </ng-container>
        </ng-template>

        <span
          *ngIf="tab.tourAnchor; else tabLabs"
          [tourAnchor]="tab.tourAnchor"
        >
          <ng-container [ngTemplateOutlet]="tabLabs" />
        </span>
      </ng-container>
    </div>
  </ng-container>
</nav>
<mat-tab-nav-panel #tabPanel />
<ng-content />
