<header mat-dialog-title>
  <h2>Add sample</h2>
  <div class="spacer"></div>
  <button mat-icon-button mat-dialog-close tabindex="-1">
    <fa-icon icon="times" />
  </button>
</header>

<!--
  Extra wrapper required to prevent default style to
  remove top padding when content is next to the title.
-->
<div>
  <mat-dialog-content>
    <app-inline-error
      class="mb-16 spacer"
      @inOutAnimation
      *ngIf="errorMsg$ | async; let errorMsg"
      >{{ errorMsg }}</app-inline-error
    >

    <div
      class="width-full mb-4 flex-row-center file-upload-wrapper theme-bg-light"
    >
      <app-file-upload
        (fileDropped)="onFileDropped($event)"
        label="Select File"
        icon="file-alt"
        class="theme-bg mr-8"
        [uploadType]="'REST'"
        [accept]="fileType"
      />

      <p class="width-full text-ellipsis mb-0">
        <ng-template #noContent>No file selected</ng-template>
        <ng-container *ngIf="file; else noContent">
          <strong>{{ file.name }}</strong>
        </ng-container>
      </p>
    </div>
    <div class="flex-space-between mb-24 pl-4 pr-4">
      <small>File format: {{ fileType }}</small>
      <small>Max size: {{ maxFileSizeMb }} MB</small>
    </div>

    <div class="field-row mb-16">
      <mat-form-field>
        <mat-label>{{ pipelineLabel }}</mat-label>
        <input
          matInput
          readonly
          [value]="selection.pipelineDef.name"
          [title]="selection.pipelineDef.name"
        />
      </mat-form-field>

      <mat-form-field>
        <mat-label>{{ testPackLabel }}</mat-label>
        <input
          matInput
          readonly
          [value]="selection.testPackDef.name"
          [title]="selection.testPackDef.name"
        />
      </mat-form-field>
    </div>

    <mat-form-field class="mb-16">
      <mat-label>Input type</mat-label>
      <input
        matInput
        readonly
        [value]="selection.pipelineDef.transformInputType"
        [title]="selection.pipelineDef.transformInputType"
      />
    </mat-form-field>

    <mat-form-field class="mb-16">
      <mat-label>Sample name</mat-label>
      <input matInput required [(ngModel)]="sampleName" />
      <fa-icon class="pr-16" matIconSuffix icon="pen" size="sm" />
    </mat-form-field>

    <app-data-storage-warning
      class="spacer pr-16"
      [serverStorage]="('readOnly' | getWorkspaceInfo | async) === false"
    />
  </mat-dialog-content>
</div>

<mat-dialog-actions>
  <button mat-button mat-dialog-close>Cancel</button>
  <button
    mat-stroked-button
    [disabled]="(canAddSample$ | async) === false"
    (click)="onAddSample()"
  >
    <fa-icon icon="plus" />
    Add sample
  </button>
</mat-dialog-actions>
