<ng-scrollbar
  [appScrollToElement]="scrollToElementSelector"
  [scrollOptions]="scrollOptions"
>
  <ul role="list" class="body-group no-list-style">
    <li role="listitem" *ngFor="let group of bodyCorpus">
      <h3 class="theme-border-bottom">
        {{ group.body }}
      </h3>
      <ul role="list" class="body-corpus-group no-list-style">
        <li
          role="listitem"
          tabindex="1"
          class="theme-border-bottom"
          *ngFor="let corpusGroup of group.corpusGroups"
          (click)="onClick(corpusGroup.id)"
          [attr.id]="corpusGroup?.id"
          [ngClass]="{
            'active theme-color-primary-alt':
              selectedBodyCorpusGroupId === corpusGroup.id,
          }"
        >
          <span>{{ corpusGroup.corpusList.join(' / ') }}</span>
        </li>
      </ul>
    </li>
  </ul>
</ng-scrollbar>
