import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { StorageMap } from '@ngx-pwa/local-storage';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import {
  filter,
  first,
  map,
  mergeMap,
  Observable,
  switchMap,
  toArray,
} from 'rxjs';

export abstract class BaseStorageService<T> {
  readonly namespace!: string;

  protected _storage = inject(StorageMap);
  protected _store = inject(Store);

  getAll(startsWith?: string): Observable<T[]> {
    return this._getNamespaceKey(startsWith).pipe(
      switchMap(namespaceKey =>
        this._storage.keys().pipe(
          filter(key => key.indexOf(namespaceKey) > -1),
          mergeMap(key => this._storage.get(key) as Observable<T>),
          toArray<T>()
        )
      )
    );
  }

  protected _set(id: string, payload: T): Observable<T> {
    return this._getNamespaceKey(id).pipe(
      switchMap(key => this._storage.set(key, payload).pipe(map(() => payload)))
    );
  }

  protected _update(id: string, update: Partial<T>): Observable<T> {
    return this._getNamespaceKey(id).pipe(
      switchMap(key =>
        this._storage.get(key).pipe(
          switchMap((item: any) => {
            const data = { ...item, ...update };
            return this._storage.set(key, data).pipe(map(() => data));
          })
        )
      )
    );
  }

  get(id: string): Observable<T> {
    return this._getNamespaceKey(id).pipe(
      switchMap(key => this._storage.get(key) as Observable<T | undefined>)
    );
  }

  delete(id: string): Observable<undefined> {
    return this._getNamespaceKey(id).pipe(
      switchMap(key => this._storage.delete(key))
    );
  }

  has(id: string): Observable<boolean> {
    return this._getNamespaceKey(id).pipe(
      switchMap(key => this._storage.has(key))
    );
  }

  clear(): Observable<undefined> {
    return this._storage.keys().pipe(
      filter(key => key.indexOf(this._getNamespace) > -1),
      mergeMap(key => this._storage.delete(key)),
      first()
    );
  }

  forceClearAll(): Observable<undefined> {
    return this._storage.clear();
  }

  private get _getNamespace() {
    return `__${this.namespace}__`;
  }

  private _getNamespaceKey(id = ''): Observable<string> {
    return this._store.pipe(
      WorkspaceSelectors.getWorkspaceId,
      first(),
      map(workspaceId => `__${workspaceId.name}${this._getNamespace}${id}`)
    );
  }
}
