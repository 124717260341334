<ng-template #titleTmp let-node>
  <app-anchor-fragment
    [appCheckFragmentClick]="node.id"
    [fragment]="node.id"
    class="segment"
  >
    <ng-container *ngIf="node.data; else nodeId">
      <strong class="segment-type">{{ node.data.segmentType }}</strong
      >&nbsp;
      <span class="segment-value">{{ node.data.segment }}</span>
    </ng-container>
  </app-anchor-fragment>

  <ng-template #nodeId>
    <h5 class="group-heading sub-heading mb-4 mt-4">
      {{ node.id | docRefTargetDisplayName }}
    </h5>
  </ng-template>
</ng-template>

<div class="contents-wrapper">
  <header class="contents-header theme-bg-light">
    <h2 class="mb-0">Segments</h2>
  </header>

  <ng-scrollbar
    *appShowSpinner="segmentTree; flexCentre: true"
    [appScrollToElement]="scrollToElementSelector"
    [scrollOptions]="scrollOptions"
    class="contents-body"
  >
    <mat-tree
      [dataSource]="dataSource"
      [treeControl]="treeControl"
      class="file-tree small-button-wrapper pt-8 pb-8"
    >
      <!-- Template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node">
        <div
          class="leaf-node"
          [ngClass]="node.classes"
          [class.selected]="node.id === selectedNode"
        >
          <ng-container
            *ngTemplateOutlet="titleTmp; context: { $implicit: node }"
          />
        </div>
      </mat-tree-node>

      <!-- Template for expandable nodes -->
      <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
        <div
          class="tree-node-item"
          [ngClass]="node.classes"
          [class.selected]="node.id === selectedNode"
          matTreeNodeToggle
        >
          <ng-container
            *ngTemplateOutlet="titleTmp; context: { $implicit: node }"
          />
        </div>

        <div class="tree-node-group" role="group">
          <ng-container matTreeNodeOutlet />
        </div>
      </mat-nested-tree-node>
    </mat-tree>
  </ng-scrollbar>
</div>
