import { DocRefSegment } from '@models';
import { naturalSort, normalize } from '@utils';
import {
  BodyCorpus,
  BodyCorpusDTO,
  BodyCorpusInfo,
  DocRefSegmentIdDTO,
  DocRefTarget,
  ProvisionDocRefTargetGroup,
  ProvisionSegmentGroup,
  RegTreeNode,
  SegmentProvisionDetail,
  UNNAMED_SEGMENT,
  UNNAMED_SEGMENT_PREFIX,
} from '../models';

export const segmentsAreEqual = (
  a: DocRefSegment,
  b: DocRefSegment
): boolean => {
  return (
    normalize(a?.segment) === normalize(b?.segment) &&
    normalize(a?.segmentType) === normalize(b?.segmentType)
  );
};

export const fromBodyCorpusDTO = (dto: BodyCorpusDTO): BodyCorpus => {
  const bodyCorpus = dto.bodyCorpusGroups.map(bodyGroup => {
    const body = bodyGroup.body;
    const id = getBodyGroupId({ body, corpusList: [] });
    const corpusGroups = bodyGroup.corpusGroups.map(({ corpusList }) => {
      return {
        id: getCorpusGroupId({ body, corpusList }),
        corpusList,
      };
    });
    return { id, body, corpusGroups: naturalSort(corpusGroups, 'id') };
  });

  return naturalSort(bodyCorpus, 'id');
};

export const getBodyGroupId = (bodyCorpusInfo: BodyCorpusInfo): string => {
  return bodyCorpusInfo.body;
};

export const getCorpusGroupId = (bodyCorpusInfo: BodyCorpusInfo): string => {
  return bodyCorpusInfo.body + '_' + bodyCorpusInfo.corpusList.join('_');
};

export const createSegmentId = (segmentId: Partial<DocRefSegmentIdDTO>) => {
  return (
    (segmentId.segments?.length ? '' : UNNAMED_SEGMENT_PREFIX) +
    [
      segmentId.docRefTarget,
      ...(segmentId.segments || []).map(s => `${s.segmentType} ${s.segment}`),
    ]
      .join('-')
      // Replace spaces and non-alphanumeric characters with a single hyphen
      .replace(/\s+|[^a-zA-Z0-9]+/g, '-')
      // Replace camel case with hyphenated words
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      // Replace consecutive hyphens with a single hyphen
      .replace(/-+/g, '-')
      // Remove leading and trailing hyphens
      .replace(/^(-+)$/g, '')
      .toLowerCase()
  );
};

export const createPathSegmentTree = (
  segmentIds: DocRefSegmentIdDTO[]
): RegTreeNode => {
  const root: RegTreeNode = { id: 'root', children: [] };
  const checkNode = (
    id: string,
    node: RegTreeNode,
    data?: any,
    classes?: string[]
  ) => {
    const existingNode = node.children?.find(n => n.id === id);

    if (existingNode) {
      return existingNode;
    } else {
      const newNode: RegTreeNode = {
        id,
        children: [],
      };

      if (data) {
        newNode['data'] = data;
      }

      if (classes) {
        newNode['classes'] = classes;
      }

      node.children = naturalSort(
        [newNode, ...(node.children || [])],
        'id',
        UNNAMED_SEGMENT_PREFIX
      );
      return newNode;
    }
  };

  segmentIds.forEach(segmentId => {
    const ruleType = segmentId.docRefTarget;
    let currentNode = root;

    currentNode = checkNode(ruleType.toLowerCase(), currentNode);

    if (segmentId.segments.length === 0) {
      const segmentPath = createSegmentId({
        docRefTarget: ruleType,
      });
      currentNode = checkNode(segmentPath, currentNode, UNNAMED_SEGMENT, [
        'unknown-segment font-italic',
      ]);
      return;
    }

    segmentId.segments.forEach((segment, idx, array) => {
      const segmentPath = createSegmentId({
        docRefTarget: ruleType,
        segments: array.slice(0, idx + 1),
      });
      currentNode = checkNode(segmentPath, currentNode, segment);
    });
  });

  return root;
};

export const createProvisionDetailGroups = (
  provisionDetails: SegmentProvisionDetail[]
): ProvisionDocRefTargetGroup[] => {
  const docRefTargetMap = new Map<DocRefTarget, ProvisionSegmentGroup[]>();

  for (const provisionDetail of provisionDetails) {
    const docRefTarget = provisionDetail?.segmentId?.docRefTarget;
    const provisionSegmentGroupList: ProvisionSegmentGroup[] =
      docRefTargetMap.get(docRefTarget) || [];
    const groupBySegment =
      provisionDetail?.segmentId?.segments[0] || UNNAMED_SEGMENT;

    // Check segment has already been added to group
    const segmentIndex = provisionSegmentGroupList?.findIndex(
      o => o.segment && segmentsAreEqual(o.segment, groupBySegment)
    );

    if (segmentIndex >= 0) {
      provisionSegmentGroupList[segmentIndex].segmentProvisionDetails.push(
        provisionDetail
      );
    } else {
      provisionSegmentGroupList?.push({
        id: createSegmentId({
          ...provisionDetail?.segmentId,
          segments: provisionDetail?.segmentId.segments.slice(0, 1),
        }),
        segment: groupBySegment,
        segmentProvisionDetails: [provisionDetail],
      });
    }

    docRefTargetMap.set(docRefTarget, provisionSegmentGroupList || []);
  }

  const provisions: ProvisionDocRefTargetGroup[] = [];
  docRefTargetMap.forEach((provisionSegmentGroups, docRefTarget) => {
    const sortedSegments = naturalSort(
      provisionSegmentGroups,
      'id',
      UNNAMED_SEGMENT_PREFIX
    );
    provisions.push({ docRefTarget, provisionSegmentGroups: sortedSegments });
  });

  return naturalSort(provisions, 'docRefTarget');
};
