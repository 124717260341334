import {
  AgGridEvent,
  CellClassRules,
  ColumnApi,
  GridApi,
  RowNode,
} from '@ag-grid-community/core';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { SampleRowState } from '@shared/modules/transform/models/sample-row-state.enum';
import { CHECKBOX_ID } from '../col-defs/checkbox-col-defs';
import { SAMPLE_NAME_FIELD } from '../col-defs/sample-name-col-defs';

export const isRowSelectable = <T extends { data: transform.DataViewerRow }>(
  node: T
): boolean => {
  return node.data?.selectable || false;
};

export const hasChangesUtil = <T extends { data: transform.DataViewerRow }>(
  node: T
): boolean => node.data?.hasChanges || false;

export const cellClassRulesUtil =
  (): CellClassRules<transform.DataViewerRow> => ({
    // TODO: this might be misused as it looks like its a row when really its a cell or both depending on context
    'ros-cell-diff': ({ data }) => data?.sampleRowState === SampleRowState.Diff,
    'ros-cell-updated': ({ data }) =>
      data?.sampleRowState === SampleRowState.Updated,
  });

export const checkShowNoRowsOverlay = ({
  api,
}: AgGridEvent<transform.DataViewerRow>): void => {
  if (api.getDisplayedRowCount() === 0) {
    api.showNoRowsOverlay();
  } else {
    api.hideOverlay();
  }
};

export const setSelectableRowCount = ({
  api,
  context,
}: AgGridEvent<transform.DataViewerRow, transform.DataViewerContext>) => {
  let selectableRowsCount = 0;
  api.forEachNode(o => {
    selectableRowsCount += o.selectable ? 1 : 0;
  });
  context.selectableRowCount = selectableRowsCount;
};

export const autoResizeColumnWithChanges = ({
  columnApi,
  context,
}: AgGridEvent<transform.DataViewerRow, transform.DataViewerContext>): void => {
  columnApi.autoSizeColumns(
    context.dataViewer?.columns
      .filter(col => !col.hidden && col.hasChanges)
      .map(col => col.headerName) || []
  );
};

export const shouldDisplayCheckboxes = (
  api: GridApi<transform.DataViewerRow>,
  columnApi: ColumnApi
): void => {
  let rowWithChangesCount = 0;
  api.getModel().forEachNode((node: RowNode<transform.DataViewerRow>) => {
    if (node.displayed && isRowSelectable(node)) {
      rowWithChangesCount++;
    }
  });
  columnApi.setColumnVisible(CHECKBOX_ID, rowWithChangesCount > 0);
};

export const checkAllSelectedRowsAreSelectable = (
  api: GridApi<transform.DataViewerRow>
): void => {
  api.setNodesSelected({
    nodes: api
      .getSelectedNodes()
      .filter(rowNode => rowNode.data?.selectable || !rowNode.displayed),
    newValue: false,
  });
};

export const autoSizeSampleNameColumn = ({
  columnApi,
  context,
}: AgGridEvent<transform.DataViewerRow, transform.DataViewerContext>): void => {
  if (context.initialDataLoaded && !context.hasSampleNameBeenResized) {
    context.hasSampleNameBeenResized = true;
    setTimeout(() => {
      columnApi?.autoSizeColumn(SAMPLE_NAME_FIELD);
    });
  }
};

export const setState = (
  dataViewer: null | transform.DataViewer
): transform.DateViewState => {
  if (!dataViewer) {
    return transform.DateViewState.Empty;
  }

  if (dataViewer.rows.length === 0) {
    return transform.DateViewState.NoData;
  }

  return transform.DateViewState.HasData;
};
