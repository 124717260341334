import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { WorkspaceApiService } from '@core/services/workspaces/workspace-api.service';
import { PackagePlanDetails } from '@features/auth/login/models/login.model';
import { ISubscriptionFormData } from '@features/documents/models';
import {
  AppDocuments,
  IUserDocumentResponse,
  PackagePlan,
  Task,
} from '@models';
import { catchError, map, Observable, of, switchMap, zip } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private _wsApi: WorkspaceApiService,
    private _http: HttpClient,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  submitServerError(payload: Record<string, any>): Observable<string> {
    return this._wsApi.loadCurrentWorkspaceName().pipe(
      switchMap(name =>
        this._http.post(
          `${this._config.resourcePaths.workspace}/${name}/errors`,
          {
            ...payload,
            userAgent: navigator.userAgent,
          },
          { responseType: 'text' }
        )
      )
    );
  }

  getSupportedTasks(): Observable<Task[]> {
    return this._http.get<Task[]>(
      `${this._config.resourcePaths.task}/supported-tasks`
    );
  }

  getDocument(documentName: AppDocuments): Observable<IUserDocumentResponse> {
    return this._http.get<IUserDocumentResponse>(
      `${this._config.resourcePaths.user}/documents/${documentName}`
    );
  }

  getSubscriptionForm(): Observable<ISubscriptionFormData> {
    return this._http.get<ISubscriptionFormData>(
      `${this._config.resourcePaths.packagePlans}/${PackagePlan.Community}`
    );
  }

  getUserSubscriptionForm(): Observable<ISubscriptionFormData> {
    return zip(
      this._http.get<Omit<ISubscriptionFormData, 'PackagePlanDetails'>>(
        `${this._config.resourcePaths.user}/package-plans`
      ),
      this._http
        .get<PackagePlanDetails>(
          `${this._config.resourcePaths.userServer}/current-package-plan`
        )
        .pipe(catchError(() => of(null)))
    ).pipe(
      map(([data, packagePlanDetails]) => ({ ...data, packagePlanDetails }))
    );
  }
}
