import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
} from '@angular/core';
import {
  IDomainModelDetails,
  WorkspaceCommands,
  WorkspaceMenuItem,
} from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { WA_WINDOW } from '@ng-web-apis/common';
import { Store } from '@ngrx/store';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { BehaviorSubject, distinctUntilChanged, first, map } from 'rxjs';

@Component({
  selector: 'app-workspace-dropdown',
  templateUrl: './workspace-dropdown.component.html',
  styleUrls: ['./workspace-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'mat-elevation-z5',
  },
})
export class WorkspaceDropdownComponent {
  constructor(
    private _store: Store,
    @Inject(WA_WINDOW) private _window: Window
  ) {}

  @Output() action = new EventEmitter<WorkspaceCommands>();

  @Input()
  set data(workspaceData: WorkspaceMenuItem | undefined) {
    if (workspaceData) {
      this._workspaceData = workspaceData;
      this.setModel(workspaceData.modelId);
    }
  }
  get data(): WorkspaceMenuItem | undefined {
    return this._workspaceData;
  }

  private _workspaceData: WorkspaceMenuItem | undefined;

  workspaceReady$ = this._store
    .select(WorkspaceSelectors.isWorkspaceLoading)
    .pipe(map(workspaceLoading => workspaceLoading === false));
  model$ = new BehaviorSubject<IDomainModelDetails | undefined>(undefined);
  noModifiedFiles$ = this._store
    .select(WorkspaceSelectors.selectedModifiedWorkspaceItems)
    .pipe(
      map(itemStates => itemStates.length === 0),
      distinctUntilChanged()
    );

  get mode() {
    return this.data?.readOnly ? 'Read Only' : 'Editable';
  }

  setModel(modelId: ModelInstanceId) {
    this._store
      .select(WorkspaceSelectors.getDomainModel(modelId))
      .pipe(first())
      .subscribe(model => this.model$.next(model));
  }

  gotoWorkspaceManager() {
    this._store.dispatch(WorkspaceActions.navigateToWorkspaces());
  }

  openInGithub(url: string) {
    this._window.open(url, '_blank');
  }
}
