import { FuncDetails, Task, TaskStatus } from '@models';
import { ensureArray } from '@utils';
import { BehaviorSubject, map, Observable, ReplaySubject } from 'rxjs';

export interface CdmWorkflowObject {
  steps: object[] | undefined;
}

export interface TestCase {
  group: string;
  name: string;
  markdown?: string;
  executionType: ExecutionType;
}

export const VALID_ROOT_TYPES = {
  WorkflowStep: 'cdm.event.workflow.WorkflowStep',
  TradeState: 'cdm.event.common.TradeState',
} as const;

export const CUSTOM_CDM_GRAPH = 'CUSTOM_CDM_GRAPH';

export interface DroppedFileMap {
  [name: string]: object[] | object;
}

export class VisualisationError {
  constructor(
    readonly userMessage: string,
    readonly supportMessage: string[],
    readonly causedByTask: Task
  ) {}
}

export type ExecutionType =
  | 'CUSTOM_FUNCTION'
  | /* Run Custom function */ 'WORKFLOW_DESCRIPTOR'
  | /* Run pre-defined workflow */ 'FUNCTION_DESCRIPTOR' /* Run pre-defined function descriptor */;

export class Visualisation {
  constructor(
    public readonly functionName: string,
    public readonly sampleFileName: string,
    public readonly executionType: ExecutionType,
    public readonly markDown?: string
  ) {}

  readonly cdmObject$ = new ReplaySubject<CdmWorkflowObject | null>(1);
  readonly graph$ = new ReplaySubject<any>(1);
  readonly error$ = new ReplaySubject<VisualisationError | null>(1);
  readonly status$ = new BehaviorSubject<TaskStatus>(TaskStatus.Pending);

  reset() {
    this.cdmObject$.next(null);
    this.graph$.next(null);
    this.error$.next(null);
    this.status$.next(TaskStatus.Pending);
  }

  setCdmObject(cdmObject: CdmWorkflowObject) {
    this.cdmObject$.next(cdmObject);
  }

  setGraph(graph: any) {
    this.graph$.next(graph);
  }

  setError(error: VisualisationError) {
    this.status$.next(TaskStatus.Error);
    this.error$.next(error);
  }

  setStatus(status: TaskStatus) {
    this.status$.next(status);
  }

  isRunning(): Observable<boolean> {
    return this.status$.pipe(map(x => x === TaskStatus.Started));
  }
}

export class CustomCdmVisualisation {
  public graph: any;
  public error: VisualisationError | null = null;
  readonly status$ = new BehaviorSubject<TaskStatus>(TaskStatus.Pending);

  constructor(public droppedFiles: DroppedFileMap) {}

  getMergedFiles(): object[] {
    return this._mergeFiles(this.droppedFiles);
  }

  updateDroppedFilesIfChanged(newDroppedFiles: DroppedFileMap): boolean {
    let changed = false;

    if (!this._keysEqual(this.droppedFiles, newDroppedFiles)) {
      changed = true;
      this.droppedFiles = { ...newDroppedFiles };
    }
    {
      const keys = Object.keys(this.droppedFiles);
      keys.forEach(key => {
        const currentFile = this.droppedFiles[key];
        const newFile = newDroppedFiles[key];
        if (JSON.stringify(currentFile) !== JSON.stringify(newFile)) {
          changed = true;
          this.droppedFiles[key] = newFile;
        }
      });
    }

    return changed;
  }

  containsFiles(): boolean {
    return Object.keys(this.droppedFiles).length > 0;
  }

  private _keysEqual(
    droppedFileMapA: DroppedFileMap,
    droppedFileMapB: DroppedFileMap
  ): boolean {
    const keysA = Object.keys(droppedFileMapA);
    const keysB = Object.keys(droppedFileMapB);

    if (keysA === keysB) return true;
    if (keysA.length !== keysB.length) return false;

    for (let i = 0; i < keysA.length; i++) {
      if (keysA[i] !== keysB[i]) return false;
    }

    return true;
  }

  private _mergeFiles(files: DroppedFileMap): object[] {
    return Object.values(files).reduce((acc: object[], fileContent) => {
      return [...acc, ...ensureArray(fileContent)];
    }, []) as object[];
  }
}

export class FunctionGroup {
  constructor(
    readonly namespace: string,
    readonly functions: FuncDetails[]
  ) {}
}
