<header mat-dialog-title>
  <h2>Class Hierarchy Viewer</h2>
  <div class="spacer"></div>
  <button mat-icon-button mat-dialog-close tabindex="-1">
    <fa-icon icon="times" />
  </button>
</header>
<div class="graphical-hierarchy-content" (window:resize)="resizeTree()">
  <app-rosetta-tree
    [hierarchyData]="data.classHierarchy"
    treeType="ClassHierarchyTree"
    [highlighted]="[data.highlighted]"
  />
</div>
