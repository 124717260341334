import { Inject, Injectable } from '@angular/core';
import { PipelineDef, TestPackDef } from '@shared/modules/transform/models';
import { TestPackGridSelection } from '@shared/modules/transform/models/test-pack-grid-selection.model';
import {
  ITransformConfig,
  TRANSFORM_CONFIG,
} from '@shared/modules/transform/models/transform-config.model';
import { TransformStateSyncService } from '@shared/modules/transform/services/transform-state-sync.service';
import { TransformStorageService } from '@shared/modules/transform/services/transform-storage.service';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  switchMap,
  tap,
} from 'rxjs';

import { PipelineSelector } from './pipeline-selector.service';
import { TestPackSelector } from './test-pack-selector.service';

@Injectable()
export class TransformSelectorManagerService {
  constructor(
    private _transformStorage: TransformStorageService,
    private _transformStateSync: TransformStateSyncService,
    @Inject(TRANSFORM_CONFIG) private _transformConfig: ITransformConfig
  ) {}

  private _testPackGridSelectionSubject$ =
    new BehaviorSubject<TestPackGridSelection | null>(null);
  private _selectedPipelineDef: PipelineDef | undefined;
  private _sub: Subscription;

  readonly pipelineSelector = new PipelineSelector(
    this._transformStorage,
    this._transformStateSync,
    this._transformConfig
  );

  readonly testPackSelector = new TestPackSelector(
    this._transformStorage,
    this._transformStateSync,
    this._transformConfig
  );

  get testPackSelected$(): Observable<TestPackDef> {
    return this.testPackSelector.selectedValue$;
  }

  get pipelineSelected$(): Observable<PipelineDef> {
    return this.pipelineSelector.selectedValue$;
  }

  get testPackGridSelection(): TestPackGridSelection {
    return this._testPackGridSelectionSubject$.getValue();
  }

  testPackGridSelection$ = this._testPackGridSelectionSubject$.asObservable();

  init(): void {
    this._sub = new Subscription();
    this._createSelectorHandlers();
    this._initialiseSelectors();
  }

  resetAll(): void {
    this.pipelineSelector.resetSelection();
    this.testPackSelector.resetSelection();
  }

  refreshTestPacks(selectTestPack?: TestPackDef): void {
    if (this._selectedPipelineDef) {
      this.testPackSelector
        .fetchOptions(this._selectedPipelineDef)
        .subscribe(() => {
          if (selectTestPack) {
            this.testPackSelector.applyOnChange(selectTestPack.id);
          }
        });
    }
  }

  cleanup(): void {
    this._sub?.unsubscribe();
  }

  getCurrentSelection(): Partial<TestPackGridSelection> | null {
    const selection = this._testPackGridSelectionSubject$.getValue();
    const partialSelection = this._selectedPipelineDef
      ? {
          pipelineDef: this._selectedPipelineDef,
        }
      : null;
    return selection || partialSelection;
  }

  private _initialiseSelectors(): void {
    this.pipelineSelector.fetchOptions().subscribe();
  }

  private _createSelectorHandlers(): void {
    this._sub.add(
      this.pipelineSelector.selectedValue$
        .pipe(
          tap(
            selectedPipeline => (this._selectedPipelineDef = selectedPipeline)
          ),
          switchMap(selectedPipeline =>
            this.testPackSelector.fetchOptions(selectedPipeline)
          )
        )
        .subscribe()
    );

    this._sub.add(
      this.testPackSelector.selectedValue$
        .pipe(
          tap(selectedTestPack =>
            this._handleTestPackSelection(selectedTestPack)
          )
        )
        .subscribe()
    );
  }

  private _handleTestPackSelection(testPackDef: TestPackDef): void {
    if (this._selectedPipelineDef && testPackDef) {
      this._testPackGridSelectionSubject$.next({
        pipelineDef: this._selectedPipelineDef,
        testPackDef,
      });
      return;
    }

    this._testPackGridSelectionSubject$.next(null);
  }
}
