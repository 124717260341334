<ng-container *ngrxLet="numberHiddenColumns$ as numberHiddenColumns">
  <button
    mat-stroked-button
    *ngIf="canHide || canReorder"
    [disabled]="disabled"
    [appRosettaOverlayTarget]="columnsPopover"
    class="button-wrapper"
    [ngClass]="{ 'hidden-column-count theme-bg-primary': numberHiddenColumns }"
  >
    <fa-icon
      [ngClass]="{ 'theme-bg-primary': numberHiddenColumns }"
      icon="table-columns"
    />
    <span>Fields</span>

    <span
      @slideIn
      *ngIf="numberHiddenColumns"
      class="ml-16 button-wrapper-label"
    >
      <strong>{{ numberHiddenColumns }}</strong> hidden
    </span>
  </button>
</ng-container>

<app-rosetta-overlay #columnsPopover>
  <app-filtered-list
    #filterInput
    inputPlaceholder="Find in Fields"
    [data]="(columns$ | async) ?? []"
    [filterFunc]="filterFunc"
  >
    <ng-template #staticContent>
      <mat-checkbox
        *ngIf="!filterInput.filterString"
        (change)="$event ? masterToggle() : null"
        [checked]="isAllSelected$ | async"
        [indeterminate]="isAnySelected$ | async"
      >
        All
      </mat-checkbox>
    </ng-template>
    <ng-template #rowContent let-column>
      <mat-checkbox
        appStopPropagation
        (change)="columnToggle(column)"
        [checked]="!column.hide"
      >
        {{ column.headerName }}
      </mat-checkbox>
    </ng-template>
  </app-filtered-list>
</app-rosetta-overlay>
