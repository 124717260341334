import { Injectable } from '@angular/core';
import { BaseWorkspaceService } from '@core/services';
import { NameTypeToggleState, RosettaTree, SelectedClass } from '@models';

@Injectable()
export class GraphicalNavigatorStateService extends BaseWorkspaceService {
  constructor() {
    super();
    this.initWorkspaceObserver();
  }

  private _reset = true;

  private _selectedClass: SelectedClass | undefined;
  get selectedClass(): SelectedClass | undefined {
    return this._selectedClass;
  }
  set selectedClass(value: SelectedClass | undefined) {
    this._selectedClass = value;
  }

  private _treeNavigator: RosettaTree | undefined;
  get treeNavigator(): RosettaTree | undefined {
    return this._treeNavigator;
  }
  set treeNavigator(value: RosettaTree | undefined) {
    this._treeNavigator = value;
  }

  private _div: HTMLDivElement | undefined;
  get div(): HTMLDivElement | undefined {
    return this._div;
  }
  set div(value: HTMLDivElement | undefined) {
    this._div = value;
  }

  private _initialClass: string | undefined;
  get initialClass(): string | undefined {
    return this._initialClass;
  }
  set initialClass(value: string | undefined) {
    this._initialClass = value;
  }

  private _nameTypeToggle: NameTypeToggleState = NameTypeToggleState.name;
  get nameTypeToggle(): NameTypeToggleState {
    return this._nameTypeToggle;
  }
  set nameTypeToggle(value: NameTypeToggleState) {
    this._nameTypeToggle = value;
  }

  setInitialClass(className: string | undefined) {
    this.initialClass = className;
    this.selectedClass = {
      className,
      children: true,
    };
  }

  resetOnSearch(className?: string) {
    this._initialClass = className;
    this._selectedClass = { className, children: true };
    this._reset = true;
  }

  resetOnTreeRender() {
    const reset = this._reset;
    this._reset = false;
    return reset;
  }

  protected _onWorkspaceSwitch(): void {
    this.setInitialClass(undefined);
    this._treeNavigator = undefined;
    this._div = undefined;
    this._reset = true;
    this._nameTypeToggle = NameTypeToggleState.name;
  }
}
