<div class="actions">
  <button mat-stroked-button (click)="onUpdatePassword()">
    <fa-icon icon="lock" />
    Update Password
  </button>
  <button
    mat-stroked-button
    [disabled]="
      !form || form.invalid || hasFormChanged() || (isSaving$ | async)
    "
    (click)="onSave()"
  >
    <fa-icon
      icon="save"
      *appShowSpinner="(isSaving$ | async) === false; diameter: 18"
    />
    Save
  </button>
</div>

<div class="form-container">
  <form *appShowSpinner="form" [formGroup]="form">
    <div class="field-row">
      <mat-form-field>
        <mat-select placeholder="Title" required formControlName="title">
          <mat-option value="Mr">Mr</mat-option>
          <mat-option value="Mrs">Mrs</mat-option>
          <mat-option value="Miss">Miss</mat-option>
          <mat-option value="Ms">Ms</mat-option>
          <mat-option value="other">Other</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="form.get('titleOther').enabled">
        <input
          matInput
          placeholder="Specify Other"
          formControlName="titleOther"
          required
          autocomplete="off"
        />
        <mat-error *ngIf="form.get('titleOther').hasError('required')">
          Other title is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="field-row">
      <mat-form-field>
        <input
          matInput
          placeholder="First Name"
          formControlName="firstName"
          required
          autocomplete="off"
        />
        <mat-error *ngIf="form.controls['firstName'].hasError('required')">
          First Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          placeholder="Last Name"
          formControlName="surname"
          required
          autocomplete="off"
        />
        <mat-error *ngIf="form.controls['surname'].hasError('required')">
          Last Name is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="field-row">
      <mat-form-field>
        <mat-select required placeholder="Country" formControlName="country">
          <mat-option *ngFor="let country of countries" [value]="country">
            {{ country }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls['country'].hasError('required')">
          Country is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field>
        <input
          matInput
          placeholder="State"
          type="text"
          formControlName="state"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div class="field-row full-width">
      <mat-form-field>
        <mat-select
          placeholder="Institution, Corporation or Organization Name"
          required
          formControlName="organizationName"
        >
          <mat-option
            *ngFor="let institute of institutions"
            [value]="institute[1]"
          >
            {{ institute[0] }}
          </mat-option>
        </mat-select>
        <mat-error
          *ngIf="form.controls['organizationName'].hasError('required')"
        >
          Institution, Corporation or Organization Name is
          <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="form.get('organizationNameOther').enabled">
        <input
          matInput
          placeholder="Specify Other"
          formControlName="organizationNameOther"
          required
          autocomplete="off"
        />
        <mat-error
          *ngIf="form.get('organizationNameOther').hasError('required')"
        >
          Other organization is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="field-row">
      <mat-form-field>
        <mat-select required placeholder="Role" formControlName="role">
          <mat-option *ngFor="let role of roles" [value]="role[1]">
            {{ role[0] }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls['role'].hasError('required')">
          Role is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="form.get('roleOther').enabled">
        <input
          matInput
          placeholder="Specify Other"
          formControlName="roleOther"
          required
          autocomplete="off"
        />
        <mat-error *ngIf="form.get('roleOther').hasError('required')">
          Other role is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="field-row">
      <mat-form-field>
        <input
          matInput
          placeholder="Job Title"
          formControlName="jobTitle"
          type="text"
          required
          autocomplete="off"
        />
        <mat-error *ngIf="form.controls['jobTitle'].hasError('required')">
          Job Title is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="field-row">
      <mat-form-field>
        <mat-select
          required
          placeholder="Intended Use"
          formControlName="intendedUse"
        >
          <mat-option *ngFor="let use of uses" [value]="use[1]">
            {{ use[0] }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls['intendedUse'].hasError('required')">
          Intended Use is <strong>required</strong>
        </mat-error>
      </mat-form-field>

      <mat-form-field *ngIf="form.get('intendedUseOther').enabled">
        <input
          matInput
          placeholder="Specify Other"
          formControlName="intendedUseOther"
          required
          autocomplete="off"
        />
        <mat-error *ngIf="form.get('intendedUseOther').hasError('required')">
          Other intended use is <strong>required</strong>
        </mat-error>
      </mat-form-field>
    </div>

    <div class="field-row full-width">
      <mat-form-field>
        <input
          matInput
          placeholder="Email Address"
          type="email"
          formControlName="emailAddress"
          email="true"
          required
          readonly
          autocomplete="off"
        />
        <mat-error *ngIf="form.controls['emailAddress'].hasError('required')">
          Email Address is <strong>required</strong>
        </mat-error>
        <mat-error *ngIf="form.controls['emailAddress'].hasError('email')">
          Email must be a valid email address
        </mat-error>
      </mat-form-field>
    </div>
  </form>
</div>
