<app-rosetta-tour-template />

<ng-template #notSupport>
  <div class="flex-align-center-center flex-1">
    <fa-icon
      icon="exclamation-circle"
      size="2xl"
      class="theme-color-yellow-alt"
    />
    <h2>
      <strong>{{ transformService.transformType | rosettaTitleCase }}</strong>
      is not available on the current model version
    </h2>
  </div>
</ng-template>

<ng-container
  *appShowSpinner="waitForWorkspaceReady$ | async; flexCentre: true"
>
  <app-transform-data-viewer
    *ngIf="transformService.isSupported$ | async; else notSupport"
    id="transform-list"
    [dataViewerSource$]="transformService.resultSource$"
    [getFileName]="transformService.getFileName"
    (rowClicked)="onRowClicked($event)"
    (openUploadDialog)="openAddSampleDialog()"
    (events)="onRowEvent($event)"
  >
    <app-rosetta-selector
      [selectors]="[
        transformSelectorManager.pipelineSelector,
        transformSelectorManager.testPackSelector,
      ]"
    />
    <app-run-status
      class="ml-16 mr-16"
      [runResultState]="transformService.runResultState$ | async"
    />
    <ng-container dataViewerActions>
      <button
        mat-stroked-button
        tourAnchor="transform.add-sample"
        [disabled]="(canAddSample$ | async) === false"
        (click)="openAddSampleDialog()"
      >
        <fa-icon icon="plus" />
        <span>Add Sample</span>
      </button>
    </ng-container>

    <app-inline-error
      *ngIf="isUploadTestPacks$ | async"
      class="mb-16"
      dataViewerBanner
      >Uploads Test Pack is no longer supported, as you can now add samples to
      all existing Test Packs.</app-inline-error
    >
  </app-transform-data-viewer>
</ng-container>
