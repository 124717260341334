import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ENGINE_ACCESS } from '@configs';
import { AuthService } from '@core/services';
import { RosettaNavigationService } from '@core/services/rosetta-navigation.service';
import { EngineFeatureTabList } from '@features/workspace/modules/engine/engine-config';
import { FeatureTab } from '@models';
import { Store } from '@ngrx/store';
import * as RouterSelectors from '@store/router/router.selector';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { first, map, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-workspace-footer-nav',
  templateUrl: 'workspace-footer-nav.component.html',
  styleUrls: ['workspace-footer-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'workspace-footer-nav' },
})
export class WorkspaceFooterNavComponent implements OnInit {
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _authService: AuthService,
    private _navigate: RosettaNavigationService,
    private _store: Store
  ) {}

  private _panelUrlMap = new Map<string, string[]>();
  private _currentPanel?: FeatureTab;

  panels!: FeatureTab[];

  isAllTabsDisabled$ = of(this._authService.has(ENGINE_ACCESS)).pipe(
    switchMap(hasAbility =>
      hasAbility
        ? of(false)
        : this._store
            .select(WorkspaceSelectors.isProjectTypeWorkspace)
            .pipe(map(isProject => !isProject))
    )
  );

  ngOnInit() {
    this.panels = this._getPanels();
  }

  panelClick(tab: FeatureTab) {
    this._saveCurrentPanelUrl(tab);

    if (this._isUrlActive([tab.url])) {
      this._navigate.closeBottomPanel();
    } else {
      this._navigate.updateBottomPanel(this._restoreState(tab));
    }
  }

  private _saveCurrentPanelUrl(tab: FeatureTab) {
    if (this._currentPanel) {
      this._store
        .select(RouterSelectors.selectRouterPathSegments)
        .pipe(first())
        .subscribe(urlSegments => {
          const urlPath = urlSegments.slice(2).map(segment => segment.path);
          if (urlPath.length > 0) {
            this._panelUrlMap.set(this._currentPanel.url, urlPath);
          }
        });
    }
    this._currentPanel = tab;
  }

  private _restoreState(tab: FeatureTab): string[] {
    if (this._panelUrlMap.has(tab.url)) {
      return this._panelUrlMap.get(tab.url);
    }
    return [tab.url];
  }

  private _isUrlActive(paths: string[]) {
    return this._router.isActive(
      this._router.createUrlTree(paths, { relativeTo: this._route }),
      {
        paths: 'subset',
        queryParams: 'ignored',
        fragment: 'ignored',
        matrixParams: 'ignored',
      }
    );
  }

  private _getPanels() {
    return EngineFeatureTabList.filter(({ params }) => {
      if (params?.ability === undefined) {
        return true;
      }

      return this._authService.has(params.ability);
    });
  }
}
