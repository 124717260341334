import { TreeUtilCallback } from '@utils';
import { Expectation, ServerError, TaskStatus } from '@models';
import {
  ExpectationResult,
  SampleFileNode,
  SampleFileNodeFull,
} from '../../models';

export const aggregateNodeData: TreeUtilCallback = (node, parent) => {
  if (node.state === TaskStatus.Error) {
    parent.state = TaskStatus.Error;
  }

  if (node.state === TaskStatus.Started) {
    parent.state = TaskStatus.Started;
  }
};

export const createSampleFileNodeFull = (
  file: SampleFileNode,
  expectations: Expectation,
  results: ExpectationResult,
  status = TaskStatus.Pending,
  errors: ServerError
): SampleFileNodeFull => ({
  id: file?.id,
  testType: file?.testType,
  parent: file?.parent,
  name: file?.name,
  environments: file?.environments,
  synonyms: file?.synonyms,
  state: status === TaskStatus.Starting ? TaskStatus.Started : status,
  isLeaf: file?.isLeaf,
  isVisible: file?.isVisible,
  expectations,
  results,
  errors,
});

export const createSampleFileNodeTree = (
  file: SampleFileNode,
  expectations: Expectation,
  results: ExpectationResult,
  status: TaskStatus,
  errors: ServerError
): SampleFileNodeFull => ({
  ...createSampleFileNodeFull(file, expectations, results, status, errors),
  children: [],
});
