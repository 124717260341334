import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  Type,
  ViewChild,
} from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Router,
} from '@angular/router';
import { AppThemes } from '@configs';
import { RouteData } from '@models';
import { ViewContainerRefDirective } from '@shared/directives/view-container-ref.directive';
import { startWith } from 'rxjs';
import { TextComponent } from '../components/text/text.component';

type RouteDataTypes = undefined | null | string | Type<any>;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
  constructor(
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  @ViewChild(ViewContainerRefDirective, { static: true })
  view!: ViewContainerRefDirective;

  private _currentData: RouteDataTypes;
  readonly appThemes = AppThemes;

  ngOnInit() {
    this._router.events
      .pipe(startWith(new NavigationEnd(0, '', '')))
      .subscribe(event => {
        const data = this._getFirstRouteData(this._route.snapshot);

        if (data !== this._currentData && event instanceof NavigationEnd) {
          this.view.viewContainerRef.clear();
          this._currentData = data;
          this._setTitle(data);
        }
      });
  }

  private _setTitle(data: RouteDataTypes) {
    if (!data) {
      return;
    }

    if (typeof data === 'string') {
      this._loadComponent(TextComponent, data);
    } else {
      this._loadComponent(data);
    }
  }

  private _getFirstRouteData(
    snapshot: ActivatedRouteSnapshot | null
  ): RouteDataTypes {
    let currentSnapshot = snapshot;
    while (currentSnapshot) {
      const navBarTitle = (currentSnapshot.data as RouteData)?.navBarTitle;
      if (navBarTitle) {
        return navBarTitle;
      }
      currentSnapshot = currentSnapshot.firstChild;
    }
    return null;
  }

  private _loadComponent(component: any, data?: any) {
    const componentRef =
      this.view.viewContainerRef.createComponent<any>(component);

    if (data) {
      componentRef.setInput('text', data);
    }
  }
}
