import { inject } from '@angular/core';
import { Task } from '@models';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TaskActions } from '@store/.';
import { filter, tap } from 'rxjs';
import { TransformService } from '../services/transform.service';
import { Translate_1_5ApiService } from '../services/translate-1-5-api.service';

export const refreshTransformData = createEffect(
  (
    actions = inject(Actions),
    service = inject(TransformService),
    apiService = inject(Translate_1_5ApiService)
  ) => {
    return actions.pipe(
      ofType(TaskActions.taskFinished),
      filter(({ name }) => name === Task.ExecutionEngineInitialisation),
      tap(() => {
        apiService.clearSynonymSourceMap();
        service.rerun();
      })
    );
  },
  { dispatch: false, functional: true }
);
