import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ALL_MODELS, IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import {
  ChangePasswordCredentials,
  PackagePlanDetails,
  Project,
  User,
  UserDetails,
  UserLoginResponse,
} from '@features/auth/login';
import { getServerErrorMessage } from '@features/auth/login/services/api-error-handler';
import { UserRegistrationCredentials } from '@features/auth/register/models/register.model';
import { IDomainModelDetails, IDomainModelResponse, isResponse } from '@models';
import { ModelInstanceId } from '@models/domain-models';
import { Store } from '@ngrx/store';
import { AppActions } from '@store/.';
import {
  Observable,
  catchError,
  first,
  map,
  pipe,
  switchMap,
  throwError,
} from 'rxjs';
import { SettingsDTO } from '../models/settings-dto.models';
import { domainModelMapper } from '@store/workspace/effects/workspace.effects.helper';
import { AuthSelectors } from '@store/selectors';

@Injectable()
export class SettingsApiService {
  constructor(
    private _store: Store,
    private _http: HttpClient,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  getAllAdminModels(): Observable<IDomainModelDetails[]> {
    return this._store.select(AuthSelectors.selectUserDocuments).pipe(
      first(),
      switchMap(userDocuments =>
        this._http
          .get<
            IDomainModelResponse[]
          >(`${this._config.resourcePaths.models}/all`)
          .pipe(
            map(domainModels => domainModelMapper(domainModels, userDocuments))
          )
      )
    );
  }

  getUserDetails(): Observable<UserRegistrationCredentials> {
    return this._http
      .get<UserRegistrationCredentials>(
        `${this._config.resourcePaths.user}/auth/user-details`
      )
      .pipe(this._errorhandler());
  }

  getProjectUserDetails(): Observable<SettingsDTO.ProjectUserDetails[]> {
    return this._http
      .get<
        SettingsDTO.ProjectUserDetails[]
      >(`${this._config.resourcePaths.projectAdmin}/users`)
      .pipe(this._errorhandler());
  }

  userDetails(): Observable<UserDetails[]> {
    return this._http.get<UserDetails[]>(
      `${this._config.resourcePaths.userServer}/details`
    );
  }

  updateUserProfile(
    data: UserRegistrationCredentials
  ): Observable<void | null> {
    return this._http
      .post<void>(`${this._config.resourcePaths.user}/auth/update-user`, data)
      .pipe(this._errorhandler());
  }

  updateUserPassword(
    data: ChangePasswordCredentials
  ): Observable<UserLoginResponse> {
    return this._http
      .post<UserLoginResponse>(
        `${this._config.resourcePaths.user}/auth/change-password`,
        data
      )
      .pipe(this._errorhandler());
  }

  downloadModelUsers(modelId: ModelInstanceId): Observable<Blob> {
    return this._http
      .get(`${this._config.resourcePaths.user}/auth/download`, {
        responseType: 'blob',
        ...(modelId !== ALL_MODELS
          ? { params: { projectFilter: modelId } }
          : null),
      })
      .pipe(this._errorhandler());
  }

  updateUserProjects(user: User, projects: Project[]): Observable<void> {
    const body = { user, projects };
    return this._http.post<void>(
      `${this._config.resourcePaths.userServer}/update-projects`,
      body
    );
  }

  updatePackagePlanDetails(
    user: User,
    packagePlanDetails: PackagePlanDetails
  ): Observable<void> {
    const body = { user, packagePlanDetails: packagePlanDetails };
    return this._http.post<void>(
      `${this._config.resourcePaths.userServer}/update-package-plan`,
      body
    );
  }

  private _errorhandler<T>(customErrorMsg?: string) {
    return pipe(
      map<T, T>(response => {
        if (isResponse(response) && !response.success) {
          throw new Error(response.message);
        }
        return response;
      }),
      catchError<T, Observable<any>>((error: any) => {
        let errorMsg: string;

        if (customErrorMsg) {
          errorMsg = customErrorMsg;
        } else if (error.error instanceof ErrorEvent) {
          errorMsg = `Error: ${error.error.message}`;
        } else {
          errorMsg = getServerErrorMessage(error);
        }

        this._store.dispatch(
          AppActions.showBasicErrorMsg({ message: errorMsg })
        );

        return throwError(() => errorMsg);
      })
    );
  }
}
