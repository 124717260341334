import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { FontsModule } from '@app/fonts/fonts.module';
import { LetDirective } from '@ngrx/component';
import { RosettaSelectorComponent } from './rosetta-selector.component';

@NgModule({
  declarations: [RosettaSelectorComponent],
  imports: [
    CommonModule,
    FontsModule,
    MatSelectModule,
    ReactiveFormsModule,
    LetDirective,
  ],
  exports: [RosettaSelectorComponent],
})
export class RosettaSelectorModule {}
