<div
  [ngSwitch]="statusClass$ | async"
  class="status-indicator"
  [style.width.px]="width"
  [style.height.px]="width"
>
  <!-- STARTED -->
  <mat-progress-spinner
    *ngSwitchCase="showSpinner"
    [diameter]="diameter"
    [strokeWidth]="strokeWidth"
    mode="indeterminate"
    color="primary"
    class="started"
  />

  <!-- Other -->
  <fa-icon
    *ngSwitchDefault
    icon="circle"
    [size]="faCssSize"
    [matTooltip]="toolTip"
    [ngClass]="statusClass$ | async"
  />
</div>
