import { Injectable } from '@angular/core';
import { PanelStateService } from '@core/services/panel-state.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TaskActions } from '@store/.';
import * as WorkspaceActions from '@store/workspace/actions';
import { map, tap } from 'rxjs';

@Injectable()
export class TaskEffects {
  constructor(
    private _actions$: Actions,
    private _panelStateService: PanelStateService
  ) {}

  submitTask$ = createEffect(
    () => {
      return this._actions$.pipe(
        ofType(TaskActions.submitTask),
        tap(({ data }) => this._panelStateService.send('submitTask', data))
      );
    },
    { dispatch: false }
  );

  switchWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.switchWorkspace),
      map(props => TaskActions.switchWorkspace(props))
    );
  });
}
