import { ModuleWithProviders, NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { LetDirective } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { CollapsiblePanelModule } from '@shared/modules/collapsible-panel/collapsible-panel.module';
import { DataStorageWarningModule } from '@shared/modules/data-storage-warning/data-storage-warning.module';
import { DiagnosticPanelModule } from '@shared/modules/diagnostic-panel/diagnostic-panel.module';
import { FilterFieldModule } from '@shared/modules/filter-field/filter-field.module';
import { InlineErrorModule } from '@shared/modules/inline-error/inline-error.module';
import { RosettaSelectorModule } from '@shared/modules/rosetta-selector/rosetta-selector.module';
import { GetWorkspaceInfoPipe } from '@shared/pipes/get-workspace-info.pipe';
import { RosettaTitleCasePipe } from '@shared/pipes/rosetta-title-case.pipe';
import { SharedModule } from '@shared/shared.module';
import { ActionPanelComponent } from '../action-panel/action-panel.component';
import { CodeViewModule } from '../code-view/code-view.module';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { RosettaMenuComponent } from '../rosetta-menu.component';
import { RosettaOverlayModule } from '../rosetta-overlay/rosetta-overlay.modules';
import { RosettaTableModule } from '../rosetta-table/rosetta-table.module';
import { RosettaTourModule } from '../rosetta-tour/rosetta-tour.module';
import { fromComponents } from './components';
import {
  ITransformConfig,
  TRANSFORM_CONFIG,
} from './models/transform-config.model';
import { TransformDetailsComponent } from './pages/transform-details/transform-details.component';
import { TransformListComponent } from './pages/transform-list/transform-list.component';
import { TransformSelectorManagerService } from './services/selectors/transform-selector-manager.service';
import { TransformLocalStorageService } from './services/transform-local-storage.service';
import { TransformServerStorageService } from './services/transform-server-storage.service';
import { TransformStorageService } from './services/transform-storage.service';
import { TransformService } from './services/transform.service';
import { Translate_1_5ApiService } from './services/translate-1-5-api.service';
import * as TransformEffects from './store/transform.effects';
import { TransformRoutingModule } from './transform-routing.module';
import { StatusComponent } from './components/status/status.component';

@NgModule({
  declarations: [
    TransformListComponent,
    TransformDetailsComponent,
    ...fromComponents,
    StatusComponent,
  ],
  imports: [
    TransformRoutingModule,
    CollapsiblePanelModule,
    DiagnosticPanelModule,
    FilterFieldModule,
    SharedModule,
    RosettaOverlayModule,
    RosettaSelectorModule,
    RosettaTableModule,
    RosettaTourModule,
    InlineErrorModule,
    DataStorageWarningModule,
    EffectsModule.forFeature([TransformEffects]),
    MatDialogModule,
    MatSlideToggleModule,
    GetWorkspaceInfoPipe,
    RosettaTitleCasePipe,
    ActionPanelComponent,
    RosettaMenuComponent,
    LetDirective,
    CodeViewModule,
    FileUploadComponent,
  ],
  providers: [
    TransformLocalStorageService,
    TransformStorageService,
    Translate_1_5ApiService,
    TransformService,
    TransformServerStorageService,
    TransformSelectorManagerService,
  ],
})
export class TransformModule {
  static forFeature(
    config: ITransformConfig
  ): ModuleWithProviders<TransformModule> {
    return {
      ngModule: TransformModule,
      providers: [{ provide: TRANSFORM_CONFIG, useValue: config }],
    };
  }
}
