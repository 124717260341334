import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { LanguageServerService } from '@core/services/language-server.service';
import { Store } from '@ngrx/store';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { deepEquals } from '@utils';
import {
  distinctUntilChanged,
  first,
  map,
  Observable,
  of,
  pipe,
  startWith,
  switchMap,
  UnaryFunction,
} from 'rxjs';

@Component({
  selector: 'app-textual',
  templateUrl: './textual.component.html',
  styleUrls: ['./textual.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'theme-bg-alt textual',
  },
})
export class TextualComponent implements OnDestroy {
  constructor(
    private _store: Store,
    private _languageServerService: LanguageServerService
  ) {}

  private _diffEditorOpen$ = this._store.select(
    WorkspaceSelectors.selectDiffEditorOpen
  );

  editor$ = this._languageServerService.editorSubject$;
  workspaceItems$ = this._store.select(WorkspaceSelectors.selectWorkspaceItems);
  isSaving$ = this._store.select(WorkspaceSelectors.hasFilesToSave);
  hasSaveErrors$ = this._store.select(WorkspaceSelectors.hasSaveErrors);

  hideCodeEditor$ = this.editor$.pipe(this._getDiffEditorOpen(true));
  showDiffEditor$ = this.editor$.pipe(this._getDiffEditorOpen(false));
  showOverrideBanner$ = this._store
    .select(WorkspaceSelectors.selectCurrentWorkspaceItemId)
    .pipe(
      distinctUntilChanged(deepEquals),
      switchMap(selectedItem => this._itemOverridesParent(selectedItem?.uri))
    );

  ngOnDestroy(): void {
    this._languageServerService.closeSocket();
  }

  openNewNamespaceDialog(): void {
    this._store.dispatch(
      WorkspaceActions.workspaceAction({ action: 'namespace' })
    );
  }

  private _getDiffEditorOpen(
    returnWhenNoEditor: boolean
  ): UnaryFunction<
    Observable<monaco.editor.IStandaloneCodeEditor>,
    Observable<boolean>
  > {
    return pipe(
      switchMap(editor =>
        editor === null ? of(returnWhenNoEditor) : this._diffEditorOpen$
      ),
      startWith(returnWhenNoEditor)
    );
  }

  private _itemOverridesParent(uri: string): Observable<boolean> {
    return this.workspaceItems$.pipe(
      first(items => items?.length > 0),
      map(
        items => items.find(item => item.info.uri === uri)?.info.overridesParent
      )
    );
  }
}
