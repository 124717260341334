<ng-container *ngrxLet="workspaceItem$ as item">
  <button
    mat-fab
    color="primary"
    class="file-name-unlock-button"
    *ngIf="
      item &&
      (isReadOnly$ | async) === false &&
      item.info.canOverrideParent &&
      !item.info.overridesParent
    "
    (click)="onUnlock(item)"
    matTooltip="Override this namespace"
    [disabled]="isLoading$ | async"
  >
    <fa-icon icon="lock-open" />
  </button>

  <app-collapsible-panel-header class="pl-8">
    <fa-icon
      class="ml-8 theme-color-50"
      *ngIf="
        item &&
        (item.info.overridesParent ||
          item.info.readOnly ||
          (isReadOnly$ | async))
      "
      [icon]="['fas', item?.info.overridesParent ? 'lock-open' : 'lock']"
      size="sm"
    />

    <div class="ml-8 mr-16 file-name-wrapper">
      <fa-icon class="theme-color-primary mr-8" [icon]="['far', 'file-code']" />
      <span
        class="file-name-breadcrumb text-ellipsis"
        [ngClass]="{ 'file-name-rtl': item?.namespace.length }"
        >{{ item?.namespace || 'Loading...' }}</span
      >
      <strong class="file-name text-align-left"
        >{{ item?.namespace.length ? ':' : '' }}{{ item?.category }}</strong
      >
    </div>

    <div class="spacer"></div>

    <ng-container #editorActionContainer />
  </app-collapsible-panel-header>
</ng-container>
