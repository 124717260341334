<ng-container *ngrxLet="vm$ as vm">
  <app-action-panel
    [actionIcon]="['fas', 'arrow-left']"
    tooltip="Go back to transform listing"
    class="action-panel"
    (action)="goBack()"
  >
    <div class="summary-text">
      <p>
        {{ vm.selection.pipelineDef.name }} /
        <small>{{ vm.selection.testPackDef.name }}</small>
      </p>
      <h3>{{ vm.sampleId }}</h3>
    </div>
    <div class="spacer"></div>
    <app-status style="flex-basis: 40px" [state]="state$ | async" />
  </app-action-panel>

  <app-collapsible-panel-group>
    <app-collapsible-panel key="transform-input" panelTitle="Input">
      <app-collapsible-panel-body>
        <ng-container
          *appShowSpinner="
            codeViewInput$ | async as codeViewInput;
            flexCentre: true
          "
        >
          <app-collapsible-panel-header contentAlign="end">
            <app-code-view-switch
              class="panel-header-switch"
              [showNative]="inputEditor.showNative"
              (viewChange)="inputEditor.showNative = $event"
              (download)="inputEditor.downloadNativeCode()"
            />
          </app-collapsible-panel-header>

          <app-code-view
            #inputEditor
            class="code-view-original"
            [codeView]="codeViewInput.codeView"
            [modelContent]="true"
            [showNative]="true"
            [showSelectedCodeView]="sampleInput.showSelected"
            [language]="inputSerialisation$ | async"
            [generateId]="false"
            [name]="vm.sampleId + '-input'"
            (clickLinkId)="outputCodeView?.scrollTo($event)"
          />
        </ng-container>
      </app-collapsible-panel-body>
    </app-collapsible-panel>

    <app-collapsible-panel key="transform-output" panelTitle="Output">
      <app-collapsible-panel-body>
        <ng-container
          *appShowSpinner="
            codeViewOutput$ | async as codeViewOutput;
            flexCentre: true
          "
        >
          <app-collapsible-panel-header contentAlign="end">
            <app-code-view-switch
              class="panel-header-switch"
              [showNative]="outputEditor.showNative"
              (viewChange)="outputEditor.showNative = $event"
              (download)="outputEditor.downloadNativeCode()"
            />
          </app-collapsible-panel-header>

          <app-code-view
            #outputEditor
            class="code-view-use-case"
            [codeView]="codeViewOutput.codeView"
            [modelContent]="true"
            [showNative]="true"
            [showSelectedCodeView]="sampleOutput.showSelected"
            [language]="outputSerialisation$ | async"
            [generateId]="false"
            [name]="vm.sampleId + '-output'"
            (clickLinkId)="inputCodeView?.scrollTo($event)"
          />
        </ng-container>
      </app-collapsible-panel-body>
    </app-collapsible-panel>

    <app-collapsible-panel key="transform-diagnostics" panelTitle="Diagnostics">
      <app-collapsible-panel-header />
      <app-collapsible-panel-body>
        <ng-container
          *appShowSpinner="
            diagnosticData$ | async as diagnosticData;
            flexCentre: true
          "
        >
          <app-filter-field
            (inputChanged)="filterDiagnostics($event)"
            [opened]="true"
          />
          <app-diagnostic [data]="diagnosticData" />
        </ng-container>
      </app-collapsible-panel-body>
    </app-collapsible-panel>
  </app-collapsible-panel-group>
</ng-container>
