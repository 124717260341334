import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG, StorageKey } from '@configs';
import { WA_LOCAL_STORAGE } from '@ng-web-apis/common';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService<K = unknown> {
  constructor(
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig,
    @Inject(WA_LOCAL_STORAGE) private _localStorage: Storage
  ) {}

  setItem<T = K>(localStorageKey: StorageKey, state: T) {
    this._localStorage.setItem(localStorageKey, JSON.stringify(state));
  }

  getItem<T = K>(localStorageKey: StorageKey): T | null {
    const item = this._localStorage.getItem(localStorageKey);
    return item && JSON.parse(item);
  }

  removeItem(localStorageKey: StorageKey) {
    this._localStorage.removeItem(localStorageKey);
  }

  clear({ includeWhiteList }: { includeWhiteList?: boolean } = {}) {
    if (includeWhiteList) {
      this._localStorage.clear();
      return;
    }

    let index = this._localStorage.length;
    while (index > 0 && index--) {
      const key = this._localStorage.key(index);
      if (key && this._config.storage.whitelist.indexOf(key) < 0) {
        this._localStorage.removeItem(key);
      }
    }
  }
}
