import { Injectable } from '@angular/core';
import { RUN_CUSTOM_FUNCTION } from '@configs';
import {
  GraphGenerationResult,
  GridViewGenerationResult,
  MultipleVisualisationSubmit,
  TaskStatus,
  WorkflowExecutionResult,
  WorkflowExecutionSubmit,
} from '@models';
import { CodeViewService } from '@shared/modules/code-view/code-view.service';
import { Execute } from '@workspace-engine/functions/models';
import { CytoscapeGraphComponent } from '@workspace-engine/visualisation/components/cytoscape-graph/cytoscape-graph.component';
import {
  CdmWorkflowObject,
  FunctionGroup,
  Visualisation,
  VisualisationError,
} from '@workspace-engine/visualisation/models/visualisation-model';
import { VisualisationService } from '@workspace-engine/visualisation/services/visualisation.service';
import { ReplaySubject, first, merge } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EngineFunctionsService {
  constructor(
    private _visualisationService: VisualisationService,
    private _codeViewService: CodeViewService
  ) {}

  functionGroups$ = new ReplaySubject<FunctionGroup[]>(1);
  outputCodeViews$ = this._codeViewService.getCodeView(RUN_CUSTOM_FUNCTION);

  visualisation: Visualisation = new Visualisation(
    RUN_CUSTOM_FUNCTION,
    'run',
    'CUSTOM_FUNCTION',
    undefined
  );

  private _workflow?: CdmWorkflowObject;

  // Get Custom Functions by group from websocket
  onGridViewGenerationFinished(
    gridViewGenerationRunResult: GridViewGenerationResult
  ) {
    const groupByNamespace = gridViewGenerationRunResult.gridViewReport
      .filter(res => res.type.toLowerCase() === 'function')
      .reduce(
        (result, { namespace, details }) => {
          if (!details) {
            return result;
          }
          if (result[namespace]) {
            result[namespace].functions.push(details.func);
          } else {
            result[namespace] = new FunctionGroup(namespace, [details.func]);
          }
          return result;
        },
        {} as Record<string, FunctionGroup>
      );

    this.functionGroups$.next(Object.values(groupByNamespace));
  }

  runCustomFunction({
    functionName: workflowFunctionClass,
    uploadedJson: payload,
  }: Execute) {
    const workflowExecutionSubmit: WorkflowExecutionSubmit = {
      workflowFunctionClass,
      payload,
      workflowName: RUN_CUSTOM_FUNCTION,
      sampleFileName: 'run',
      executionType: 'CUSTOM_FUNCTION',
    };

    const multipleVisualisationSubmit: MultipleVisualisationSubmit = {
      workflowExecutionSubmissions: [workflowExecutionSubmit],
    };

    this._visualisationService.emitMultipleVisualisationExecutionTask(
      multipleVisualisationSubmit
    );
  }

  onWorkflowExecutionStarted() {
    this.visualisation.setStatus(TaskStatus.Started);
  }

  onWorkflowExecutionFinished(
    workflowExecutionResult: WorkflowExecutionResult
  ) {
    this.visualisation.setCdmObject(workflowExecutionResult.workflow);
    this._workflow = workflowExecutionResult.workflow;
  }

  onGraphGenerationFinished(graphGenerationRunResult: GraphGenerationResult) {
    this.visualisation.setGraph(graphGenerationRunResult.graph.graph);
    this._getOutput(this._workflow, graphGenerationRunResult.graph.graph);
  }

  onTaskError(error: any) {
    const { userMessage, supportMessage, causedBy } = error;
    this.visualisation.setError(
      new VisualisationError(userMessage, supportMessage, causedBy)
    );
  }

  resetData() {
    this.visualisation.reset();
    this._codeViewService.deleteCodeView(RUN_CUSTOM_FUNCTION);
  }

  private _getOutput(displayedCdm?: CdmWorkflowObject, graph?: any) {
    const [rosettaNamespace, rosettaClass] = this._getNamespaceAndClass(graph);
    this._codeViewService.runCodeViewGeneration(
      displayedCdm,
      rosettaNamespace,
      rosettaClass,
      RUN_CUSTOM_FUNCTION
    );

    merge(
      this._codeViewService.getCodeView(RUN_CUSTOM_FUNCTION),
      this.visualisation.error$
    )
      .pipe(first(Boolean))
      .subscribe(() => this.visualisation.setStatus(TaskStatus.Finished));
  }

  private _getNamespaceAndClass(graph: any): [string, string] {
    const [rootId] = graph.roots;
    const rootNode = graph.nodes.find((node: any) => node.data.id === rootId);
    const [, rosettaNamespace, rosettaClass] = rootNode.data.node.clazz.match(
      CytoscapeGraphComponent.ROSETTA_CLASS_REGEX
    );
    return [rosettaNamespace, rosettaClass];
  }
}
