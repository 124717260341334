import {
  LANGUAGE_ROSETTA,
  LANGUAGE_ROSETTA_ORIGINAL,
} from '@workspace-design/textual/models/editor.const';
import { Registry } from 'monaco-textmate'; // peer dependency
import { loadWASM } from 'onigasm'; // peer dependency of 'monaco-textmate'
import { wireTmGrammars } from './monaco-textmate-patch';

let wasmLoaded = false;

export async function liftOff(
  editor: monaco.editor.IEditor,
  languageFilePromise: Promise<any>
) {
  if (!wasmLoaded) {
    await loadWASM(`assets/onigasm/onigasm.wasm`);
    wasmLoaded = true;
  }

  const registry = new Registry({
    getGrammarDefinition: async () => {
      const response = await languageFilePromise.catch(() => null);
      return {
        format: 'json',
        content: response?.ok ? await response.body : '{}',
      };
    },
  });

  // map of monaco "language id's" to TextMate scopeNames
  const grammars = new Map();
  grammars.set(LANGUAGE_ROSETTA, 'source.rosetta');
  grammars.set(LANGUAGE_ROSETTA_ORIGINAL, 'source.rosetta');
  return await wireTmGrammars(monaco, registry, grammars, editor);
}
