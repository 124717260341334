import { TreeApiService } from '@core/services';
import {
  ColourLegends,
  ConditionIcon,
  NodeColours,
  RosettaTree,
  TreeStructure,
  ZOOM_SCALE,
} from '@models';
import { Store } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import * as d3 from 'd3';
import { HierarchyNode, HierarchyPointNode, Selection, TreeLayout } from 'd3';
import { ZoomBehavior } from 'd3-zoom';
import { first, switchMap } from 'rxjs';

export class TreeNavigator implements RosettaTree<TreeStructure> {
  constructor(
    private _store: Store,
    private _treeApiService: TreeApiService,
    private _chartContainer: HTMLElement,
    private _nodeDecorators: any[],
    private _nodeTextFunction: any,
    private _nodeSelectFunction: (node: HierarchyNode<any>) => void,
    private _nodeHoverFunction: (tooltip: string) => void
  ) {
    this.init(_chartContainer);
    this._drawLegend();
  }

  readonly duration = 750;
  readonly nodeRadius = 5;

  highlightNodes: string[] = [];

  searchFields: string[] = ['name', 'type', 'id'];

  tree!: TreeLayout<TreeStructure>;

  baseSvg!: Selection<SVGSVGElement, unknown, null, undefined>;
  svgGroup!: Selection<SVGGElement, any, any, any>;

  zoomListener: ZoomBehavior<any, any> = d3
    .zoom()
    .scaleExtent([0.1, 3])
    .on('zoom', () => {
      if (d3.event.transform === null) {
        return;
      }
      this.svgGroup.attr('transform', d3.event.transform);
    });

  root?: HierarchyNode<TreeStructure>;
  openSiblingOnSearch = false;

  // global static id generator
  idCounter = 0;

  static saveNodePositions(treeData: HierarchyPointNode<TreeStructure>) {
    treeData.each(d => (d.data._x0 = d.x));
    treeData.each(d => (d.data._y0 = d.y));
  }

  static fillNode(d: HierarchyNode<TreeStructure>) {
    if (d.data._tag === 'found') {
      return NodeColours.Selected;
    }

    // open node - white
    if (d.children && d.children.length > 0) {
      return NodeColours.UnselectedOpen;
    }

    if (
      (d.data._children && d.data._children.length > 0) ||
      d.data.hasChildren
    ) {
      return NodeColours.HasChildren;
    }

    // closed and have no children - terminal
    if (
      (d.data._children && d.data._children.length === 0) ||
      (d.children && d.children.length === 0) ||
      !d.data._children
    ) {
      return NodeColours.Terminal;
    }

    return NodeColours.UnselectedOpen;
  }

  // Creates a curved (diagonal) path from parent to the child nodes
  static diagonalCurvedPath(
    s: { x: number; y: number },
    d: { x: number; y: number }
  ) {
    return `M ${s.y} ${s.x}
            C ${(s.y + d.y) / 2} ${s.x},
              ${(s.y + d.y) / 2} ${d.x},
              ${d.y} ${d.x}`;
  }

  static collapse(d: HierarchyNode<TreeStructure>) {
    if (d.children) {
      d.data._children = d.children;
      d.children = undefined;
    }
  }

  static expand(d: HierarchyNode<TreeStructure>) {
    if (d.data._children) {
      d.children = d.data._children;
      d.data._children = undefined;
    }
  }

  init(element: HTMLElement) {
    this.baseSvg = d3
      .select(element)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .call(this.zoomListener);

    const defs = this.baseSvg
      .append('defs')
      .append('g')
      .attr('id', 'conditions');

    defs
      .append('rect')
      .attr('width', 14)
      .attr('height', 16)
      .style('fill', 'transparent');

    defs
      .append('path')
      .attr('d', ConditionIcon)
      .attr('transform', 'scale(0.03)');

    this.svgGroup = this.baseSvg.append('g');
    this.tree = d3
      .tree()
      .size([this._size.height, this._size.width]) as TreeLayout<TreeStructure>;
  }

  resizeNavigator(): void {
    // only do this once the svg is rendered
    this.baseSvg.transition().on('end', () => {
      this.tree.size([this._size.height, this._size.width]);
    });
  }

  highlightUpdateAndCenterNode(highlight: string[]) {
    this.highlightNodes = highlight;
    this._highlight();
    const expandHighlighted = this._expandHighlighted();
    if (!expandHighlighted || !this.root) {
      return;
    }
    this._update(this.root);
    this._centerNode(expandHighlighted, false);
  }

  updateAndCenterNode() {
    if (!this.root) {
      return;
    }
    this._update(this.root);
    this._centerNode(this.root, true);
  }

  createTreeData(treeData: TreeStructure) {
    this.root = d3.hierarchy(treeData);
    this.root.data._x0 = this._size.height * 0.5;
    this.root.data._y0 = 0;
    this.root.children?.forEach(d => this._collapseAll(d));
  }

  private _getType(sourceNode: HierarchyNode<TreeStructure>) {
    return this._store.select(WorkspaceSelectors.selectWorkspaceInfo).pipe(
      first(isNotNull),
      switchMap(workspaceInfo =>
        this._treeApiService.getTreeData(
          workspaceInfo.owner,
          workspaceInfo.id.name,
          sourceNode.data.type
        )
      )
    );
  }

  setNodeSelectFunction(f: any) {
    this._nodeSelectFunction = f;
  }

  setNodeHoverFunction(f: any) {
    this._nodeHoverFunction = f;
  }

  private get _size() {
    return {
      width: this._chartContainer?.offsetWidth || 100,
      height: this._chartContainer?.offsetHeight || 100,
    };
  }

  private _drawLegend() {
    const group = this.baseSvg
      .append('g')
      .attr('transform', 'translate(16,16)');

    group
      .append('rect')
      .attr('width', 150)
      .attr('height', 135)
      .classed('legend', true);

    group
      .append('text')
      .attr('x', 10)
      .attr('y', 20)
      .html('Legend:')
      .classed('legend-title', true)
      .classed('theme-color-fill', true);

    const yNodeDecorator = 30;

    const groupNode = group
      .selectAll('g.node-decorator-legend')
      .data(this._nodeDecorators)
      .enter()
      .append('g')
      .attr('transform', 'translate(10,' + yNodeDecorator + ')');
    groupNode
      .append('text')
      .attr('x', 20)
      .attr('y', 15)
      .html(d => d.name)
      .classed('legend-text', true);
    groupNode
      .append('use')
      .attr('xlink:href', d => d.icon)
      .classed('legend-icon theme-color-fill', true);

    const yColour = yNodeDecorator + 20;

    const groupNodeColours = group
      .selectAll('g.colour-legend')
      .data(ColourLegends)
      .enter()
      .append('g')
      .attr('transform', d => {
        const y = d.id * 20 + yColour;
        return 'translate(10,' + y + ')';
      });
    groupNodeColours
      .append('text')
      .attr('x', 20)
      .attr('y', 15)
      .html(d => d.legend)
      .classed('legend-text', true);
    groupNodeColours
      .append('g')
      .attr('transform', 'translate(5,10)')
      .append('circle')
      .attr('r', this.nodeRadius)
      .attr('y', d => d.id * 20)
      .style('fill', d => d.colour)
      .style('stroke', NodeColours.HasChildren);
  }

  private _uniqueId() {
    return this.idCounter++;
  }

  private _updateSelected(source: HierarchyNode<TreeStructure>) {
    this._clearAll(this.root);
    this._nodeSelectFunction(source);
    this._highlightNode(source);
    this._update(source);
    this._centerNode(source, false);
  }

  private _singleClickNode(sourceNode: HierarchyNode<TreeStructure>) {
    if (sourceNode.children) {
      TreeNavigator.collapse(sourceNode);
      this._updateSelected(sourceNode);
    } else {
      if (sourceNode.data._children && sourceNode.data._children.length > 0) {
        // d._children is present -> open tree to existing data
        TreeNavigator.expand(sourceNode);
        this._updateSelected(sourceNode);
      } else if (sourceNode.data.hasChildren) {
        this._getType(sourceNode).subscribe(newTree => {
          const newDataTree = d3.hierarchy(newTree);
          if (newDataTree.children) {
            newDataTree.children.forEach(
              newChild => (newChild.parent = sourceNode)
            );
          }
          this._setDepth(newDataTree, sourceNode.depth);
          this._collapseAllChildren(newDataTree);

          sourceNode.children = newDataTree.children;
          this._updateSelected(sourceNode);
        });
      } else {
        this._updateSelected(sourceNode);
      }
    }
  }

  private _updateNodes(
    source: HierarchyPointNode<TreeStructure> | HierarchyNode<TreeStructure>,
    treeData: HierarchyPointNode<TreeStructure>
  ) {
    const nodes = treeData.descendants();

    let maxLabelLength = 0;
    nodes.forEach(
      n => (maxLabelLength = Math.max(n.data.name?.length || 0, maxLabelLength))
    );

    nodes.forEach(d => (d.y = d.depth * (maxLabelLength * 6)));

    const node = this.svgGroup
      .selectAll('g.node')
      .data(nodes, (d: any) => d.id || (d.id = this._uniqueId()));

    const nodeEnter = node
      .enter()
      .append('g')
      .attr(
        'transform',
        () => 'translate(' + source.data._y0 + ',' + source.data._x0 + ')'
      )
      .on('click', d => {
        if (d3.event.defaultPrevented) {
          return;
        }
        this._singleClickNode(d);
      })
      .attr('cursor', 'pointer')
      .classed('node', true);

    nodeEnter
      .append('circle')
      .attr('r', 1e-6)
      .style('fill', d =>
        d.data._children ? NodeColours.HasChildren : NodeColours.UnselectedOpen
      )
      .classed('node', true);

    nodeEnter
      .append('text')
      .attr('x', -10)
      .attr('y', 0)
      .attr('dy', '.35em')
      .attr('text-anchor', 'end')
      .html(d => this._nodeTextFunction(d.data))
      .on('mouseover', d => this._nodeHoverFunction(this._getRolloverText(d)))
      .on('mouseout', () => this._nodeHoverFunction(''))
      .append('svg:title')
      .text(d => this._getRolloverText(d, false));
    nodes.forEach(d => (d.data._ic = 0));

    this._nodeDecorators.forEach(nodeDecorator =>
      nodeEnter
        .filter(d => nodeDecorator.check(d.data))
        .append('g')
        .append('use')
        .attr('xlink:href', () => nodeDecorator.icon)
        .classed('legend-icon theme-color-fill', true)
        .on('mouseover', d =>
          this._nodeHoverFunction(nodeDecorator.display(d.data))
        )
        .on('mouseout', () => this._nodeHoverFunction(''))
        .append('svg:title')
        .text(d => nodeDecorator.display(d.data))
    );

    // const nodeUpdate = nodeEnter.merge(node);
    const nodeUpdate = node.merge(nodeEnter as any);

    nodeUpdate
      .transition()
      .duration(this.duration)
      .attr('transform', d => 'translate(' + d.y + ',' + d.x + ')');

    nodeUpdate
      .select('circle.node')
      .attr('r', this.nodeRadius)
      .style('fill', d => TreeNavigator.fillNode(d))
      .style('stroke', d => {
        if (d.data._tag === 'found') {
          return NodeColours.Selected;
        }
        return '';
      });

    let exitTransition = node.exit().transition().duration(this.duration);

    if ('y' in source) {
      exitTransition = exitTransition.attr(
        'transform',
        'translate(' + source.y + ',' + source.x + ')'
      );
    }
    const nodeExit = exitTransition.remove();

    // On exit reduce the node circles size to 0
    nodeExit.select('circle').attr('r', 1e-6);

    // On exit reduce the opacity of text labels
    nodeExit.select('text').style('fill-opacity', 1e-6);
  }

  private _update(source: HierarchyNode<TreeStructure>) {
    if (!this.root) {
      return;
    }
    this.tree = this.tree.size([this._calculateHeight(), this._size.width]);

    const treeData = this.tree(this.root);
    this._highlight();

    this._updateNodes(source, treeData);
    this._updateLinks(source, treeData);
    TreeNavigator.saveNodePositions(treeData);

    this._collapseAllChildren(source);
  }

  private _centerNode(
    source: HierarchyNode<TreeStructure>,
    resetZoom: boolean
  ) {
    const node = this.baseSvg.node();

    if (!node || !source.data._y0 || !source.data._x0) {
      return;
    }

    const scale = resetZoom ? ZOOM_SCALE : d3.zoomTransform(node).k;

    const x = -source.data._y0 * scale + this._size.width * 0.5;
    const y = -source.data._x0 * scale + this._size.height * 0.5;

    this.baseSvg
      .transition()
      .duration(this.duration)
      .call(
        this.zoomListener.transform,
        d3.zoomIdentity.translate(x, y).scale(scale)
      );
  }

  private _updateLinks(
    source: HierarchyPointNode<TreeStructure> | HierarchyNode<TreeStructure>,
    treeData: HierarchyPointNode<TreeStructure>
  ) {
    const links = treeData.descendants().slice(1);
    const start = { x: source.data._x0, y: source.data._y0 };
    const end =
      'y' in source
        ? { x: source.x, y: source.y }
        : { x: source.data._x0, y: source.data._y0 };

    const link = this.svgGroup
      .selectAll('path.node-link')
      .data(links, (d: any) => d.id);

    const linkEnter = link
      .enter()
      .insert('path', 'g')
      .attr('d', () => TreeNavigator.diagonalCurvedPath(start, start))
      .classed('node-link', true);

    const linkUpdate = link.merge(linkEnter as any);

    // const linkUpdate = linkEnter.merge(link);

    linkUpdate
      .transition()
      .duration(this.duration)
      .attr('d', d => TreeNavigator.diagonalCurvedPath(d, d.parent as any));

    link
      .exit()
      .transition()
      .duration(this.duration)
      .attr('d', () => TreeNavigator.diagonalCurvedPath(end, end))
      .remove();
  }

  private _calculateHeight() {
    const levelWidth = [1];

    function childCount(level: number, n?: HierarchyNode<TreeStructure>) {
      if (n && n.children && n.children.length > 0) {
        if (levelWidth.length <= level + 1) {
          levelWidth.push(0);
        }

        levelWidth[level + 1] += n.children.length;
        n.children.forEach(d => childCount(level + 1, d));
      }
    }

    childCount(0, this.root);
    return d3.max(levelWidth) * 25;
  }

  private _getRolloverText(
    d: HierarchyNode<TreeStructure>,
    html = true
  ): string {
    return `${html ? '<strong>' : ''}Name:${
      html ? '</strong>' : ''
    } ${this._nodeTextFunction(d.data)} ${html ? '<strong>' : ''}Type:${
      html ? '</strong>' : ''
    } ${d.data.type ? d.data.type : d.data.name}`;
  }

  private _highlightNode(node: HierarchyNode<TreeStructure>) {
    const nodeIds: string[] = [];
    let parent = node;
    while (parent && parent.id) {
      nodeIds.push(parent.id);
      parent = parent.parent as any;
    }
    this.highlightNodes = nodeIds;
    this._highlight();
  }

  private _highlight() {
    if (this.highlightNodes.length > 0) {
      this.highlightNodes.forEach(className =>
        this.searchFields.forEach(f => {
          if (this.root) {
            this._searchTree(this.root, f, className);
          }
        })
      );
    }
  }

  private _expandHighlighted() {
    if (!this.root) {
      return undefined;
    }
    if (this.highlightNodes.length > 0) {
      this._collapseAll(this.root);
      const anies = this._expandAllFound(this.root);
      if (anies.length > 0) {
        return anies.pop();
      }
    }
    return this.root;
  }

  private _searchTree(
    d: HierarchyNode<TreeStructure>,
    searchField: any,
    searchText: string
  ) {
    if (d.children) {
      for (const child of d.children) {
        this._searchTree(child, searchField, searchText);
      }
    } else if (d.data._children) {
      for (const child of d.data._children) {
        this._searchTree(child, searchField, searchText);
      }
    }
    let matched = false;
    if (typeof searchField === 'function') {
      matched = searchField(d);
    } else if ((d as any)[searchField]) {
      matched = (d as any)[searchField] === searchText;
    } else if ((d.data as any)[searchField]) {
      matched = (d.data as any)[searchField] === searchText;
    }

    if (matched) {
      let parent: HierarchyNode<TreeStructure> | null = d;
      while (parent !== null) {
        parent.data._tag = 'found';
        parent = parent.parent;
      }
    }
  }

  private _clearAll(d?: HierarchyNode<TreeStructure>) {
    if (!d) {
      return;
    }
    d.data._tag = '';
    if (d.children) {
      d.children.forEach(x => this._clearAll(x));
    } else if (d.data._children) {
      d.data._children.forEach(x => this._clearAll(x));
    }
  }

  private _collapseAll(d: HierarchyNode<TreeStructure>) {
    if (d.children) {
      d.data._children = d.children;
      d.data._children.forEach(x => this._collapseAll(x));
      d.children = undefined;
    }
  }

  private _setDepth(d: HierarchyNode<TreeStructure>, depth: number) {
    (d as any).depth = depth;
    if (d.children) {
      d.children.forEach(x => this._setDepth(x, depth + 1));
    }
  }

  private _collapseAllChildren(source: HierarchyNode<TreeStructure>) {
    if (source.children) {
      source.children.forEach(d => this._collapseAll(d));
    }
  }

  private _expandAllFound(d: HierarchyNode<TreeStructure>) {
    const found = [];
    if (d.data._children) {
      if (d.data._tag === 'found') {
        found.push(d);
        if (this.openSiblingOnSearch) {
          d.children = d.data._children;
          d.children.forEach(b =>
            this._expandAllFound(b).forEach(x => found.push(x))
          );
          d.data._children = undefined;
        } else {
          let childrenAreFoundToo = false;
          d.data._children.forEach(
            s =>
              (childrenAreFoundToo =
                childrenAreFoundToo || s.data._tag === 'found')
          );
          if (childrenAreFoundToo) {
            d.children = d.data._children;
            d.children.forEach(b =>
              this._expandAllFound(b).forEach(x => found.push(x))
            );
            d.data._children = undefined;
          }
        }
      }
    }
    return found;
  }
}
