import { LoadingState, TaskStatus, TranslateIngestionFile } from '@models';
import { createFeatureSelector, createSelector, select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import {
  arrayToTree,
  calculateDescendantCompletenessScore,
  deepCopy,
  getLeafDescendantAggregatedStatus,
} from '@utils';
import { filter, first, pipe } from 'rxjs';

import {
  EnvironmentConfig,
  ExpectationsMap,
  FileData,
  FileFullMap,
  FileMap,
  FileMapTree,
  SampleFileNode,
  SampleFileNodeFull,
  TestType,
} from '../../models';
import * as TranslateReducer from '../reducers';
import {
  aggregateNodeData,
  createSampleFileNodeFull,
  createSampleFileNodeTree,
} from './selector.helper';

const selectFeature = createFeatureSelector<TranslateReducer.State>(
  TranslateReducer.featureKey
);

// Store slice selectors
const selectFileMap = createSelector(
  selectFeature,
  (state: TranslateReducer.State) => state.fileMap
);

// File Ids
const selectFileIdsAll = createSelector(
  selectFeature,
  (state: TranslateReducer.State) => state.fileIds.all
);
const selectFileIdsLeaf = createSelector(
  selectFeature,
  (state: TranslateReducer.State) => state.fileIds.leaf
);
const selectFileIdsUpload = createSelector(
  selectFeature,
  (state: TranslateReducer.State) => state.fileIds.uploaded
);
const selectFileIdsNode = createSelector(
  selectFeature,
  (state: TranslateReducer.State) => state.fileIds.node
);
const selectFileIdsFiltered = createSelector(
  selectFeature,
  (state: TranslateReducer.State) => state.fileIds.filtered
);
const selectRunState = createSelector(
  selectFeature,
  (state: TranslateReducer.State) => state.runState
);

// File Data
const selectFileData = createSelector(
  selectFeature,
  (state: TranslateReducer.State) => state.fileData
);
const selectFileExpectations = createSelector(
  selectFeature,
  (state: TranslateReducer.State) => state.fileData.expectations
);
const selectFileResults = createSelector(
  selectFeature,
  (state: TranslateReducer.State) => state.fileData.results
);
const selectFileStatuses = createSelector(
  selectFeature,
  (state: TranslateReducer.State) => state.fileData.statuses
);

const selectFileFilter = createSelector(
  selectFeature,
  (state: TranslateReducer.State) => state.filter
);
const selectState = createSelector(
  selectFeature,
  (state: TranslateReducer.State) => state.state
);
const selectUploadingState = createSelector(
  selectFeature,
  (state: TranslateReducer.State) => state.uploadingState
);
const selectEnvironmentConfig = createSelector(
  selectFeature,
  (state: TranslateReducer.State) => state.environmentConfig
);

// Computed store data
const sampleFileLoading = createSelector(
  selectState,
  (state: LoadingState) => state === LoadingState.LOADING
);

const sampleFileUploading = createSelector(
  selectUploadingState,
  (state: LoadingState) => state === LoadingState.LOADING
);

const getFullNodeFileMap = createSelector(
  selectFileIdsAll,
  selectFileMap,
  selectFileData,
  (
    ids: string[],
    fileMap: FileMap,
    { expectations, results, statuses, errors }: FileData
  ) => {
    return ids.reduce<Record<string, SampleFileNodeFull>>((acc, id) => {
      acc[id] = createSampleFileNodeFull(
        fileMap[id],
        expectations[id],
        results[id],
        statuses[id],
        errors[id]
      );
      return acc;
    }, {});
  }
);

const getSampleFileNodeFull = (id: string) =>
  createSelector(
    selectFileMap,
    selectFileData,
    (fileMap: FileMap, { expectations, results, statuses, errors }: FileData) =>
      fileMap[id] &&
      createSampleFileNodeFull(
        fileMap[id],
        expectations[id],
        results[id],
        statuses[id],
        errors[id]
      )
  );

const getNodeStatus = (id: string) =>
  createSelector(selectFileData, ({ statuses }: FileData) => statuses[id]);

const getNodeResult = (id: string) =>
  createSelector(selectFileData, ({ results }: FileData) => results[id]);

const getFilteredSampleFileList = createSelector(
  selectFileMap,
  selectFileIdsFiltered,
  (fileMap: FileMap, filteredIds: string[]) =>
    filteredIds.map(id => fileMap[id])
);

const getFilteredSampleFileNodeFullList = createSelector(
  getFilteredSampleFileList,
  selectFileData,
  (
    files: SampleFileNode[],
    { expectations, results, statuses, errors }: FileData
  ) =>
    files.map<SampleFileNodeFull>(file =>
      createSampleFileNodeTree(
        file,
        expectations[file.id],
        results[file.id],
        statuses[file.id],
        errors[file.id]
      )
    )
);

const getFilteredLeafNodesFull = createSelector(
  getFilteredSampleFileNodeFullList,
  (fileList: SampleFileNodeFull[]) => fileList.filter(file => file.isLeaf)
);

const getFilteredSampleFileNodeFullTree = createSelector(
  getFullNodeFileMap,
  getFilteredLeafNodesFull,
  (fileMap: FileFullMap, fileList: SampleFileNodeFull[]): FileMapTree => {
    const copyFileMap = deepCopy(fileMap);
    return {
      fileMap: copyFileMap,
      tree: arrayToTree(fileList, copyFileMap, aggregateNodeData),
    };
  }
);

const getFilteredSampleFileNodeFullTreePipe = pipe(
  filter(
    (state: AppState) =>
      state[TranslateReducer.featureKey].state === LoadingState.LOADED
  ),
  select(getFilteredSampleFileNodeFullTree),
  first()
);

const getNodeDescendantsStatus = (id: string) =>
  createSelector(
    getFilteredSampleFileNodeFullTree,
    ({ fileMap }: FileMapTree) => getLeafDescendantAggregatedStatus(fileMap[id])
  );

const getNodeCompletenessScore = (id: string) =>
  createSelector(
    getFilteredSampleFileNodeFullTree,
    ({ fileMap }: FileMapTree) =>
      calculateDescendantCompletenessScore(fileMap[id])
  );

const getSampleFileCount = createSelector(
  getFilteredLeafNodesFull,
  (files: SampleFileNodeFull[]) => files.length
);

const envToSynonym = (envName: string) =>
  createSelector(
    selectEnvironmentConfig,
    (config: Record<string, EnvironmentConfig>) =>
      config[envName] || Object.values(config)[0]
  );

const getAllEnvironments = createSelector(
  selectFileMap,
  selectFileIdsLeaf,
  (fileMap: FileMap, allLeafIds: string[]) => [
    ...new Set(
      allLeafIds.reduce<string[]>(
        (acc, id) => acc.concat(fileMap[id]?.environments || []),
        []
      )
    ),
  ]
);

const getSampleFileFilteredEnvironmentCount = createSelector(
  getFilteredLeafNodesFull,
  (files: SampleFileNodeFull[]) =>
    [
      ...new Set(
        files.reduce<string[]>(
          (acc, file) => acc.concat(file.environments || []),
          []
        )
      ),
    ].length
);

const getSampleFileUploadCount = createSelector(
  selectFileIdsUpload,
  (ids: string[]) => ids.length
);

const getTranslateIngestionFilesForRun = (ids: string[]) =>
  createSelector(
    selectFileMap,
    selectFileExpectations,
    selectFileData,
    (fileMap: FileMap, expectations: ExpectationsMap, { statuses }: FileData) =>
      ids
        .filter(id => statuses[id] !== TaskStatus.Unable_To_Run)
        .map(id => {
          const node = fileMap[id];
          const isUpload = node.testType === TestType.UPLOADED_TO_CLIENT;
          const fileContent = isUpload ? node.content : null;
          const result: TranslateIngestionFile = {
            id,
            fileName: expectations[id].fileName,
            ingestionEnvironment: node.environments[0],
            testType: node.testType,
          };

          if (fileContent) {
            result.fileContent = fileContent;
          }

          return result;
        })
  );

export const TranslateSelectors = {
  envToSynonym,
  getAllEnvironments,
  getFilteredLeafNodesFull,
  getFilteredSampleFileList,
  getFilteredSampleFileNodeFullList,
  getFilteredSampleFileNodeFullTree,
  getFilteredSampleFileNodeFullTreePipe,
  getFullNodeFileMap,
  getNodeCompletenessScore,
  getNodeDescendantsStatus,
  getNodeResult,
  getNodeStatus,
  getSampleFileCount,
  getSampleFileFilteredEnvironmentCount,
  getSampleFileNodeFull,
  getSampleFileUploadCount,
  getTranslateIngestionFilesForRun,
  sampleFileLoading,
  sampleFileUploading,
  selectEnvironmentConfig,
  selectFeature,
  selectFileData,
  selectFileExpectations,
  selectFileFilter,
  selectFileIdsAll,
  selectFileIdsFiltered,
  selectFileIdsLeaf,
  selectFileIdsNode,
  selectFileIdsUpload,
  selectFileMap,
  selectFileResults,
  selectFileStatuses,
  selectRunState,
  selectState,
  selectUploadingState,
};
