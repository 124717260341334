import { NgModule } from '@angular/core';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatMenuModule } from '@angular/material/menu';
import { RouterModule } from '@angular/router';
import { LetDirective } from '@ngrx/component';
import { ClickToOpenUpgradeDialogDirective } from '@shared/directives/click-to-open-upgrade-dialog.directive';
import { ShowUpgradeOfferDirective } from '@shared/directives/show-upgrade-offer.directive';
import {
  HasAbilityDirectiveModule,
  TextBadgeModule,
  ThemeToggleModule,
} from '@shared/modules';
import { InlineErrorModule } from '@shared/modules/inline-error/inline-error.module';
import { LogoModule } from '@shared/modules/logo/logo.module';
import { RosettaMenuComponent } from '@shared/modules/rosetta-menu.component';
import { RosettaOverlayModule } from '@shared/modules/rosetta-overlay/rosetta-overlay.modules';
import { UpgradePlanButtonComponent } from '@shared/modules/upgrade-plan-button.component';
import { SharedModule } from '@shared/shared.module';
import { HelpMenuComponent } from './components/help-menu/help-menu.component';
import { IssueTrackerComponent } from './components/issue-tracker/issue-tracker.component';
import { NotificationMenuComponent } from './components/notification-menu/notification-menu.component';
import { TextComponent } from './components/text/text.component';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { NavbarComponent } from './pages/navbar.component';

@NgModule({
  declarations: [
    NavbarComponent,
    HelpMenuComponent,
    NotificationMenuComponent,
    TextComponent,
    UserMenuComponent,
    IssueTrackerComponent,
  ],
  imports: [
    RosettaOverlayModule,
    ThemeToggleModule,
    TextBadgeModule,
    InlineErrorModule,
    HasAbilityDirectiveModule,
    RouterModule,
    SharedModule,
    MatButtonToggleModule,
    MatMenuModule,
    LetDirective,
    LogoModule,
    RosettaMenuComponent,
    UpgradePlanButtonComponent,
    ShowUpgradeOfferDirective,
    ClickToOpenUpgradeDialogDirective,
  ],
})
export class NavbarModule {}
