import { Injectable } from '@angular/core';
import { TaskService } from '@core/services';
import {
  CodeCompilationResult,
  Task,
  TaskNotification,
  TaskStatus,
} from '@models';
import { Store } from '@ngrx/store';
import { TaskActions } from '@store/.';
import { TaskListeners } from '../task-listener.models';

@Injectable()
export class PojoCompilationListenerService implements TaskListeners {
  constructor(
    private _store: Store,
    private _taskService: TaskService
  ) {}

  action(task: TaskNotification) {
    if (task.task !== Task.PojoCompilation) {
      return;
    }

    if (task.status === 'STARTED') {
      this._taskService.updateTaskStatus(
        task.task,
        undefined,
        TaskStatus.Started
      );
      // Update dependent tasks
      this._taskService.updateAllSubTaskStatus(
        Task.StaticJavaCompilation,
        TaskStatus.Pending
      );

      this._store.dispatch(TaskActions.taskStarted({ name: task.task }));
    }

    if (task.status === 'FINISHED') {
      const pojoCompileResult = task.payload as CodeCompilationResult;
      const pojoCompileStatus = pojoCompileResult.success
        ? TaskStatus.Finished
        : TaskStatus.Error;
      this._taskService.updateTaskStatus(
        task.task,
        undefined,
        pojoCompileStatus
      );

      this._store.dispatch(
        TaskActions.taskFinished({
          name: task.task,
          payload: task.payload,
        })
      );
    }
  }
}
