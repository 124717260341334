import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { RosettaClassDetails, SymbolIdentifier } from '@models';

@Component({
  selector: 'app-rosetta-tree-node-attribute',
  templateUrl: './rosetta-tree-node-attribute.component.html',
  styleUrls: ['./rosetta-tree-node-attribute.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RosettaTreeNodeAttributeComponent {
  constructor(private _cdr: ChangeDetectorRef) {}

  @Input()
  set classDetails(details: Partial<RosettaClassDetails>) {
    if (!details) {
      return;
    }

    this.classDetail = details;

    const { uri, namespace, className } = details;

    if (uri && namespace && className) {
      this.classIdentification = {
        uri,
        namespace,
        fqn: `${namespace}.${className}`,
        name: className,
        syntax: '',
      };
    }

    this._cdr.detectChanges();
  }

  @Input()
  set rosettaPath(v: string) {
    this.#rosettaPath = v;
  }
  get rosettaPath() {
    return this.#rosettaPath;
  }
  #rosettaPath = '';

  @Output() hierarchyItem = new EventEmitter<string>();

  classDetail?: Partial<RosettaClassDetails>;
  classIdentification?: SymbolIdentifier;

  showHierarchy() {
    this.hierarchyItem.emit(this.classDetail?.className);
  }
}
