import { Task, TaskStatus } from '@models';
import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import * as actions from '../actions/task.actions';

export const featureKey = 'tasks';

export interface TaskStatusMap<T = unknown> {
  [Task.StaticJavaCompilation]?: T;
  [Task.PojoCompilation]?: T;
  [Task.RootTypeCache]?: T;
  [Task.ExecutionEngineInitialisation]?: T;
}
export type TaskStatusKey = keyof TaskStatusMap;

export interface State {
  lastStatusChange: number;
  statusMap: TaskStatusMap<TaskStatus>;
  taskMap: TaskStatusMap<any>;
}

export const initialState: State = {
  lastStatusChange: Date.now(),
  statusMap: {},
  taskMap: {},
};

export const reducer = createReducer(
  initialState,
  on(actions.switchWorkspace, state => ({ ...state, statusMap: {} })),
  on(actions.taskStarted, (state, { name }) =>
    produce(state, draft => {
      // When a new task is started and both statuses are finished we can
      // assume a new task has started and we should reset them to pending
      if (
        draft.statusMap[Task.StaticJavaCompilation] === TaskStatus.Finished &&
        draft.statusMap[Task.PojoCompilation] === TaskStatus.Finished &&
        draft.statusMap[Task.ExecutionEngineInitialisation] ===
          TaskStatus.Finished
      ) {
        draft.statusMap[Task.StaticJavaCompilation] = TaskStatus.Pending;
        draft.statusMap[Task.PojoCompilation] = TaskStatus.Pending;
        draft.statusMap[Task.ExecutionEngineInitialisation] =
          TaskStatus.Pending;
      }

      draft.statusMap[name] = TaskStatus.Started;
    })
  ),
  on(actions.taskFinished, (state, { name, payload }) =>
    produce(state, draft => {
      if (payload.success || !payload.hasOwnProperty('success')) {
        draft.statusMap[name] = TaskStatus.Finished;
      } else {
        draft.statusMap[name] = TaskStatus.Error;
      }

      draft.lastStatusChange = Date.now();
      draft.taskMap[name] = payload;
    })
  )
);
