import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ToolbarAction } from '@workspace-design/textual/models/toolbar-action.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-editor-button',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'editor-button',
  },
  template: `
    <button
      mat-icon-button
      [disabled]="disabled | async"
      (click)="actions.runAction()"
      [matTooltip]="actions.tooltip"
    >
      <div
        *ngIf="actions.tourAnchor; else elseTpl"
        [tourAnchor]="actions.tourAnchor"
      >
        <ng-container *ngTemplateOutlet="elseTpl" />
      </div>

      <ng-template #elseTpl>
        <fa-layers
          class="document-format"
          [fixedWidth]="true"
          *ngIf="actions.formatDocument; else iconTmpl"
        >
          <fa-layers-text content="{...}" />
        </fa-layers>
        <ng-template #iconTmpl>
          <fa-icon [icon]="actions.icon" [style.color]="actions?.color" />
        </ng-template>
      </ng-template>
    </button>
  `,
})
export class EditorButtonComponent {
  @Input() disabled?: Observable<boolean>;
  @Input() actions!: ToolbarAction;
}
