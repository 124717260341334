<ng-template #docReferenceTemplate let-docReference="docReference">
  <span class="mat-body-strong">Body</span> {{ docReference.body }}
  <span class="mat-body-strong">Corpus</span>
  {{ docReference.corpusList.join(' ') }}
  <div *ngFor="let ref of docReference.corpusRef">
    <span class="mat-body-strong"> {{ ref.segmentType }}</span> "{{
      ref.segment
    }}"
  </div>
  <span class="mat-caption">{{ docReference.provision }}</span>
  <mat-divider />
</ng-template>

<ng-scrollbar *ngIf="classDetail">
  <h2>
    {{ classDetail.className }}
    <app-copy-to-clipboard [path]="rosettaPath" />
  </h2>

  <div class="flex-row mb-24" *ngIf="classDetail.uri">
    <button
      mat-stroked-button
      class="mr-8"
      [appGoToSymbol]="classIdentification"
    >
      <fa-icon icon="pen" />
      Textual
    </button>

    <button mat-stroked-button (click)="showHierarchy()">
      <fa-icon icon="sitemap" />
      Hierarchy
    </button>
  </div>

  <div
    class="class-definition"
    *ngFor="let docReference of classDetail?.docReferences"
  >
    <ng-container
      *ngTemplateOutlet="docReferenceTemplate; context: { docReference }"
    />
  </div>

  <p class="class-definition mb-24">{{ classDetail.definition }}</p>

  <div *ngIf="classDetail.attributes?.length > 0">
    <mat-divider class="mb-24" />

    <h3 class="font-weight-semi-bold">Attributes</h3>

    <mat-list dense>
      <mat-list-item *ngFor="let classAttribute of classDetail.attributes">
        <div matListItemTitle class="pb-4">
          <span>{{ classAttribute.name }}</span
          ><fa-icon
            *ngIf="
              classAttribute?.docReferences?.length > 0 ||
              classAttribute.description
            "
            [appRosettaOverlayHoverTarget]="overlay"
            icon="info-circle"
            class="ml-4 opacity-50 cursor-pointer"
          />
        </div>
        <code matListItemLine *ngIf="classAttribute.attributes.cardinality">
          {{ classAttribute.type }}
          {{ classAttribute.attributes.cardinality }}
        </code>

        <app-rosetta-overlay #overlay>
          <app-rosetta-menu maxWidth="250">
            <div *ngFor="let docReference of classAttribute.docReferences">
              <ng-container
                *ngTemplateOutlet="
                  docReferenceTemplate;
                  context: { docReference: docReference }
                "
              />
            </div>

            <p>{{ classAttribute.description }}</p>
          </app-rosetta-menu>
        </app-rosetta-overlay>
      </mat-list-item>
    </mat-list>
  </div>
</ng-scrollbar>
