import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { User } from '@features/auth/login';
import {
  CopyWorkspaceRequest,
  UserSession,
  WorkspaceId,
  WorkspaceInfo,
} from '@models';
import { createFormDataWithFile } from '@utils';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class SupportApiService {
  constructor(
    private _http: HttpClient,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  getUserSessions(): Observable<UserSession[]> {
    return this._http.get<UserSession[]>(
      `${this._config.resourcePaths.notificationServer}/users`
    );
  }

  getWorkspaceInfosForUser(userId: string): Observable<WorkspaceInfo[]> {
    return this._http.get<WorkspaceInfo[]>(
      `${
        this._config.resourcePaths.workspaceCore
      }/workspace-infos-for-user/${encodeURIComponent(userId)}`
    );
  }

  workspaceExistsForUser(
    userId: string,
    workspaceName: string
  ): Observable<boolean> {
    return this._http.get<boolean>(
      `${this._config.resourcePaths.workspaceCore}/${userId}/${workspaceName}/exists`
    );
  }

  downloadAndZipWorkspace(workspaceId: WorkspaceId): Observable<Blob> {
    return this._http
      .get(
        `${this._config.resourcePaths.workspaceCore}/${workspaceId.name}/download`,
        {
          responseType: 'blob',
        }
      )
      .pipe(catchError(() => throwError(() => 'Error zipping workspace')));
  }

  uploadWorkspace(file: File, workspaceName: string): Observable<void> {
    const formData = createFormDataWithFile(file);

    return this._http.post<void>(
      `${this._config.resourcePaths.workspaceCore}/${workspaceName}/upload`,
      formData
    );
  }

  copyWorkspace(request: CopyWorkspaceRequest): Observable<void> {
    return this._http.post<void>(
      `${this._config.resourcePaths.workspaceCore}/copy-workspace`,
      request
    );
  }

  listUsers(filterString: string): Observable<User[]> {
    return this._http.get<User[]>(
      `${
        this._config.resourcePaths.userServer
      }/support/list?filter=${encodeURIComponent(filterString)}`
    );
  }
}
