import { Task } from '@models';

export const CODE_EDITOR_SAVE_INTERVAL = 5_000;
export const WORKSPACE_LOAD_TIMEOUT = 45_000;
export const READ_ONLY_PREFIX = 'read-only';
export const READ_WRITE_PREFIX = 'read-write';
export const DEFAULT_MODEL_NAME = `${READ_ONLY_PREFIX}-COMMON-DOMAIN-MODEL`;
export const BLANK_MODEL_URI = 'rosetta-core-blank-model-uri';
export const OVERRIDE_NAMESPACE_SYNTAX = 'override';
export const WORKSPACE_READY = 'uiReady';
export const WORKSPACE_READY_TASKS = [
  Task.PojoCompilation,
  Task.StaticJavaCompilation,
  Task.ExecutionEngineInitialisation,
] as const;
