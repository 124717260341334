import {
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
  RouteReuseStrategy,
} from '@angular/router';
import { RouteData } from '@models';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private _storedRoutes = new Map<string, DetachedRouteHandle>();

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return (route.data as RouteData)?.reuse || false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    if (handle) {
      this._storedRoutes.set(this._getRouteKey(route), handle);
    }
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return (
      !!route.routeConfig && !!this._storedRoutes.has(this._getRouteKey(route))
    );
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this._storedRoutes.get(this._getRouteKey(route)) || null;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future.routeConfig === curr.routeConfig;
  }

  clearCache() {
    this._storedRoutes.clear();
  }

  private _getRouteKey(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .filter(u => u.url)
      .map(u => u.url)
      .join('/');
  }
}
