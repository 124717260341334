import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import {
  Diagnostic,
  DiagnosticGroup,
  DiagnosticItem,
  DiagnosticTemplateDetails,
} from '@shared/modules/diagnostic-panel/diagnostic.model';

@Component({
  selector: 'app-diagnostic',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './diagnostic.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  host: {
    class: 'diagnostic',
  },
})
export class DiagnosticComponent {
  @Input() data: Diagnostic | null;

  @Output() panelClicked = new EventEmitter<DiagnosticItem>();

  templateMap = new Map<DiagnosticGroup['def'], DiagnosticTemplateDetails>();
}
