import { RosettaSelectorBase } from '@shared/modules/rosetta-selector/rosetta-selector.base.service';
import { Observable } from 'rxjs';

import { PipelineDef } from '../../models/pipeline-def.model';
import { ITransformConfig } from '../../models/transform-config.model';
import { TransformStateSyncService } from '../transform-state-sync.service';
import { TransformStorageService } from '../transform-storage.service';

export class PipelineSelector extends RosettaSelectorBase<PipelineDef> {
  constructor(
    private _transformStorage: TransformStorageService,
    private _transformStateSync: TransformStateSyncService,
    private _transformConfig: ITransformConfig
  ) {
    super(_transformConfig.pipelineSelector);
  }

  override getListItemValue = (item: PipelineDef) => item.id;
  override getListItemLabel = (item: PipelineDef) => item.name;

  get value(): string {
    return this._transformStateSync.get(
      this._transformConfig.type,
      this._transformConfig.pipelineSelector.key
    );
  }
  set value(_value: string) {
    // Value is set by pipeline and stored in the State Sync Service
  }

  protected fetchData(): Observable<PipelineDef[]> {
    return this._transformStorage.getPipelines();
  }
}
