import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { HEX } from '@models';
import { contrast, lightenDarkenColor } from '@utils';

@Component({
  standalone: true,
  selector: 'app-dev-banner',
  host: { class: 'dev-banner' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<small>Development</small>`,
  styles: [
    `
      :host {
        display: block;
        text-transform: uppercase;
        text-align: center;
      }
    `,
  ],
})
export class DevBannerComponent {
  @Input() color: HEX | null | undefined;
  @HostBinding('style.color')
  get styleColor() {
    return this.color && contrast(this.color);
  }
  @HostBinding('style.background')
  get backgroundColor() {
    return this.color && lightenDarkenColor(this.color, 20);
  }
}
