import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { getDialogOptions } from '@configs';
import { WorkspaceApiService } from '@core/services';
import { DialogComponent, Task, WorkspaceId } from '@models';
import { Store } from '@ngrx/store';
import { TaskActions } from '@store/.';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { saveAs } from 'file-saver';
import { first, tap } from 'rxjs';

@Component({
  selector: 'app-download-workspace-dialog',
  templateUrl: './download-workspace-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadWorkspaceDialogComponent implements DialogComponent {
  constructor(
    private _wsApi: WorkspaceApiService,
    private _store: Store
  ) {}

  downloads = ['Rosetta', 'Java'] as const;

  static options(): MatDialogConfig {
    return getDialogOptions('xs');
  }

  download(downloadType: 'Rosetta' | 'Java' | 'Daml') {
    const downloadMap = {
      Rosetta: this._downloadRosetta.bind(this),
      Java: this._downloadJava.bind(this),
      Daml: this._downloadDaml.bind(this),
    };

    this._store
      .select(WorkspaceSelectors.selectWorkspaceId)
      .pipe(
        first(),
        tap(workspaceId => {
          downloadMap[downloadType](workspaceId);
        })
      )
      .subscribe();
  }

  private _downloadRosetta(workspaceId: WorkspaceId) {
    this._wsApi
      .downloadAndZipRosetta(workspaceId)
      .pipe(
        tap(data => {
          saveAs(data, workspaceId.name + '-rosetta.zip');
        })
      )
      .subscribe();
  }

  private _downloadJava(workspaceId: WorkspaceId) {
    this._wsApi
      .downloadAndZipJava(workspaceId)
      .pipe(
        tap(data => {
          saveAs(data, workspaceId.name + '-java.zip');
        })
      )
      .subscribe();
  }

  private _downloadDaml(_workspaceId: WorkspaceId) {
    this._store.dispatch(
      TaskActions.submitTask({
        data: {
          task: Task.DamlGenerate,
          payload: true,
        },
      })
    );
  }
}
