import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { ScrollToElementDirective } from '../../directives/scroll-to-element.directive';
import { RegProvisionDetails } from '../../models';
import { SelectedElementIdEvent } from '../../models/scroll-to.model';

@Component({
  selector: 'app-provision-details',
  templateUrl: './provision-details.component.html',
  styleUrls: ['./provision-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'provision-details theme-bg-alt' },
})
export class ProvisionDetailsComponent {
  @ViewChild(ScrollToElementDirective)
  private readonly _scrollToElementDirective!: ScrollToElementDirective;

  @ViewChild(NgScrollbar) scrollbar!: NgScrollbar;

  @Output() fragmentChanged = new EventEmitter<SelectedElementIdEvent>();
  @Input() provisionDetails: RegProvisionDetails | null = null;

  @Input()
  set selectedSegmentEvent(value: SelectedElementIdEvent) {
    if (value?.type === 'click') {
      const selector = `[data-fragment="${value.id}"]`;

      if (this.scrollToElementSelector === selector) {
        // Change detection does not get triggered when value is the same so trigger scroll to manually
        this._scrollToElementDirective.scrollToElement();
      }

      this.scrollToElementSelector = selector;
    }
  }

  scrollToElementSelector?: string;
  scrollOptions = {
    top: -16,
  };

  onFragmentChanged(value: string) {
    this.fragmentChanged.emit({ type: 'scroll', id: value });
  }
}
