/*
Use numeric values so that we can easily check:
- Ranges
- Passed a certain state
*/
export enum StatusStateName {
  Error = -3,
  Missing = -2,
  Empty = -1,
  Pending = 0, // We want pending to always be "0"
  Started = 1,
  Finished = 2,
}
