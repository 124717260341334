import { Inject, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { APP_UPDATE_CHECK, IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { ConfirmationService } from '@core/modules/confirmation/confirmation.service';
import { filter, switchMap, timer } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  constructor(
    private _updates: SwUpdate,
    private _confirm: ConfirmationService,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  init() {
    if (!this._updates.isEnabled) {
      return;
    }

    timer(0, APP_UPDATE_CHECK)
      .pipe(
        switchMap(() => this._updates.checkForUpdate()),
        filter(updateAvailable => !!updateAvailable)
      )
      .subscribe(() => this._promptUser());
  }

  private _promptUser() {
    this._confirm
      .open(
        {
          message: this._config.text.uiUpdate,
          confirmButtonText: 'Refresh',
        },
        true
      )
      .afterClosed()
      .subscribe(shouldReload => shouldReload && this._reloadPage());
  }

  private _reloadPage() {
    this._updates.activateUpdate().then(() => document.location.reload());
  }
}
