import { Injectable } from '@angular/core';
import { BaseStorageService } from '@core/services/base-storage.service';
import { IngestRunResult } from '@models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslateResultsStorageService extends BaseStorageService<IngestRunResult> {
  override readonly namespace = 'RESULTS';

  constructor() {
    super();
  }

  set(result: IngestRunResult): Observable<IngestRunResult> {
    return this._set(result.id, result);
  }

  update(result: IngestRunResult) {
    return this._update(result.id, result);
  }
}
