import { AgGridModule } from '@ag-grid-community/angular';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { LetDirective } from '@ngrx/component';
import {
  ModelPipeModule,
  ProjectLogoModule,
  ProjectRowModule,
} from '@shared/modules';
import { TableColumnMenuComponent } from '@shared/modules/rosetta-table/menu-components/table-column-menu/table-column-menu.component';
import { RosettaTableComponent } from '@shared/modules/rosetta-table/rosetta-table.component';
import { SharedModule } from '@shared/shared.module';
import { EmptyStringPipeModule } from '../empty-string-pipe/empty-string-pipe.module';
import { FilteredListComponent } from '../filtered-list/filtered-list.component';
import { RosettaMenuComponent } from '../rosetta-menu.component';
import { RosettaOverlayModule } from '../rosetta-overlay/rosetta-overlay.modules';
import { TextInputComponent } from '../text-input/text-input.component';
import { UpgradeIconModule } from '../upgrade-icon/upgrade-icon.module';
import * as fromCellComponents from './cell-components';
import * as fromEditorComponents from './editor-components';
import * as fromFilterComponents from './filter-components';
import * as fromTooltipComponents from './tooltip-components';

ModuleRegistry.registerModules([ClientSideRowModelModule, CsvExportModule]);

@NgModule({
  declarations: [
    RosettaTableComponent,
    TableColumnMenuComponent,
    ...fromCellComponents.components,
    ...fromTooltipComponents.components,
    ...fromFilterComponents.components,
    ...fromEditorComponents.components,
  ],
  imports: [
    SharedModule,
    RosettaOverlayModule,
    MatListModule,
    AgGridModule,
    DragDropModule,
    MatDatepickerModule,
    MatNativeDateModule,
    EmptyStringPipeModule,
    ProjectLogoModule,
    ProjectRowModule,
    UpgradeIconModule,
    ModelPipeModule,
    LetDirective,
    RosettaMenuComponent,
    TextInputComponent,
    FilteredListComponent,
  ],
  exports: [RosettaTableComponent, EmptyStringPipeModule],
})
export class RosettaTableModule {}
