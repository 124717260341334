import { FILTER_DELIMITER } from '@configs';
import { CodeViewWithDiagnosticsDTO } from '@models/dto';
import {
  Diagnostic,
  DiagnosticGroup,
  DiagnosticSection,
} from '@shared/modules/diagnostic-panel/diagnostic.model';

import { TransformDTO } from './../transform/models/transform-dto.model';

export type DiagnosticResultKey = 'validation' | 'mapping' | 'qualification';

export function mapDiagnosticData(
  filter: string,
  diagnosticData: CodeViewWithDiagnosticsDTO
): Diagnostic {
  const resultMap = new Map<DiagnosticResultKey, DiagnosticGroup[]>();

  const validation =
    diagnosticData.diagnostics?.diagnosticRecords?.validation?.results;
  if (validation) {
    mapMappingResultsToDiagnostic(filter, 'validation', validation, resultMap);
  }

  const mapping =
    diagnosticData.diagnostics?.diagnosticRecords?.mapping?.results;
  if (mapping) {
    mapMappingResultsToDiagnostic(filter, 'mapping', mapping, resultMap);
  }

  return resultMap;
}

function mapMappingResultsToDiagnostic(
  filter: string,
  key: DiagnosticResultKey,
  diagnosticResults: TransformDTO.DiagnosticResults,
  resultMap: Diagnostic
): void {
  const successItems = diagnosticResults.successes?.details;
  const failureItems = diagnosticResults.failures?.details;
  const excludedItems = diagnosticResults.excluded?.details;
  const filterTest = createFilterTest(filter);

  const mappingSuccesses: DiagnosticGroup =
    successItems.reduce<DiagnosticGroup>(
      (acc, item) => diagnosticGroupReducer(filterTest, acc, item),
      {
        def: 'success',
        name: 'Successes',
        color: '#018301', // Green
        sections: [],
        total: 0,
      }
    );

  const mappingFailures: DiagnosticGroup = failureItems.reduce<DiagnosticGroup>(
    (acc, item) => diagnosticGroupReducer(filterTest, acc, item),
    {
      def: 'failure',
      name: 'Failures',
      color: '#d83737', // Red
      sections: [],
      total: 0,
    }
  );

  const mappingExcluded: DiagnosticGroup =
    excludedItems?.length > 0
      ? excludedItems.reduce<DiagnosticGroup>(
          (acc, item) => diagnosticGroupReducer(filterTest, acc, item),
          {
            def: 'excluded',
            name: 'Excluded',
            color: '#949494', // Grey
            useLightTextColor: true,
            sections: [],
            total: 0,
          }
        )
      : null;

  const diagnosticGroups = [mappingFailures, mappingSuccesses];
  if (mappingExcluded) {
    diagnosticGroups.push(mappingExcluded);
  }
  resultMap.set(key, diagnosticGroups);
}

function createFilterTest(filterStr: string): (item: any) => boolean {
  const filterRegExp = new RegExp(filterStr, 'mi');
  return (item: any): boolean => {
    const itemStr = Object.values(item)
      .map(value => (typeof value === 'string' ? value : ''))
      .join(FILTER_DELIMITER);
    return filterRegExp.test(itemStr);
  };
}

function diagnosticGroupReducer(
  filterTest: (item: any) => boolean,
  acc: DiagnosticGroup,
  item: TransformDTO.DiagnosticResultDetails
) {
  if (!filterTest(item)) {
    return acc;
  }

  let section: DiagnosticSection | undefined = acc.sections.find(
    section => section.category === item.category
  );
  if (!section) {
    section = {
      category: item.category,
      items: [],
    };
    acc.sections.push(section);
  }

  section.items.push({
    name: item.name,
    inputPath: item.inputPath,
    outputPaths: item.outputPaths,
    issues: item.issues,
  });

  acc.total += 1;

  return acc;
}
