<app-collapsible-panel-header />
<app-async-container class="regulation-wrapper" [stream]="bodyCorpus$">
  <ng-template #mainTemplate let-bodyCorpus>
    <as-split appSplitSaveSize="regulation-panel" [gutterSize]="1">
      <!-- All split area sizes need to add upto 100 or they will be ignored-->
      <as-split-area
        [size]="25"
        [maxSize]="25"
        [minSize]="1"
        class="regulation-list"
      >
        <app-body-corpus-list
          [bodyCorpus]="bodyCorpus"
          [selectedBodyCorpusGroupId]="selectedBodyCorpusGroupId$ | async"
          (changed)="bodyCorpusChanged($event)"
        />
      </as-split-area>

      <as-split-area [size]="75" class="regulation-details theme-bg">
        <app-segment-tree
          [segmentTree]="segmentTree$ | async"
          [selectedNode]="selectedSegmentId$ | async"
        />

        <app-provision-details
          [provisionDetails]="provisionDetails$ | async"
          [selectedSegmentEvent]="selectedSegmentEvent$ | async"
          (fragmentChanged)="onFragmentChanged($event)"
        />
      </as-split-area>
    </as-split>
  </ng-template>

  <ng-template #errorTemplate let-error>
    <app-inline-error>{{ error.message }}</app-inline-error>
  </ng-template>
</app-async-container>
