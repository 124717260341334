import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthActions, TaskActions, WebsocketActions } from '@store/.';
import * as WorkspaceActions from '@store/workspace/actions';
import { TranslateActions } from '@workspace-engine/translate/store';
import { delay, map } from 'rxjs';

@Injectable()
export class TranslateMappingEffects {
  constructor(private _actions$: Actions) {}

  switchWorkspace$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WorkspaceActions.switchWorkspace),
      map(() => TranslateActions.switchWorkspace())
    );
  });

  ingestionTestRunFinished$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WebsocketActions.ingestionTestRunFinished),
      map(props => TranslateActions.ingestionTestRunFinished(props))
    );
  });

  ingestionTestRunResultsSaved$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WebsocketActions.ingestionTestRunResultsSaved),
      map(props => TranslateActions.ingestionTestRunResultsSaved(props))
    );
  });

  translateIngestionRunStarted$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WebsocketActions.translateIngestionRunStarted),
      map(props => TranslateActions.translateIngestionRunStarted(props))
    );
  });

  translateIngestionRunFinished$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WebsocketActions.translateIngestionRunFinished),
      delay(500), // HACK - Fixes race condition between group finishes and individual task finish reaching the store
      map(props => TranslateActions.translateIngestionRunFinished(props))
    );
  });

  translateIngestionRunErrored$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WebsocketActions.translateIngestionRunErrored),
      map(props => TranslateActions.translateIngestionRunErrored(props))
    );
  });

  allTasksCancelled$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(WebsocketActions.allTasksCancelled),
      map(() => TranslateActions.allTasksCancelled())
    );
  });

  taskStarted$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(TaskActions.taskStarted),
      map(props => TranslateActions.taskStarted(props))
    );
  });

  taskFinished$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(TaskActions.taskFinished),
      map(props => TranslateActions.taskFinished(props))
    );
  });

  cleanup$ = createEffect(() => {
    return this._actions$.pipe(
      ofType(AuthActions.cleanupSessionState, WorkspaceActions.switchWorkspace),
      map(() => TranslateActions.cleanup())
    );
  });
}
