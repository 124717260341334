import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { Task, TaskStatus, WorkspaceId } from '@models';
import { Store } from '@ngrx/store';
import { TransformType } from '@shared/modules/transform/models/transform-config.model';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import {
  currentWorkspaceIdObserver,
  isNotNull,
  WorkspaceUrlPrefixOperator,
} from '@utils';
import {
  catchError,
  first,
  map,
  merge,
  of,
  reduce,
  share,
  switchMap,
  take,
  tap,
} from 'rxjs';

interface SupportFeatures {
  workspaceName: string;
  features: Map<string, boolean>;
}

@Injectable()
export class SupportedFeatureService {
  constructor(
    private _store: Store,
    private _http: HttpClient,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  private _supportFeatures = [
    // This method has been copied from the RegulationApiService so that we can find out if a model
    // supports the new regulation panel before opening it. It can be removed when this has been fully rolled out.
    {
      featureTabName: 'regulation',
      resourcePath: this._config.resourcePaths.bsp,
      endpoint: '/regulation/body-corpus-list',
    },
    {
      featureTabName: 'translate_1_5',
      resourcePath: this._config.resourcePaths.pipeline,
      endpoint: `/${TransformType.Translate_1_5}/pipelines`,
    },
    {
      featureTabName: 'reports',
      resourcePath: this._config.resourcePaths.pipeline,
      endpoint: `/${TransformType.Report}/pipelines`,
    },
    {
      featureTabName: 'projection',
      resourcePath: this._config.resourcePaths.pipeline,
      endpoint: `/${TransformType.Projection}/pipelines`,
    },
  ] as const;

  private _supportedFeaturesCache?: SupportFeatures;

  private _fetchSupportedFeatures = currentWorkspaceIdObserver(this._store, {
    taskToWatch: Task.ExecutionEngineInitialisation,
    waitForStatusList: [TaskStatus.Finished],
    skipInitial: false,
  }).pipe(
    switchMap(({ name }) =>
      merge(
        ...this._supportFeatures.map(
          ({ featureTabName, resourcePath, endpoint }) =>
            this._checkFeatureSupported(featureTabName, resourcePath, endpoint)
        )
      ).pipe(
        take(this._supportFeatures.length),
        reduce((acc, curr) => {
          acc.features.set(curr.featureTabName, curr.isSupported);
          return acc;
        }, this._createSupportedFeature(name)),
        tap(supportedFeatures => {
          this._supportedFeaturesCache = supportedFeatures;
        })
      )
    ),
    // Ensure a single observable is shared across multiple subscribers
    share()
  );

  checkSupportFor(featureTabName: string) {
    return this._store.select(WorkspaceSelectors.selectWorkspaceId).pipe(
      first(isNotNull),
      switchMap(({ name }) =>
        this._useCacheOrFetch(name).pipe(
          map(({ features }) => features.get(featureTabName))
        )
      )
    );
  }

  private _createSupportedFeature(workspaceName: WorkspaceId['name']) {
    return {
      workspaceName,
      features: new Map(),
    };
  }

  private _useCacheOrFetch(name: WorkspaceId['name']) {
    // Fetch new supported feature map when workspace name has changed
    return this._supportedFeaturesCache?.workspaceName === name
      ? of(this._supportedFeaturesCache)
      : this._fetchSupportedFeatures;
  }

  private _checkFeatureSupported(
    featureTabName: string,
    resourcePath: string,
    endpoint: string
  ) {
    return this._store.pipe(
      WorkspaceUrlPrefixOperator(resourcePath),
      switchMap(url => this._http.get<any>(`${url}${endpoint}`)),
      map(() => ({ featureTabName, isSupported: true })),
      catchError(() => of({ featureTabName, isSupported: false }))
    );
  }
}
