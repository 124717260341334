import {
  IngestRunResult,
  Task,
  TranslateIngestRunResult,
  TranslateIngestRunStart,
} from '@models';
import { createAction, props } from '@ngrx/store';
import {
  EnvironmentConfig,
  Filter,
  ResponseSampleFileNodeFlattened,
  SampleFileNodeFull,
} from '../../models';

// Sample Files
export const getAllSampleFiles = createAction(
  '[Translate Panel] Get All Sample Files'
);
export const sampleFilesLoaded = createAction(
  '[Translate API] Sample Files Loaded (Flattened)',
  props<{ payload: ResponseSampleFileNodeFlattened }>()
);
export const sampleFilesLoadFailure = createAction(
  '[Translate API] Sample Loading Error'
);
export const uploadSampleFile = createAction(
  '[Translate Panel] Upload A Sample File'
);
export const sampleFileUploaded = createAction(
  '[Translate API] Sample File Uploaded (Flattened)',
  props<{ payload: ResponseSampleFileNodeFlattened }>()
);
export const sampleFileUploadError = createAction(
  '[Translate API] Sample File Upload Error',
  props<{ message: string }>()
);
export const loadEnvironmentConfig = createAction(
  '[Translate API] Load Environment Config'
);
export const loadEnvironmentConfigSuccess = createAction(
  '[Translate API] Load Environment Config Success',
  props<{ environmentConfig: EnvironmentConfig[] }>()
);
export const deleteSampleFile = createAction(
  '[Translate Panel] Delete Sample File',
  props<{ node: SampleFileNodeFull }>()
);
export const deleteSampleFileSuccess = createAction(
  '[Translate Panel] Delete Sample File Success',
  props<{ id: string }>()
);

export const panelOpen = createAction('[Translate Panel] Panel Open');

// Filter
export const filterSampleFiles = createAction(
  '[Translate Panel] Filter Sample Files',
  props<{ filter: Filter }>()
);

// Run Sample FIles
export const runSampleFiles = createAction(
  '[Translate Panel] Run Sample File',
  props<{ nodeIds: string[] }>()
);
export const startSampleFiles = createAction(
  '[Translate Panel] Start Sample File',
  props<{ nodeIds: string[] }>()
);
export const runSampleFilesRequestSent = createAction(
  '[Translate Panel] Run Sample File Request Sent'
);

export const runSampleFilesRequestQueued = createAction(
  '[Translate Panel] Run Sample File Request Queued'
);

// Mapping actions
export const taskFinished = createAction(
  '[Translate Panel] Task Finished',
  props<{ name: Task; payload: any }>()
);

export const switchWorkspace = createAction(
  '[Translate Panel] Switch Workspace'
);

export const ingestionTestRunFinished = createAction(
  '[Translate Panel] Ingestion Test Run Finished',
  props<{ payload: IngestRunResult }>()
);

export const ingestionTestRunResultsSaved = createAction(
  '[Translate Panel] Ingestion Test Run Results Saved',
  props<{ payload: IngestRunResult }>()
);

export const translateIngestionRunStarted = createAction(
  '[Translate Panel] Translate Ingestion Run Started',
  props<{ payload: TranslateIngestRunStart }>()
);

export const translateIngestionRunFinished = createAction(
  '[Translate Panel] Translate Ingestion Run Finished',
  props<{ payload: TranslateIngestRunResult }>()
);

export const translateIngestionRunErrored = createAction(
  '[Translate Panel] Translate Ingestion Run Errored',
  props<{ payload: TranslateIngestRunResult }>()
);

export const allTasksCancelled = createAction(
  '[Translate Panel] All Tasks Cancelled'
);

export const taskStarted = createAction(
  '[Translate Panel] Task Started',
  props<{ name: Task }>()
);

export const cleanup = createAction('[Translate Panel] Cleanup');
