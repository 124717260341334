import { DocRefSegment } from '@models';

export interface BodyCorpusSegmentsDTO {
  body: string;
  bodyDefinition: string;
  corpusList: CorpusDTO[];
  segmentIds: DocRefSegmentIdDTO[];
}

export interface CorpusDTO {
  name: string;
  definition?: string;
}

export interface DocRefSegmentIdDTO {
  segments: DocRefSegment[];
  docRefTarget: DocRefTarget;
}

export enum DocRefTarget {
  Condition = 'CONDITION',
  EligibilityRule = 'ELIGIBILITY_RULE',
  Enum = 'ENUM',
  Function = 'FUNCTION',
  ReportingRule = 'REPORTING_RULE',
  Type = 'TYPE',
  Attribute = 'ATTRIBUTE',
  EnumValue = 'ENUM_VALUE',
  Other = 'OTHER',
}
