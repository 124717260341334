import { booleanAttribute, Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[appDisableClick]',
})
export class DisableClickDirective {
  @Input({ transform: booleanAttribute })
  appDisableClick = false;

  @HostBinding('style.pointer-events') get pEvents(): string {
    return this.appDisableClick ? 'none' : 'auto';
  }
}
