export const ALL_MODELS = 'ALL_MODELS';
export const READ_WRITE_SERVER_KEY = 'READ_WRITE_SERVER_KEY';

export const DEFAULT_API_TIMEOUT = 5_000;
export const STATUS_POLLING_INTERVAL = 3_000;

export const LOGGING_DELAY = 1_000;
export const APP_UPDATE_CHECK = 120_000;

export const MAX_FILE_UPLOAD_WS = 1024 * 1024; // This is the max size the websockets support (see RosettaCustomWebsocketBundle)

export const MAX_FILE_UPLOAD = 1024 * 1024 * 100;

export const RUN_CUSTOM_FUNCTION = 'run-custom-function';

export const APP_DOCUMENTS = 'rosetta-core';
export const UPLOAD_DIR = 'uploads'; // This is used by translate
export const FILTER_DELIMITER = '◬';
export const EN_DASH = '–';

export const DAYS_REMAINING_THRESHOLD = 10;

export const DATE_FORMAT = 'yyyy-MM-dd';

export const LOCALE = 'en-UK';
