import { Injectable } from '@angular/core';
import { DownloadService } from '@core/services';
import { DownloadGenerateResult, Task, TaskNotification } from '@models';
import { TaskListeners } from '../task-listener.models';

@Injectable()
export class DamlGenerationListenerService implements TaskListeners {
  constructor(private _downloadService: DownloadService) {}

  action(task: TaskNotification) {
    if (task.task !== Task.DamlGenerate) {
      return;
    }

    if (task.status === 'STARTED') {
      // TODO spin something
    }

    if (task.status === 'FINISHED') {
      const downloadGenResult = task.payload as DownloadGenerateResult;
      this._downloadService.onDownloadFinished(downloadGenResult);
    }
  }
}
