import { Color } from '@models';
import { EditorActionDividerComponent } from '@workspace-design/textual/components/buttons/editor-action-divider.component';
import { EditorActionDropdownComponent } from '@workspace-design/textual/components/buttons/editor-action-dropdown.component';
import {
  customComponent,
  editorAction,
  editorTriggerAction,
  ToolbarActions,
} from './editor-toolbar.helpers';
import { SearchAllComponent } from '@workspace-design/textual/components/search-all/search-all.component';
import { WorkspaceStaticErrorMenuComponent } from '@workspace-design/textual/components/workspace-static-error-menu/workspace-static-error-menu.component';
import { isEmptyString } from '@utils';

export type ToolbarButtonNames =
  | 'collapse'
  | 'divider'
  | 'expandAll'
  | 'formatDocument'
  | 'goBack'
  | 'goForward'
  | 'gotoSymbol'
  | 'openCommand'
  | 'redo'
  | 'searchAll'
  | 'staticError'
  | 'syntaxError'
  | 'undo'
  | 'zoomIn'
  | 'zoomOut'
  | 'menu';

export const ToolbarButtons = (
  editor: monaco.editor.IStandaloneCodeEditor,
  warningColor: Color
) => {
  const buttonMap: Record<ToolbarButtonNames, ToolbarActions> = {
    syntaxError: editorAction(
      editor,
      'editor.action.marker.nextInFiles',
      'exclamation-circle',
      'Go to Next Error',
      warningColor
    ),

    staticError: customComponent(WorkspaceStaticErrorMenuComponent),

    divider: customComponent(EditorActionDividerComponent),

    openCommand: editorAction(
      editor,
      'editor.action.quickCommand',
      'asterisk',
      'Open Commands'
    ),

    searchAll: customComponent(SearchAllComponent, editor),

    menu: customComponent(EditorActionDropdownComponent),

    gotoSymbol: editorAction(
      editor,
      'rosetta.action.goToWorkspaceSymbol',
      'thumbtack',
      'Go to Symbol'
    ),

    undo: editorTriggerAction(editor, 'undo', 'undo', 'Undo'),

    redo: editorTriggerAction(editor, 'redo', 'redo', 'Redo'),

    goBack: editorAction(
      editor,
      'editor.action.goBack',
      'arrow-left',
      'Go Back'
    ),

    goForward: editorAction(
      editor,
      'editor.action.goForward',
      'arrow-right',
      'Go Forward'
    ),

    zoomIn: editorAction(
      editor,
      'editor.action.fontZoomIn',
      'magnifying-glass-plus',
      'Zoom In'
    ),

    zoomOut: editorAction(
      editor,
      'editor.action.fontZoomOut',
      'magnifying-glass-minus',
      'Zoom Out'
    ),

    collapse: editorAction(
      editor,
      'editor.foldLevel1',
      'square-minus',
      'Collapse'
    ),

    expandAll: editorAction(
      editor,
      'editor.unfoldAll',
      'square-plus',
      'Expand All',
      undefined,
      false,
      'rosetta-editor.expand'
    ),

    formatDocument: editorAction(
      editor,
      editor => {
        let formatSelection: boolean | null = true;
        const selection = editor.getSelection();
        formatSelection =
          selection &&
          !isEmptyString(editor.getModel()?.getValueInRange(selection));
        return formatSelection
          ? 'editor.action.formatSelection'
          : 'editor.action.formatDocument';
      },
      'minus',
      'Format Document',
      undefined,
      true,
      'rosetta-editor.document-format'
    ),
  };

  return (key: ToolbarButtonNames) => buttonMap[key];
};
