import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, IsActiveMatchOptions } from '@angular/router';
import { RosettaNavigationService } from '@core/services/rosetta-navigation.service';
import { CustomParams, FeatureTab, isViewOption, QueryParams } from '@models';

const matchOptions: IsActiveMatchOptions = {
  paths: 'subset',
  queryParams: 'subset',
  fragment: 'ignored',
  matrixParams: 'ignored',
};

@Component({
  selector: 'app-workspace-header',
  templateUrl: './workspace-header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'workspace-header',
  },
})
export class WorkspaceHeaderComponent {
  constructor(
    private _navigate: RosettaNavigationService,
    private _route: ActivatedRoute
  ) {}

  readonly tabs: FeatureTab[] = [
    {
      label: 'Textual',
      name: 'textual',
      icon: 'pen',
      queryParams: { [QueryParams.VIEW]: 'textual' },
    },
    {
      label: 'Definition',
      name: 'definition',
      icon: 'th',
      queryParams: { [QueryParams.VIEW]: 'definition' },
    },
    {
      label: 'Graphical',
      name: 'graphical',
      icon: 'project-diagram',
      queryParams: { [QueryParams.VIEW]: 'graphical' },
    },
    {
      label: 'Regulation',
      name: 'regulation',
      icon: 'tasks',
      queryParams: { [QueryParams.VIEW]: 'regulation' },
      tourAnchor: 'new-regulation.supported-model',
    },
  ].map(tab => ({ ...tab, matchOptions, url: '/' }) as FeatureTab);

  selectPanel({ name }: FeatureTab) {
    if (!isViewOption(name)) {
      return;
    }
    const queryParams: CustomParams = {
      [QueryParams.VIEW]: name,
    };
    this._navigate.to([], {
      queryParams,
      relativeTo: this._route,
    });
  }
}
