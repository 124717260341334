import { Injectable } from '@angular/core';
import {
  generateName,
  getFileContents,
  stripExtension,
} from '@app/utils/file-utils';
import { UPLOAD_DIR } from '@configs';
import { BaseStorageService } from '@core/services/base-storage.service';
import { Expectation } from '@models';
import {
  ResponseSampleFileNode,
  TestType,
} from '@workspace-engine/translate/models';
import { TranslateSelectors } from '@workspace-engine/translate/store';
import { first, map, Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslateUploadStorageService extends BaseStorageService<ResponseSampleFileNode> {
  override readonly namespace = 'TRANSLATE';

  set(file: File, selectedEnv: string): Observable<ResponseSampleFileNode> {
    return getFileContents(file).pipe(
      switchMap(content =>
        this._store
          .select(TranslateSelectors.envToSynonym(selectedEnv))
          .pipe(
            map(config =>
              this._createResponseSampleFileNode(
                file,
                selectedEnv,
                content,
                config.synonymSourceNames
              )
            )
          )
      ),
      first(),
      switchMap(payload => this._set(this._generateId(file.name), payload))
    );
  }

  update(id: string, update: Partial<ResponseSampleFileNode>) {
    return this._update(id, update);
  }

  private _createResponseSampleFileNode(
    file: File,
    env: string,
    content: string,
    synonyms: string[]
  ): ResponseSampleFileNode {
    return {
      id: this._generateId(file.name),
      testType: TestType.UPLOADED_TO_CLIENT,
      parent: UPLOAD_DIR,
      name: generateName(file.name),
      environments: [env],
      synonyms,
      expectations: {
        fileName: this._createFilePath(file.name, env),
      } as Expectation,
      content,
    };
  }

  private _createFilePath(name: string, env: string): string {
    return `${UPLOAD_DIR}/${env}/${name}`;
  }

  private _generateId(filename: string): string {
    return `${UPLOAD_DIR}.${stripExtension(filename)}`;
  }
}
