import { PACKAGE_PLAN_CONFIG } from '@configs';
import { PackagePlan } from '@models';
import { PackagePlanDetails, UserLoginResponse } from '@features/auth/login';

export const getUserInitials = (
  email: string | undefined | null
): string | null => {
  if (!email || email.trim().length === 0) {
    return null;
  }

  const sanitizedEmail = email
    .trim()
    .replace(/@.*$/, '')
    .replace(/[^a-zA-Z\.]/g, '');
  const splitEmail = sanitizedEmail.split('.');

  if (splitEmail.length > 1) {
    return `${splitEmail[0].charAt(0)}${splitEmail[1].charAt(0)}`.toUpperCase();
  } else {
    return sanitizedEmail.substring(0, 2).toUpperCase();
  }
};

export const setPackagePlanDetails = (
  user: UserLoginResponse
): PackagePlanDetails => {
  const rosettaUser = user.rosettaAuth?.rosettaUser;
  const packagePlanMeta = rosettaUser?.metaData?.['package_plan_metadata'];
  const packagePlan = rosettaUser?.userPermissions?.packagePlan;

  return {
    packagePlan: packagePlan || PackagePlan.Community,
    startDate: packagePlanMeta?.start_date || '',
    endDate: packagePlanMeta?.end_date || '',
    trial: packagePlanMeta?.trial || false,
  };
};

export const setUserProjectLimit = (
  packagePlan: PackagePlan = PackagePlan.Community
): number => {
  return Number(
    PACKAGE_PLAN_CONFIG.workspaceLimits.get(packagePlan) ??
      PACKAGE_PLAN_CONFIG.workspaceLimitDefault
  );
};
