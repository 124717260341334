import { Injectable } from '@angular/core';
import { RUN_CUSTOM_FUNCTION } from '@configs';
import { TaskService } from '@core/services';
import {
  GraphGenerationResult,
  Task,
  TaskNotification,
  TaskStatus,
  WorkflowExecutionResult,
} from '@models';
import { EngineFunctionsService } from '@workspace-engine/functions/services/engine-functions.service';
import { TaskListeners } from '../task-listener.models';

@Injectable()
export class RunCustomFunctionListenerService implements TaskListeners {
  constructor(
    private _taskService: TaskService,
    private _engineFunctionsService: EngineFunctionsService
  ) {}

  action(task: TaskNotification) {
    if (
      (task.task !== Task.WorkflowExecution &&
        task.task !== Task.GraphGeneration) ||
      (task.payload.workflowName !== RUN_CUSTOM_FUNCTION &&
        task.payload.fileName !== RUN_CUSTOM_FUNCTION + ':run' &&
        task.payload.errors?.causedBy !== RUN_CUSTOM_FUNCTION + ':run')
    ) {
      return;
    }

    if (task.status === 'STARTED') {
      if (task.task === Task.WorkflowExecution) {
        this._engineFunctionsService.onWorkflowExecutionStarted();
      }
    }

    if (task.status === 'FINISHED') {
      if (task.task === Task.WorkflowExecution) {
        this._engineFunctionsService.onWorkflowExecutionFinished(
          task.payload as WorkflowExecutionResult
        );
      }

      if (task.task === Task.GraphGeneration) {
        this._engineFunctionsService.onGraphGenerationFinished(
          task.payload as GraphGenerationResult
        );
      }
    }

    if (task.status === 'ERROR') {
      this._taskService.updateAllSubTaskStatus(task.task, TaskStatus.Error);
      this._engineFunctionsService.onTaskError(task.payload.errors);
    }
  }
}
