import { ChangeDetectionStrategy, Component } from '@angular/core';
import { InOutAnimation } from '@shared/animations';

@Component({
  selector: 'app-editor-override-banner',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <div @inOutAnimation class="theme-bg-primary text-align-center">
    This overrides a parent namespace
  </div>`,
  animations: [InOutAnimation],
})
export class EditorOverrideBannerComponent {}
