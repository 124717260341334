<div
  class="workspace"
  matRipple
  *ngIf="workspaceInfo$ | async as info"
  [appRosettaOverlayTarget]="overlay"
>
  <fa-icon icon="caret-down" size="sm" />

  <div class="project-type-wrapper">
    <app-loading-spinner
      [diameter]="34"
      *ngIf="(isLoading$ | async) === true"
    />
    <app-project-type
      [color]="modelColor$ | async"
      [data]="info.workspaceType"
      size="xs"
    />
  </div>

  <h2 class="mat-h2 mb-0 text-ellipsis">
    {{ info.description }}
  </h2>

  <app-upgrade-icon
    [modelInfo]="{
      modelId: info.modelId,
      modelVersion: info.modelVersion,
      modelShortname: info.modelShortname,
      upgradeWarning: info.upgradeWarning,
      deprecationMessage: info.deprecationMessage,
    }"
    warningType="full"
  />

  <app-rosetta-overlay #overlay
    ><app-workspace-dropdown [data]="info" (action)="onAction($event)"
  /></app-rosetta-overlay>
</div>
