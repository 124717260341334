import {
  trigger,
  query,
  animate,
  transition,
  style,
  group,
} from '@angular/animations';
import { ANIMATION_EASING_SLOW } from '@configs';

export const EngineSvgAnimation = trigger('animate', [
  transition('* => animate', [
    query('.engine-svg-lg-cog', style({ 'transform-origin': '13px 19px' })),
    query('.engine-svg-sm-cog', style({ 'transform-origin': '29px 10px' })),
    group([
      query(
        '.engine-svg-lg-cog',
        animate(ANIMATION_EASING_SLOW, style({ transform: 'rotate(360deg)' }))
      ),
      query(
        '.engine-svg-sm-cog',
        animate(ANIMATION_EASING_SLOW, style({ transform: 'rotate(-360deg)' }))
      ),
    ]),
  ]),
]);
