import { TASK_MESSAGE_LISTENERS } from '../task-listener.models';
import { DamlGenerationListenerService } from './daml-generation.listener.service';
import { ExecutionEngineInitialisationListenerService } from './execution-engine-initialisation.listener.service';
import { ExportListenerService } from './export.listener.service';
import { GraphGenerationListenerService } from './graph-generation.listener.service';
import { GridViewGenerationListenerService } from './grid-view-generation.listener.service';
import { IngestTestRunListenerService } from './ingest-test-run.listener.service';
import { JsonValidationListenerService } from './json-validation.listener.service';
import { PojoCompilationListenerService } from './pojo-compilation.listener.service';
import { RootTypeCacheListenerService } from './root-type-cache.listener.service';
import { RunCustomFunctionListenerService } from './run-custom-function.listener.service';
import { StaticCompilationListenerService } from './static-compilation.listener.service';
import { TranslateGenerateCompileListenerService } from './translate-generation-compile.listener.service';
import { TranslateIngestRunListenerService } from './translate-ingest-run.listener.service';
import { WorkflowExecutionListenerService } from './workflow-execution.listener.service';

export const TaskMessageListeners = [
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: IngestTestRunListenerService,
    multi: true,
  },
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: PojoCompilationListenerService,
    multi: true,
  },
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: RootTypeCacheListenerService,
    multi: true,
  },
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: StaticCompilationListenerService,
    multi: true,
  },
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: TranslateGenerateCompileListenerService,
    multi: true,
  },
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: TranslateIngestRunListenerService,
    multi: true,
  },
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: ExportListenerService,
    multi: true,
  },
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: JsonValidationListenerService,
    multi: true,
  },
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: GraphGenerationListenerService,
    multi: true,
  },
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: WorkflowExecutionListenerService,
    multi: true,
  },
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: GridViewGenerationListenerService,
    multi: true,
  },
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: DamlGenerationListenerService,
    multi: true,
  },
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: RunCustomFunctionListenerService,
    multi: true,
  },
  {
    provide: TASK_MESSAGE_LISTENERS,
    useClass: ExecutionEngineInitialisationListenerService,
    multi: true,
  },
];
