import {
  ChangeDetectionStrategy,
  Component,
  Input,
  ViewEncapsulation,
} from '@angular/core';
import { HEX, WorkspaceType } from '@models';
import { useDarkForeground } from '@utils';

@Component({
  selector: 'app-project-type-text',
  template: `<h2 class="mb-0" [style.color]="styleColor">
    {{ typeMap[workspaceType] }} <br />
    Workspace
  </h2>`,
  styles: [
    `
      .mat-typography h2 {
        font-weight: 600;
        line-height: 1.3;
        opacity: 0.75;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectTypeTextComponent {
  styleColor = '#FFFFFF';
  readonly typeMap = {
    [WorkspaceType.User]: 'User',
    [WorkspaceType.Project]: 'Project',
    [WorkspaceType.Contribution]: 'Open Contribution',
    [WorkspaceType.Guest]: 'Guest User',
  };

  @Input() workspaceType?: WorkspaceType = WorkspaceType.User;

  @Input()
  set color(value: HEX) {
    if (value) {
      this.styleColor = useDarkForeground(value)
        ? 'rgba(0,0,0, 0.60)'
        : 'rgba(255,255,255, 0.75)';
    }
  }
}
