import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModelLogoPipe } from './model-logo.pipe';

@NgModule({
  declarations: [ModelLogoPipe],
  imports: [CommonModule],
  exports: [ModelLogoPipe],
})
export class ModelLogoPipeModule {}
