import { Task, TaskStatus, WorkspaceId } from '@models';
import { Store } from '@ngrx/store';
import { TaskStatusKey } from '@store/reducers/task.reducer';
import { TaskSelectors } from '@store/selectors';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  Observable,
  of,
  shareReplay,
  skip,
  switchMap,
} from 'rxjs';

export type TWorkspaceObserverConfig = Partial<{
  taskToWatch: TaskStatusKey;
  waitForStatusList: TaskStatus[];
  skipInitial: boolean;
  skipReadyCheck: boolean;
}>;

const defaultConfig: TWorkspaceObserverConfig = {
  taskToWatch: Task.PojoCompilation,
  waitForStatusList: [TaskStatus.Started, TaskStatus.Finished],
  skipInitial: true,
  skipReadyCheck: false,
};

/*
 The operator should only emit when:
 - A workspace has been set
 - prev and next values are different

 ## SkipInitial
 The initial value can also be skipped as the workspace would not have changed

 ## SkipReadyCheck
 The operator can optionally wait for a specific task before emitting
 */
export function currentWorkspaceIdObserver(
  _store: Store,
  config: TWorkspaceObserverConfig = {}
): Observable<WorkspaceId> {
  const { taskToWatch, waitForStatusList } = {
    ...defaultConfig,
    ...config,
  };
  return _store.select(WorkspaceSelectors.selectWorkspaceId).pipe(
    filter(isNotNull),
    distinctUntilChanged((prev, next) => prev?.name === next?.name),
    skip(config.skipInitial ? 1 : 0),
    switchMap(workspaceId => {
      return config.skipReadyCheck
        ? of(workspaceId)
        : combineLatest([
            _store.select(TaskSelectors.selectTaskStatus(taskToWatch)),
            _store.select(WorkspaceSelectors.selectWorkspaceReady),
          ]).pipe(
            first(
              ([taskStatus, isReady]) =>
                isReady &&
                waitForStatusList.some(status => status === taskStatus)
            ),
            switchMap(() => of(workspaceId))
          );
    }),
    shareReplay({ refCount: true, bufferSize: 1 })
  );
}
