import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { Store } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import { TestCase } from '@workspace-engine/visualisation/models/visualisation-model';
import { first, Observable, switchMap } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class VisualisationApiService {
  constructor(
    private _http: HttpClient,
    private _store: Store,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  getTestCaseList(): Observable<TestCase[]> {
    return this._store.select(WorkspaceSelectors.selectWorkspaceId).pipe(
      first(isNotNull),
      switchMap(workspaceId =>
        this._http.get<TestCase[]>(
          `${this._config.resourcePaths.visualisation}/function-test-cases/${workspaceId.name}`,
          { responseType: 'json' }
        )
      )
    );
  }
}
