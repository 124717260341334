import { IconName } from '@fortawesome/fontawesome-svg-core';
import { Color } from '@models';
import { isString } from '@utils';
import {
  ToolbarAction,
  ToolbarActionComponent,
} from '@workspace-design/textual/models/toolbar-action.model';

export const isCustomComponent = (
  action: any
): action is ToolbarActionComponent => {
  return action.component !== undefined;
};

export const customComponent = (
  component: any,
  editor?: monaco.editor.IStandaloneCodeEditor
): ToolbarActionComponent => {
  return {
    component,
    editor,
  };
};

export const editorAction = (
  editor: monaco.editor.IStandaloneCodeEditor,
  actionName:
    | string
    | ((editor: monaco.editor.IStandaloneCodeEditor) => string),
  icon: IconName,
  tooltip: string,
  color?: Color,
  formatDocument?: boolean /* HACK To allow special icon */,
  tourAnchor?: string
): ToolbarAction => {
  return {
    icon,
    tooltip,
    color,
    formatDocument,
    tourAnchor,
    runAction: () => {
      editor.focus();
      editor
        .getAction(isString(actionName) ? actionName : actionName(editor))
        .run();
    },
  };
};

export const editorTriggerAction = (
  editor: monaco.editor.IStandaloneCodeEditor,
  actionName: string,
  icon: IconName,
  tooltip: string
): ToolbarAction => {
  return {
    icon,
    tooltip,
    runAction: () => {
      editor.focus();
      editor.trigger(null, actionName, null);
    },
  };
};

export type ToolbarActions = ToolbarActionComponent | ToolbarAction;
