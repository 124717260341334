import { Injectable } from '@angular/core';
import { ApiService, BaseWorkspaceService } from '@core/services';
import { TaskService } from '@core/services/task.service';
import {
  GraphGenerationResult,
  GraphGenerationSubmit,
  MultipleVisualisationSubmit,
  Task,
  TaskStatus,
  WorkflowExecutionResult,
  WorkflowExecutionSubmit,
} from '@models';
import { TaskActions } from '@store/.';
import { GraphPath } from '@workspace-engine/visualisation/components/visualisation/visualisation.component';
import { VisualisationApiService } from '@workspace-engine/visualisation/services/visualisation-api.service';
import { first, Observable, ReplaySubject } from 'rxjs';
import {
  CdmWorkflowObject,
  CustomCdmVisualisation,
  CUSTOM_CDM_GRAPH,
  Visualisation,
  VisualisationError,
} from '../models/visualisation-model';

export interface VisualisationMap {
  [functionName: string]: {
    [sampleFileName: string]: Visualisation;
  };
}

@Injectable({
  providedIn: 'root',
})
export class VisualisationService extends BaseWorkspaceService {
  constructor(
    private _apiService: ApiService,
    private _taskService: TaskService,
    private _vizApi: VisualisationApiService
  ) {
    super();
    this.initWorkspaceObserver();
  }

  private _initialised = false;

  visualisations$ = new ReplaySubject<VisualisationMap>(1);
  customCdmVisualisations?: CustomCdmVisualisation;

  onGraphGenerationFinished(graphGenerationRunResult: GraphGenerationResult) {
    if (
      this.customCdmVisualisations &&
      graphGenerationRunResult.fileName === CUSTOM_CDM_GRAPH
    ) {
      this.customCdmVisualisations.graph = graphGenerationRunResult.graph.graph;
      this.customCdmVisualisations.error = null;
      this.customCdmVisualisations.status$.next(TaskStatus.Finished);
    } else {
      const [functionName, sampleFileName] =
        graphGenerationRunResult.fileName.split(':');

      this._setVisualisationGraph(
        functionName,
        sampleFileName,
        graphGenerationRunResult.graph.graph
      );

      this.setVisualisationStatus(
        functionName,
        sampleFileName,
        TaskStatus.Finished
      );
    }
  }

  onGraphGenerationError(error: any) {
    const { userMessage, supportMessage, causedBy } = error;

    if (this.customCdmVisualisations && causedBy === CUSTOM_CDM_GRAPH) {
      this.customCdmVisualisations.graph = undefined;
      this.customCdmVisualisations.error = new VisualisationError(
        userMessage,
        supportMessage,
        Task.GraphGeneration
      );
      this.customCdmVisualisations.status$.next(TaskStatus.Error);
    } else {
      const [functionName, sampleFileName] = causedBy.split(':');
      this._setVisualisationError(
        functionName,
        sampleFileName,
        new VisualisationError(
          userMessage,
          supportMessage,
          Task.GraphGeneration
        )
      );
    }
  }

  onWorkflowExecutionFinished({
    workflowName,
    sampleFileName,
    workflow,
  }: WorkflowExecutionResult) {
    if (sampleFileName) {
      this._setVisualisationCdmObject(workflowName, sampleFileName, workflow);
    }
  }

  onWorkflowExecutionError(error: any) {
    const { userMessage, supportMessage, causedBy } = error;

    const [functionName, sampleFileName] = causedBy.split(':');
    this._setVisualisationError(
      functionName,
      sampleFileName,
      new VisualisationError(
        userMessage,
        supportMessage,
        Task.WorkflowExecution
      )
    );
  }

  runIndividualWorkflow(functionName: string, sampleFileName: string) {
    this.visualisations$.pipe(first()).subscribe(vMap => {
      const multipleVisualisationSubmit: MultipleVisualisationSubmit = {
        workflowExecutionSubmissions: [],
      };
      const vis = vMap[functionName][sampleFileName];

      const workflowExecutionSubmit: WorkflowExecutionSubmit = {
        workflowName: functionName,
        workflowFunctionClass:
          this.workflowNameToJavaFunctionClass(functionName),
        sampleFileName,
        executionType: vis.executionType,
      };

      this.setVisualisationStatus(
        functionName,
        sampleFileName,
        TaskStatus.Started
      );

      multipleVisualisationSubmit.workflowExecutionSubmissions.push(
        workflowExecutionSubmit
      );

      this.emitMultipleVisualisationExecutionTask(multipleVisualisationSubmit);
    });
  }

  workflowNameToJavaFunctionClass(workflowName: string): string {
    const words = workflowName.split(/-/g);
    const upperCaseWords = words.map(
      word => word[0].toUpperCase() + word.slice(1)
    );
    return 'org.isda.cdm.workflows.' + upperCaseWords.join('');
  }

  runGraphGeneration(
    functionName: string,
    sampleFileName: string,
    json: any,
    rootType: string
  ) {
    const graphGenerationSubmit: GraphGenerationSubmit = {
      name: `${functionName}:${sampleFileName}`,
      rootType,
      json: JSON.stringify(json),
    };

    this._waitForStaticJavaCompileToFinish().subscribe(() => {
      this._store.dispatch(
        TaskActions.submitTask({
          data: {
            task: Task.GraphGeneration,
            payload: graphGenerationSubmit,
          },
        })
      );
    });
  }

  runCustomCdmGraphGeneration(json: any, rootType: string) {
    const graphGenerationSubmit: GraphGenerationSubmit = {
      name: CUSTOM_CDM_GRAPH,
      rootType,
      json: JSON.stringify(json),
    };

    this._waitForStaticJavaCompileToFinish().subscribe(() => {
      this._store.dispatch(
        TaskActions.submitTask({
          data: {
            task: Task.GraphGeneration,
            payload: graphGenerationSubmit,
          },
        })
      );
    });
  }

  sendErrorToBackend(payload: Record<string, any>) {
    this._apiService.submitServerError(payload).subscribe();
  }

  init() {
    if (this._initialised) {
      return;
    }

    this._vizApi.getTestCaseList().subscribe(testCaseList => {
      const visualisationsMap = testCaseList.reduce<VisualisationMap>(
        (acc, testCase) => {
          const newMap = { ...acc };
          if (newMap[testCase.group] === undefined) {
            newMap[testCase.group] = {};
          }

          newMap[testCase.group][testCase.name] = new Visualisation(
            testCase.group,
            testCase.name,
            testCase.executionType,
            testCase.markdown
          );
          return newMap;
        },
        {}
      );

      this.visualisations$.next(visualisationsMap);
      this._initialised = true;
    });

    this.customCdmVisualisations = new CustomCdmVisualisation({});
  }

  emitMultipleVisualisationExecutionTask(
    multipleVisualisationSubmit: MultipleVisualisationSubmit
  ) {
    this._waitForStaticJavaCompileToFinish().subscribe(() => {
      this._store.dispatch(
        TaskActions.submitTask({
          data: {
            task: Task.MultipleVisualisationExecution,
            payload: multipleVisualisationSubmit,
          },
        })
      );
    });
  }

  setVisualisationStatus(
    functionName: string,
    sampleFileName: string,
    taskStatus: TaskStatus
  ) {
    this._getVisualisationMap().subscribe(visualisationMap => {
      visualisationMap[functionName][sampleFileName].setStatus(taskStatus);
    });
  }

  extractNewGraphSourceGivenPath(
    workflow?: CdmWorkflowObject | null,
    graphPath?: GraphPath
  ): object | undefined {
    if (!workflow || !graphPath) {
      return {};
    }
    let sourceTopLevelEntity: object | undefined = workflow;
    if (workflow.steps) {
      sourceTopLevelEntity = workflow.steps.find(
        (p: any) =>
          p.meta &&
          p.meta.globalKey &&
          p.meta.globalKey === graphPath.topLevelGlobalKey
      );
    }

    if (graphPath.elements.length === 0) {
      return sourceTopLevelEntity;
    }

    return graphPath.elements.reduce((acc, element) => {
      let newEntity: any = acc[element.pathName];
      if (typeof element.index === 'number') {
        newEntity = newEntity[element.index];
      }
      return newEntity;
    }, sourceTopLevelEntity as any);
  }

  protected _onWorkspaceSwitch(): void {
    this._initialised = false;
    this.visualisations$.pipe(first()).subscribe(visualisations => {
      Object.keys(visualisations).forEach(groupKey => {
        const group = visualisations[groupKey];

        Object.keys(group).forEach(key => {
          group[key].reset();
        });
      });
    });
  }

  private _setVisualisationError(
    functionName: string,
    sampleFileName: string,
    error: VisualisationError
  ) {
    this._getVisualisationMap().subscribe(visualisationMap => {
      visualisationMap[functionName][sampleFileName].setError(error);
    });
  }

  private _setVisualisationGraph(
    functionName: string,
    sampleFileName: string,
    graph: any
  ) {
    this._getVisualisationMap().subscribe(visualisationMap => {
      visualisationMap[functionName][sampleFileName].setGraph(graph);
    });
  }

  private _setVisualisationCdmObject(
    functionName: string,
    sampleFileName: string,
    cdmObject: CdmWorkflowObject
  ) {
    this._getVisualisationMap().subscribe(visualisationMap => {
      visualisationMap[functionName][sampleFileName].setCdmObject(cdmObject);
    });
  }

  private _getVisualisationMap(): Observable<VisualisationMap> {
    return this.visualisations$.pipe(first());
  }

  private _waitForStaticJavaCompileToFinish(): Observable<TaskStatus> {
    return this._taskService
      .getTaskStatus(Task.StaticJavaCompilation)
      .pipe(first(taskStatus => taskStatus === TaskStatus.Finished));
  }
}
