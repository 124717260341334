import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { NotificationService } from '@core/modules/snack-bar';
import * as WorkspaceActions from '@store/workspace/actions';
import { Store } from '@ngrx/store';
import { DisposableCollection } from 'monaco-languageclient';

export interface ViewState {
  uri: string;
  viewState: monaco.editor.ICodeEditorViewState;
}

@Injectable()
export class RosettaEditorHistoryService {
  constructor(
    private _store: Store,
    private _notify: NotificationService,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  private _backViewState: ViewState[] = [];
  private _forwardViewState: ViewState[] = [];
  private _currentViewState?: ViewState;

  private _editor?: monaco.editor.IStandaloneCodeEditor;
  private _disposables = new DisposableCollection();

  init(editor: monaco.editor.IStandaloneCodeEditor) {
    this.reset();
    this._editor = editor;
    this._disposables.push(editor);
    this._bindCursorPosition();
  }

  reset() {
    this._disposables.dispose();
    this._backViewState = [];
    this._forwardViewState = [];
    this._currentViewState = undefined;
    this._editor = undefined;
  }

  goBack() {
    this._updateViewState({
      direction: 'Back',
      fromList: this._backViewState,
      toList: this._forwardViewState,
    });
  }

  goForward() {
    this._updateViewState({
      direction: 'Forward',
      fromList: this._forwardViewState,
      toList: this._backViewState,
    });
  }

  saveCurrentViewState() {
    // setTimeout used to ensure model state restored before saving
    setTimeout(() => {
      const current = this._getCurrentViewState();
      const last = this._currentViewState;
      const dup =
        last &&
        last.viewState &&
        last.uri === current?.uri &&
        last.viewState.cursorState[0].position.lineNumber ===
          current.viewState.cursorState[0].position.lineNumber;

      if (this._currentViewState) {
        if (dup) {
          this._backViewState.pop();
        }
        this._backViewState.push(this._currentViewState);
      }

      // Set new current view state
      this._currentViewState = current;

      // Always clear all undone states when adding new ones
      this._forwardViewState = [];

      // Trim view state to maximum length
      this._backViewState = this._backViewState.slice(
        -this._config.editor.maxHistoryState
      );
    });
  }

  private _getCurrentViewState() {
    const model = this._editor?.getModel();

    if (!model) {
      return undefined;
    }

    const uri = model.uri.toString();
    const viewState = this._editor.saveViewState();

    if (!viewState) {
      return undefined;
    }

    return { uri, viewState };
  }

  private _updateViewState({
    direction,
    fromList,
    toList,
  }: {
    direction: 'Back' | 'Forward';
    fromList: ViewState[];
    toList: ViewState[];
  }) {
    // Get previous state
    const nextViewState = fromList.pop();
    this._notify.dismiss();

    if (!this._editor || !nextViewState || !this._currentViewState) {
      this._notify.showWarning({
        message: `No more ${direction} actions`,
      });
      return;
    }

    toList.push(this._currentViewState);
    this._currentViewState = nextViewState;

    // select saved item
    this._store.dispatch(
      WorkspaceActions.selectWorkspaceItem({
        uri: nextViewState.uri,
        ignoreHistoryState: true,
      })
    );
    // restore state
    this._editor.restoreViewState(nextViewState.viewState);
    this._editor.focus();
  }

  private _bindCursorPosition() {
    if (!this._editor) {
      return;
    }

    this._disposables.push(
      this._editor.onDidChangeCursorPosition(e => {
        // Check source so that the history state doesn't get lost when going back
        if (e.source === 'mouse') {
          this.saveCurrentViewState();
        }
      })
    );
  }
}
