import { Injectable } from '@angular/core';
import { TaskService } from '@core/services';
import {
  GridViewGenerationResult,
  Task,
  TaskNotification,
  TaskStatus,
} from '@models';
import { EngineFunctionsService } from '@workspace-engine/functions/services/engine-functions.service';
import { RosettaGridService } from '@workspace-design/definition/services/rosetta-grid.service';
import { TaskListeners } from '../task-listener.models';

@Injectable()
export class GridViewGenerationListenerService implements TaskListeners {
  constructor(
    private _rosettaGridService: RosettaGridService,
    private _engineFunctionService: EngineFunctionsService,
    private _taskService: TaskService
  ) {}

  action(task: TaskNotification) {
    if (task.task !== Task.GridViewGeneration) {
      return;
    }

    if (task.status === 'STARTED') {
      this._taskService.updateTaskStatus(
        task.task,
        undefined,
        TaskStatus.Started
      );
      this._rosettaGridService.onGridViewGenerationStarted();
    }

    if (task.status === 'FINISHED') {
      const gridViewGenerationRunResult =
        task.payload as GridViewGenerationResult;
      this._taskService.updateTaskStatus(
        task.task,
        undefined,
        TaskStatus.Finished
      );
      this._rosettaGridService.onGridViewGenerationFinished(
        gridViewGenerationRunResult
      );
      this._engineFunctionService.onGridViewGenerationFinished(
        gridViewGenerationRunResult
      );
    }
  }
}
