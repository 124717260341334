import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { RosettaUser } from '@features/auth/login';
import {
  RosettaClassDetails,
  RosettaClasses,
  RosettaClassHierarchy,
  SymbolIdentifier,
  TreeStructure,
  WorkspaceId,
} from '@models';
import { Store } from '@ngrx/store';
import { NodeNameType } from '@shared/modules/code-view/models/code-view.model';
import { AuthSelectors } from '@store/selectors';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import { combineLatest, first, Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TreeApiService {
  constructor(
    private _http: HttpClient,
    private _store: Store,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  getTreeData(
    owner: string,
    workspaceName: string,
    className: string
  ): Observable<TreeStructure> {
    return this._http.get<TreeStructure>(
      `${this._config.resourcePaths.navigator}/roottree/${owner}/${workspaceName}/${className}`
    );
  }

  getClassList(
    owner: string,
    workspaceName: string
  ): Observable<RosettaClasses> {
    return this._http.get<RosettaClasses>(
      `${this._config.resourcePaths.navigator}/classes/${owner}/${workspaceName}`
    );
  }

  getClassData(
    owner: string,
    workspaceName: string,
    type: string
  ): Observable<RosettaClassDetails> {
    return this._http.get<RosettaClassDetails>(
      `${this._config.resourcePaths.navigator}/search/class/details/${owner}/${workspaceName}/${type}`
    );
  }

  getClassHierarchyData(
    owner: string,
    workspaceName: string,
    type: string
  ): Observable<RosettaClassHierarchy> {
    return this._http.get<RosettaClassHierarchy>(
      `${this._config.resourcePaths.navigator}/classhierarchy/${owner}/${workspaceName}/${type}`
    );
  }

  getSymbolFromType(nodeType: NodeNameType): Observable<SymbolIdentifier> {
    return combineLatest([
      this._store
        .select(WorkspaceSelectors.selectWorkspaceId)
        .pipe(first(isNotNull)),
      this._store
        .select(AuthSelectors.selectRosettaUser)
        .pipe(first(isNotNull)),
    ]).pipe(
      switchMap(([ws, user]) =>
        this._http.get<SymbolIdentifier>(this._createUrl(ws, user, nodeType))
      )
    );
  }

  private _createUrl(
    ws: WorkspaceId,
    user: RosettaUser,
    type: NodeNameType
  ): string {
    const baseUrl = `${this._config.resourcePaths.symbol}/${user.userId}/${ws.name}`;
    return type.parentType
      ? `${baseUrl}/${type.parentType}/${type.name}`
      : `${baseUrl}/${type.name}`;
  }
}
