import { InjectionToken } from '@angular/core';
import { MONACO_THEMES } from '@configs';
import { createUrl } from '@utils';
import { RosettaConfiguration } from '@workspace-design/textual/config/rosetta-monaco-language';
import {
  LANGUAGE_ROSETTA,
  LANGUAGE_ROSETTA_ORIGINAL,
} from '@workspace-design/textual/models/editor.const';
import {
  MonacoInit,
  ServiceOverridesFunctions,
} from '@workspace-design/textual/models/rosetta-editor.model';
import {
  semanticTokenToScopes,
  TMToMonacoToken,
} from '@workspace-design/textual/services/monaco-textmate-patch';
import { languages } from 'monaco-editor-core';
import { buildWorkerDefinition } from 'monaco-editor-workers';
import { first } from 'rxjs';

export const MONACO_LOADER = new InjectionToken<MonacoLoader>('MonacoLoader', {
  factory: () => new MonacoLoader(),
});

let loadedMonaco = false;
let loadPromise: Promise<void>;

export class MonacoLoader {
  loadMonaco(monacoInit?: MonacoInit) {
    // Wait until monaco editor is available
    if (loadedMonaco) {
      // Wait until monaco editor is available
      loadPromise.then(() => {
        monacoInit?.onMonacoInit(monaco);
      });
    } else {
      loadedMonaco = true;
      loadPromise = new Promise<void>((resolve: any) => {
        const baseUrl = 'assets/monaco/min/vs';
        if (typeof (window as any).monaco === 'object') {
          resolve();
          return;
        }
        const onGotAmdLoader: any = () => {
          // Load monaco
          (window as any).require.config({
            paths: {
              vs: baseUrl,
              'monaco-languages': 'assets/monaco-languages/release',
              'monaco-json': `${baseUrl}/language/json/`,
            },
          });
          (window as any).require(['vs/editor/editor.main'], () => {
            buildWorkerDefinition(
              'assets/monaco-workers/dist/workers',
              createUrl().toString(),
              false
            );
            this._registerRosettaLanguage();
            monacoInit?.onMonacoInit(monaco);
            this._defineThemes();
            this._loadStuffBefore();
            resolve();
          });
        };

        // Load AMD loader if necessary
        if (!(window as any).require) {
          const loaderScript: HTMLScriptElement =
            document.createElement('script');
          loaderScript.type = 'text/javascript';
          loaderScript.src = `${baseUrl}/loader.js`;
          loaderScript.addEventListener('load', onGotAmdLoader);
          document.body.appendChild(loaderScript);
        } else {
          onGotAmdLoader();
        }
      });
    }
    return loadPromise;
  }

  runServiceOverridesInit(serviceOverrides: ServiceOverridesFunctions) {
    (window as any).require(
      [
        'vs/editor/standalone/browser/standaloneCodeEditorService',
        'vs/editor/contrib/message/browser/messageController',
        'vs/editor/standalone/browser/standaloneThemeService',
      ],
      (
        standaloneCodeService: any,
        messageController: any,
        standaloneThemeService: any
      ) => {
        // Monkey patch monaco lib to open selected editor model from peek widget (cmd + click)
        this._patchOpenEditor(serviceOverrides, standaloneCodeService);

        // Monkey patch monaco lib to add a message when the editor is read-only
        this._dynamicReadOnlyMessage(serviceOverrides, messageController);

        // Monkey patch monaco lib to support semantic token to textmate scope conversion
        this._supportSemanticTokenScopes(standaloneThemeService);
      }
    );
  }

  private _loadStuffBefore() {
    (window as any).require(
      [
        'monaco-languages/monaco.contribution',
        'monaco-json/monaco.contribution',
      ],
      () => {}
    );
  }

  private _defineThemes() {
    const themes = MONACO_THEMES as Record<string, any>;
    for (const themeGroup of Object.keys(themes)) {
      for (const theme of Object.keys(themes[themeGroup])) {
        const themePath = `${themeGroup}-${theme}`;
        // register theme using themePath
        monaco.editor.defineTheme(themePath, themes[themeGroup][theme]);
      }
    }
  }

  private _registerRosettaLanguage() {
    this._initialiseLanguage(LANGUAGE_ROSETTA, {
      id: LANGUAGE_ROSETTA,
      aliases: ['ROSETTA', 'rosetta'],
      extensions: ['.rosetta'],
      filenamePatterns: ['working/**/*.rosetta'],
      mimetypes: ['text/rosetta'],
    });

    this._initialiseLanguage(LANGUAGE_ROSETTA_ORIGINAL, {
      id: LANGUAGE_ROSETTA_ORIGINAL,
      extensions: ['.rosetta'],
      mimetypes: ['text/originalrosetta'],
    });
  }

  private _initialiseLanguage(
    languageName: string,
    languageExtensionPoint: languages.ILanguageExtensionPoint
  ) {
    monaco.languages.onLanguage(languageName, () => {
      monaco.languages.setLanguageConfiguration(
        languageName,
        RosettaConfiguration
      );
    });

    monaco.languages.register(languageExtensionPoint);
  }

  private _dynamicReadOnlyMessage(
    serviceOverrides: ServiceOverridesFunctions,
    messageController: any
  ) {
    messageController.MessageController.prototype._onDidAttemptReadOnlyEdit =
      function () {
        if (this._editor.hasModel()) {
          serviceOverrides
            .readonlyMessage()
            .pipe(first())
            .subscribe(msg => {
              this.showMessage(msg, this._editor.getPosition());
            });
        }
      };
  }

  private _patchOpenEditor(
    serviceOverrides: ServiceOverridesFunctions,
    standaloneCodeService: any
  ) {
    standaloneCodeService.StandaloneCodeEditorService.prototype.openCodeEditor =
      (data: any, editor: monaco.editor.ICodeEditor) => {
        if (!editor) {
          return Promise.resolve(null);
        }
        serviceOverrides.openEditor(data['resource'], () => {
          const options = data['options'];
          editor.setSelection(options.selection);
          editor.revealLineInCenter(options.selection.startLineNumber);
        });
        return Promise.resolve(editor);
      };
  }

  private _supportSemanticTokenScopes(standaloneThemeService: any) {
    const oldDefineTheme =
      standaloneThemeService.StandaloneThemeService.prototype.defineTheme;
    standaloneThemeService.StandaloneThemeService.prototype.defineTheme =
      function (
        themeName: string,
        themeData: monaco.editor.IStandaloneThemeData
      ) {
        oldDefineTheme.apply(this, [themeName, themeData]);
        const theme = this._knownThemes.get(themeName);
        const oldGetTokenStyleMetadata =
          theme.constructor.prototype.getTokenStyleMetadata;
        theme.constructor.prototype.getTokenStyleMetadata = function (
          type: string,
          modifiers: string[],
          modelLanguage: string
        ) {
          const scopes = semanticTokenToScopes(type, modifiers);
          const monacoToken = TMToMonacoToken(this.tokenTheme, scopes);
          return oldGetTokenStyleMetadata.apply(this, [
            monacoToken,
            [],
            modelLanguage,
          ]);
        };
        standaloneThemeService.StandaloneThemeService.prototype.defineTheme =
          oldDefineTheme;
      };
  }
}
