import { WorkspaceId } from '@models';
import { createAction, props } from '@ngrx/store';
import { TaskStatusKey } from '@store/reducers/task.reducer';

export const taskStarted = createAction(
  '[Task Service] Task Started',
  props<{ name: TaskStatusKey }>()
);
export const taskFinished = createAction(
  '[Task Service] Task Finished',
  props<{ name: TaskStatusKey; payload: any }>()
);

export const submitTask = createAction(
  '[Task Service] Submit Task',
  props<{ data: any }>()
);

export const switchWorkspace = createAction(
  '[Task Service] Switch Workspace',
  props<{ workspaceId: WorkspaceId }>()
);
