import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { DocRefSegment } from '@models';
import { Store } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import { first, map, switchMap } from 'rxjs';
import {
  BodyCorpusDTO,
  BodyCorpusSegmentsDTO,
  RegProvisionDetailsDTO,
} from '../models';

@Injectable()
export class RegulationApiService {
  constructor(
    private _http: HttpClient,
    private _store: Store,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  private _createReportUrl$ = this._store
    .select(WorkspaceSelectors.selectWorkspaceInfo)
    .pipe(
      first(isNotNull),
      map(
        ({ id: { name, userId } }) =>
          `${this._config.resourcePaths.bsp}/${userId}/${name}/regulation`
      )
    );

  getBodyCorpus() {
    return this._createReportUrl$.pipe(
      switchMap(url => this._http.get<BodyCorpusDTO>(`${url}/body-corpus-list`))
    );
  }

  getBodyCorpusContent(body: string, corpusList: string[]) {
    return this._createReportUrl$.pipe(
      switchMap(url =>
        this._http.get<BodyCorpusSegmentsDTO>(
          `${url}/${body}/${corpusList.join('/')}`
        )
      )
    );
  }

  getProvisionDetails(
    body: string,
    corpusList: string[],
    segments: DocRefSegment[]
  ) {
    return this._createReportUrl$.pipe(
      switchMap(url =>
        this._http.post<RegProvisionDetailsDTO[]>(
          `${url}/${body}/${corpusList.join('/')}`,
          segments
        )
      )
    );
  }
}
