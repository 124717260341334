import { Inject, Injectable, Optional } from '@angular/core';
import { TaskService } from '@core/services/task.service';
import { Task, TaskNotification, TaskStatus } from '@models';
import { TaskListeners, TASK_MESSAGE_LISTENERS } from './task-listener.models';

@Injectable()
export class TaskMessagesService {
  constructor(
    private _taskService: TaskService,
    @Optional()
    @Inject(TASK_MESSAGE_LISTENERS)
    private _taskListeners: TaskListeners[]
  ) {}

  private _customErrorHandlingTasks = [
    Task.WorkflowExecution,
    Task.GraphGeneration,
  ];

  onTaskNotification(task: TaskNotification) {
    if (task.status === 'CANCELLED') {
      this._onTaskCancel(task);
      return;
    }

    if (
      task.status === 'ERROR' &&
      !this._customErrorHandlingTasks.includes(task.task)
    ) {
      this._onTaskError(task);
      return;
    }

    if (!task.payload) {
      return;
    }

    const listeners = this._taskListeners || [];
    listeners.forEach(listener => listener.action(task));
  }

  private _onTaskCancel(task: TaskNotification) {
    this._taskService.updateAllSubTaskStatus(task.task, TaskStatus.Pending);
  }

  private _onTaskError(task: TaskNotification) {
    // Update only the task that caused the unexpected error
    // eslint-disable-next-line  no-console
    console.warn(
      `Internal Server Error from task infra, TaskNotification: ${JSON.stringify(
        task
      )}`
    );
    this._taskService.updateAllSubTaskStatus(task.task, TaskStatus.Error);
  }
}
