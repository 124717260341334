import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StripHtmlPipe } from './strip-html.pipe';

@NgModule({
  declarations: [StripHtmlPipe],
  imports: [CommonModule],
  exports: [StripHtmlPipe],
})
export class StripHtmlModule {}
