import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { createFormDataWithFile } from '@utils';
import { uniqBy } from 'lodash-es';
import {
  Observable,
  OperatorFunction,
  catchError,
  concat,
  concatMap,
  first,
  forkJoin,
  from,
  last,
  map,
  of,
  startWith,
  switchMap,
  tap,
  throwError,
  zip,
} from 'rxjs';
import { isTranslate_1_5 } from '../helpers/translate-1-5.helpers';
import {
  PipelineDef,
  PipelineRunData,
  PipelineRunInfo,
  PipelineRunSample,
  SampleRowAction,
  SampleRowStatus,
  TRANSLATE_1_5_RUN_STEP,
  TRANSLATE_1_5_TOTAL_STEPS,
  TestPackDef,
  TestPackSampleId,
  TransientSample,
  TransientTestPack,
} from '../models';
import { toTransientSampleDTO } from '../models/mapper';
import { TestPackGridSelection } from '../models/test-pack-grid-selection.model';
import { TestPackGrid } from '../models/test-pack-grid.model';
import {
  ITransformConfig,
  TRANSFORM_CONFIG,
  TransformType,
} from '../models/transform-config.model';
import { TransformLocalStorageService } from './transform-local-storage.service';
import { TransformServerStorageService } from './transform-server-storage.service';
import { TransformStateSyncService } from './transform-state-sync.service';
import { Translate_1_5ApiService } from './translate-1-5-api.service';

type IAddSampleCallback = (
  selection: TestPackGridSelection,
  sample: TransientSample,
  isFirstInPipeline: boolean
) => Observable<TestPackSampleId>;

type IServiceToRunFunc<T> = (
  isLastItem: boolean,
  selection: TestPackGridSelection
) => Observable<T>;

@Injectable()
export class TransformStorageService {
  constructor(
    private _store: Store,
    private _serverService: TransformServerStorageService,
    private _transformStateSync: TransformStateSyncService,
    private _localStorage: TransformLocalStorageService,
    private _translateService: Translate_1_5ApiService,
    @Inject(TRANSFORM_CONFIG) private _transformConfig: ITransformConfig
  ) {}

  getSelection(
    pipelineId: string,
    testPackId: string
  ): Observable<TestPackGridSelection> {
    return this.getPipelines().pipe(
      map(pipelines => pipelines.find(p => p.id === pipelineId)),
      switchMap(pipelineDef =>
        this.getTestPacks(pipelineDef).pipe(
          map(testPacks =>
            testPacks.find(testPack => testPack.id === testPackId)
          ),
          map(testPackDef => ({
            pipelineDef,
            testPackDef,
          }))
        )
      )
    );
  }

  getPipelines(): Observable<PipelineDef[]> {
    return this._serverService.getPipelines(this._transformConfig.type);
  }

  getTestPacks(pipelineDef: PipelineDef): Observable<TestPackDef[]> {
    return zip(
      this._serverService.getTestPacks(
        this._transformConfig.type,
        pipelineDef.id
      ),
      this._localStorage.getTestPacks(pipelineDef.id)
    ).pipe(map(TestPacks => uniqBy(TestPacks.flat(), 'id')));
  }

  runTestPackPipeline(
    selection: TestPackGridSelection
  ): Observable<PipelineRunInfo<PipelineRunData>> {
    return this._serverService.getAggregatedPipelines(selection).pipe(
      tap(aggregateSelection =>
        this._transformStateSync.setPipelines(aggregateSelection)
      ),
      switchMap(({ upstream, midstream }) =>
        this._runCompletePipeline<PipelineRunData>(
          [...upstream, midstream],
          this._getTestPackPipelineToRun()
        )
      )
    );
  }

  runTranslate_1_5(
    testPackGridSelection: TestPackGridSelection
  ): Observable<PipelineRunInfo<PipelineRunData>> {
    this._transformStateSync.setPipelines([testPackGridSelection]);
    const getPipelineRunResult = (data: any = null): PipelineRunInfo => ({
      result: {
        data,
        details: {
          testPackGridSelection,
          description: `${TransformType.Translate_1_5} ${testPackGridSelection.pipelineDef.name}`,
          currentPipeline: TRANSLATE_1_5_RUN_STEP,
          totalPipelines: TRANSLATE_1_5_TOTAL_STEPS,
        },
      },
      errorMessage: null,
    });
    return concat(
      this._translateService.preRunCheck(testPackGridSelection),
      of(getPipelineRunResult()),
      this._runTestPack(testPackGridSelection).pipe(
        map(result => getPipelineRunResult(result))
      )
    );
  }

  /**
   * TODO: Remove this once Upload test packs have been completely removed
   * @deprecated we should not be using this method any more as we pipeline all test packs
   */
  runUploadTestPack(
    selection: TestPackGridSelection
  ): Observable<PipelineRunInfo<PipelineRunData>> {
    return this._runTestPack(selection).pipe(
      map(result => ({
        result: {
          data: result,
          details: {
            testPackGridSelection: selection,
            data: result,
            description: 'Running uploaded test pack',
            currentPipeline: 1,
            totalPipelines: 1,
          },
        },
        errorMessage: null,
      }))
    );
  }

  codeViewData(
    testPackGridSelection: TestPackGridSelection,
    sampleId: string
  ): Observable<PipelineRunInfo<PipelineRunSample>> {
    if (isTranslate_1_5(testPackGridSelection)) {
      return this._runTranslate_1_5CodeViewData(
        testPackGridSelection,
        sampleId
      );
    }
    return this._serverService
      .getUpstreamPipelines(testPackGridSelection)
      .pipe(
        switchMap(upstreamSelections =>
          this._runCompletePipeline<PipelineRunSample>(
            [...upstreamSelections, testPackGridSelection],
            this._getCodeViewPipelineToRun(sampleId)
          )
        )
      );
  }

  updateSample(
    selection: TestPackGridSelection,
    sampleIds: string[]
  ): Observable<void> {
    /*
    NOTE: Currently users cannot update uploaded samples
    */
    return this._serverService.updateSample(selection, sampleIds);
  }

  revertSample(
    selection: TestPackGridSelection,
    sampleIds: string[]
  ): Observable<void> {
    /*
    NOTE: Currently users cannot revert uploaded samples
    */
    return this._serverService.revertSample(selection, sampleIds);
  }

  deleteSample(
    testPackGridSelection: TestPackGridSelection,
    sampleId: string
  ): Observable<void> {
    return this._serverService
      .getDownstreamPipelines(testPackGridSelection)
      .pipe(
        map(downstreamSelections => [
          testPackGridSelection,
          ...downstreamSelections,
        ]),
        switchMap(pipelines =>
          from(pipelines).pipe(
            concatMap(selection =>
              this._localStorage
                .getSample(selection, sampleId)
                .pipe(
                  switchMap(sample =>
                    sample
                      ? this._localStorage.deleteSample(selection, sampleId)
                      : this._serverService.deleteSample(selection, sampleId)
                  )
                )
            ),
            last()
          )
        )
      );
  }

  addSample(
    testPackGridSelection: TestPackGridSelection,
    transientSample: TransientSample
  ): Observable<TestPackDef> {
    return this._store.select(WorkspaceSelectors.isReadonlyWorkspace).pipe(
      first(),
      switchMap(isReadonly =>
        isReadonly
          ? this._addOnClient(testPackGridSelection, transientSample)
          : this._addOnServer(testPackGridSelection, transientSample)
      ),
      last(),
      map(() => testPackGridSelection.testPackDef)
    );
  }

  private _runTranslate_1_5CodeViewData(
    testPackGridSelection: TestPackGridSelection,
    sampleId: string
  ): Observable<PipelineRunInfo<PipelineRunSample>> {
    return concat(
      this._translateService.preRunCheck(testPackGridSelection),
      this._runCompletePipeline<PipelineRunSample>(
        [testPackGridSelection],
        this._getCodeViewPipelineToRun(sampleId)
      ).pipe(
        map(result => ({
          ...result,
          result: {
            ...result.result,
            details: {
              ...result.result.details,
              currentPipeline: TRANSLATE_1_5_RUN_STEP,
              totalPipelines: TRANSLATE_1_5_TOTAL_STEPS,
            },
          },
        }))
      )
    );
  }

  private _addOnClient(
    testPackGridSelection: TestPackGridSelection,
    transientSample: TransientSample
  ): Observable<void> {
    const transactionId = Date.now();
    return this._addSamplesToConnectedPipelines(
      (
        selection: TestPackGridSelection,
        sample: TransientSample,
        isFirstInPipeline: boolean
      ) =>
        this._serverService
          .validateSample(
            selection,
            createFormDataWithFile(toTransientSampleDTO(sample))
          )
          .pipe(
            switchMap(() =>
              this._localStorage.addSample(
                selection,
                {
                  ...sample.sample,
                  isFirstInPipeline,
                },
                transactionId
              )
            ),
            catchError(e =>
              this._localStorage
                .deleteDownstreamSamples(
                  selection,
                  transientSample.sample.sampleDef.id
                )
                .pipe(switchMap(() => throwError(() => e)))
            )
          ),
      testPackGridSelection,
      transientSample,
      true // isFirstSample
    );
  }

  private _addOnServer(
    testPackGridSelection: TestPackGridSelection,
    transientSample: TransientSample
  ): Observable<void> {
    return this._addSamplesToConnectedPipelines(
      (selection: TestPackGridSelection, sample: TransientSample) =>
        this._serverService.addSample(
          selection,
          createFormDataWithFile(toTransientSampleDTO(sample))
        ),
      testPackGridSelection,
      transientSample
    ).pipe(
      catchError(e =>
        this._serverService
          .deleteDownstreamSamples(
            testPackGridSelection,
            transientSample.sample.sampleDef.id
          )
          .pipe(switchMap(() => throwError(() => e)))
      )
    );
  }

  private _addSamplesToConnectedPipelines(
    addSampleCallback: IAddSampleCallback,
    testPackGridSelection: TestPackGridSelection,
    transientSample: TransientSample,
    isFirstSample = false
  ): Observable<void> {
    return addSampleCallback(
      testPackGridSelection,
      transientSample,
      isFirstSample
    ).pipe(
      switchMap(testPackSampleId =>
        this._serverService
          .geConnectedDownstreamPipelines(testPackGridSelection)
          .pipe(
            switchMap(nextDownStreams => {
              if (nextDownStreams.length < 1) {
                return of(null);
              }
              return this._runSampleAndAddToConnectedPipelines(
                addSampleCallback,
                nextDownStreams,
                testPackSampleId,
                transientSample
              );
            })
          )
      )
    );
  }

  private _runSampleAndAddToConnectedPipelines(
    addSampleCallback: IAddSampleCallback,
    nextDownStreams: TestPackGridSelection[],
    { selection, sampleDef }: TestPackSampleId,
    transientSample: TransientSample
  ): Observable<void> {
    return this._serverService
      .runTransientSample(
        selection,
        sampleDef.id,
        this._updateTransientSampleId(transientSample, sampleDef.id)
      )
      .pipe(
        switchMap(nextTransientSample =>
          forkJoin(
            nextDownStreams.map(downstream =>
              this._addSamplesToConnectedPipelines(
                addSampleCallback,
                downstream,
                nextTransientSample
              )
            )
          ).pipe(map(() => null))
        )
      );
  }

  private _runTestPack(
    testPackGridSelection: TestPackGridSelection,
    transientTestPack?: TransientTestPack
  ): Observable<TestPackGrid> {
    return this._localStorage
      .getSamples(testPackGridSelection)
      .pipe(
        switchMap(localTransientTestPack =>
          this._serverService
            .runTestPack(
              testPackGridSelection,
              this._mergeTransientTestPacks(
                localTransientTestPack,
                transientTestPack
              )
            )
            .pipe(
              this._processTestPackGridWithLocalData(
                testPackGridSelection,
                localTransientTestPack
              )
            )
        )
      );
  }

  private _runTransientTestPack(
    testPackGridSelection: TestPackGridSelection,
    transientTestPack?: TransientTestPack
  ): Observable<TransientTestPack> {
    return this._localStorage
      .getSamples(testPackGridSelection)
      .pipe(
        switchMap(localTransientTestPack =>
          this._serverService.runTransientTestPack(
            testPackGridSelection,
            this._mergeTransientTestPacks(
              localTransientTestPack,
              transientTestPack
            )
          )
        )
      );
  }

  private _processTestPackGridWithLocalData(
    testPackGridSelection: TestPackGridSelection,
    localTransientTestPack?: TransientTestPack
  ): OperatorFunction<TestPackGrid, TestPackGrid> {
    return map(testPackGrid => {
      return {
        ...testPackGrid,
        rows: testPackGrid.rows.map(row => {
          const sample = localTransientTestPack?.samples.find(
            sample => sample.sampleDef.id === row.sampleId
          );

          if (!sample) {
            return row;
          }

          return {
            ...row,
            /*
              ******************     HACK     ******************
              TODO: Remove this when the backend respects UI Ids Work
              around: UI to replace backend Ids for the UI generated Ids
              **************************************************
            */
            sampleId: sample.sampleDef.id,

            /*
              ***************     Update sample     ************
              Client to update the sample row status and actions as it
              can figure out if the sample is first in the pipeline
              **************************************************
            */
            sampleRowStatus: sample.isFirstInPipeline
              ? row.sampleRowStatus
              : SampleRowStatus.AddedUpstream,
            actions: sample.isFirstInPipeline
              ? row.actions
              : row.actions.filter(action => action !== SampleRowAction.Delete),
          };
        }),
        testPackDef: testPackGridSelection.testPackDef,
      };
    });
  }

  private _getCodeViewPipelineToRun(
    sampleId: string
  ): IServiceToRunFunc<PipelineRunSample> {
    let upstreamTransientSample: TransientSample | null = null;

    return (lastItem, selection) => {
      if (lastItem) {
        return this._getSampleSource$(
          upstreamTransientSample,
          selection,
          sampleId
        ).pipe(
          switchMap(sample =>
            forkJoin({
              input: this._serverService.codeViewInput(
                selection,
                sampleId,
                sample
              ),
              output: this._serverService.codeViewOutput(
                selection,
                sampleId,
                sample
              ),
            })
          )
        );
      }

      return this._serverService
        .runTransientSample(selection, sampleId, upstreamTransientSample)
        .pipe(
          tap(response => {
            upstreamTransientSample = response;
          }),
          catchError(error => throwError(() => error))
        );
    };
  }

  private _getSampleSource$(
    upstreamTransientSample: TransientSample | null,
    selection: TestPackGridSelection,
    sampleId: string
  ): Observable<TransientSample | null> {
    return upstreamTransientSample
      ? of(upstreamTransientSample)
      : this._localStorage
          .getSample(selection, sampleId)
          .pipe(catchError(() => of(null)));
  }

  private _getTestPackPipelineToRun(): IServiceToRunFunc<PipelineRunData> {
    let prevResponse: TransientTestPack;
    return (lastItem, selection) => {
      return lastItem
        ? this._runTestPack(selection, prevResponse)
        : this._runTransientTestPack(selection, prevResponse).pipe(
            tap(response => {
              prevResponse = response;
            })
          );
    };
  }

  private _runCompletePipeline<T>(
    completePipeline: TestPackGridSelection[],
    getServiceToRun: IServiceToRunFunc<T>
  ): Observable<PipelineRunInfo<T>> {
    const totalPipelines = completePipeline.length;
    const isLastItem = (index: number) => index + 1 === totalPipelines;
    return from(completePipeline).pipe(
      concatMap((testPackGridSelection, index) => {
        const transformType =
          testPackGridSelection.pipelineDef.transformType.toLowerCase();
        const pipeline = testPackGridSelection.pipelineDef.name;
        const newPipelineRunDetails: PipelineRunInfo<T> = {
          result: {
            data: null,
            details: {
              testPackGridSelection,
              description: `${transformType} ${pipeline}`,
              currentPipeline: index + 1,
              totalPipelines,
            },
          },
          errorMessage: null,
        };

        return getServiceToRun(isLastItem(index), testPackGridSelection).pipe(
          // Emit value to mark the end of another PipelineRunResult
          switchMap(data =>
            of<PipelineRunInfo<T>>({
              ...newPipelineRunDetails,
              result: {
                ...newPipelineRunDetails.result,
                data,
              },
            })
          ),
          // Emit value to mark the start of another PipelineRunResult
          startWith(newPipelineRunDetails)
        );
      })
    );
  }

  private _mergeTransientTestPacks(
    ...transientTestPacks: TransientTestPack[]
  ): TransientTestPack | null {
    transientTestPacks = transientTestPacks.filter(Boolean);
    if (!transientTestPacks || !transientTestPacks.length) {
      return null;
    }
    const [first, ...testPacks] = transientTestPacks;
    return testPacks.reduce(
      (acc, curr) => ({
        ...acc,
        samples: [...acc.samples, ...curr.samples],
      }),
      first
    );
  }

  private _updateTransientSampleId(
    transientSample: TransientSample,
    id: string
  ): TransientSample {
    return {
      ...transientSample,
      sample: {
        ...transientSample.sample,
        sampleDef: {
          ...transientSample.sample.sampleDef,
          id,
        },
      },
    };
  }
}
