import { Injectable } from '@angular/core';
import { RootTypeService } from '@core/services/root-type.service';
import { Task, TaskNotification } from '@models';
import { Store } from '@ngrx/store';
import { TaskActions } from '@store/.';
import { TaskListeners } from '../task-listener.models';

@Injectable()
export class RootTypeCacheListenerService implements TaskListeners {
  constructor(
    private _store: Store,
    private _rootTypeService: RootTypeService
  ) {}

  action(task: TaskNotification) {
    if (task.task !== Task.RootTypeCache) {
      return;
    }

    const roots = task.payload as string[];
    this._rootTypeService.onRootTypesChanged(roots);

    if (task.status === 'FINISHED') {
      this._store.dispatch(
        TaskActions.taskFinished({
          name: task.task,
          payload: task.payload,
        })
      );
    }
  }
}
