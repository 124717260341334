import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HasAbilityDirective } from './has-ability.directive';

@NgModule({
  declarations: [HasAbilityDirective],
  imports: [CommonModule],
  exports: [HasAbilityDirective],
})
export class HasAbilityDirectiveModule {}
