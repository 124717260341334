import { Injectable } from '@angular/core';
import { Task, TaskNotification } from '@models';
import { Store } from '@ngrx/store';
import { TaskActions } from '@store/.';
import { TaskListeners } from '../task-listener.models';

@Injectable()
export class ExecutionEngineInitialisationListenerService
  implements TaskListeners
{
  constructor(private _store: Store) {}

  action(task: TaskNotification) {
    if (task.task !== Task.ExecutionEngineInitialisation) {
      return;
    }

    if (task.status === 'STARTED') {
      this._store.dispatch(TaskActions.taskStarted({ name: task.task }));
    }

    if (task.status === 'FINISHED') {
      this._store.dispatch(
        TaskActions.taskFinished({ name: task.task, payload: task.payload })
      );
    }
  }
}
