<ng-container *ngIf="model$ | async as model">
  <div class="dropdown-header" [ngStyle]="{ background: model.primaryColor }">
    <app-project-card-menu [model]="model" class="card-menu" />
    <app-project-logo class="mr-8" [data]="model" />
    <app-project-type-text
      [workspaceType]="data?.workspaceType"
      [color]="model.primaryColor"
    />
  </div>
  <app-dev-banner
    *ngIf="data.developmentVersion"
    [color]="model.primaryColor"
  />
  <div class="dropdown-body">
    <div class="dropdown-body__info">
      <div>
        <h4>Project</h4>
        <p>{{ model.name }}</p>
      </div>
      <div>
        <h4>Owner</h4>
        <p>{{ data.name }}</p>
      </div>
    </div>
    <div
      *appShowSpinner="workspaceReady$ | async; flexCentre: true"
      class="dropdown-body__actions actions-list"
    >
      <button mat-stroked-button (click)="action.emit('download')">
        <fa-icon icon="download" />
        Download
      </button>
      <button
        *ngIf="data.githubUrl"
        mat-stroked-button
        (click)="openInGithub(data.githubUrl)"
      >
        <fa-icon [icon]="['fab', 'github']" />
        Open on Github
      </button>
      <ng-container *ngIf="!data.readOnly">
        <button
          [disabled]="noModifiedFiles$ | async"
          mat-stroked-button
          (click)="action.emit('contribute')"
        >
          <fa-icon icon="code-merge" />
          Contribute
        </button>
        <button
          *ngIf="data.canUpgrade"
          mat-flat-button
          color="primary"
          (click)="action.emit('upgrade')"
        >
          <fa-icon icon="circle-arrow-up" />
          Upgrade
        </button>
      </ng-container>
    </div>
  </div>
  <div class="recently-viewed">
    <div class="recently-viewed__table">
      <app-workspace-recent-list />
    </div>
    <div class="recently-viewed__actions actions-list">
      <button mat-stroked-button (click)="gotoWorkspaceManager()">
        <fa-icon icon="layer-group" />
        Workspace Manager
      </button>
    </div>
  </div>
</ng-container>
