import { SampleCellState } from '@shared/modules/transform/models/sample-cell-state.enum';

export interface DataViewerSummary {
  rows: number;
  columns: number;
  valid: number;
  warning: number;
  error: number;
  cellStates: Partial<Record<SampleCellState, number>>;
}

export const getDefaultDataViewerSummary = (): DataViewerSummary => ({
  rows: 0,
  columns: 0,
  valid: 0,
  warning: 0,
  error: 0,
  cellStates: {},
});
