import {
  IngestionReport,
  QualificationReport,
  ServerError,
  Task,
  ValidationReport,
} from '@models';
import { CodeView } from '@shared/modules/code-view/models/code-view.model';
import { TestType } from '@workspace-engine/translate/models';
import { ExecutionType } from '@workspace-engine/visualisation/models/visualisation-model';

export interface TranslateIngestionFile {
  id: string;
  fileName: string;
  ingestionEnvironment: string;
  testType: TestType;
  fileContent?: string;
}

export interface JsonValidatorSubmit {
  name: string;
  json: string;
  rosettaNamespace: string;
  rosettaClass: string;
}

export interface GraphGenerationSubmit {
  name: string;
  rootType: string;
  json: string;
}

export interface WorkflowExecutionSubmit {
  workflowName: string;
  workflowFunctionClass: string;
  sampleFileName?: string;
  executionType: ExecutionType;
  payload?: string;
}

export interface MultipleVisualisationSubmit {
  workflowExecutionSubmissions: WorkflowExecutionSubmit[];
}

// TaskNotification
export type TaskNotificationStatus =
  | 'STARTED'
  | 'FINISHED'
  | 'ERROR'
  | 'CANCELLED';

export interface TaskNotification {
  task: Task;
  status: TaskNotificationStatus;
  payload?: any;
}

export interface CodeCompilationResult {
  codeBase: string;
  success: boolean;
  aggregateStatus: string;
  compileErrors?: ServerError;
  classPath: string[];
}

export interface TranslateGenerateAndCompileStart {
  envs: string[];
}

export interface TranslateGenerateAndCompileResult {
  envs: string[];
  filteredEnvsGenerated: string[];
  errors?: ServerError;
}

export interface TranslateIngestRunStart {
  ids: string[];
}

export interface TranslateIngestRunResult {
  ids?: string[];
  expectationCount?: number;
  successCount?: number;
  errors?: ServerError;
}

export interface IngestRunStart {
  ids: string[];
  type: string;
  tests: Expectation[];
  supportedIngestionEnvNames: string[];
}

export interface Expectation {
  fileName: string | undefined;
  excludedPaths: number;
  outstandingMappings: number;
  validationFailures: number;
  qualificationExpectation?: QualificationExpectation;
  groupName: string;
}

export interface QualificationExpectation {
  qualifiableObjectCount: number;
  qualifyResults: { qualifiedName: string; qualifiedObjectClass: string }[];
  success: boolean;
}

export interface IngestRunResult {
  id: string;
  fileName: string;
  ingestionEnvName: string;
  report: IngestionReport;
  result: IngestResults;
  success: boolean;
  errors?: ServerError;
}

export interface IngestResults {
  input: CodeView;
  output: CodeView;
  assertionFailures: string[];
}

export interface JsonValidateStart {
  fileName: string;
}

export interface JsonValidateResult {
  fileName: string;
  success: boolean;
  validationReport: ValidationReport;
  qualificationReport: QualificationReport;
  jsonCodeView: CodeView;
}

export class GraphGenerationResult {
  fileName!: string;
  graph: any;
}

export class WorkflowExecutionResult {
  workflowName!: string;
  workflow: any;
  sampleFileName?: string;
}

export interface DownloadGenerateResult {
  task: string;
  downloadName: string;
  downloadPath: string;
  errors: string[];
}

export interface APIExportResult {
  task: string;
  name: string;
  apiType: string;
  timestamp?: Date;
  apiURL?: string;
}

export interface IngestionEnvironmentApiExport {
  name: string;
  displayName: string;
}

export interface GridViewGenerationResult {
  gridViewReport: ModelView[];
}

export interface ModelView {
  namespace: string;
  type: string;
  name: string;
  definition?: string;
  details?: ModelViewDetails;
}

export interface ModelViewDetails {
  func: FuncDetails;
  enum: string; // placeholder
  type: string; // placeholder
}

export interface FuncDetails {
  functionName: string;
  functionInputs: ModelAttribute[];
  functionOutput: ModelAttribute[];
}

export interface ModelAttribute {
  name: string;
  type: string;
  complexType: boolean;
  unbounded: boolean;
  optional: boolean;
}

export enum TaskStatus {
  Pending = 'Pending',
  Starting = 'Starting',
  Started = 'Started',
  Finished = 'Finished',
  Finished_Error = 'Finished_Error',
  Finished_Stale = 'Finished_Stale',
  Finished_Stale_Running = 'Finished_Stale_running',
  Error = 'Error',
  Unable_To_Run = 'Unable_To_Run',
  Deleting = 'Deleting',
  Deleted = 'Deleted',
  Stopped = 'Stopped',
}

export enum RunningState {
  WAITING = 'WAITING',
  RUNNING = 'RUNNING',
  READY = 'READY',
}
