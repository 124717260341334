import { UPLOAD_DIR } from '@configs';
import { LoadingState, RunningState, Task, TaskStatus } from '@models';
import { createReducer, on } from '@ngrx/store';
import { produce } from 'immer';
import {
  defaultFilter,
  EnvironmentConfig,
  FileData,
  FileIds,
  FileMap,
  Filter,
} from '../../models';
import * as actions from '../actions';
import {
  aggregateParentStatuses,
  leafNodeResults,
  setFilteredFileIds,
  startSampleFileIngestion,
} from './reducers.helper';

export const featureKey = 'translate';

export interface State {
  fileMap: FileMap;
  fileIds: FileIds;
  fileData: FileData;
  filter: Filter;
  state: LoadingState;
  runState: RunningState;
  uploadingState: LoadingState;
  environmentConfig: Record<string, EnvironmentConfig>;
}

export const initialState: Readonly<State> = {
  fileMap: {},
  fileIds: {
    all: [],
    leaf: [],
    uploaded: [],
    node: [],
    filtered: [],
  },
  fileData: {
    expectations: {},
    results: {},
    statuses: {},
    errors: {},
  },
  filter: defaultFilter,
  state: LoadingState.INITIAL,
  runState: RunningState.WAITING,
  uploadingState: LoadingState.INITIAL,
  environmentConfig: {},
};

export const reducer = createReducer(
  initialState,
  on(actions.getAllSampleFiles, state => ({
    ...state,
    state:
      state.state === LoadingState.INITIAL ? LoadingState.LOADING : state.state,
  })),

  //TODO: this action is also being used as an initialiser, initialising should be done no a workspace switch action
  on(actions.sampleFilesLoaded, (state, { payload }) =>
    produce(initialState, draft => {
      draft.state = LoadingState.LOADED;
      draft.fileMap = payload.file;
      draft.fileData.expectations = payload.expectations;
      draft.fileIds = {
        all: payload.allIds,
        leaf: payload.allLeafIds,
        uploaded: payload.allUploadedIds,
        node: payload.allNodeIds,
        filtered: payload.allIds,
      };

      // State to persist between workspace switches
      draft.runState = state.runState;
      draft.environmentConfig = state.environmentConfig;

      payload.allIds.forEach(id => {
        if (payload.file[id].environments.length === 0) {
          draft.fileData.statuses[id] = TaskStatus.Unable_To_Run;
          draft.fileData.errors[id] = {
            timestamp: '',
            userMessage:
              'Unable to run sample file due to missing "Ingestion Environment"',
            supportMessage: [],
          };
        }
      });
    })
  ),

  on(actions.uploadSampleFile, state => ({
    ...state,
    uploadingState: LoadingState.LOADING,
  })),
  on(actions.sampleFileUploadError, state => ({
    ...state,
    uploadingState: LoadingState.INITIAL,
  })),
  on(actions.sampleFileUploaded, (state, { payload }) =>
    setFilteredFileIds(
      produce(state, draft => {
        draft.fileIds.all = [...payload.allIds, ...draft.fileIds.all];
        draft.fileIds.leaf = [...payload.allLeafIds, ...draft.fileIds.leaf];
        draft.fileIds.uploaded = [
          ...payload.allUploadedIds,
          ...draft.fileIds.uploaded,
        ];

        draft.fileMap[UPLOAD_DIR].childIds = draft.fileIds.uploaded;

        draft.fileMap = { ...draft.fileMap, ...payload.file };
        draft.fileData.expectations = {
          ...payload.expectations,
          ...draft.fileData.expectations,
        };

        payload.allIds.forEach(id => {
          delete draft.fileData.statuses[id];
          delete draft.fileData.results[id];
        });

        draft.uploadingState = LoadingState.LOADED;
      })
    )
  ),

  on(actions.sampleFilesLoadFailure, state => ({
    ...state,
    state: LoadingState.ERROR,
  })),

  on(actions.filterSampleFiles, (state, { filter }) =>
    setFilteredFileIds({
      ...state,
      filter,
    })
  ),

  on(actions.deleteSampleFile, (state, { node }) =>
    produce(state, draft => {
      draft.fileData.statuses[node.id] = TaskStatus.Deleting;
    })
  ),

  on(actions.deleteSampleFileSuccess, (state, { id }) =>
    setFilteredFileIds(
      produce(state, draft => {
        delete draft.fileData.expectations[id];
        delete draft.fileData.results[id];
        delete draft.fileData.statuses[id];
        delete draft.fileMap[id];

        draft.fileMap[UPLOAD_DIR].childIds = state.fileMap[
          UPLOAD_DIR
        ]?.childIds?.filter(childId => childId !== id);

        draft.fileIds.leaf = state.fileIds.leaf.filter(
          childId => childId !== id
        );
        draft.fileIds.uploaded = state.fileIds.uploaded.filter(
          childId => childId !== id
        );
        draft.fileIds.filtered = state.fileIds.filtered.filter(
          childId => childId !== id
        );
        draft.fileIds.all = state.fileIds.all.filter(childId => childId !== id);
      })
    )
  ),

  on(actions.loadEnvironmentConfigSuccess, (state, { environmentConfig }) => ({
    ...state,
    environmentConfig: environmentConfig.reduce(
      (acc, env) => ({
        ...acc,
        [env.ingestionEnvName]: {
          ...env,
          synonymSourceNames: env.synonymSourceNames.slice(-1),
        },
      }),
      {}
    ),
  })),

  on(actions.switchWorkspace, () => ({
    ...initialState,
    state: LoadingState.LOADING,
    runState: RunningState.WAITING,
  })),

  on(actions.runSampleFilesRequestSent, state => ({
    ...state,
    runState: RunningState.RUNNING,
  })),

  on(actions.startSampleFiles, (state, { nodeIds }) =>
    aggregateParentStatuses(startSampleFileIngestion(state, nodeIds), nodeIds)
  ),

  on(actions.ingestionTestRunFinished, (state, { payload }) =>
    produce(state, draft => {
      // Change status so that it doesn't get turned into an error when StaticJavaCompilation finishes
      draft.fileData.statuses[payload.id] = TaskStatus.Starting;
    })
  ),

  on(actions.ingestionTestRunResultsSaved, (state, { payload }) =>
    aggregateParentStatuses(leafNodeResults(state, payload), payload.id)
  ),

  on(actions.translateIngestionRunStarted, (state, { payload }) =>
    produce(state, draft => {
      payload.ids.forEach(id => {
        draft.fileData.statuses[id] = TaskStatus.Started;
      });
    })
  ),

  on(actions.translateIngestionRunFinished, (state, { payload }) =>
    aggregateParentStatuses(
      produce(state, draft => {
        draft.runState = RunningState.READY;

        payload.ids?.forEach(id => {
          if (
            draft.fileData.statuses[id] === TaskStatus.Started ||
            draft.fileData.statuses[id] === TaskStatus.Starting
          ) {
            draft.fileData.statuses[id] = TaskStatus.Error;
          }
        });
      }),
      payload.ids
    )
  ),

  on(actions.translateIngestionRunErrored, state =>
    produce(state, draft => {
      draft.runState = RunningState.READY;

      for (const key in draft.fileData.statuses) {
        if (
          !draft.fileData.statuses.hasOwnProperty(key) ||
          draft.fileData.statuses[key] === TaskStatus.Started
        ) {
          draft.fileData.statuses[key] = TaskStatus.Error;
        }
      }
    })
  ),

  on(actions.allTasksCancelled, state =>
    produce(state, draft => {
      for (const key in draft.fileData.statuses) {
        if (
          !draft.fileData.statuses.hasOwnProperty(key) ||
          draft.fileData.statuses[key] === TaskStatus.Started
        ) {
          delete draft.fileData.statuses[key];
        }
      }
    })
  ),

  on(actions.taskStarted, (state, { name }) => {
    if (name !== Task.PojoCompilation) {
      return state;
    }

    return produce(state, draft => {
      draft.runState = RunningState.WAITING;
      Object.entries(draft.fileData.statuses).forEach(([key, status]) => {
        if (status === TaskStatus.Finished_Stale) {
          draft.fileData.statuses[key] = TaskStatus.Finished_Stale_Running;
        } else if (
          draft.fileData.statuses[key] === TaskStatus.Started ||
          draft.fileData.statuses[key] === TaskStatus.Starting
        ) {
          draft.fileData.statuses[key] = TaskStatus.Error;
        }
      });
    });
  }),

  on(actions.taskFinished, (state, { name }) => {
    if (name !== Task.StaticJavaCompilation) {
      return state;
    }

    return produce(state, draft => {
      draft.runState = RunningState.READY;
      Object.entries(draft.fileData.statuses).forEach(([key, status]) => {
        if (
          status === TaskStatus.Finished ||
          status === TaskStatus.Finished_Error ||
          status === TaskStatus.Finished_Stale_Running
        ) {
          draft.fileData.statuses[key] = TaskStatus.Finished_Stale;
        }
      });
    });
  }),

  on(actions.cleanup, () => initialState)
);
