import {
  AgGridEvent,
  ColDef,
  ColumnApi,
  ColumnVisibleEvent,
  GridApi,
  GridOptions,
} from '@ag-grid-community/core';
import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';
import { WorkspacePersist } from '@models/persist';
import { InOutAnimation } from '@shared/animations';
import { ROW_HEIGHT } from '@shared/modules/rosetta-table/models/rosetta-table.const';
import { RosettaTableOptions } from '@shared/modules/rosetta-table/models/rosetta-table.model';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { deepEquals, deepMerge, isObjectEmpty } from '@utils';
import {
  Observable,
  distinctUntilChanged,
  map,
  share,
  startWith,
  switchMap,
} from 'rxjs';
import { PipelineRunInfo } from '../../models';
import { TestPackGrid } from '../../models/test-pack-grid.model';
import { getColDefMap } from './col-defs';
import { dataColDef } from './col-defs/data-col-defs';
import { updateSummary } from './col-defs/summary-col-defs';
import { CustomNoRowsOverlayComponent } from './components/custom-no-rows-overlay/custom-no-rows-overlay.component';
import { IconHeaderComponent } from './components/icon-header/icon-header.component';
import {
  autoResizeColumnWithChanges,
  autoSizeSampleNameColumn,
  checkAllSelectedRowsAreSelectable,
  checkShowNoRowsOverlay,
  hasChangesUtil,
  isRowSelectable,
  setSelectableRowCount,
  setState,
  shouldDisplayCheckboxes,
} from './utils/helpers';

@Component({
  selector: 'app-transform-data-viewer',
  templateUrl: './transform-data-viewer.component.html',
  styleUrls: ['./transform-data-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [InOutAnimation],
  host: {
    class: 'theme-bg',
  },
})
export class TransformDataViewerComponent {
  constructor(@Attribute('id') public id: string) {}

  @Input({ required: true })
  dataViewerSource$: Observable<PipelineRunInfo<TestPackGrid> | null>;
  @Input() getFileName?: () => string;

  @Output() rowClicked = new EventEmitter<transform.DataViewerRow>();
  @Output() events = new EventEmitter<transform.DataViewerEvent>();

  private _showChanges = new WorkspacePersist<boolean>(
    `${this.id}:show-changes`,
    {
      default: false,
    }
  );
  private _nodeIds = new WorkspacePersist<string[]>(`${this.id}:node-ids`, {
    default: [],
    type: Array,
  });

  context = new transform.DataViewerContext(this, this._showChanges.value);
  private _colDefs = getColDefMap();

  // The table apis are only public so that we can use them in tests
  gridApi!: GridApi<transform.DataViewerRow>;
  columnApi!: ColumnApi;

  readonly tableOptions: RosettaTableOptions<transform.DataViewerRow> = {
    canDownload: true,
    rowHeight: ROW_HEIGHT.Small,
    canHideColumns: true,
    rowClassRules: {
      'ros-row-error': ({ data }) => !!data?.errorMessage,
    },
    /* getRowId is needed to ensure column sort and filers persist */
    getRowId: params => params.data.sampleId,
    getFileName: () => (this.getFileName ? this.getFileName() : 'undefined'),
    isExternalFilterPresent: ({ context }): boolean => context.showChanges,
    doesExternalFilterPass: hasChangesUtil,
  };

  readonly gridOptions: GridOptions<transform.DataViewerRow> = {
    rowSelection: 'multiple',
    context: this.context,
    isRowSelectable: isRowSelectable,
    suppressRowClickSelection: true,
    onColumnVisible: this._columnVisible.bind(this),
    onModelUpdated: this._modelUpdated.bind(this),
    onGridReady: event => {
      event.context = this.context;
      this.gridApi = event.api;
      this.columnApi = event.columnApi;
      this.context.readySubject.next();
    },
    onFirstDataRendered: event => {
      this._restoreSelectedRows();
      this._modelUpdated(event);
    },
    onSelectionChanged: () => {
      this._storeSelectedRows();
      this._onSelectionChanged();
    },
    onFilterChanged: params => {
      checkAllSelectedRowsAreSelectable(params.api);
      shouldDisplayCheckboxes(params.api, params.columnApi);
    },
    noRowsOverlayComponent: CustomNoRowsOverlayComponent,
    suppressMenuHide: true,
    columnTypes: {
      summaryColumn: {
        pinned: 'right',
        cellClass: 'ros-center-aligned ros-small-padding',
        headerClass: 'ros-small-padding',
        headerComponent: IconHeaderComponent,
        resizable: false,
        maxWidth: 60,
      },
    },
    tooltipShowDelay: 0,
  };

  pipelineRunInfo$: Observable<PipelineRunInfo<transform.DataViewer>> =
    this.context.readySubject.pipe(
      switchMap(() =>
        this.dataViewerSource$.pipe(
          map(runUpdate => this._enrichRunUpdateData(runUpdate)),
          map(runUpdate => {
            if (runUpdate?.result?.data) {
              const data = runUpdate.result.data;
              this._updateContext(data);
              this._createColDefs(data);
              this.tabulatorUnsupported.set(data.tabulatorUnsupported);
            }
            return runUpdate;
          })
        )
      ),
      share()
    );

  ready$ = this.context.readySubject.asObservable();
  summary$ = this.context.summarySubject.pipe(distinctUntilChanged(deepEquals));
  selectionChanged$ = this.context.selectedRowsSubject.asObservable();
  tabulatorUnsupported = signal(false);

  cols$ = this.context.columnSubject.pipe(
    map(() => this._getColDefs()),
    share()
  );

  loading$ = this.pipelineRunInfo$.pipe(
    map(({ result }) => result.data === null),
    startWith(true)
  );

  onActionBarEvent(event: transform.DataViewerEvent): void {
    this.events.emit(event);
    this.gridApi.deselectAll();
  }

  onRowClicked(row: transform.DataViewerRow): void {
    this.rowClicked.emit(row);
  }

  toggleShowChanges(): void {
    this.context.showChanges = !this.context.showChanges || false;
    this._showChanges.value = this.context.showChanges;
    this.gridApi.setColumnDefs(this._getColDefs());
    this.gridApi.onFilterChanged();
  }

  private _enrichRunUpdateData(
    runUpdate: PipelineRunInfo<TestPackGrid>
  ): PipelineRunInfo<transform.DataViewer> {
    const data = runUpdate?.result?.data;
    const defaultPipelineRunUpdate: PipelineRunInfo = {
      result: {
        data: null,
        details: null,
      },
      errorMessage: null,
    };

    return deepMerge(defaultPipelineRunUpdate, {
      result: { data: data ? new transform.DataViewer(data) : null },
    });
  }

  private _updateContext(dataViewer: transform.DataViewer | null): void {
    this.context.dataState = setState(dataViewer);

    if (!this.context.initialDataLoaded) {
      this.context.initialDataLoaded = true;
    }

    if (this.context.dataState !== transform.DateViewState.HasData) {
      this._createColDefs(null);
      return null;
    }

    // Deselect rows which have had actions
    // applied on them when new data comes in
    if (this.context.selectedActionRows.length) {
      this.gridApi.deselectAll();
      this.context.selectedActionRows.length = 0;
    }

    // Ensure we we reset the initial
    // sample resize when the id changes
    if (
      !deepEquals(
        dataViewer.testPackGridSelection,
        this.context.dataViewer?.testPackGridSelection
      )
    ) {
      this.context.hasSampleNameBeenResized = false;
    }

    this.context.dataViewer = dataViewer;
  }

  private _storeSelectedRows(): void {
    this._nodeIds.value = this.gridApi.getSelectedNodes().map(node => node.id);
  }

  private _restoreSelectedRows(): void {
    const nodeIds: string[] = this._nodeIds.value;
    setTimeout(() => {
      nodeIds.forEach(id => this.gridApi.getRowNode(id)?.setSelected(true));
    });
  }

  private _getColDefs(): ColDef<transform.DataViewerRow>[] {
    const colDefs = this.context.colDefs();
    const colDefsData =
      colDefs.length > 0
        ? [this._createColDef(colDefs), this._colDefs.summary].flat()
        : [];

    return this.context.dataViewer?.rows.length > 0
      ? [
          this._checkIfCheckboxIsNeeded(),
          this._colDefs.sampleName,
          colDefsData,
        ].flat()
      : [];
  }

  private _checkIfCheckboxIsNeeded(): ColDef<transform.DataViewerRow>[] {
    if (this.context.canPerformActions) {
      return [this._colDefs.checkbox];
    }

    return [];
  }

  private _createColDef(
    cols: transform.DataViewerCol[]
  ): ColDef<transform.DataViewerRow>[] {
    return cols.map(col => ({
      headerName: col.headerName,
      colId: col.fieldNameId,
      headerTooltip: col.toolTip,
      initialHide: col.hidden,
      ...dataColDef(),
    }));
  }

  private _createColDefs(dataViewer: transform.DataViewer | null): void {
    this.context.dataViewerCols = [];
    this.context.colDefsWithChanges = [];
    this.context.dataViewerColMap = {};

    if (dataViewer) {
      dataViewer.columns.forEach(column => {
        this.context.dataViewerColMap[column.fieldNameId] = column;
        this.context.dataViewerCols.push(column);
        if (column.hasChanges) {
          this.context.colDefsWithChanges.push(column);
        }
      });
    }

    this.context.columnSubject.next();
  }

  private _onSelectionChanged(): void {
    this.context.selectedRowsSubject.next(this.gridApi.getSelectedRows());
  }

  private _columnVisible({
    visible,
    columns,
    api,
    columnApi,
    context,
  }: ColumnVisibleEvent<
    transform.DataViewerRow,
    transform.DataViewerContext
  >): void {
    if (!isObjectEmpty(context.dataViewerColMap) && visible !== undefined) {
      columns?.forEach(col => {
        const column = context.dataViewerColMap[col.getId()];
        if (column) {
          column.hidden = !visible;
        }
      });
    }
    checkAllSelectedRowsAreSelectable(api);
    shouldDisplayCheckboxes(api, columnApi);
    context.columnSubject.next();
  }

  private _modelUpdated(event: AgGridEvent<transform.DataViewerRow>): void {
    /* setTimeout required as all the checks below require the model update to have updated the table before running*/
    setTimeout(() => {
      checkShowNoRowsOverlay(event);
      updateSummary(event);
      setSelectableRowCount(event);
      autoResizeColumnWithChanges(event);
      shouldDisplayCheckboxes(event.api, event.columnApi);
      autoSizeSampleNameColumn(event);
    });
  }
}
