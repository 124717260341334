import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ExternalLink } from '@configs';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoPanelComponent {
  @Input() panelTitle = '';
  @Input() hideLogo = false;

  readonly externalLink = ExternalLink;
}
