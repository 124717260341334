import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RosettaClasses } from '@models';
import { GraphicalNavigatorStateService } from '@workspace-design/graphical/services/graphical-navigator-state.service';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'app-rosetta-class-search',
  templateUrl: './rosetta-class-search.component.html',
  styleUrls: ['./rosetta-class-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RosettaClassSearchComponent {
  private _classes?: RosettaClasses;

  @Output()
  selectedItem = new EventEmitter<string>();

  filteredSearchData?: Observable<string[]>;
  searchForm = new UntypedFormGroup({
    searchInputControl: new UntypedFormControl(),
  });

  constructor(readonly stateService: GraphicalNavigatorStateService) {}

  @Input() set searchData(classes: RosettaClasses) {
    this._populateSearchData(classes);
  }

  itemSelected(className: string) {
    this.stateService.resetOnSearch(className);
    this.selectedItem.emit(className);
  }

  private _filterSearches(value: string) {
    if (!this._classes) {
      return [];
    }
    const filterValue = value.toLowerCase();
    return this._classes.classNames.filter(option =>
      option.toLowerCase().includes(filterValue)
    );
  }

  private _populateSearchData(classes: RosettaClasses) {
    if (classes) {
      this._classes = classes;
      this.searchForm.controls['searchInputControl'].setValue(
        this.stateService.initialClass
      );
      this.filteredSearchData = this.searchForm.controls[
        'searchInputControl'
      ].valueChanges.pipe(
        startWith(''),
        map(value => this._filterSearches(value))
      );
    }
  }
}
