<button mat-icon-button [appRosettaOverlayTarget]="overlay">
  <fa-icon [style.color]="config.color.warn" icon="exclamation-triangle" />
</button>

<app-rosetta-overlay #overlay>
  <div
    class="static-compile-menu"
    *ngIf="codeCompilationResult$ | async as compileResult"
  >
    <div class="arrow-container">
      <div class="up-arrow"></div>
    </div>
    <mat-card appearance="outlined">
      <mat-card-content>
        <h3>Static compilation errors</h3>
        <div *ngIf="compileResult.compileErrors.errorFiles?.length > 0">
          <h4>File(s) with errors:</h4>
          <p class="mat-small">
            {{ compileResult.compileErrors.errorFiles?.join(', ') }}
          </p>
        </div>
        <h4>Stacktrace:</h4>
        <div class="stack-trace">
          <app-copy-to-clipboard
            [path]="compileResult.compileErrors.supportMessage"
          />

          <textarea
            matInput
            [value]="compileResult.compileErrors.supportMessage"
            rows="10"
            disabled
            class="resizable"
          ></textarea>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</app-rosetta-overlay>
