import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RootTypeService {
  private _rootTypes = new BehaviorSubject<string[]>([]);

  onRootTypesChanged(roots: string[]) {
    this._rootTypes.next(roots);
  }

  getRootTypes(): Observable<string[]> {
    return this._rootTypes;
  }
}
