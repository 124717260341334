import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { ErrorCode, handleError } from '@configs';
import { Task, TaskStatus, WorkspaceId } from '@models';
import { WorkspacePersist } from '@models/persist';
import { Store } from '@ngrx/store';
import { AppState } from '@store/reducers';
import * as RouterActions from '@store/router/router.actions';
import {
  currentWorkspaceIdObserver,
  isNotNull,
  modelChangeOperator,
  naturalSort,
} from '@utils';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  startWith,
  switchMap,
  tap,
  throwError,
  zip,
} from 'rxjs';
import {
  BodyCorpus,
  BodyCorpusGroupSelection,
  BodyCorpusSegmentsDTO,
  BODY_CORPUS_GROUP_PARAM,
  DocRefId,
  RegProvisionDetails,
  RegTreeNode,
  UNNAMED_SEGMENT_PREFIX,
} from '../models';
import { SelectedElementIdEvent } from '../models/scroll-to.model';
import { RegulationApiService } from './regulation-api.service';
import {
  createPathSegmentTree,
  createProvisionDetailGroups,
  createSegmentId,
  fromBodyCorpusDTO,
  getBodyGroupId,
  getCorpusGroupId,
} from './regulation.factory';

@Injectable()
export class RegulationService {
  private _initialLoad = true;
  private _currentWorkspaceId: WorkspaceId | null = null;

  private _selectedSegmentEventSubject =
    new ReplaySubject<SelectedElementIdEvent | null>(1);
  private _selectedBodyCorpusGroupIdSubject = new ReplaySubject<string | null>(
    1
  );
  private _selectedBodyCorpusSubject =
    new ReplaySubject<BodyCorpusGroupSelection | null>(1);
  private _bodyCorpusContent$ = this._selectedBodyCorpusSubject.pipe(
    switchMap(bodyCorpusGroup =>
      (this._store as Observable<AppState>).pipe(
        modelChangeOperator(Task.PojoCompilation, TaskStatus.Finished),
        concatMap(() =>
          bodyCorpusGroup
            ? this._api
                .getBodyCorpusContent(
                  bodyCorpusGroup.body,
                  bodyCorpusGroup.corpusGroup.corpusList
                )
                .pipe(startWith(null))
            : of(null)
        )
      )
    ),
    shareReplay(1)
  );

  private _bodyCorpusGroupSelectionMap: Map<string, BodyCorpusGroupSelection> =
    new Map();

  private _persistedSelection = new WorkspacePersist<{
    workspaceId: WorkspaceId;
    selectedBodyCorpusGroupId: string;
  }>('regulation-selection', {
    default: {
      workspaceId: null,
      selectedBodyCorpusGroupId: null,
    },
  });

  /*
  When subscribed to fetch and cache data for a bodyCorpus,
  then if nothing is selected set the initial selection and
  fetch segments for selection. Update bodyCorpus when switching workspaces.
  */
  bodyCorpus$: Observable<BodyCorpus> = currentWorkspaceIdObserver(
    this._store,
    {
      skipInitial: false,
    }
  ).pipe(
    tap(workspaceId => {
      this._checkWorkspace(workspaceId);
    }),
    switchMap(() => this._fetchBodyCorpus()),
    tap(bodyCorpus => {
      this._setBodyCorpusMap(bodyCorpus);
      this._selectInitialBodyCorpus(bodyCorpus);
    }),
    shareReplay(1)
  );

  /*
  Return segmentTree when a bodyCorpus is selected or return null otherwise
  */
  bodyCorpusTree$: Observable<RegTreeNode | null> =
    this._bodyCorpusContent$.pipe(
      map(dto => dto && createPathSegmentTree(dto.segmentIds)),
      shareReplay(1)
    );

  /*
  Return segmentDetailsList when a bodyCorpus is selected or null otherwise
  */
  bodyCorpusProvisionDetails$: Observable<RegProvisionDetails | null> =
    this._bodyCorpusContent$.pipe(
      switchMap(dto => (dto ? this._fetchDetails(dto) : of(null))),
      shareReplay(1)
    );

  /*
  Call bodyCorpus api and on an 501 error return true so the user can see the legacy page
  */
  newRegViewSupported$ = this.bodyCorpus$.pipe(
    map(() => true),
    catchError(e => {
      if (e instanceof HttpErrorResponse && e.status === ErrorCode.ERROR_501) {
        return of(false);
      }
      return throwError(() => handleError(e));
    }),
    shareReplay(1)
  );

  selectedSegmentEvent$ = this._selectedSegmentEventSubject.pipe(
    shareReplay(1)
  );

  selectedBodyCorpusGroupId$ = this._selectedBodyCorpusGroupIdSubject.pipe(
    filter(isNotNull),
    distinctUntilChanged(),
    tap(id => this._processSelectedBodyCorpusGroup(id)),
    shareReplay(1)
  );

  constructor(
    private _store: Store,
    private _api: RegulationApiService,
    private _activatedRoute: ActivatedRoute
  ) {}

  updateSelectedSegmentId(event: SelectedElementIdEvent) {
    this._selectedSegmentEventSubject.next(event);
  }

  updateSelectedBodyCorpusGroupId(id: string | null) {
    this._selectedBodyCorpusGroupIdSubject.next(id);
  }

  selectSegment(docRef: DocRefId): void {
    const bodyCorpusInfo = {
      body: docRef.body,
      corpusList: docRef.corpusList,
    };

    const selectedBodyCorpus = {
      id: getBodyGroupId(bodyCorpusInfo),
      body: docRef.body,
      corpusGroup: {
        id: getCorpusGroupId(bodyCorpusInfo),
        corpusList: docRef.corpusList,
      },
    };

    const bodyCorpusGroupId = selectedBodyCorpus.corpusGroup.id;
    const elementId = createSegmentId({
      docRefTarget: docRef.docRefTarget,
      segments: docRef.segments,
    });

    this._navigate(elementId, {
      bodyCorpusGroupId,
    });
  }

  selectBodyCorpusGroup(bodyCorpusGroupId: string) {
    this._navigate(undefined, {
      bodyCorpusGroupId,
    });
  }

  private _selectInitialBodyCorpus(bodyCorpus: BodyCorpus) {
    let bodyCorpusGroupId: string | null | undefined;
    const queryParam = this._activatedRoute.snapshot.queryParamMap.get(
      BODY_CORPUS_GROUP_PARAM
    );

    if (this._initialLoad) {
      // 1. Get from query param
      // 2. Get from local storage
      bodyCorpusGroupId =
        queryParam || this._persistedSelection.value?.selectedBodyCorpusGroupId;
      this._initialLoad = false;
    }

    if (
      (!bodyCorpusGroupId ||
        !this._bodyCorpusGroupSelectionMap.has(bodyCorpusGroupId)) &&
      bodyCorpus.length > 0
    ) {
      // 3. Get first body corpus from list
      const [firstBodyCorpus] = bodyCorpus;
      bodyCorpusGroupId = firstBodyCorpus.corpusGroups[0].id;
    }

    if (bodyCorpusGroupId) {
      if (queryParam === bodyCorpusGroupId) {
        // Select body corpus without populating query param
        this._selectedBodyCorpusGroupIdSubject.next(bodyCorpusGroupId);
        return;
      }

      // Populate query param and let query param subscription handle the selection
      this.selectBodyCorpusGroup(bodyCorpusGroupId);
    }
  }

  private _processSelectedBodyCorpusGroup(bodyCorpusGroupId: string): void {
    const bodyCorpusGroupSelection =
      this._getBodyCorpusForSelection(bodyCorpusGroupId);

    if (!bodyCorpusGroupSelection) {
      // Body corpus group doesn't exist
      return;
    }

    this._persistedSelection.value = {
      workspaceId: this._currentWorkspaceId,
      selectedBodyCorpusGroupId: bodyCorpusGroupId,
    };

    if (!this._activatedRoute.snapshot.fragment) {
      // Clear previously selected segment
      this._selectedSegmentEventSubject.next(null);
    }

    this._selectedBodyCorpusSubject.next(bodyCorpusGroupSelection);
  }

  private _navigate(fragment?: string, queryParams?: Params | null): void {
    this._store.dispatch(
      RouterActions.goto({
        extras: {
          fragment,
          queryParams,
        },
      })
    );
  }

  private _getBodyCorpusForSelection(
    bodyCorpusGroupId: string
  ): BodyCorpusGroupSelection | undefined {
    return this._bodyCorpusGroupSelectionMap.get(bodyCorpusGroupId);
  }

  private _setBodyCorpusMap(bodyCorpus: BodyCorpus): void {
    bodyCorpus.forEach(bodyCorpusGroup => {
      bodyCorpusGroup.corpusGroups.forEach(corpusGroup => {
        this._bodyCorpusGroupSelectionMap.set(corpusGroup.id, {
          body: bodyCorpusGroup.body,
          corpusGroup: {
            id: corpusGroup.id,
            corpusList: corpusGroup.corpusList,
          },
        });
      });
    });
  }

  private _clearData() {
    this._selectedBodyCorpusSubject.next(null);
    this._persistedSelection.reset();
  }

  private _checkWorkspace(workspaceId: WorkspaceId | null) {
    const hasWorkspaceChanged =
      this._persistedSelection.value.workspaceId?.name !== workspaceId?.name;

    if (!this._initialLoad || hasWorkspaceChanged) {
      this._clearData();
    }

    this._currentWorkspaceId = workspaceId;
  }

  private _fetchBodyCorpus() {
    return this._api.getBodyCorpus().pipe(map(dto => fromBodyCorpusDTO(dto)));
  }

  private _fetchDetails(bodyCorpusDetails: BodyCorpusSegmentsDTO) {
    const list$ = bodyCorpusDetails.segmentIds.map(segmentId =>
      this._api
        .getProvisionDetails(
          bodyCorpusDetails.body,
          bodyCorpusDetails.corpusList.map(corpus => corpus.name),
          segmentId.segments
        )
        .pipe(
          map(provisionDetailsList => ({
            id: createSegmentId(segmentId),
            segmentId,
            provisionDetailsList,
          }))
        )
    );

    return zip(list$).pipe(
      map(data => {
        data = naturalSort(data, 'id', UNNAMED_SEGMENT_PREFIX);
        const result: RegProvisionDetails = {
          bodyCorpusDetails,
          provisionDetailGroups: createProvisionDetailGroups(data),
        };
        return result;
      })
    );
  }
}
