import { HierarchyNode } from 'd3';

export interface TreeStructure {
  name: string | undefined;
  type: string;
  hasChildren: boolean;
  children: Array<TreeStructure>;
  attributes: Record<string, Array<string>>;
  uri?: string;
  namespace?: string;

  _children?: HierarchyNode<TreeStructure>[];
  _x0?: number;
  _y0?: number;
  _tag?: string;
  _id?: number;
  _ic?: number;
}

export interface TreeNode {
  data: TreeStructure;
  parent: TreeNode | undefined;
}
export interface RosettaClasses {
  classNames: Array<string>;
  rootClassNames: Array<string>;
}

export interface RosettaClassDetails {
  abstract: boolean;
  definition: string;
  attributes: Array<RosettaClassAttribute>;
  className: string;
  superType: Array<any>;
  stereotypes: Array<any>;
  regulatoryReferences: Array<any>;
  allRegulatoryReferences: Array<any>;
  synonyms: Array<any>;
  marketPractices: Array<any>;
  uri: string;
  namespace: string;
  docReferences: string[];
}

export interface RosettaClassAttribute {
  id: string;
  name: string;
  type: string;
  description: string;
  attributes: RosettaClassAttributeAttribute;
  docReferences: string[];
  grammar: string;
}

export interface RosettaClassAttributeAttribute {
  synonyms: Array<string>;
  cardinality: Array<string>;
}

export interface RosettaClassHierarchy {
  name: string;
  hasChildren: boolean;
  children: Array<any>;
  attributes: Record<string, Array<string>>;
  type: string;
}

export interface RosettaClassHierarchyDialog {
  classHierarchy: RosettaClassHierarchy;
  highlighted: string;
}

export interface SymbolIdentifier {
  uri: string;
  namespace: string;
  fqn: string;
  name: string;
  syntax: string;
}

export interface DocumentSymbol {
  name: string;

  uri?: string;

  /**
   * More detail for this symbol, e.g the signature of a function.
   */
  detail?: string;

  /**
   * The kind of this symbol.
   */
  kind: number;

  /**
   * Indicates if this symbol is deprecated.
   */
  deprecated?: boolean;

  /**
   * The range enclosing this symbol not including leading/trailing whitespace but everything else
   * like comments. This information is typically used to determine if the clients cursor is
   * inside the symbol to reveal in the symbol in the UI.
   */
  range: {
    start: { line: number; character: number };
    end: { line: number; character: number };
  };

  /**
   * The range that should be selected and revealed when this symbol is being picked, e.g the name of a function.
   * Must be contained by the `range`.
   */
  selectionRange: {
    start: { line: number; character: number };
    end: { line: number; character: number };
  };

  /**
   * Children of this symbol, e.g. properties of a class.
   */
  children?: DocumentSymbol[];
}

export interface SelectedClass {
  className: string | undefined;
  children: boolean;
}

export enum NameTypeToggleState {
  name = 1,
  type,
}

export interface RosettaTree<DATUM = any> {
  highlightUpdateAndCenterNode(nodesToHighlight: string[]): void;
  resizeNavigator(): void;
  createTreeData(tree: any): void;
  updateAndCenterNode(): void;
  setNodeSelectFunction(param: (node: HierarchyNode<DATUM>) => void): void;
  setNodeHoverFunction(param: (tooltip: string) => void): void;
}

export const ROSETTA_TREE_LEGEND: any = [
  {
    id: 0,
    name: 'Has Condition',
    check: function (d: any) {
      return (
        d.attributes &&
        d.attributes.datarules &&
        d.attributes.datarules.length > 0
      );
    },
    icon: '#conditions',
    x: 0,
    y: 0,
    display: function (d: any) {
      const plural = d.attributes.datarules.length > 1 ? 's' : '';
      return (
        d.name + ' has ' + d.attributes.datarules.length + ' Condition' + plural
      );
    },
  },
];

export const NodeColours = {
  Selected: '#ff4136', // red
  UnselectedOpen: '#fff',
  HasChildren: 'lightsteelblue',
  Terminal: 'MediumAquaMarine',
};

export const ColourLegends = [
  { id: 0, colour: NodeColours.UnselectedOpen, legend: 'Children revealed' },
  { id: 1, colour: NodeColours.Selected, legend: 'Selected' },
  { id: 2, colour: NodeColours.HasChildren, legend: 'Has children' },
  { id: 3, colour: NodeColours.Terminal, legend: 'Terminal' },
];

export const ZOOM_SCALE = 2;

export const ConditionIcon =
  'M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z';
