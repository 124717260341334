import { Directive, Input } from '@angular/core';
import { UserAbilities } from '@configs';
import { AuthService } from '@core/services';
import { Store } from '@ngrx/store';
import { RenderTemplateDirective } from '@shared/directives/abstract-render-template.directive';
import { AuthSelectors } from '@store/selectors';
import { map, tap } from 'rxjs';

@Directive({
  selector: '[appHasAbility]',
})
export class HasAbilityDirective extends RenderTemplateDirective {
  constructor(
    private _authService: AuthService,
    private _store: Store
  ) {
    super();
  }

  @Input()
  set appHasAbility(ability: UserAbilities) {
    this._store
      .pipe(
        AuthSelectors.waitToLoadUser,
        map(() => this._authService.has(ability)),
        tap(hasRole => this.render(hasRole))
      )
      // eslint-disable-next-line @ngrx/no-store-subscription
      .subscribe();
  }
}
