import { Injectable, OnDestroy } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { RegTreeNode } from '@features/workspace/modules/design/regulation/models';
import { Store } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { Observable, skip, Subscription } from 'rxjs';

import { SegmentTreeControl } from './segment-tree.control';

@Injectable()
export class SegmentTreeService implements OnDestroy {
  constructor(private _store: Store) {}

  dataSource = new MatTreeNestedDataSource<RegTreeNode>();
  treeControl = new SegmentTreeControl(node => node?.children);

  getFirstLeafNode(tree: RegTreeNode): RegTreeNode | null {
    if (!tree.children || tree.children.length === 0) {
      return tree; // Node is a leaf node
    }

    for (const child of tree.children) {
      const firstLeafNode = this.getFirstLeafNode(child);
      if (firstLeafNode) {
        return firstLeafNode; // Return the first leaf node found
      }
    }

    return null; // No leaf node found in the children
  }

  private _sub = new Subscription();

  private _currentWorkspaceId$ = this._store.select(
    WorkspaceSelectors.selectWorkspaceId
  );

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  init(regTreeNode$: Observable<RegTreeNode>) {
    this._startObservables(regTreeNode$);
  }

  private _startObservables(regTree: Observable<RegTreeNode>) {
    this._sub.add(
      regTree.subscribe(items => {
        this.dataSource.data = items.children;
        this.treeControl.setNodes(items.children);
      })
    );

    this._sub.add(
      // Skip the first value since it is the initial state
      this._currentWorkspaceId$.pipe(skip(1)).subscribe(() => {
        this.treeControl.clearData();
      })
    );
  }
}
