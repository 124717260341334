import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { NotificationService } from '@core/modules/snack-bar';
import { ServerErrorResponse, ServerErrorType } from '@models/dto';
import { Store } from '@ngrx/store';
import * as WorkspaceActions from '@store/workspace/actions';

@Injectable({
  providedIn: 'root',
})
export class ErrorHandlerService {
  constructor(
    private _store: Store,
    private _notify: NotificationService,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  handleError(errorResponse: HttpErrorResponse): void {
    switch (errorResponse.status) {
      case 500: {
        // eslint-disable-next-line no-console
        console.error('Internal Server Error: ', errorResponse);
        if (
          (errorResponse.error as ServerErrorResponse)?.errorType ===
          ServerErrorType.InvalidCompiledPojo
        ) {
          this._store.dispatch(WorkspaceActions.invalidCompiledPojoError());
        }
        break;
      }

      case 504: {
        this._notify.showError({
          message: this._config.text.gatewayTimeout,
        });
        break;
      }

      default: {
        return;
      }
    }
  }
}
