import { Injectable } from '@angular/core';
import { RUN_CUSTOM_FUNCTION } from '@configs';
import { TaskService } from '@core/services';
import {
  Task,
  TaskNotification,
  TaskStatus,
  WorkflowExecutionResult,
} from '@models';
import { VisualisationService } from '@workspace-engine/visualisation/services/visualisation.service';
import { TaskListeners } from '../task-listener.models';

@Injectable()
export class WorkflowExecutionListenerService implements TaskListeners {
  constructor(
    private _visualisationService: VisualisationService,
    private _taskService: TaskService
  ) {}

  action(task: TaskNotification) {
    if (
      task.task !== Task.WorkflowExecution ||
      task.payload.workflowName === RUN_CUSTOM_FUNCTION
    ) {
      return;
    }

    if (task.status === 'STARTED') {
      this._taskService.updateTaskStatus(
        task.task,
        undefined,
        TaskStatus.Started
      );
    }

    if (task.status === 'FINISHED') {
      this._taskService.updateTaskStatus(
        task.task,
        undefined,
        TaskStatus.Finished
      );
      this._visualisationService.onWorkflowExecutionFinished(
        task.payload as WorkflowExecutionResult
      );
    }

    if (task.status === 'ERROR') {
      this._visualisationService.onWorkflowExecutionError(task.payload.errors);
      const causedByTask = Task[task.task];
      this._taskService.updateAllSubTaskStatus(causedByTask, TaskStatus.Error);
    }
  }
}
