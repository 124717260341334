import { SelectionModel } from '@angular/cdk/collections';
import { NestedTreeControl } from '@angular/cdk/tree';
import { RegTreeNode } from '@features/workspace/modules/design/regulation/models';
import { debounceTime, merge } from 'rxjs';

export class SegmentTreeControl extends NestedTreeControl<RegTreeNode, string> {
  constructor(
    getChildren: (dataNode: RegTreeNode) => RegTreeNode[] | undefined
  ) {
    super(getChildren, {
      trackBy: dataNode => dataNode.id,
    });
  }

  private _selectionModel = new SelectionModel<string>(true);
  // private errorModel = new SelectionModel<string>(true);
  private _nodeBranchMap = new Map<string, RegTreeNode[]>();

  setNodes(nodes: RegTreeNode[]) {
    this.dataNodes = nodes;
    this._createNodeBranchMap(nodes);
  }

  // selectNodeByUri(uri: string) {
  //   // Find the node with the given uri
  //   const nodeList = this.nodeBranchMap.get(uri);

  //   // If we found the node, select it
  //   if (nodeList) {
  //     this.selectionModel.clear();
  //     this.selectionModel.select(
  //       ...nodeList.map(value => this._trackByValue(value))
  //     );
  //     this.expandAncestors(nodeList);
  //   }
  // }

  // setNodeErroredByUris(uris: string[]) {
  //   const erroredNodes: RegTreeNode[] = [];
  //   // Find the nodes with the given uris
  //   uris.forEach(uri => {
  //     const nodeList = this.nodeBranchMap.get(uri);
  //     if (nodeList) {
  //       erroredNodes.push(...nodeList);
  //     }
  //   });

  //   // If we found the nodes, select them
  //   this.errorModel.clear();
  //   this.errorModel.select(
  //     ...erroredNodes.map(value => this._trackByValue(value))
  //   );
  // }

  isSelected(node: RegTreeNode) {
    return this._selectionModel.isSelected(this._trackByValue(node));
  }

  // hasError(node: RegTreeNode) {
  //   return this.errorModel.isSelected(this._trackByValue(node));
  // }

  clearData() {
    this._selectionModel.clear();
    // this.errorModel.clear();
    this.expansionModel.clear();
    this._nodeBranchMap.clear();
  }

  modelChanged() {
    return merge(this._selectionModel.changed).pipe(debounceTime(150));
  }

  // private expandAncestors(nodes: RegTreeNode[]) {
  //   // If the node has a parent, expand it and expand its parent
  //   nodes.forEach(node => node.children && this.expand(node));
  // }

  private _createNodeBranchMap(nodes: RegTreeNode[]) {
    const traverse = (node: RegTreeNode, path: RegTreeNode[] = []) => {
      if (node) {
        path.push(node);
      }

      if (node.children) {
        node.children.forEach(item => {
          traverse(item, path.slice());
        });
      } else {
        this._nodeBranchMap.set(node.id, path);
      }
    };

    nodes.forEach(node => traverse(node));
  }
}
