import { Pipe, PipeTransform } from '@angular/core';
import { getDaysRemaining } from '@utils';

@Pipe({
  standalone: true,
  name: 'daysUntilDate',
})
export class DaysUntilDatePipe implements PipeTransform {
  transform(
    targetDate: string | null | undefined,
    displayDays = true
  ): string | number {
    const numberDays = getDaysRemaining(targetDate);
    if (displayDays) {
      return `${numberDays} day${numberDays === 1 ? '' : 's'}`;
    } else {
      return numberDays;
    }
  }
}
