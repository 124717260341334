import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { ITooltipParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Project } from '@features/auth/login';
import { Sizes } from '@shared/modules/project-logo/project-logo.component';

interface ProjectTooltipParams {
  size: Sizes;
}

@Component({
  selector: 'app-project-tooltip',
  templateUrl: './project-tooltip.component.html',
  styleUrls: ['./project-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectTooltipComponent implements ITooltipAngularComp {
  modelIds!: string[];
  size!: Sizes;

  agInit({
    value,
    size = 'xs',
  }: ITooltipParams<any, Project[]> & ProjectTooltipParams) {
    this.size = size;
    this.modelIds = value?.map(({ id }) => id) || [];
  }

  refresh(): boolean {
    return false;
  }
}
