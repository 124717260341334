import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TreeApiService } from '@core/services';
import { RosettaTree, TreeStructure } from '@models';
import { GraphicalNavigatorStateService } from '@workspace-design/graphical/services/graphical-navigator-state.service';
import { Store } from '@ngrx/store';
import { debounce } from '@utils';
import { Subject } from 'rxjs';
import { ClassHierarchyTree } from './rosetta-tree-class.navigator';
import { TreeNavigator } from './rosetta-tree.navigator';
import { ResizeObserverService } from '@ng-web-apis/resize-observer';

@Component({
  selector: 'app-rosetta-tree',
  templateUrl: './rosetta-tree.component.html',
  styleUrls: ['./rosetta-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [ResizeObserverService],
})
export class RosettaTreeComponent {
  constructor(
    private _store: Store,
    private _treeApiService: TreeApiService,
    private _stateService: GraphicalNavigatorStateService
  ) {}

  @ViewChild('chart', { static: true }) private _chartContainer!: ElementRef;
  @Input() treeType = 'Navigator';
  @Input() nodeDecorators: any = [];
  @Input() nodeTextDecorator: any;
  @Input() highlighted: string[] = [];
  @Output() nodeSelected: EventEmitter<any> = new EventEmitter();

  nodeHover$ = new Subject<string>();

  @Input() set treeData(tree: TreeStructure) {
    // setTimeout is used to ensure drawing the tree happens after other
    // elements are rendered so that the tree can calculate the correct width
    setTimeout(() => {
      if (!tree && this._stateService.treeNavigator) {
        this._restoreTree();
      } else if (tree && this._stateService.resetOnTreeRender()) {
        this.seedTree(tree);
      }
    });
  }

  @Input() set hierarchyData(tree: TreeStructure) {
    this.seedTree(tree);
  }

  @debounce(300)
  resizeNavigator() {
    setTimeout(() => {
      if (this._stateService.treeNavigator) {
        this._stateService.treeNavigator.resizeNavigator();
      }
    }, 250);
  }

  seedTree(tree: TreeStructure) {
    this._clearHtmlDiv();

    if (this.treeType === 'Navigator') {
      const div: HTMLDivElement = document.createElement('div');

      div.style.height = '100%';
      div.style.width = '100%';

      this._chartContainer.nativeElement.append(div);
      this._stateService.div = div;

      this._stateService.treeNavigator = this._createTreeNavigator(
        div,
        this.nodeTextDecorator,
        this.nodeDecorators,
        this._createSelectionFunction(),
        this._createHoverFunction()
      );
    } else {
      this._stateService.treeNavigator = new ClassHierarchyTree(
        this._chartContainer
      );
    }

    this._stateService.treeNavigator.createTreeData(tree);

    if (this.highlighted.length > 0) {
      this._stateService.treeNavigator.highlightUpdateAndCenterNode(
        this.highlighted
      );
    } else {
      this._stateService.treeNavigator.updateAndCenterNode();
    }
  }

  private _createTreeNavigator(
    chartContainer: HTMLElement,
    nodeTextDecorator: any,
    nodeDecorators: any[],
    nodeSelectFunction: (node: any) => void,
    nodeHoverFunction: (tooltip: any) => void
  ): RosettaTree<TreeStructure> {
    return new TreeNavigator(
      this._store,
      this._treeApiService,
      chartContainer,
      nodeDecorators,
      nodeTextDecorator ? nodeTextDecorator : (d: any) => d.name,
      nodeSelectFunction,
      nodeHoverFunction
    );
  }

  private _createSelectionFunction() {
    return (node: any) => this.nodeSelected.emit(node);
  }

  private _createHoverFunction() {
    return (tooltip: any) => this.nodeHover$.next(tooltip);
  }

  private _clearHtmlDiv() {
    this._chartContainer.nativeElement.innerHTML = '';
  }

  private _restoreTree() {
    if (this._stateService.treeNavigator) {
      this._stateService.treeNavigator.resizeNavigator();
    }

    this._clearHtmlDiv();

    this._chartContainer.nativeElement.append(this._stateService.div);
    this._stateService.treeNavigator?.setNodeSelectFunction(
      this._createSelectionFunction()
    );
    this._stateService.treeNavigator?.setNodeHoverFunction(
      this._createHoverFunction()
    );
  }
}
