import { Injectable } from '@angular/core';
import { RUN_CUSTOM_FUNCTION } from '@configs';
import { TaskService } from '@core/services/task.service';
import {
  GraphGenerationResult,
  Task,
  TaskNotification,
  TaskStatus,
} from '@models';
import { VisualisationService } from '@workspace-engine/visualisation/services/visualisation.service';
import { TaskListeners } from '../task-listener.models';

@Injectable()
export class GraphGenerationListenerService implements TaskListeners {
  constructor(
    private _visualisationService: VisualisationService,
    private _taskService: TaskService
  ) {}

  action(task: TaskNotification) {
    if (
      task.task !== Task.GraphGeneration ||
      task.payload?.fileName === RUN_CUSTOM_FUNCTION + ':run' ||
      task.payload?.errors?.causedBy === RUN_CUSTOM_FUNCTION + ':run'
    ) {
      return;
    }

    if (task.status === 'STARTED') {
      this._taskService.updateTaskStatus(
        task.task,
        undefined,
        TaskStatus.Started
      );
    }

    if (task.status === 'FINISHED') {
      const graphGenerationRunResult = task.payload as GraphGenerationResult;
      this._taskService.updateTaskStatus(
        task.task,
        undefined,
        TaskStatus.Finished
      );
      this._visualisationService.onGraphGenerationFinished(
        graphGenerationRunResult
      );
    }

    if (task.status === 'ERROR') {
      this._visualisationService.onGraphGenerationError(task.payload.errors);
      const causedByTask = Task[task.task];
      this._taskService.updateAllSubTaskStatus(causedByTask, TaskStatus.Error);
    }
  }
}
