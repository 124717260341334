import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-workspace-content',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'workspace-content theme-bg-light' },
  styles: [
    `
      @use 'partials/variables' as var;

      :host {
        display: block;
        height: calc(100% - var.$panel-height-lg);
        position: relative;
      }
    `,
  ],
  template: `
    <app-textual appShowWhenView="textual" #editor />
    <app-rosetta-grid appShowWhenView="definition" />
    <app-rosetta-graphical-navigator *appShowWhenView="'graphical'" />
    <app-regulation *appShowWhenView="'regulation'" />
  `,
})
export class WorkspaceContentComponent {}
