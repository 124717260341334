import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EN_DASH } from '@configs';
import * as transform from '@shared/modules/transform/models/data-viewer';

@Component({
  selector: 'app-summary',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <span>
      <fa-icon icon="table-list" class="mr-4" />
      <span>Samples</span><strong>{{ summaryData.rows || EN_DASH }}</strong>
    </span>

    <span>
      <fa-icon icon="check-circle" class="theme-color-success" />
      <strong>{{ summaryData.valid || EN_DASH }}</strong>
    </span>

    <span>
      <fa-icon icon="exclamation-circle" class="theme-color-yellow-alt" />
      <strong>{{ summaryData.warning || EN_DASH }}</strong>
    </span>

    <span>
      <fa-icon icon="exclamation-triangle" class="theme-color-warn" />
      <strong>{{ summaryData.error || EN_DASH }}</strong>
    </span>
  `,
  styles: [
    `
      :host {
        padding: 0.25rem 1rem;

        > span {
          padding-right: 1.5rem;

          strong {
            padding-left: 1rem;
          }
        }
      }
    `,
  ],
  host: {
    class: 'flex-row color-50',
  },
})
export class SummaryComponent {
  readonly EN_DASH = EN_DASH;

  @Input()
  public set summaryData(value: transform.DataViewerSummary) {
    if (value !== null) {
      this._summaryData = value;
    }
  }
  public get summaryData(): transform.DataViewerSummary {
    return this._summaryData;
  }
  private _summaryData: transform.DataViewerSummary =
    transform.getDefaultDataViewerSummary();
}
