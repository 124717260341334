import { TaskStatus } from '@models';
import { select } from '@ngrx/store';
import { AppState } from '@store/reducers';
import { TaskStatusKey } from '@store/reducers/task.reducer';
import { TaskSelectors } from '@store/selectors/task.selector';
import {
  distinctUntilChanged,
  filter,
  map,
  MonoTypeOperatorFunction,
  Observable,
  withLatestFrom,
} from 'rxjs';

/*
 This operator will emit every time the specified task status changes to the emitOnTaskStatus.

!! NOTE: When checking for any status which is not finished (end state) this operator might miss it and so never fire.
 */
export function modelChangeOperator(
  task: TaskStatusKey,
  emitOnTaskStatus: TaskStatus
): MonoTypeOperatorFunction<AppState> {
  return (source$: Observable<AppState>) => {
    return source$.pipe(
      withLatestFrom(
        source$.pipe(select(TaskSelectors.selectTaskStatus(task)))
      ),
      distinctUntilChanged(([, prev], [, next]) => prev === next),
      filter(([, taskStatus]) => emitOnTaskStatus === taskStatus),
      map(([store]) => store)
    );
  };
}
