<app-rosetta-tour-template />

<as-split [gutterSize]="8" appSplitSaveSize="rosetta-editor">
  <as-split-area [minSize]="7" [size]="30" [maxSize]="65" [order]="1">
    <ng-container *ngrxLet="(workspaceItems$ | async).length as showTemplate">
      <app-collapsible-panel-header *ngIf="!showTemplate" />
      <div
        class="file-tree-wrapper"
        *appShowSpinner="showTemplate; flexCentre: true"
      >
        <app-collapsible-panel-header>
          <fa-icon
            *ngIf="hasSaveErrors$ | async"
            class="pl-8 theme-color-warn"
            icon="exclamation-triangle"
            size="lg"
            matTooltip="Unable to save changes to your files. Please check your internet connection and try again."
          />
          <ng-container *ngIf="isSaving$ | async">
            <fa-icon
              icon="circle-notch"
              class="theme-color-primary pl-16 pr-8"
              [classes]="['fa-spin']"
              [ngStyle]="{
                '--fa-animation-duration': '.9s',
              }"
            />
            <small class="opacity-50">Saving...</small>
          </ng-container>
          <div class="spacer"></div>
          <div appStopPropagation class="white-space-nowrap pr-8">
            <button
              mat-icon-button
              matTooltip="Go To Open File"
              (click)="fileTree.gotoCurrentFile()"
            >
              <fa-icon icon="crosshairs" />
            </button>
            <button
              mat-icon-button
              matTooltip="Collapse All"
              (click)="fileTree.collapseAll()"
            >
              <fa-icon icon="minus-square" />
            </button>
            <button
              mat-icon-button
              matTooltip="Expand All"
              (click)="fileTree.expandAll()"
            >
              <fa-icon icon="plus-square" />
            </button>
          </div>
        </app-collapsible-panel-header>

        <app-file-tree #fileTree [workspaceItems]="workspaceItems$" />

        <button
          *appShowWhenWorkspace="'read-write'"
          mat-button
          class="add-namespace theme-border-top-light"
          aria-label="Create new namespace"
          (click)="openNewNamespaceDialog()"
        >
          <fa-icon icon="plus" />
          New Namespace
        </button>
      </div>
    </ng-container>
  </as-split-area>
  <as-split-area
    [size]="70"
    [order]="2"
    *ngrxLet="showOverrideBanner$ as showOverrideBanner"
  >
    <div class="flex-column height-full">
      <app-editor-toolbar />
      <app-editor-override-banner *ngIf="showOverrideBanner" />
      <app-loading-spinner
        classes="flex-1"
        flexCentre="true"
        *ngIf="(editor$ | async) === null"
      />
      <app-code-editor
        class="flex-1"
        [hidden]="hideCodeEditor$ | async"
        [class.editor-content-with-banner]="showOverrideBanner"
      />
      <app-diff-editor
        class="flex-1"
        *ngIf="showDiffEditor$ | async"
        [class.editor-content-with-banner]="showOverrideBanner"
      />
    </div>
  </as-split-area>
</as-split>
