<button mat-icon-button [matMenuTriggerFor]="projectCardMenu">
  <fa-icon icon="ellipsis-v" />
</button>

<mat-menu #projectCardMenu="matMenu" class="project-card-menu">
  <ng-template matMenuContent>
    <a *ngIf="showProjectAdmin" (click)="gotoProjectAdmin()" mat-menu-item>
      <fa-icon icon="user-gear" [fixedWidth]="true" />
      <span>Project Admin</span>
    </a>
    <a *ngIf="allDataPresent()" (click)="viewLicence()" mat-menu-item>
      <fa-icon icon="id-badge" [fixedWidth]="true" />
      <span>View License</span>
    </a>
    <a (click)="openReleaseNoteDialog()" mat-menu-item>
      <fa-icon icon="list" [fixedWidth]="true" />
      <span>Release Notes</span></a
    >
    <a
      *ngFor="let link of model.links"
      [href]="link.url"
      target="_blank"
      mat-menu-item
    >
      <fa-icon icon="external-link-alt" [fixedWidth]="true" />
      <span>{{ link.name }}</span>
    </a>
  </ng-template>
</mat-menu>
