<div
  class="model-options__wrapper"
  *ngIf="currentModel$ | async as currentModel"
  @slideIn
>
  <app-curve-svg [color]="currentModel.primaryColor" />
  <div class="model-options" [ngStyle]="modelStyle">
    <app-project-logo size="sm" [data]="currentModel" />
  </div>
</div>
