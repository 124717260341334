/// <reference types='monaco-editor-core/monaco'/>
import { Observable } from 'rxjs';
import { Diagnostic, Uri } from 'vscode';

export enum ConnectionState {
  Disconnected = 'Disconnected',
  AwaitingDiagnostics = 'AwaitingDiagnostics',
  Connected = 'Connected',
  Connecting = 'Connecting',
  Ready = 'Ready',
}

export interface MonacoInit {
  onMonacoInit(monacoLib: typeof monaco): void;
}

export interface ServiceOverridesFunctions {
  openEditor(uri: Uri, restoreViewState: () => void): void;
  findModel(uri: Uri): monaco.editor.ITextModel | undefined;
  readonlyMessage(): Observable<string>;
}

export interface CustomLanguageClientCallbacks {
  onDiagnostics(uri: Uri, diagnostics: Diagnostic[]): void;
}
