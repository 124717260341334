import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { NotificationService } from '@core/modules/snack-bar';
import { Store } from '@ngrx/store';
import { mapDiagnosticData } from '@shared/modules/code-view/code-view.utils';
import { selectRouterParams } from '@store/router/router.selector';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  first,
  last,
  map,
  Observable,
  of,
  share,
  shareReplay,
  switchMap,
  UnaryFunction,
} from 'rxjs';

import { CodeViewData, PipelineRunInfo } from '../../models';
import { TRANSFORM_ROUTE_PARAMS } from '../../models/transform.const';
import { TransformStorageService } from '../../services/transform-storage.service';
import { TransformService } from '../../services/transform.service';

@Injectable()
export class TransformDetailsService {
  constructor(
    private _store: Store,
    private _notificationService: NotificationService,
    private _transformStorageService: TransformStorageService,
    private _transformService: TransformService,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  viewModel$ = combineLatest([
    this._transformService.workspaceReadyForTransform$,
    this._store.select(selectRouterParams),
  ]).pipe(
    first(),
    switchMap(([, params]) =>
      this._transformStorageService
        .getSelection(
          params[TRANSFORM_ROUTE_PARAMS.pipelineId],
          params[TRANSFORM_ROUTE_PARAMS.testPackId]
        )
        .pipe(
          map(selection => ({
            selection,
            sampleId: params[TRANSFORM_ROUTE_PARAMS.sampleId],
          }))
        )
    ),
    shareReplay(1)
  );

  private _diagnosticDataFilter$ = new BehaviorSubject<string>('');

  private _codeViewData$ = this.viewModel$.pipe(
    switchMap(({ selection, sampleId }) =>
      this._transformStorageService
        .codeViewData(selection, sampleId)
        .pipe(last<any, PipelineRunInfo>())
    ),
    share<PipelineRunInfo<CodeViewData>>(),
    this._codeViewError()
  );

  codeViewInput$ = this._codeViewData$.pipe(
    map(pipelineUpdate => pipelineUpdate.result.data.input)
  );

  codeViewOutput$ = this._codeViewData$.pipe(
    map(pipelineUpdate => pipelineUpdate.result.data.output)
  );

  diagnosticData$ = combineLatest([
    this.codeViewOutput$,
    this._diagnosticDataFilter$,
  ]).pipe(
    map(([diagnosticsData, filter]) =>
      mapDiagnosticData(filter, diagnosticsData)
    )
  );

  overallSuccess$ = this.codeViewOutput$.pipe(
    map(data => data.diagnostics?.overallSuccess)
  );

  filterDiagnostics(searchTerm: string): void {
    this._diagnosticDataFilter$.next(searchTerm);
  }

  private _codeViewError<T>(): UnaryFunction<
    Observable<T>,
    Observable<T | null>
  > {
    return catchError<T, Observable<null>>(e => {
      this._notificationService.showError({
        message: this._config.text.codeViewError,
      });

      // eslint-disable-next-line no-console
      console.error(e);

      return of(null);
    });
  }
}
