<ng-container *ngrxLet="pipelineRunInfo$ | async as value">
  <app-inline-error *ngIf="value?.errorMessage">{{
    value.errorMessage
  }}</app-inline-error>

  <app-rosetta-table
    [id]="id"
    [rowData]="value?.result.data?.rows || []"
    [colDefs]="cols$ | async"
    [options]="tableOptions"
    [gridOptions]="gridOptions"
    (rowClicked)="rowClicked.emit($event)"
  >
    <header class="width-full">
      <!-- Content Project main slot -->
      <ng-content />

      <app-action-bar
        [selectedRows]="selectionChanged$ | async"
        [loading]="loading$ | async"
        [showChanges]="context.showChanges"
        (applyAction)="onActionBarEvent($event)"
      />

      <div class="spacer"></div>

      <mat-slide-toggle
        class="mr-16 color-50"
        [disabled]="loading$ | async"
        [checked]="context.showChanges"
        (change)="toggleShowChanges()"
        labelPosition="before"
        >Show Changes</mat-slide-toggle
      >

      <!-- Content project extra action buttons for the table -->
      <ng-content select="[dataViewerActions]" />
    </header>

    <ng-container rosettaTableBanner>
      <ng-content select="[dataViewerBanner]" />
      <app-inline-error
        *ngIf="tabulatorUnsupported()"
        errorType="warning"
        class="mb-16"
        dataViewerBanner
        >Tabulated data cannot be displayed, click on sample to view
        details.</app-inline-error
      >
    </ng-container>
  </app-rosetta-table>
  <app-summary [summaryData]="summary$ | async" />
</ng-container>
