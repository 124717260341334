import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import { RosettaNavigationService } from '@core/services/rosetta-navigation.service';
import { CodeViewComponent } from '@shared/modules/code-view/code-view.component';
import { map, startWith } from 'rxjs';

import { StatusConfig } from '../../components/status/status.config';
import {
  ITransformConfig,
  TRANSFORM_CONFIG,
} from '../../models/transform-config.model';
import { TransformDTO } from './../../models/transform-dto.model';
import { TransformDetailsService } from './transform-details.service';

@Component({
  selector: 'app-transform-details',
  templateUrl: './transform-details.component.html',
  styleUrls: ['./transform-details.component.scss'],
  host: { class: 'transform-details' },
  changeDetection: ChangeDetectionStrategy.OnPush,
  // This is here so that the service gets destroyed when the component is closed
  providers: [TransformDetailsService],
})
export class TransformDetailsComponent {
  constructor(
    private _service: TransformDetailsService,
    private _navigate: RosettaNavigationService,
    @Inject(TRANSFORM_CONFIG) private _transformConfig: ITransformConfig
  ) {}

  readonly namespace = this._transformConfig.featureTabName;
  readonly sampleInput = this._transformConfig.sampleInput;
  readonly sampleOutput = this._transformConfig.sampleOutput;

  @ViewChild('inputEditor')
  readonly inputCodeView: CodeViewComponent;

  @ViewChild('outputEditor')
  readonly outputCodeView: CodeViewComponent;

  vm$ = this._service.viewModel$;
  codeViewInput$ = this._service.codeViewInput$;
  codeViewOutput$ = this._service.codeViewOutput$;
  diagnosticData$ = this._service.diagnosticData$;
  inputSerialisation$ = this.vm$.pipe(
    map(vm =>
      this._getLanguage(vm.selection.pipelineDef.inputSerialisationFormat)
    )
  );
  outputSerialisation$ = this.vm$.pipe(
    map(vm =>
      this._getLanguage(vm.selection.pipelineDef.outputSerialisationFormat)
    )
  );
  state$ = this._service.overallSuccess$.pipe(
    startWith(undefined),
    map(success => this._getStatusConfig(success))
  );

  goBack(): void {
    this._navigate.updateBottomPanel([this._transformConfig.url]);
  }

  filterDiagnostics(searchTerm: string): void {
    this._service.filterDiagnostics(searchTerm);
  }

  private _getLanguage(
    format: TransformDTO.SerialisationFormat
  ): 'json' | 'xml' {
    if (format === TransformDTO.SerialisationFormat.XML) {
      return 'xml';
    }
    return 'json';
  }

  private _getStatusConfig(success?: boolean): StatusConfig {
    if (success === true) {
      return {
        themeClass: 'theme-color-success',
        icon: 'circle',
        classes: ['fa-bounce', 'fa-xs'],
        styles: {
          '--fa-animation-iteration-count': '1',
        },
        text: 'Success',
      };
    }

    if (success === false) {
      return {
        themeClass: 'theme-color-warn',
        icon: 'circle',
        classes: ['fa-beat-fade', 'fa-xs'],
        styles: {
          '--fa-animation-iteration-count': '1',
        },
        text: 'Failed',
      };
    }

    return {
      themeClass: 'theme-color-pending',
      icon: 'circle',
      classes: ['fa-beat-fade', 'fa-xs'],
      text: 'Pending',
    };
  }
}
