import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { RELEASE_NOTE_DELAY } from '@configs';
import { ReleaseNoteDialogService } from '@core/modules/release-notes/services/release-notes-dialog.service';
import { DialogComponent } from '@models';
import { Store } from '@ngrx/store';
import { InOutAnimation } from '@shared/animations';
import { AppActions } from '@store/.';
import { delay, first, Subscription, tap } from 'rxjs';

@Component({
  selector: 'app-release-notes-viewer-dialog',
  templateUrl: './release-notes-viewer-dialog.component.html',
  styleUrls: ['./release-notes-viewer-dialog.component.scss'],
  animations: [InOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReleaseNotesViewerDialogComponent
  implements OnInit, OnDestroy, DialogComponent
{
  constructor(
    private _releaseNoteDialogService: ReleaseNoteDialogService,
    private _store: Store
  ) {}

  releaseNotes$ = this._releaseNoteDialogService.releaseNotes$;

  private _sub = new Subscription();

  ngOnInit() {
    this._sub.add(
      this.releaseNotes$
        .pipe(
          first(),
          delay(RELEASE_NOTE_DELAY),
          tap(() => this._store.dispatch(AppActions.seenReleaseNote()))
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  static options(): MatDialogConfig {
    return {
      disableClose: false,
      autoFocus: true,
      restoreFocus: false,
      panelClass: 'medium-dialog',
      width: '750px',
    };
  }
}
