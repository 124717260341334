import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  editorToolbar,
  IEditorToolbar,
  IRosettaConfig,
  ROSETTA_CONFIG,
} from '@configs';
import { LanguageServerService } from '@core/services/language-server.service';
import { Task, TaskStatus, WorkspaceItem } from '@models';
import { Store } from '@ngrx/store';
import { TaskSelectors } from '@store/selectors';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  Subscription,
  tap,
} from 'rxjs';
import { EditorButtonComponent } from '@workspace-design/textual/components/buttons/editor-action-button.component';
import { ToolbarButtonNames, ToolbarButtons } from './editor-toolbar.buttons';
import { isCustomComponent, ToolbarActions } from './editor-toolbar.helpers';
import * as WorkspaceActions from '@store/workspace/actions';

@Component({
  selector: 'app-editor-toolbar',
  templateUrl: './editor-toolbar.component.html',
  styleUrls: ['./editor-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditorToolbarComponent implements AfterViewInit, OnDestroy {
  constructor(
    private _store: Store,
    private _languageService: LanguageServerService,
    private _cdr: ChangeDetectorRef,
    @Inject(ROSETTA_CONFIG) public config: IRosettaConfig
  ) {}

  @ViewChild('editorActionContainer', { read: ViewContainerRef })
  editorActionContainer!: ViewContainerRef;

  buttonsMap!: (key: ToolbarButtonNames) => ToolbarActions;

  workspaceItem$ = this._store.select(
    WorkspaceSelectors.selectCurrentWorkspaceItem
  );

  isReadOnly$ = this._store.select(WorkspaceSelectors.isReadonlyWorkspace);

  isLoading$ = this._store.select(WorkspaceSelectors.isConnectionNotReady);

  private _sub = new Subscription();

  private _diffEditorOpen$ = this._store.select(
    WorkspaceSelectors.selectDiffEditorOpen
  );

  private _syntaxError$ = this._store.select(
    WorkspaceSelectors.hasWorkspaceItemsError
  );
  private _workspaceReady$ = this._store.select(
    WorkspaceSelectors.selectWorkspaceReady
  );

  private _showStaticError$ = this._store
    .select(TaskSelectors.selectTaskStatus(Task.StaticJavaCompilation))
    .pipe(
      startWith(false),
      map(task => task === TaskStatus.Error)
    );

  private _editor$ = this._languageService.editorSubject$.pipe(
    filter(isNotNull),
    distinctUntilChanged((prev, next) => prev.getId() === next.getId()),
    tap(editor => this._initEditorActions(editor))
  );

  ngAfterViewInit(): void {
    this._sub.add(
      combineLatest([
        this._diffEditorOpen$,
        this._editor$,
        this._syntaxError$,
        this._showStaticError$,
        this._workspaceReady$,
        this.isReadOnly$,
      ])
        .pipe(
          distinctUntilChanged(
            (prev, curr) => prev === curr,
            ([diffEditorOpen, editor, syntaxError, showStaticError]) =>
              `${editor.getId()}-${diffEditorOpen}-${syntaxError}-${showStaticError}`
          )
        )
        .subscribe(
          ([
            diffEditorOpen,
            editor,
            syntaxError,
            showStaticError,
            isReady,
            isReadOnly,
          ]) =>
            !isReady
              ? []
              : this._renderActions(
                  editor,
                  this._initialiseToolbarIcons(
                    diffEditorOpen,
                    syntaxError,
                    showStaticError,
                    isReadOnly
                  )
                )
        )
    );
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  onUnlock(item: WorkspaceItem): void {
    this._store.dispatch(
      WorkspaceActions.confirmForkFile({
        uri: item.info.uri,
      })
    );
  }

  private _initEditorActions = (
    editor: monaco.editor.IStandaloneCodeEditor
  ): void => {
    this.buttonsMap = ToolbarButtons(editor, this.config.color.warn);
  };

  private _renderActions(
    editor: monaco.editor.IStandaloneCodeEditor,
    actions: ToolbarActions[]
  ): void {
    this.editorActionContainer.clear();

    actions.forEach(action => {
      if (isCustomComponent(action)) {
        const comp = this.editorActionContainer.createComponent<any>(
          action.component
        );
        comp.setInput('editor', editor);
      } else {
        const comp = this.editorActionContainer.createComponent(
          EditorButtonComponent
        );
        comp.setInput('disabled', this.isLoading$);
        comp.setInput('actions', action);
      }
      this._cdr.markForCheck();
    });
  }

  private _initialiseToolbarIcons = (
    diffEditorOpen: boolean,
    syntaxError: boolean,
    showStaticError: boolean,
    isReadOnly: boolean
  ): any => {
    let actions: IEditorToolbar;

    if (isReadOnly) {
      actions = editorToolbar.readOnlyEditor;
    } else if (diffEditorOpen) {
      actions = editorToolbar.diffEditor;
    } else {
      actions = editorToolbar.editor;
    }

    return [
      ...this._getConditionalBtn(syntaxError, showStaticError),
      ...actions,
    ].map(btnName => ({ ...this.buttonsMap(btnName) }));
  };

  private _getConditionalBtn(
    syntaxError: boolean,
    showStaticError: boolean
  ): ToolbarButtonNames[] {
    if (!syntaxError && !showStaticError) {
      return [];
    }

    const buttons: ToolbarButtonNames[] = [];
    syntaxError && buttons.push('syntaxError');
    showStaticError && buttons.push('staticError');
    return buttons.concat('divider');
  }
}
