import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { FontsModule } from '@app/fonts/fonts.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import * as fromComponents from './components';
import * as fromDirective from './directives';
import { FeatureToggleModule } from './modules/feature-toggle/feature-toggle.module';
import { LoadingSpinnerModule } from './modules/loading-spinner/loading-spinner.module';
import { LogoModule } from './modules/logo/logo.module';
import { RosettaMenuComponent } from './modules/rosetta-menu.component';
import { RosettaOverlayModule } from './modules/rosetta-overlay/rosetta-overlay.modules';
import { StopPropagationModule } from './modules/stop-propagation/stop-propagation.module';
import { RosettaFilterPipe } from './pipes/rosetta-filter.pipe';

const matModules = [
  MatBadgeModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatDialogModule,
  MatDividerModule,
  MatInputModule,
  MatProgressSpinnerModule,
  MatRippleModule,
  MatSelectModule,
  MatToolbarModule,
  MatTooltipModule,
  MatTreeModule,
];

@NgModule({
  declarations: [
    ...fromComponents.componentsExport,
    ...fromDirective.directives,
  ],
  imports: [
    CommonModule,
    FontsModule,
    ReactiveFormsModule,
    FormsModule,

    RouterModule,

    FeatureToggleModule,
    LoadingSpinnerModule,
    RosettaOverlayModule,
    StopPropagationModule,

    ...matModules,

    NgScrollbarModule,

    LogoModule,
    RosettaFilterPipe,
    RosettaMenuComponent,
  ],
  exports: [
    CommonModule,
    FontsModule,
    ReactiveFormsModule,
    FormsModule,

    ...fromComponents.componentsExport,
    ...fromDirective.directives,
    ...matModules,

    // From modules
    LoadingSpinnerModule,
    StopPropagationModule,
    FeatureToggleModule,
    NgScrollbarModule,
  ],
})
export class SharedModule {}
