import { ElementRef } from '@angular/core';
import { RosettaTree } from '@models';
import * as d3 from 'd3';

export class ClassHierarchyTree implements RosettaTree {
  constructor(private _chartContainer: ElementRef) {
    this.zoomListener = d3
      .zoom()
      .scaleExtent([0.1, 3])
      .on('zoom', () => {
        if (d3.event.transform != null) {
          this.svgGroup.attr('transform', d3.event.transform);
        }
      });

    this.svgBase = d3
      .select(this._chartContainer.nativeElement)
      .append('svg')
      .attr('width', this._viewerWidth())
      .attr('height', this._viewerHeight())
      .call(this.zoomListener);

    // declares a tree layout and a ssigns the size
    this.tree = d3.tree().size([this._viewerWidth(), this._viewerHeight()]);

    this.svgGroup = this.svgBase
      .append('g')
      .classed('drawarea', true)
      .append('g');
    const defs = this.svgBase.append('defs');
    this.initArrowDef(defs);
  }

  svgBase: any;
  svgGroup: any;
  tree: any;
  zoomListener: any;
  root: any;

  readonly classNodeRect = { width: 400, height: 550, textMargin: 0 };

  setNodeSelectFunction() {}
  setNodeHoverFunction() {}

  highlightUpdateAndCenterNode(currentValue: string[]): void {
    const width =
      this._calculateMaxWidth(this.root) * this.classNodeRect.width * 1.4;
    const height = this.findMaxDepth(this.root) * this.classNodeRect.height;

    this.tree.size([width, height]);

    const centerNode = this.findNode(this.root, currentValue[0]);
    // find the first node that matches our highlighted node

    this.draw(centerNode);

    this.centre(centerNode);
  }

  findNode(node: any, nodeName: string) {
    const found = this.allNodes(node).filter(f => f.data.name === nodeName);
    return found ? found[0] : node;
  }

  allNodes(node: any) {
    const nodes = [node];
    if (node.children) {
      node.children.forEach((x: any) =>
        this.allNodes(x).forEach(y => nodes.push(y))
      );
    }
    return nodes;
  }

  resizeNavigator(): void {
    const element = this._chartContainer.nativeElement;

    this.svgBase
      .attr('width', element.offsetWidth)
      .attr('height', element.offsetHeight);

    this.tree.size([element.offsetHeight, element.offsetWidth]);

    this.centre(this.root);
  }

  createTreeData(tree: any): void {
    //  assigns the data to a hierarchy using parent-child relationships
    // maps the node data to the tree layout
    this.root = d3.hierarchy(tree);
  }

  updateAndCenterNode(): void {
    const width =
      this._calculateMaxWidth(this.root) * this.classNodeRect.width * 1.4;
    const height = this.findMaxDepth(this.root) * this.classNodeRect.height;

    this.tree.size([width, height]);

    this.draw(this.root);

    this.centre(this.root);
  }

  centre(node: any) {
    const scale = d3.zoomTransform(this.svgBase.node()).k;

    const x = (this._viewerWidth() - this.classNodeRect.width) / 2 - node.x;
    const y = (this._viewerHeight() - this.classNodeRect.height) / 2 - node.y;

    this.svgBase
      .transition()
      .duration(500)
      .call(
        this.zoomListener.transform,
        d3.zoomIdentity.translate(x, y).scale(scale)
      );
  }

  findMaxDepth(node: any) {
    let maxDepth = node.depth + 1;
    if (node.children) {
      node.children.forEach(
        (n: any) => (maxDepth = Math.max(this.findMaxDepth(n), maxDepth))
      );
    }
    return maxDepth;
  }

  draw(centerNode: any) {
    const rootNode = this.tree(this.root);
    const nodeGroup = this.svgGroup.append('g').attr('id', 'nodes');

    const linkGroup = this.svgGroup.append('g').attr('id', 'links');

    // adds each node as a group
    const node = nodeGroup.selectAll('g.node').data(rootNode.descendants());

    const box = node
      .enter()
      .insert('g', 'g.node')
      .attr('transform', (d: any) => 'translate(' + d.x + ',' + d.y + ')')
      .classed('node', true);

    box
      .append('g')
      .append('rect')
      .attr('rx', 6)
      .attr('ry', 6)
      .attr('width', this.classNodeRect.width)
      .attr('height', this.classNodeRect.height)
      .attr('fill', (x: any) => (x === centerNode ? 'goldenrod' : 'Khaki'));

    box
      .append('foreignObject')
      .attr('x', this.classNodeRect.textMargin)
      .attr('y', this.classNodeRect.textMargin)
      .attr('width', () =>
        this.classNodeRect.width - this.classNodeRect.textMargin * 2 < 0
          ? 0
          : this.classNodeRect.width - this.classNodeRect.textMargin * 2
      )
      .attr('height', () =>
        this.classNodeRect.height - this.classNodeRect.textMargin * 2 < 0
          ? 0
          : this.classNodeRect.height - this.classNodeRect.textMargin * 2
      )
      .append('xhtml')
      .html((d: any) => this.textArea(d));

    const link = linkGroup
      .selectAll('path')
      .data(rootNode.descendants().slice(1));

    // adds the links between the nodes
    link
      .enter()
      .insert('path', 'g')
      .attr('d', (d: any) => this.elbow(d, this.classNodeRect))
      .attr('marker-start', 'url(#start-arrow)')
      .classed('node-link', true);
  }

  textArea(d: any) {
    const attrs = Object.keys(d.data.attributes)
      .map(
        a =>
          '<div class="clearfix"><span class="pull-left bold lead node-text">' +
          a +
          '</span> ' +
          '<span class="pull-right small node-sub-text">' +
          d.data.attributes[a].join(' ') +
          '</span></div>'
      )
      .join('');

    return (
      '<div class="wordwrap">' +
      '<p class="node-text-header">' +
      d.data.name +
      '</p><hr>' +
      attrs +
      '</div>'
    );
  }

  line(d: any) {
    return (
      'M' +
      d.x +
      ',' +
      d.y +
      'C' +
      d.x +
      ',' +
      (d.y + d.parent.y) / 2 +
      ' ' +
      d.parent.x +
      ',' +
      (d.y + d.parent.y) / 2 +
      ' ' +
      d.parent.x +
      ',' +
      d.parent.y
    );
  }

  elbow(d: any, rectNode: any) {
    return (
      'M' +
      (d.parent.x + rectNode.width / 2) +
      ',' +
      (d.parent.y + rectNode.height) +
      ' V' +
      (d.parent.y + rectNode.height + 20) +
      ' H' +
      (d.x + rectNode.width / 2) +
      ' V' +
      d.y
    );
  }

  initArrowDef(defs: any) {
    // Build the arrows definitions
    // End arrow
    defs
      .append('marker')
      .attr('id', 'end-arrow')
      .attr('viewBox', '0 -12 24 24')
      .attr('refX', 0)
      .attr('refY', 0)
      .attr('markerWidth', 12)
      .attr('markerHeight', 12)
      .attr('orient', 'auto')
      .append('path')
      .attr('d', 'M0,-10L20,0L0,10')
      .classed('arrow', true);

    // Start arrow
    defs
      .append('marker')
      .attr('id', 'start-arrow')
      .attr('viewBox', '0 -12 24 24')
      .attr('refX', 0)
      .attr('refY', 0)
      .attr('markerWidth', 12)
      .attr('markerHeight', 12)
      .attr('orient', 'auto')
      .append('path')
      .attr('d', 'M20,-10L0,0L20,10')
      .classed('arrow', true);
  }

  diagonal(d: any, rectNode: any) {
    const start = {
      x: d.parent.x + rectNode.width / 2 + 10,
      y: d.parent.y + rectNode.height,
    };

    const end = {
      x: d.x + rectNode.width / 2,
      y: d.y,
    };

    const m = (start.y + end.y) / 2;

    const points = [
      start,
      {
        x: start.x,
        y: m,
      },
      {
        x: end.x,
        y: m,
      },
      end,
    ];

    const p = points.map(f => [f.x, f.y]);
    return 'M' + p[0] + 'C' + p[1] + ' ' + p[2] + ' ' + p[3];
  }

  private _viewerHeight(): number {
    return this._chartContainer.nativeElement.offsetHeight;
  }

  private _viewerWidth(): number {
    return this._chartContainer.nativeElement.offsetWidth;
  }

  private _calculateMaxWidth(node: any) {
    let maxWidth = 0;

    if (node.children) {
      if (node.children.length % 2 === 0) {
        maxWidth += node.children.length;
      } else {
        maxWidth += node.children.length - 1;
      }
      node.children.forEach(
        (n: any) => (maxWidth += this._calculateMaxWidth(n))
      );
    }
    return maxWidth;
  }
}
