import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { Store } from '@ngrx/store';
import { WorkspaceUrlPrefixOperator } from '@utils';
import { BehaviorSubject, map, switchMap } from 'rxjs';

import { CodeView, CodeViewApi } from './models/code-view.model';

@Injectable({
  providedIn: 'root',
})
export class CodeViewService {
  constructor(
    private _store: Store,
    private _http: HttpClient,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  private readonly _defaultCodeViewLabel = 'default-code-view';
  private _codeViewSubject$ = new BehaviorSubject<Map<string, CodeView>>(
    new Map()
  );

  private _engineUrl$ = this._store.pipe(
    WorkspaceUrlPrefixOperator(this._config.resourcePaths.engine)
  );

  codeViewGeneration$ = this.getCodeView(this._defaultCodeViewLabel);

  runCodeViewGeneration(
    sourceEntity: any,
    rosettaNamespace: string,
    rosettaClass: string,
    label?: string
  ) {
    const payload: CodeViewApi.CodeViewGenerationSubmit = {
      entityName: rosettaClass,
      json: JSON.stringify(sourceEntity),
      type: `${rosettaNamespace}.${rosettaClass}`,
      isComplexType: true,
      rosettaNamespace,
      label,
    };

    this._engineUrl$
      .pipe(
        switchMap(url =>
          this._http.post<CodeViewApi.CodeViewWithDiagnostics>(
            `${url}/code-view/`,
            payload
          )
        )
      )
      .subscribe(codeViewWithDiags => {
        this._codeViewSubject$.next(
          this._codeViewSubject$.value.set(
            label || this._defaultCodeViewLabel,
            codeViewWithDiags.codeView
          )
        );
      });
  }

  getCodeView(label: string) {
    return this._codeViewSubject$.pipe(map(codeViews => codeViews.get(label)));
  }

  deleteCodeView(key: string) {
    const codeViewMap = this._codeViewSubject$.value;
    codeViewMap.delete(key);
    this._codeViewSubject$.next(codeViewMap);
  }

  clearAllCodeViews() {
    this._codeViewSubject$.next(new Map());
  }
}
