import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DynamicUrlPathInterceptor } from '@core/interceptors/dynamic-url-path-interceptor';
import { AuthInterceptor } from './auth-interceptor';
import { ErrorInterceptor } from './error-interceptor';
import { HideProprietaryModelsWhenPresentingInterceptor } from './hide-proprietary-models-when-presenting-interceptor';
import { RetryBackoffInterceptor } from './retry-backoff-interceptor';
import { RefreshTokenInterceptor } from './token-refresh-interceptor';

export const httpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RefreshTokenInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HideProprietaryModelsWhenPresentingInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: RetryBackoffInterceptor,
    multi: true,
  },
  // The service interceptor needs to be last in the list as it
  // changes the url and could break logic in other interceptors
  {
    provide: HTTP_INTERCEPTORS,
    useClass: DynamicUrlPathInterceptor,
    multi: true,
  },
];
