import { ColDef } from '@ag-grid-community/core';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { cellClassRulesUtil, isRowSelectable } from '../utils/helpers';

export const CHECKBOX_ID = 'checkbox';
export const checkboxColDef = (): ColDef<transform.DataViewerRow> => ({
  colId: CHECKBOX_ID,
  checkboxSelection: isRowSelectable,
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  pinned: 'left',
  resizable: false,
  sortable: false,
  maxWidth: 48, // Smallest width possible due to padding
  cellClassRules: cellClassRulesUtil(),
});
