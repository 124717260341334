import { StorageKey } from '@configs';
import { Persist, PersistOptions } from 'var-persist';

// All persisted state will be cleared when opening a new workspace
export class WorkspacePersist<T> extends Persist<T> {
  constructor(key: string, options?: PersistOptions<T>) {
    super(`${StorageKey.workspacePrefix}:${key}`, options);
  }

  reset() {
    super.value = super.options?.default;
    super.clear();
  }
}
