import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { of } from 'rxjs';
import { RegTreeNode } from '@features/workspace/modules/design/regulation/models';
import { SegmentTreeService } from './segment-tree.service';

type SegmentTreeNode = RegTreeNode | null | undefined;

@Component({
  selector: 'app-segment-tree',
  templateUrl: './segment-tree.component.html',
  styleUrls: ['./segment-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SegmentTreeService],
  host: { class: 'segment-tree theme-bg-alt' },
})
export class SegmentTreeComponent {
  scrollToElementSelector?: string;
  scrollOptions = {
    top: -8,
  };

  @Input()
  get segmentTree(): SegmentTreeNode {
    return this._segmentTree;
  }
  set segmentTree(value: SegmentTreeNode) {
    this._segmentTree = value;

    if (value) {
      this._segmentTreeService.init(of(value));
    }
  }

  @Input()
  get selectedNode(): string | undefined {
    return this._selectedNode;
  }
  set selectedNode(value: string | undefined) {
    this._selectedNode = value;
    this.scrollToElementSelector = `[data-fragment="${value}"]`;
  }

  private _selectedNode?: string;
  private _segmentTree?: SegmentTreeNode;

  get dataSource() {
    return this._segmentTreeService.dataSource;
  }

  get treeControl() {
    return this._segmentTreeService.treeControl;
  }

  hasChild = (_: number, node: RegTreeNode) =>
    !!node.children && node.children.length > 0;

  constructor(private _segmentTreeService: SegmentTreeService) {}
}
