import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  ViewChild,
} from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { getDialogOptions } from '@configs';
import { DialogComponent } from '@models';
import { WA_WINDOW } from '@ng-web-apis/common';

@Component({
  selector: 'app-issue-tracker',
  templateUrl: './issue-tracker.component.html',
  styleUrls: ['issue-tracker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IssueTrackerComponent implements DialogComponent, AfterViewInit {
  constructor(@Inject(WA_WINDOW) private _window: Window) {}

  @ViewChild('tracker') trackerEl!: ElementRef;
  @ViewChild('resolved') resolvedEl?: ElementRef;
  @ViewChild('form') formEl!: ElementRef;

  static options(): MatDialogConfig {
    return getDialogOptions('max');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (event.target instanceof Window) {
      this._setIframeHeights(event.target.innerHeight);
    }
  }

  ngAfterViewInit() {
    this._setIframeHeights(this._window.innerHeight);
  }

  private _setIframeHeights(windowHeight: number) {
    this._window.requestAnimationFrame(() => {
      const height = `${windowHeight - 290}px`;

      this.trackerEl.nativeElement.height = height;
      this.formEl.nativeElement.height = height;

      if (this.resolvedEl) {
        this.resolvedEl.nativeElement.height = height;
      }
    });
  }
}
