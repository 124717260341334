import { HttpClient } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import {
  editorDefaultOptions,
  RosettaConfig,
  TM_LANGUAGE_JSON_FILENAME,
} from '@configs';
import { EditorStateService } from '@core/services/editor-state.service';
import { LanguageServerService } from '@core/services/language-server.service';
import { RosettaEditorHistoryService } from '@core/services/rosetta-editor-history.service';
import { Workspace, WorkspaceItem } from '@models';
import { Store } from '@ngrx/store';
import { AppSelectors } from '@store/selectors';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { currentWorkspaceIdObserver, deepEquals, isNotNull } from '@utils';
import { LANGUAGE_ROSETTA } from '@workspace-design/textual/models/editor.const';
import { liftOff } from '@workspace-design/textual/services/monaco-textmate-loader';
import { groupBy } from 'lodash-es';
import {
  DisposableCollection,
  SymbolInformation,
  WorkspaceSymbolRequest,
} from 'monaco-languageclient';
import {
  debounceTime,
  distinctUntilChanged,
  first,
  firstValueFrom,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';

@Component({
  selector: 'app-code-editor',
  template: '',
  styleUrls: ['./code-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CodeEditorComponent implements AfterViewInit, OnDestroy {
  constructor(
    private _languageService: LanguageServerService,
    private _editorStateService: EditorStateService,
    private _historyService: RosettaEditorHistoryService,
    private _editorElement: ElementRef,
    private _store: Store,
    private _httpClient: HttpClient
  ) {}

  private _editor?: monaco.editor.IStandaloneCodeEditor;
  private _unsubscribe$ = new Subject<void>();
  private _disposables = new DisposableCollection();
  private _viewStateChanged$ = new Subject<void>();
  private _currentUri?: string;

  ngAfterViewInit() {
    this._languageService.loadMonaco({
      onMonacoInit: () => {
        currentWorkspaceIdObserver(this._store, {
          skipReadyCheck: true,
          skipInitial: false,
        })
          .pipe(
            switchMap(() =>
              this._store
                .select(WorkspaceSelectors.selectWorkspace)
                .pipe(first(isNotNull))
            ),
            takeUntil(this._unsubscribe$)
          )
          // eslint-disable-next-line @ngrx/no-store-subscription
          .subscribe(workspace => {
            // We have to initialize the editor before we can dispose and create new models
            this._initEditorInstance(workspace);
            if (this._editor) {
              this._setup(this._editor);
              this._loadTextMate(this._editor);
              this._createModels(workspace.items);
              this._selectInitialModel();
            }
          });

        this._languageService.overrides();
      },
    });

    this._store
      .select(WorkspaceSelectors.selectWorkspaceItemEditorState)
      .pipe(
        distinctUntilChanged(deepEquals),
        tap(options => this._languageService.updateEditorOptions(options)),
        takeUntil(this._unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
    this._editorCleanup();
    this._historyService.reset();
  }

  private _loadTextMate(editor?: monaco.editor.IStandaloneCodeEditor) {
    editor &&
      liftOff(
        editor,
        firstValueFrom(
          this._httpClient.get(
            `${RosettaConfig.resourcePaths.assets}/${TM_LANGUAGE_JSON_FILENAME}`,
            { observe: 'response', responseType: 'text' }
          )
        )
      ).then(disposables => {
        disposables.forEach(d => this._disposables.push(d));
      });
  }

  private _editorCleanup() {
    this._disposables.dispose();

    monaco.editor.getModels().forEach(model => model.dispose());

    if (this._editor) {
      this._languageService.editorSubject$.next(null);
      this._editor.setModel(null);
      this._editor.dispose();
      this._editor = undefined;
    }
  }

  private _createModels(items: WorkspaceItem[]) {
    this._languageService.createModels(items);
  }

  private _selectInitialModel() {
    this._store
      .select(WorkspaceSelectors.selectCurrentWorkspaceItemUri)
      .pipe(first())
      .subscribe(uri => {
        this._store.dispatch(
          WorkspaceActions.selectWorkspaceItem({
            uri,
          })
        );
      });
  }

  private _initEditorInstance(workspace: Workspace) {
    // Dispose of old editor and editor models before creating a new one
    this._editorCleanup();

    const editor = this._createEditor(this._editorElement);

    // Customise editor
    this._addWorkspaceSymbolSearch(editor);
    this._enableReadOnlyHoverText(editor);

    this._historyService.init(editor);
    this._addActions(editor);

    editor.onDidPaste(pasteEvent => this._sanitizeModel(pasteEvent.range));

    // Start monaco language client
    this._languageService.connectToServer(workspace);

    // Store editor instance so that other components/services can access it
    this._editor = editor;
    this._languageService.editorSubject$.next(editor);
  }

  private _addActions(editor: monaco.editor.IStandaloneCodeEditor) {
    const isMacLike = /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform);
    const keyMod = isMacLike ? monaco.KeyMod.CtrlCmd : monaco.KeyMod.Alt;

    this._disposables.push(
      editor.addAction({
        id: 'editor.action.goBack',
        label: 'Go Back',
        keybindings: [keyMod || monaco.KeyCode.LeftArrow],
        run: () => this._historyService.goBack(),
      })
    );
    this._disposables.push(
      editor.addAction({
        id: 'editor.action.goForward',
        label: 'Go Forward',
        keybindings: [keyMod || monaco.KeyCode.RightArrow],
        run: () => this._historyService.goForward(),
      })
    );
  }

  private _setup(editor: monaco.editor.IStandaloneCodeEditor) {
    this._disposables.push(
      editor.onDidScrollChange(() => this._viewStateChanged$.next())
    );
    this._disposables.push(
      editor.onDidChangeHiddenAreas(() => this._viewStateChanged$.next())
    );
    this._disposables.push(
      editor.onDidChangeCursorPosition(() => this._viewStateChanged$.next())
    );

    this._viewStateChanged$.pipe(debounceTime(300)).subscribe(() => {
      if (this._currentUri && !this._languageService.diffEditor) {
        this._editorStateService.save(editor, this._currentUri);
      }
    });

    this._disposables.push(
      editor.onDidChangeModel(event => {
        if (!this._languageService.diffEditor) {
          this._currentUri = event.newModelUrl?.toString();
          if (this._currentUri) {
            this._editorStateService.restore(editor, this._currentUri);
          }
        }
      })
    );
  }

  private _addWorkspaceSymbolSearch(
    editor: monaco.editor.IStandaloneCodeEditor
  ) {
    let quickInputCommand: any;

    (window as any).require(
      ['vs/platform/quickinput/common/quickInput'],
      (quickInput: any) => {
        quickInputCommand = editor.addCommand(0, (accessor, func) => {
          // a hacker way to get the input service
          const quickInputService = accessor.get(quickInput.IQuickInputService);
          func(quickInputService);
        });
      }
    );

    editor.addAction({
      id: 'rosetta.action.goToWorkspaceSymbol',
      label: 'Go to Symbol in Workspace...',
      run: editor => {
        const client = this._languageService.languageClient;

        client &&
          client
            .sendRequest<SymbolInformation[]>(WorkspaceSymbolRequest.method, {
              query: '',
            })
            .then(result => {
              editor.trigger('', quickInputCommand, (quickInput: any) => {
                const filterPattern = /\.[A-Z]/;
                const uriPattern = /edit:\/|\.rosetta/g;
                const nameGroupsPattern = /^([a-z.]+)\.([A-Z].+)$/;
                const dotPattern = /\./g;
                const dashPattern = /-/g;
                const matchLastDotPattern = /\.(?=[^.]*$)/;

                const groupedByUri = groupBy(
                  result.filter(i => filterPattern.test(i.name)),
                  item =>
                    item.location.uri
                      .replace(uriPattern, '')
                      .split('/')
                      .at(-1)
                      ?.replace(dashPattern, '.')
                      .replace(matchLastDotPattern, ':')
                );

                const picks = Object.entries(groupedByUri).flatMap(
                  ([key, items]) => [
                    {
                      type: 'separator',
                      label: `${key} (${items.length})`,
                    },
                    ...items.map(i => ({
                      id: i.name,
                      label: i.name
                        .replace(nameGroupsPattern, '$2')
                        .replace(dotPattern, ' -> '),
                      description: `(${i.name.replace(
                        nameGroupsPattern,
                        '$1'
                      )})`,
                      location: i.location,
                    })),
                  ]
                );

                quickInput.pick(picks).then((selected: SymbolInformation) => {
                  if (!selected) {
                    return;
                  }
                  const location = selected.location;
                  this._languageService.setSymbolLocation({
                    documentRange: location.range,
                    uri: location.uri.toString(),
                  });
                });
              });
            });
      },
    });
  }

  private _sanitizeModel(range: monaco.Range) {
    const textModel = this._editor?.getModel();
    const text = textModel?.getValueInRange(range);
    const sanitizedString = text?.replace(/[^\x00-\x7F]/g, '');
    sanitizedString &&
      textModel?.applyEdits([{ range, text: sanitizedString }]);
  }

  private _createEditor(
    editorContainer: ElementRef
  ): monaco.editor.IStandaloneCodeEditor {
    this._store
      .select(AppSelectors.isDarkTheme)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(isDark => this._languageService.setTheme({ isDark }));

    return monaco.editor.create(editorContainer.nativeElement, {
      ...editorDefaultOptions,
      model: null,
      language: LANGUAGE_ROSETTA,
      tabSize: 4,
      insertSpaces: true,
      detectIndentation: false,
      renderValidationDecorations: 'on',
      'semanticHighlighting.enabled': true,
    });
  }

  private _enableReadOnlyHoverText(
    editor: monaco.editor.IStandaloneCodeEditor
  ) {
    editor.onKeyDown(e => {
      if (editor.getOption(monaco.editor.EditorOption.readOnly)) {
        if (!e.ctrlKey && !e.altKey && !e.metaKey) {
          if (this._usuallyProducesCharacter(e.keyCode)) {
            editor.trigger('', 'type', { text: 'nothing' });
          }
        }
      }
    });
  }

  private _usuallyProducesCharacter(keyCode: monaco.KeyCode) {
    if (keyCode >= monaco.KeyCode.Digit0 && keyCode <= monaco.KeyCode.Digit9) {
      return true;
    }
    if (
      keyCode >= monaco.KeyCode.Numpad0 &&
      keyCode <= monaco.KeyCode.Numpad9
    ) {
      return true;
    }
    if (keyCode >= monaco.KeyCode.KeyA && keyCode <= monaco.KeyCode.KeyZ) {
      return true;
    }
    switch (keyCode) {
      case monaco.KeyCode.Enter:
      case monaco.KeyCode.Space:
      case monaco.KeyCode.Delete:
      case monaco.KeyCode.Semicolon:
      case monaco.KeyCode.Equal:
      case monaco.KeyCode.Comma:
      case monaco.KeyCode.Minus:
      case monaco.KeyCode.Period:
      case monaco.KeyCode.Slash:
      case monaco.KeyCode.Backslash:
      case monaco.KeyCode.Quote:
      case monaco.KeyCode.Tab:
      case monaco.KeyCode.BracketLeft:
      case monaco.KeyCode.BracketRight:
      case monaco.KeyCode.Backquote:
      case monaco.KeyCode.OEM_8:
        return true;
    }
    return false;
  }
}
