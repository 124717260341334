import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-editor-action-divider',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<mat-divider class="mr-4 ml-4" vertical="true" />`,
  styles: [
    `
      :host {
        display: flex;
        height: 100%;
        align-items: center;
      }

      .mat-divider {
        height: 70%;
      }
    `,
  ],
})
export class EditorActionDividerComponent {
  @Input() editor!: monaco.editor.IStandaloneCodeEditor;
}
