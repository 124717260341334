import {
  ChangeDetectorRef,
  ComponentRef,
  EmbeddedViewRef,
  inject,
  TemplateRef,
  Type,
  ViewContainerRef,
} from '@angular/core';

export abstract class RenderTemplateDirective<C = any> {
  protected hasView = false;
  protected context?: C = undefined;

  private _containerRef = inject(ViewContainerRef);
  protected templateRef = inject(TemplateRef);
  protected cdr = inject(ChangeDetectorRef);

  protected render(
    condition: boolean,
    cb?: (viewRef: EmbeddedViewRef<any>) => void
  ): void;
  protected render<R>(
    condition: boolean,
    cb?: (componentRef: ComponentRef<R>) => void,
    component?: Type<R>
  ): void;
  protected render(
    condition: boolean,
    cb?: (v: any) => void,
    component?: any
  ): void {
    if (condition && !this.hasView) {
      this.hasView = true;
      const result = this._create(component);
      if (cb) {
        cb(result);
      }
    } else if (!condition && this.hasView) {
      this._containerRef.clear();
      this.hasView = false;
    }
    this.cdr.markForCheck();
  }

  private _create<R>(
    component?: Type<R>
  ): EmbeddedViewRef<any> | ComponentRef<R> {
    if (component) {
      return this._containerRef.createComponent(component);
    } else {
      return this._containerRef.createEmbeddedView(
        this.templateRef,
        this.context
      );
    }
  }
}
