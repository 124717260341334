export enum StorageKey {
  AccessToken = 'access_token', //The access token needs to be the same as the access token on the server
  EditorOptions = 'rosetta.editor-options',
  ExpiresAt = 'rosetta.expires-at',
  LoginDetails = 'rosetta.login-details',
  SplitPanelConfig = 'rosetta.split-panel-config',
  Store = 'rosetta.store',
  ThemeConfig = 'rosetta.theme-config',
  TourConfig = 'rosetta.tour-config',
  workspacePrefix = 'rosetta.workspace', // This is used to find and remove saved state when switching workspaces
  WorkspaceTab = 'rosetta.workspace-tabs',
}
