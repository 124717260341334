import { Inject, Injectable } from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { LanguageServerService } from '@core/services/language-server.service';
import { PanelStateService } from '@core/services/panel-state.service';
import { UiPanel, Workspace, WorkspaceId } from '@models';
import { Store } from '@ngrx/store';
import { AppActions } from '@store/.';
import { AuthSelectors } from '@store/selectors';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import {
  catchError,
  combineLatest,
  concat,
  filter,
  first,
  Observable,
  retry,
  skip,
  takeUntil,
  tap,
  throwError,
  timeout,
} from 'rxjs';
import { WorkspaceApiService } from './workspace-api.service';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  constructor(
    private _wsApi: WorkspaceApiService,
    private _panelStateService: PanelStateService,
    private _languageServerService: LanguageServerService,
    private _store: Store,
    @Inject(ROSETTA_CONFIG) private _config: IRosettaConfig
  ) {}

  private _loggedOut$ = this._store
    .select(AuthSelectors.isLoggedIn)
    .pipe(filter(isLoggedIn => !isLoggedIn));

  load(workspaceId: WorkspaceId) {
    this._store.dispatch(WorkspaceActions.clearServices());
    this._store.dispatch(WorkspaceActions.switchWorkspace({ workspaceId }));

    this.close();

    const switchWorkspace$ = this._setWorkspace(workspaceId).pipe(first());

    const waitForSocketsToClose$ = combineLatest([
      this._languageServerService.isSocketState(WebSocket.CLOSED),
      this._panelStateService.isSocketState(WebSocket.CLOSED),
    ]).pipe(
      first(
        ([languageSocketState, panelState]) => languageSocketState && panelState
      )
    );

    const startPanelStateService$ = this._store
      .select(WorkspaceSelectors.selectWorkspaceInfo)
      .pipe(
        first(isNotNull),
        tap(info => this._panelStateService.start(info))
      );

    const connectPanelSocket$ = this._panelStateService.webSocketState.pipe(
      first(state => state === WebSocket.OPEN)
    );

    const waitForUiEditor$ = this._panelStateService
      .isPanelReady(UiPanel.Editor)
      .pipe(first(isReady => !!isReady));

    concat(
      switchWorkspace$,
      waitForSocketsToClose$,
      startPanelStateService$,
      connectPanelSocket$,
      waitForUiEditor$
    )
      .pipe(
        skip(4),
        first(),
        timeout(this._config.workspaceSwitch.timeout),
        retry(this._config.workspaceSwitch.numberRetries),
        takeUntil(this._loggedOut$)
      )
      .subscribe({
        next: () => {
          this._store.dispatch(WorkspaceActions.switchWorkspaceSuccess());
        },
        error: e => this._errorHandler(e),
      });
  }

  close() {
    // TODO: This will need refactoring, setting an observable
    // "isReady" for the editor to wait for is not ideal
    this._panelStateService.close();
    this._languageServerService.closeSocket();
  }

  private _errorHandler(error: any) {
    if (error && error.status === 401) {
      // 401 are handled by the token refresh interceptor
      return;
    }

    this._store.dispatch(WorkspaceActions.switchWorkspaceFailure());
    this._store.dispatch(AppActions.disconnectFromWorkspace());
  }

  private _setWorkspace(workspaceId: WorkspaceId): Observable<Workspace> {
    return this._wsApi.getWorkspace(workspaceId, true).pipe(
      tap(workspace =>
        this._store.dispatch(WorkspaceActions.loadWorkspaceItems({ workspace }))
      ),
      timeout(this._config.workspaceSelect.timeout),
      catchError(err => {
        if (err.status === 401) {
          return throwError(() => err);
        }
        return throwError(() => ({
          code: err.status,
          message: `Error setting workspace, logging out: ${err.message}`,
          userMessage: this._config.text.workspaceLoadError,
        }));
      })
    );
  }
}
