import { NgModule } from '@angular/core';
import { CollapsiblePanelModule } from '@shared/modules/collapsible-panel/collapsible-panel.module';
import { FileTreeLeafNodeComponent } from '@shared/modules/file-tree/file-tree-leaf-node/file-tree-leaf-node.component';
import { FileTreeNodeComponent } from '@shared/modules/file-tree/file-tree-node/file-tree-node.component';
import { FileTreeComponent } from '@shared/modules/file-tree/file-tree/file-tree.component';
import { HasAbilityPipe } from '@shared/pipes/has-ability.pipe';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    FileTreeComponent,
    FileTreeNodeComponent,
    FileTreeLeafNodeComponent,
  ],
  imports: [SharedModule, CollapsiblePanelModule, HasAbilityPipe],
  exports: [FileTreeComponent],
})
export class FileTreeModule {}
