import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams, IRowNode } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { getValueToDisplay } from '@shared/modules/rosetta-table/utils/rosetta-table-utils';
import { TransformDataViewerComponent } from '@shared/modules/transform/components/transform-data-viewer/transform-data-viewer.component';
import { SampleRowStatus } from '@shared/modules/transform/models';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { SampleRowAction } from '@shared/modules/transform/models/sample-action.enum';

@Component({
  selector: 'app-cell-sample-name',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './cell-sample-name.component.html',
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
        height: 100%;
      }

      .remove-row {
        text-decoration: line-through;
        opacity: 0.25;
      }

      .small-button-wrapper {
        display: flex;
        gap: 0.5rem;
      }
    `,
  ],
})
export class CellSampleNameComponent implements ICellRendererAngularComp {
  rowNode!: IRowNode<transform.DataViewerRow>;
  cellValue!: string;
  rowError?: string;
  parentTable!: TransformDataViewerComponent;

  canUpdate = false;
  canDiscard = false;
  canDelete = false;
  selectable = false;

  readonly sampleRowAction = SampleRowAction;
  readonly sampleRowStatus = SampleRowStatus;

  agInit(
    params: ICellRendererParams<
      transform.DataViewerRow,
      transform.DataViewerCell,
      transform.DataViewerContext
    >
  ) {
    this.parentTable = params.context.parentTable;
    this.rowNode = params.node;
    this.cellValue = getValueToDisplay(params);
    this._initData(params);
  }

  /*
  Cell Renderer Component Refresh Lifecycle
  - refresh() is called 0...n times (i.e. it may never be called, or called multiple times)
   */
  refresh(): boolean {
    return false;
  }

  onClick(type: SampleRowAction) {
    this.parentTable.onActionBarEvent({
      type,
      rows: [this.rowNode.data],
      testPackGridSelection:
        this.parentTable.context.dataViewer.testPackGridSelection,
      context: this.parentTable.context,
    });
  }

  private _initData(params: ICellRendererParams<transform.DataViewerRow>) {
    if (!params.data) {
      return;
    }

    if (params.data.selectable) {
      this.selectable = true;
      this.canUpdate = params.data.checkAction(SampleRowAction.Accept);
      this.canDiscard = params.data.checkAction(SampleRowAction.Revert);
      this.canDelete = params.data.checkAction(SampleRowAction.Delete);
    }

    this.rowError = params.data.errorMessage;
  }
}
