import { coerceCssPixelValue } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
} from '@angular/core';
import { FilterPipeFunc } from '@models';
import { RosettaFilterPipe } from '@shared/pipes/rosetta-filter.pipe';
import { RosettaMenuComponent } from '../rosetta-menu.component';
import { TextInputComponent } from '../text-input/text-input.component';

@Component({
  standalone: true,
  selector: 'app-filtered-list',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './filtered-list.component.html',
  styleUrls: ['./filtered-list.component.scss'],
  imports: [
    CommonModule,
    RosettaMenuComponent,
    RosettaFilterPipe,
    TextInputComponent,
  ],
})
export class FilteredListComponent<T = unknown> {
  @ContentChild('staticContent', { read: TemplateRef })
  staticContent!: TemplateRef<undefined>;

  @ContentChild('rowContent', { read: TemplateRef })
  template!: TemplateRef<{ $implicit: T }>;

  @Input() data: T[] = [];
  @Input() filterString = '';
  @Input() maxHeight = 300;
  @Input() filterFunc?: FilterPipeFunc<T>;

  get itemWrapperStyles() {
    return {
      maxHeight: coerceCssPixelValue(this.maxHeight),
    };
  }

  constructor(
    @Attribute('inputPlaceholder') public inputPlaceholder = 'Filter'
  ) {}
}
