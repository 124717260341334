import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { IRosettaConfig, ROSETTA_CONFIG } from '@configs';
import { Task } from '@models';
import { Store } from '@ngrx/store';
import { TaskSelectors } from '@store/selectors';
import { startWith } from 'rxjs';

@Component({
  selector: 'app-workspace-static-error-menu',
  templateUrl: './workspace-static-error-menu.component.html',
  styleUrls: ['./workspace-static-error-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WorkspaceStaticErrorMenuComponent {
  @Input() editor!: monaco.editor.IStandaloneCodeEditor;

  codeCompilationResult$ = this._store
    .select(TaskSelectors.selectTaskPayload(Task.StaticJavaCompilation))
    .pipe(startWith({ success: true, task: null, status: null }));

  constructor(
    private _store: Store,
    @Inject(ROSETTA_CONFIG) public config: IRosettaConfig
  ) {}
}
