import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  ViewChild,
} from '@angular/core';
import { MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getDialogOptions } from '@configs';
import { DialogComponent, RosettaClassHierarchyDialog } from '@models';
import { RosettaTreeComponent } from '../rosetta-tree/rosetta-tree.component';

@Component({
  selector: 'app-rosetta-graphical-hierarchy',
  templateUrl: './rosetta-graphical-hierarchy.component.html',
  styleUrls: ['./rosetta-graphical-hierarchy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RosettaGraphicalHierarchyComponent implements DialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RosettaClassHierarchyDialog
  ) {}

  @ViewChild(RosettaTreeComponent)
  private _treeComponent?: RosettaTreeComponent;

  static options(data: RosettaClassHierarchyDialog): MatDialogConfig {
    return getDialogOptions('max-padding', {
      autoFocus: false,
      data,
    });
  }

  resizeTree() {
    this._treeComponent?.resizeNavigator();
  }
}
