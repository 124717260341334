import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { LanguageServerService } from '@core/services/language-server.service';
import { Store } from '@ngrx/store';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { OffsetTopDirective } from '@workspace-design/textual/directives/offset-top.directive';
import { ScrollableDirective } from '@workspace-design/textual/directives/scrollable.directive';
import { LANGUAGE_ROSETTA } from '@workspace-design/textual/models/editor.const';
import {
  FindRange,
  ModelData,
  ModelFinds,
  ResultID,
} from '@workspace-design/textual/models/find-range.model';
import { first, Subscription, timer } from 'rxjs';

@Component({
  selector: 'app-search-all',
  templateUrl: './search-all.component.html',
  styleUrls: ['./search-all.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchAllComponent implements OnDestroy {
  constructor(
    private _languageService: LanguageServerService,
    private _store: Store
  ) {}

  @Input() editor!: monaco.editor.IStandaloneCodeEditor;
  @ViewChild('searchVal') searchElement!: ElementRef;
  @ViewChildren(OffsetTopDirective) listItems!: QueryList<OffsetTopDirective>;
  @ViewChild(ScrollableDirective) list!: ScrollableDirective;

  searchValue: string | null = null;
  resultList: ModelFinds[] | null = [];
  currentResultId: ResultID = { fileId: -1, rowId: -1 };
  isLoading$ = this._store.select(WorkspaceSelectors.isConnectionNotReady);

  private _sub = new Subscription();

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  applyFilter(value: string) {
    if (!value) {
      this._clearResults();
      return;
    }

    this.searchValue = value;
    const findAllResults: Array<ModelFinds> = monaco.editor
      .getModels()
      .filter(model => model.getLanguageId() === LANGUAGE_ROSETTA)
      .map((model, index) => {
        let newRanges: Array<FindRange> = [];
        const modelData: ModelData = {
          uri: model.uri.toString(),
          fileName: this._extractShortFilenames(model.uri.path),
        };
        const ranges = model.findMatches(
          value,
          true,
          false,
          false,
          null,
          false
        );
        if (ranges && ranges.length > 0) {
          newRanges = ranges.map(({ range }, findIndex) => {
            if (range) {
              if (index === 0 && findIndex === 0) {
                this.editor.setSelection(range);
                this.editor.setPosition({
                  lineNumber: range.startLineNumber,
                  column: range.startColumn,
                });
              }
              const rangeText = this._getRangeTextWithHightLight(
                model,
                range,
                value
              );
              return { range: range, rangeText };
            }
            return { range: range };
          });
        }

        return { ranges: newRanges, model: modelData };
      });

    this.resultList = findAllResults.filter(
      result => result.ranges && result.ranges.length > 0
    );

    if (this.resultList.length === 0) {
      this.resultList = null;
    }

    this.currentResultId = { rowId: -1, fileId: -1 };
  }

  moveResult(direction: 'UP' | 'DOWN') {
    if (!this.resultList || this.resultList.length === 0) {
      return;
    }

    if (direction === 'DOWN') {
      this.currentResultId.rowId += 1;
      if (
        this.currentResultId.fileId > -1 &&
        this._getResultLengthOfFile(this.currentResultId.fileId) <=
          this.currentResultId.rowId
      ) {
        this.currentResultId.rowId = 0;
        this.currentResultId.fileId += 1;
        if (this.resultList.length <= this.currentResultId.fileId) {
          this.currentResultId.fileId = 0;
        }
      }
      if (this.currentResultId.fileId < 0) {
        this.currentResultId.fileId = 0;
      }
    } else {
      this.currentResultId.rowId -= 1;
      if (this.currentResultId.rowId < 0) {
        this.currentResultId.fileId -= 1;
        if (this.currentResultId.fileId < 0) {
          this.currentResultId.fileId = this.resultList.length - 1;
        }
        this.currentResultId.rowId =
          this._getResultLengthOfFile(this.currentResultId.fileId) - 1;
      }
      if (this.currentResultId.fileId < 0) {
        this.currentResultId.fileId = this.resultList.length - 1;
      }
    }

    if (this.resultList[this.currentResultId.fileId]) {
      this.goToRange(
        this.resultList[this.currentResultId.fileId].model.uri,
        this.resultList[this.currentResultId.fileId].ranges[
          this.currentResultId.rowId
        ].range,
        this.currentResultId
      );
    } else {
      //TODO - do something with "this.currentResultId"
    }

    timer(200)
      .pipe(first())
      .subscribe(() => {
        this.list.focus();
        this._goToResultOffset(direction);
      });
  }

  goToRange(
    uri: string,
    range: monaco.Range,
    currentResultId: ResultID = this.currentResultId
  ) {
    this._selectItemWithFileName(uri);

    this._languageService.selectTextItem(range);
    this.currentResultId = currentResultId;
  }

  private _extractShortFilenames(filePath: string) {
    const splitPath = filePath
      .substring(filePath.lastIndexOf('/') + 1)
      .replace(`.${LANGUAGE_ROSETTA}`, '')
      .split('-');

    const path = splitPath.slice(0, -1).join('.');
    return `${path}${path.length ? ':' : ''}${splitPath.at(-1)}`;
  }

  private _clearResults() {
    this.searchValue = null;
    this.resultList = [];
    this.currentResultId = { rowId: -1, fileId: -1 };
    this.searchElement.nativeElement.value = '';
  }

  private _getRangeTextWithHightLight(
    model: monaco.editor.ITextModel,
    range: monaco.Range,
    searchValue: string
  ) {
    const rangeOffset = 40; // offset that get text from range
    const regEx = new RegExp(`${searchValue}`, 'ig');
    const originRangeTxt = model.getValueInRange({
      ...range,
      startColumn:
        range.startColumn > rangeOffset ? range.startColumn - rangeOffset : 0,
      endColumn: range.endColumn + rangeOffset + 1,
    });
    let rangeTxt: string = originRangeTxt;
    const lastCharacterOfRangeTxt = originRangeTxt.slice(-1);

    if (
      rangeTxt.indexOf(' ') > -1 &&
      rangeTxt.indexOf(' ') < rangeTxt.search(regEx) - 7
    ) {
      rangeTxt = rangeTxt.substring(rangeTxt.indexOf(' ') + 1);
    }

    if (
      lastCharacterOfRangeTxt !== ' ' &&
      lastCharacterOfRangeTxt !== '.' &&
      lastCharacterOfRangeTxt !== "'" &&
      lastCharacterOfRangeTxt !== '"' &&
      lastCharacterOfRangeTxt !== ']' &&
      lastCharacterOfRangeTxt !== ':'
    ) {
      rangeTxt = rangeTxt.replace(/.$/, '...').trim();
    } else {
      rangeTxt = rangeTxt.replace(/.$/, '').trim();
    }

    const matchValue = rangeTxt.match(regEx);

    if (matchValue && matchValue.length > 0) {
      return rangeTxt.replace(regEx, `<mark>${matchValue[0]}</mark>`);
    }
    return originRangeTxt
      .trim()
      .replace(regEx, `<mark>${originRangeTxt.match(regEx).at(0)}</mark>`);
  }

  private _selectItemWithFileName(uri: string) {
    this._store.dispatch(WorkspaceActions.selectWorkspaceItem({ uri }));
  }

  private _getResultLengthOfFile(fileId: number) {
    return this.resultList?.[fileId].ranges.length || 0;
  }

  private _goToResultOffset(direction: string | null = null) {
    const selectedItem =
      this.listItems && this.listItems.find(item => !!item.isSelected);
    if (!selectedItem) {
      return;
    }
    if (direction === 'DOWN') {
      if (selectedItem.offsetTop - this.list.scrollTop >= 350) {
        this.list.scrollTop = selectedItem.offsetTop - 350;
      } else if (selectedItem.offsetTop <= 25) {
        this.list.scrollTop = 0;
      }
    } else if (direction === 'UP') {
      if (selectedItem.offsetTop <= this.list.scrollTop + 25) {
        this.list.scrollTop =
          selectedItem.offsetTop - 25 >= 0 ? selectedItem.offsetTop - 25 : 0;
      } else if (selectedItem.offsetTop - this.list.scrollTop >= 350) {
        this.list.scrollTop = selectedItem.offsetTop - 375;
      }
    } else {
      if (selectedItem.offsetTop >= 575) {
        this.list.scrollTop = selectedItem.offsetTop - 575;
      } else if (selectedItem.offsetTop >= 350) {
        this.list.scrollTop = selectedItem.offsetTop - 300;
      }
    }
  }
}
