<ng-scrollbar
  [appScrollToElement]="scrollToElementSelector"
  [scrollOptions]="scrollOptions"
  [skipScrollWhenInView]="false"
  appScrollFragmentTracker
  [scrollTopOffset]="scrollOptions.top"
  (fragmentChanged)="onFragmentChanged($event)"
  *appShowSpinner="
    provisionDetails?.bodyCorpusDetails as bodyCorpus;
    flexCentre: true
  "
>
  <h2>
    {{ bodyCorpus | bodyCorpusTitle }}
  </h2>
  <p *ngIf="bodyCorpus.bodyDefinition">{{ bodyCorpus.bodyDefinition }}</p>
  <ng-container *ngFor="let corpus of bodyCorpus.corpusList">
    <p *ngIf="corpus.definition">{{ corpus.definition }}</p>
  </ng-container>

  <app-provision-details-group
    *ngFor="let provisionGroup of provisionDetails?.provisionDetailGroups"
    [provisionGroup]="provisionGroup"
  />
</ng-scrollbar>
