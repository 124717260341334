import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
  Optional,
  SkipSelf,
} from '@angular/core';
import { RecursivePartial, StatusStateName } from '@models';
import { deepMerge } from '@utils';
import { BehaviorSubject, map, Observable } from 'rxjs';

import { PipelineRunInfo, PipelineRunResult } from '../../models';
import {
  defaultConfig,
  STATUS_COMPONENT_CONFIG,
  Statuses,
} from './run-status.config';

@Component({
  selector: 'app-run-status',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        display: flex;
        gap: 0 0.25rem;
        text-wrap: nowrap;
      }
      .status-text {
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    `,
  ],
  template: `<app-status [state]="state" />
    <div class="status-text">
      <span class="color-50">{{ text$ | async }}</span>
      <small class="color-75 text-ellipsis">{{ description$ | async }}</small>
    </div>`,
})
export class RunStatusComponent {
  constructor(
    @Inject(STATUS_COMPONENT_CONFIG)
    @Optional()
    @SkipSelf()
    statusConfigOverride: RecursivePartial<Statuses> = {}
  ) {
    this._config = deepMerge(defaultConfig, statusConfigOverride);
  }

  private _runResultState$ = new BehaviorSubject<PipelineRunInfo>(null);
  private _config!: Statuses;

  get state() {
    return this._config[this.statusStateName];
  }

  @Input() set runResultState(value: PipelineRunInfo) {
    this._setState(value);
    this._runResultState$.next(value);
  }

  statusStateName = StatusStateName.Missing;

  text$: Observable<string> = this._runResultState$.pipe(
    map(() => this.state.text)
  );

  description$: Observable<string> = this._runResultState$.pipe(
    map(runUpdate => {
      if (!runUpdate?.result) {
        return '';
      }

      const runResult = runUpdate.result.details;
      const countText = `${runResult.currentPipeline} of ${runResult.totalPipelines}`;
      if (this._isRunComplete(runUpdate.result)) {
        return `${countText}`;
      }

      return `${countText} - ${runResult.description}`;
    })
  );

  private _setState(runUpdate: PipelineRunInfo): void {
    if (!runUpdate) {
      this.statusStateName = StatusStateName.Missing;
    } else if (runUpdate.errorMessage) {
      this.statusStateName = StatusStateName.Error;
    } else if (this._isRunComplete(runUpdate.result)) {
      this.statusStateName = StatusStateName.Finished;
    } else {
      this.statusStateName = StatusStateName.Started;
    }
  }

  private _isRunComplete(runResult: PipelineRunResult): boolean {
    return (
      runResult.details.currentPipeline === runResult.details.totalPipelines &&
      runResult.data !== null
    );
  }
}
