import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { FontsModule } from '@app/fonts/fonts.module';
import { LetDirective } from '@ngrx/component';
import { ScrollPositionDirective } from '@shared/modules/scroll-position.directive';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { CopyToClipboardComponent } from '../copy-to-clipboard/copy-to-clipboard.component';
import { GoToSymbolDirective } from '../go-to-symbol.directive';
import { PersistExpandStateDirective } from '../persist-expand-state.directive';
import { RosettaMenuComponent } from '../rosetta-menu.component';
import { RosettaOverlayModule } from '../rosetta-overlay/rosetta-overlay.modules';
import { StopPropagationModule } from '../stop-propagation/stop-propagation.module';
import { CodeViewSearchComponent } from './code-view-search/code-view-search.component';
import { CodeViewComponent } from './code-view.component';
import { CodeViewNativeComponent } from './components/code-view-native/code-view-native.component';
import { CodeViewSelectedComponent } from './components/code-view-selected/code-view-selected.component';
import { CodeViewSwitchComponent } from './components/code-view-switch/code-view-switch.component';

@NgModule({
  declarations: [
    CodeViewSwitchComponent,
    CodeViewNativeComponent,
    CodeViewSearchComponent,
    CodeViewSelectedComponent,
    CodeViewComponent,
  ],
  imports: [
    CommonModule,
    FontsModule,
    CopyToClipboardComponent,
    FormsModule,
    GoToSymbolDirective,
    LetDirective,
    MatTooltipModule,
    MatTreeModule,
    MatButtonModule,
    NgScrollbarModule,
    PersistExpandStateDirective,
    RosettaMenuComponent,
    RosettaOverlayModule,
    ScrollPositionDirective,
    StopPropagationModule,
  ],
  exports: [CodeViewComponent, CodeViewSwitchComponent],
})
export class CodeViewModule {}
