import {
  FileFullMap,
  NodeCompleteness,
  RunStatus,
  SampleFileNodeFull,
} from '@workspace-engine/translate/models';
import { TaskStatus } from '../models';
import { nullAddition, round } from './number-utils';
import { naturalSort } from './array-utils';
import { UPLOAD_DIR } from '@configs';

export type TreeUtilCallback = (
  node: SampleFileNodeFull,
  parent: SampleFileNodeFull
) => void;

export function arrayToTree(
  leafNodes: SampleFileNodeFull[],
  nodeMap: FileFullMap,
  cbFunc?: TreeUtilCallback
): SampleFileNodeFull[] {
  const checkedNodes: Record<string, boolean> = {};
  const rootList: SampleFileNodeFull[] = [];

  leafNodes.forEach(function buildParent(node: SampleFileNodeFull) {
    const parentNode = node.parent && nodeMap[node.parent];

    if (!checkedNodes[node.id] && !parentNode) {
      rootList.push(node);
    }

    if (!checkedNodes[node.id]) {
      checkedNodes[node.id] = true;

      if (parentNode) {
        parentNode.children = naturalSort(
          [...(parentNode.children || []), node],
          'name'
        );

        if (cbFunc) {
          cbFunc(node, parentNode);
        }

        buildParent(nodeMap[parentNode.id]);
      }
    }
  });
  return naturalSort(rootList, 'name', UPLOAD_DIR);
}

export function getLeafDescendants<T = SampleFileNodeFull>(
  node: SampleFileNodeFull,
  cb?: (node: SampleFileNodeFull) => T
): T[] {
  if (!node?.children) {
    return [];
  }
  return node.children
    ?.map(function traverse(item: SampleFileNodeFull): any {
      if (item?.children && item.children.length > 0) {
        return item.children.map(i => traverse(i));
      } else if (item.isLeaf) {
        return cb ? cb(item) : item;
      }
    })
    .flat(Infinity);
}

export function calculateDescendantCompletenessScore(
  node: SampleFileNodeFull
): NodeCompleteness {
  const totals: NodeCompleteness & { length: number } = {
    length: 0,
    mapping: 0,
    validation: 0,
    qualification: null,
  };

  if (!node?.isVisible) {
    return { mapping: 0, validation: 0, qualification: null };
  }

  getLeafDescendants(node).forEach(child => {
    if (totals.qualification === null) {
      totals.qualification = child.expectations?.qualificationExpectation
        ? 0
        : null;
    }

    if (child.results) {
      totals.length++;
      totals.mapping += child.results.mapping.completeness;
      totals.validation += child.results.validation.completeness;
      totals.qualification = child.expectations?.qualificationExpectation
        ? nullAddition(
            totals.qualification,
            child.results.qualifications.completeness
          )
        : nullAddition(totals.qualification, null);
    }
  });

  return {
    mapping: round(totals.mapping / totals.length),
    validation: round(totals.validation / totals.length),
    qualification:
      totals.qualification === null
        ? null
        : round(totals.qualification / totals.length),
  };
}

export function getLeafDescendantAggregatedStatus(
  node: SampleFileNodeFull
): RunStatus {
  const result: RunStatus = { total: 0, ran: 0 };

  if (node?.isVisible) {
    getLeafDescendants(node, n => n.state)?.forEach(status => {
      result.total++;

      if (
        status === TaskStatus.Finished ||
        status === TaskStatus.Finished_Error
      ) {
        result.ran++;
      }
    });
  }

  return result;
}
