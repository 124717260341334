import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationModule } from '@core/modules/notifications/notifications.module';
import { ReleaseNotesModule } from '@core/modules/release-notes/release-notes.module';
import { TaskMessagesModule } from '@core/modules/task-messages/task-messages.module';
import { EditorStateService } from '@core/services/editor-state.service';
import { LanguageServerMiddlewareService } from '@core/services/language-server-middleware.service';
import { LanguageServerService } from '@core/services/language-server.service';
import { PanelStateService } from '@core/services/panel-state.service';
import { RosettaEditorHistoryService } from '@core/services/rosetta-editor-history.service';
import { StorageModule } from '@ngx-pwa/local-storage';
import { CookieService } from 'ngx-cookie-service';
import { StatusPollingService } from '../services/status-polling.service';
import { ConfirmationModule } from './confirmation/confirmation.module';
import { GaModule } from './ga/ga.module';
import { MatModule } from './mat/mat.module';
import { NavbarModule } from './navbar/navbar.module';
import { SnackBarModule } from './snack-bar/snack-bar.module';
import { NgrxStoreModule } from './store/ngrx-store.module';

export function disableAnimations(): boolean {
  return window.location.hash.indexOf('disableAnimations') > -1;
}

@NgModule({
  imports: [
    ConfirmationModule,
    NavbarModule,
    ReleaseNotesModule,
    NotificationModule,
    SnackBarModule,
    StorageModule.forRoot({
      IDBNoWrap: true,
    }),
    GaModule,
    NgrxStoreModule,
    BrowserAnimationsModule.withConfig({
      disableAnimations: disableAnimations(),
    }),

    // TODO: Move to lazy loaded module RosettaCoreModule
    // when other dependencies are moved
    TaskMessagesModule,
    MatModule,
  ],
  providers: [
    CookieService,

    // TODO: Move to lazy loaded module RosettaCoreModule
    // when other dependencies are moved
    LanguageServerService,
    LanguageServerMiddlewareService,
    PanelStateService,
    RosettaEditorHistoryService,
    EditorStateService,
    StatusPollingService,
  ],
})
export class CoreModule {}
