import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QueryParams, ViewOptions } from '@models';
import { map, Subscription, tap } from 'rxjs';

@Directive({
  selector: '[appShowWhenView]',
})
export class ShowWhenViewDirective implements OnInit, OnDestroy {
  constructor(
    private _renderer: Renderer2,
    private _element: ElementRef,
    private _route: ActivatedRoute,
    private _containerRef: ViewContainerRef,
    private _cdr: ChangeDetectorRef,
    @Optional() private _templateRef: TemplateRef<any>
  ) {}

  @Input() appShowWhenView!: ViewOptions;

  private _sub = new Subscription();
  private _hasView = false;

  ngOnInit() {
    this._sub.add(
      this._route.queryParamMap
        .pipe(
          map(params => params.get(QueryParams.VIEW) as ViewOptions),
          tap(currentView => this._toggleElement(currentView))
        )
        .subscribe()
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  private _toggleElement(viewName: ViewOptions) {
    if (this._templateRef) {
      viewName === this.appShowWhenView ? this._add() : this._remove();
      return;
    }

    viewName === this.appShowWhenView ? this._show() : this._hide();
  }

  private _add() {
    if (this._hasView) {
      return;
    }
    this._hasView = true;
    this._containerRef.createEmbeddedView(this._templateRef);
    this._cdr.markForCheck();
  }

  private _remove() {
    if (!this._hasView) {
      return;
    }
    this._hasView = false;
    this._containerRef.clear();
  }

  private _show() {
    this._renderer.removeAttribute(this._element.nativeElement, 'hidden');
  }

  private _hide() {
    this._renderer.setAttribute(this._element.nativeElement, 'hidden', 'true');
  }
}
