import { ColDef } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UiPanel } from '@models';
import { RosettaTableOptions } from '@shared/modules/rosetta-table/models/rosetta-table.model';
import { isNotNull } from '@utils';
import { filter, map } from 'rxjs';
import { RosettaGridService } from '../services/rosetta-grid.service';

@Component({
  selector: 'app-rosetta-grid',
  templateUrl: './rosetta-grid.component.html',
  styleUrls: ['./rosetta-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RosettaGridComponent {
  constructor(private _gridService: RosettaGridService) {}

  uiPanel = UiPanel;

  data$ = this._gridService.gridViewGeneration$.pipe(
    filter(isNotNull),
    map(result => result.gridViewReport)
  );

  options: RosettaTableOptions = {
    filter: true,
    suppressRowHoverHighlight: true,
  };

  defaultColDef: ColDef = {
    flex: 1,
  };

  cols: ColDef[] = [
    {
      headerName: 'Namespace',
      field: 'namespace',
      valueGetter: 'data.namespace',
      initialSort: 'asc',
    },
    {
      headerName: 'Name',
      field: 'name',
      valueGetter: 'data.name',
    },
    {
      headerName: 'Category',
      field: 'type',
      valueGetter: 'data.type',
    },
    {
      headerName: 'Description',
      field: 'definition',
      valueGetter: 'data.definition',
      sortable: false,
      tooltipField: 'definition',
    },
  ];
}
