<app-rosetta-menu>
  <app-text-input [label]="inputPlaceholder" [(value)]="filterString" />
  <div class="filtered-list__static-content">
    <ng-container [ngTemplateOutlet]="staticContent" />
  </div>
  <div class="filtered-list__item-wrapper" [ngStyle]="itemWrapperStyles">
    <div
      class="filtered-list__item"
      *ngFor="let datum of data | filter: filterString : filterFunc"
    >
      <ng-container
        *ngTemplateOutlet="template; context: { $implicit: datum }"
      />
    </div>
  </div>
</app-rosetta-menu>
