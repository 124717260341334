import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { InternalLink } from '@configs';
import { Store } from '@ngrx/store';
import { AuthActions } from '@store/.';
import { AuthSelectors } from '@store/selectors';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'v2-style-wrapper',
  },
})
export class LoginPageComponent {
  constructor(
    private _fb: UntypedFormBuilder,
    private _store: Store
  ) {}

  readonly internalLink = InternalLink;

  hidePassword = true;
  isLoggingIn$ = this._store.select(AuthSelectors.isLoggingIn);

  loginForm = this._fb.group({
    username: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  login() {
    if (this.loginForm.invalid) {
      return;
    }

    this._store.dispatch(
      AuthActions.login({
        userCredentials: {
          userName: this.loginForm.value.username,
          password: this.loginForm.value.password,
        },
      })
    );
  }
}
