import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit,
} from '@angular/core';
import { NotificationService } from '@core/modules/snack-bar';
import { UpdateService } from '@core/services/update.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        height: 100%;
      }
    `,
  ],
})
export class AppComponent implements OnInit {
  constructor(
    private _notify: NotificationService,
    private _updateService: UpdateService
  ) {}

  ngOnInit() {
    this._updateService.init();
  }

  @HostListener('dragover', ['$event'])
  drag(event: DragEvent) {
    event.preventDefault();
  }

  @HostListener('drop', ['$event'])
  drop(event: DragEvent) {
    this._notify.showWarning({ message: 'Unable to drop file here.' });
    event.preventDefault();
  }
}
