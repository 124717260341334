import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TreeApiService } from '@core/services';
import {
  NameTypeToggleState,
  ROSETTA_TREE_LEGEND,
  RosettaClassDetails,
  RosettaClassHierarchy,
  RosettaClassHierarchyDialog,
  TreeNode,
  TreeStructure,
} from '@models';
import { Store } from '@ngrx/store';
import { InOutAnimation } from '@shared';
import { TaskSelectors } from '@store/selectors';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import { GraphicalNavigatorStateService } from '@workspace-design/graphical/services/graphical-navigator-state.service';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  first,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  Subscription,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';

import { RosettaGraphicalHierarchyComponent } from '../rosetta-graphical-hierarchy/rosetta-graphical-hierarchy.component';

@Component({
  selector: 'app-rosetta-graphical-navigator',
  templateUrl: './rosetta-graphical-navigator.component.html',
  styleUrls: ['./rosetta-graphical-navigator.component.scss'],
  animations: [InOutAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RosettaGraphicalNavigatorComponent implements OnInit, OnDestroy {
  constructor(
    private _store: Store,
    private _api: TreeApiService,
    private _dialog: MatDialog,
    private _stateService: GraphicalNavigatorStateService
  ) {}

  readonly nameTypeState = NameTypeToggleState;

  rosettaTree?: Observable<TreeStructure>;
  classDetails?: Observable<Partial<RosettaClassDetails>>;
  selectedRosettaPath?: string;
  tooltip?: string;
  rosettaLegend = ROSETTA_TREE_LEGEND;

  private _sub = new Subscription();

  update$ = new BehaviorSubject<void>(undefined);
  workspaceReady$ = this._store.select(WorkspaceSelectors.isConnectionReady);

  workspaceInfo$ = this._store
    .select(WorkspaceSelectors.selectWorkspaceInfo)
    .pipe(
      first(isNotNull),
      shareReplay(1),
      takeWhile(() => !this._sub.closed)
    );

  loadClassList$ = this.workspaceInfo$.pipe(
    switchMap(({ owner, id }) =>
      this.update$.pipe(
        first(),
        // The delay is a workaround for the fact that the workspace
        // ready status doesn't mean the class list is ready. The actual
        // task status we need is "rootTypeCache" ready but this is unreliable
        delay(500),
        switchMap(() =>
          this._api
            .getClassList(owner, id.name)
            .pipe(catchError(() => of(null)))
        ),
        tap(
          classList =>
            !this._stateService.initialClass &&
            classList &&
            this._stateService.setInitialClass(
              classList.rootClassNames[classList.rootClassNames.length - 1]
            )
        )
      )
    )
  );

  hasErrors$ = this._store
    .select(TaskSelectors.hasErrors)
    .pipe(distinctUntilChanged());

  canRenderTree$ = this.loadClassList$.pipe(
    startWith(true),
    map(classList => {
      if (classList && typeof classList === 'object') {
        this.buildTree(this._stateService.initialClass);
      }
      return !!classList;
    }),
    switchMap(hasClassList =>
      this.hasErrors$.pipe(
        first(),
        map(hasErrors => hasClassList || !hasErrors)
      )
    ),
    shareReplay(1)
  );

  showToolbar$ = this.workspaceReady$.pipe(
    switchMap(isReady => (isReady ? this.canRenderTree$ : of(isReady)))
  );

  nameTypeToggleState$ = new BehaviorSubject<NameTypeToggleState>(
    NameTypeToggleState.name
  );
  nameTypeToggleFunc$ = this.nameTypeToggleState$.pipe(
    map(state =>
      state === NameTypeToggleState.name
        ? this.treeNodeTextName
        : this.treeNodeTextType
    )
  );

  ngOnInit() {
    this.nameTypeToggleState$.next(this._stateService.nameTypeToggle);

    this._sub.add(
      this._store
        .select(WorkspaceSelectors.getWorkspaceTaskStatus)
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe(() => this.update$.next())
    );
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }

  treeNodeTextName(node: any) {
    return node.name.substr(node.name.lastIndexOf('.') + 1);
  }

  treeNodeTextType(node: any) {
    return node.type;
  }

  getClassData(dataType: string) {
    return this.getData('getClassData', dataType);
  }

  getTreeData(dataType: string): Observable<TreeStructure> {
    return this.getData('getTreeData', dataType);
  }

  getClassHierarchyData(dataType: string): Observable<RosettaClassHierarchy> {
    return this.getData('getClassHierarchyData', dataType);
  }

  getData(
    typeName: 'getClassData',
    dataType: string
  ): Observable<RosettaClassDetails>;
  getData(typeName: 'getTreeData', dataType: string): Observable<TreeStructure>;
  getData(
    typeName: 'getClassHierarchyData',
    dataType: string
  ): Observable<RosettaClassHierarchy>;
  getData(typeName: string, dataType: string) {
    return this.workspaceInfo$.pipe(
      switchMap(info =>
        (this._api as any)[typeName](info.owner, info.id.name, dataType)
      )
    );
  }

  buildTree(rootClass: string | undefined) {
    if (!rootClass || !this._stateService.selectedClass?.className) {
      return;
    }
    this.rosettaTree = this.getTreeData(rootClass);
    this.selectedRosettaPath = undefined;
    if (this._stateService.selectedClass?.children) {
      this.classDetails = this.getClassData(
        this._stateService.selectedClass?.className
      );
    } else {
      const node: TreeNode = {
        data: {
          type: this._stateService.selectedClass.className,
          hasChildren: false,
          name: undefined,
          children: [],
          attributes: {},
          uri: undefined,
          namespace: undefined,
        },
        parent: undefined,
      };
      this.classTreeSelected(node);
    }
  }

  buildPath(node: TreeNode): string | undefined {
    if (!node?.data?.name) {
      return undefined;
    }
    const name = node.data.name.substring(node.data.name.indexOf('.') + 1);
    return node.parent ? this.buildPath(node.parent) + ' -> ' + name : name;
  }

  classTreeSelected(node: TreeNode) {
    this.selectedRosettaPath = this.buildPath(node);
    this._stateService.selectedClass = {
      className: node.data.type,
      children: node.data.hasChildren,
    };
    if (node.data.hasChildren) {
      this.classDetails = this.getClassData(node.data.type).pipe(
        catchError(() =>
          of({
            className: 'Error',
            definition: 'No data available due to an error in the model',
          })
        )
      );
    } else {
      this.classDetails = this._createPrimitivePartial(node.data.type);
    }
  }

  openHierarchyDialog(type: string) {
    this.getClassHierarchyData(type)
      .pipe(first())
      .subscribe(data => {
        this.hierarchyDialog(data, type);
      });
  }

  reset() {
    this._stateService.resetOnSearch(this._stateService.initialClass);
    this.buildTree(this._stateService.initialClass);
  }

  hierarchyDialog(classHierarchy: RosettaClassHierarchy, highlighted: string) {
    this._dialog.open<
      RosettaGraphicalHierarchyComponent,
      RosettaClassHierarchyDialog
    >(
      RosettaGraphicalHierarchyComponent,
      RosettaGraphicalHierarchyComponent.options({
        classHierarchy,
        highlighted,
      })
    );
  }

  toggleNameType($event: boolean) {
    const state = $event ? NameTypeToggleState.type : NameTypeToggleState.name;
    this._stateService.nameTypeToggle = state;
    this.nameTypeToggleState$.next(state);
    this.reset();
  }

  private _createPrimitivePartial(
    type: string
  ): Observable<Partial<RosettaClassDetails>> {
    return of({
      className: type,
      definition: 'No data',
    });
  }
}
