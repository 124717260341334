import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { catchError, ignoreElements, Observable, of } from 'rxjs';

@Component({
  selector: 'app-async-container',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: { class: 'stream-container' },
  template: `
    <ng-container
      *ngIf="{ value: stream$ | async, error: streamError$ | async } as data"
      [ngSwitch]="spinnerOnLoading"
    >
      <ng-container *ngSwitchCase="true">
        <ng-container *ngIf="data.value; else loading">
          <ng-container
            *ngTemplateOutlet="mainTemplate; context: { $implicit: data.value }"
          />
        </ng-container>

        <ng-template #loading>
          <ng-container *ngIf="!data.error">
            <app-loading-spinner [flexCentre]="true" />
          </ng-container>

          <ng-container *ngIf="data.error">
            <ng-container
              *ngTemplateOutlet="
                errorTemplate;
                context: { $implicit: data.error }
              "
            />
          </ng-container>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="false">
        <ng-container *ngIf="!data.error; else error">
          <ng-container
            *ngTemplateOutlet="mainTemplate; context: { $implicit: data.value }"
          />
        </ng-container>

        <ng-template #error>
          <ng-container
            *ngTemplateOutlet="
              errorTemplate;
              context: { $implicit: data.error }
            "
          />
        </ng-template>
      </ng-container>
    </ng-container>
  `,
})
export class AsyncContainerComponent<TData = unknown, TError = unknown> {
  @ContentChild('mainTemplate') mainTemplate!: TemplateRef<{
    $implicit: TData;
  }>;
  @ContentChild('errorTemplate') errorTemplate!: TemplateRef<{
    $implicit: TError;
  }>;

  @Input() set stream(value$: Observable<TData>) {
    if (value$) {
      this.stream$ = value$;
      this.streamError$ = this.stream$.pipe(
        ignoreElements(),
        catchError(err => of(err))
      );
    }
  }

  @Input()
  spinnerOnLoading = true;

  stream$?: Observable<TData>;
  streamError$?: Observable<TError>;
}
