import { ChangeDetectionStrategy, Component } from '@angular/core';
import { COLOR_WHITE } from '@configs';
import { HEX } from '@models';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { Store } from '@ngrx/store';
import { SlideInAnimation } from '@shared/animations';
import { contrast } from '@utils';
import { tap } from 'rxjs';

@Component({
  selector: 'app-model-options',
  templateUrl: './model-options.component.html',
  styleUrls: ['./model-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [SlideInAnimation],
})
export class ModelOptionsComponent {
  constructor(private _store: Store) {}

  modelStyle: Record<string, string> = {};

  currentModel$ = this._store
    .select(WorkspaceSelectors.selectCurrentWorkspaceModel)
    .pipe(tap(model => this._setColour(model?.primaryColor)));

  private _setColour(color: HEX = COLOR_WHITE) {
    this.modelStyle = {
      background: color,
      color: contrast(color),
    };
  }
}
