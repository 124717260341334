import { inject } from '@angular/core';
import { RosettaNavigationService } from '@core/services/rosetta-navigation.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED } from '@ngrx/router-store';
import { AppActions } from '@store/.';
import { map, tap } from 'rxjs';

import * as RouterActions from './router.actions';

export const gotoWorkspaceManager = createEffect(
  (actions$ = inject(Actions), navigate = inject(RosettaNavigationService)) => {
    return actions$.pipe(
      ofType(RouterActions.gotoWorkspaceManager),
      tap(() => {
        navigate.workspaceManager();
      })
    );
  },
  { dispatch: false, functional: true }
);

export const gotoWorkspace = createEffect(
  (actions$ = inject(Actions), navigate = inject(RosettaNavigationService)) => {
    return actions$.pipe(
      ofType(RouterActions.gotoWorkspace),
      tap(({ workspaceId: { name } }) => {
        navigate.to(['workspaces', name], {
          queryParams: {
            view: 'textual',
            topPanel: true,
            bottomPanel: false,
          },
        });
      })
    );
  },
  { dispatch: false, functional: true }
);

export const goto = createEffect(
  (actions$ = inject(Actions), navigate = inject(RosettaNavigationService)) => {
    return actions$.pipe(
      ofType(RouterActions.goto),
      tap(({ path, extras }) => {
        navigate.to(path, extras);
      })
    );
  },
  { dispatch: false, functional: true }
);

export const openBottomPanel = createEffect(
  (actions$ = inject(Actions), navigate = inject(RosettaNavigationService)) => {
    return actions$.pipe(
      ofType(RouterActions.openBottomPanel),
      tap(({ path }) => {
        navigate.updateBottomPanel(path);
      })
    );
  },
  { dispatch: false, functional: true }
);

export const gotoProjectAdmin = createEffect(
  (actions$ = inject(Actions), navigate = inject(RosettaNavigationService)) => {
    return actions$.pipe(
      ofType(RouterActions.gotoProjectAdmin),
      tap(({ modelId }) => {
        navigate.gotoProjectAdmin(modelId);
      })
    );
  },
  { dispatch: false, functional: true }
);

export const routerNavigated = createEffect(
  (actions$ = inject(Actions)) => {
    return actions$.pipe(
      ofType(ROUTER_NAVIGATED),
      map(() => AppActions.closeAllOverlays())
    );
  },
  { dispatch: true, functional: true }
);
