import { ColDef } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FilterPipeFunc } from '@models';
import { SlideInAnimation } from '@shared/animations';
import { RosettaTableComponent } from '@shared/modules/rosetta-table/rosetta-table.component';
import { ensureArray } from '@utils';
import { BehaviorSubject, first, map, switchMap } from 'rxjs';
import { COLUMN_TYPE_IGNORE } from '../../rosetta-table.helpers';

@Component({
  selector: 'app-table-column-menu',
  templateUrl: './table-column-menu.component.html',
  styleUrls: ['./table-column-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [SlideInAnimation],
  host: {
    class: 'table-column-menu',
  },
})
export class TableColumnMenuComponent implements OnInit {
  constructor(private _parent: RosettaTableComponent) {}

  @Input() disabled = false;

  canReorder = true;
  canHide = true;
  columnFilter = '';

  filterFunc: FilterPipeFunc<ColDef> = search => {
    const regex = new RegExp(search, 'gi');
    return item => !item?.pinned && (item['headerName'] || '').match(regex);
  };

  columns$ = new BehaviorSubject<ColDef[]>([]);
  editableCols$ = this.columns$.pipe(
    map(cols => cols.filter(col => !col.pinned))
  );
  numberHiddenColumns$ = this.editableCols$.pipe(
    map(cols => cols.filter(col => col.hide).length)
  );
  isAllSelected$ = this.editableCols$.pipe(
    map(cols => cols.every(col => !col.hide))
  );
  isAnySelected$ = this.editableCols$.pipe(
    switchMap(cols =>
      this.numberHiddenColumns$.pipe(
        map(hiddenCol => hiddenCol > 0 && hiddenCol < cols.length)
      )
    )
  );

  ngOnInit(): void {
    this._parent.gridReady
      .pipe(first())
      .subscribe(() => this._initAfterGridReady());
  }

  columnToggle({ colId }: ColDef): void {
    const cols = this.columns$.value;
    const col = cols.find(c => c.colId === colId);

    if (!this.canHide || !col) {
      return;
    }

    col.hide = !col?.hide;

    this._setColumns(cols);
    this._parent.gridApi.setColumnDefs(cols);
  }

  masterToggle(): void {
    const cols = this.columns$.value;
    const editableCols = cols.filter(col => !col.pinned);
    const showHide =
      editableCols.length !== editableCols.filter(col => col.hide).length;
    editableCols.forEach(c => (c.hide = showHide));
    this._setColumns(cols);
    this._parent.gridApi.setColumnDefs(cols);
  }

  private _initAfterGridReady(): void {
    this.canReorder = this._parent.options.canReorder || false;
    this.canHide = this._parent.options.canHideColumns || false;
    this._updateColumns();
    this._addEventListener();
  }

  private _addEventListener(): void {
    this._parent.gridApi.addEventListener(
      'newColumnsLoaded',
      this._updateColumns.bind(this)
    );
  }

  private _updateColumns(): void {
    this._setColumns(this._parent.gridApi.getColumnDefs() || []);
  }

  private _setColumns(cols: ColDef[]): void {
    this.columns$.next(
      cols.filter(col => !ensureArray(col.type).includes(COLUMN_TYPE_IGNORE))
    );
  }
}
