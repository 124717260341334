import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-data-storage-warning',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <fa-icon class="mr-8 theme-color-highlight" size="lg" icon="info-circle" />
    <small>
      <ng-container *ngIf="serverStorage; else localStorage">
        Data will be stored securely in your workspace, avoid uploading
        sensitive information.
      </ng-container>

      <ng-template #localStorage>
        Data will be stored to your local device.
      </ng-template>
    </small>
  `,
  styles: [
    `
      :host {
        display: inline-flex;
        align-items: center;
      }
    `,
  ],
})
export class DataStorageWarningComponent {
  @Input() serverStorage = true;
}
