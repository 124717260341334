<h3>
  <span>{{ heading }}</span>
  <span>
    <app-chip-label
      *ngFor="let group of groups$ | async"
      [value]="group.total"
      [color]="group.color"
      [useLightForeground]="group.useLightTextColor"
    />
  </span>
</h3>

<ng-container *ngFor="let group of groups$ | async; let idx = index">
  <section *ngIf="groupHasItems(group)" [style.--border-color]="group.color">
    <h4 matRipple (click)="openGroup[idx] = !openGroup[idx]">
      <span>{{ group.name }}</span>
      <fa-icon
        size="sm"
        [icon]="openGroup[idx] ? 'chevron-right' : 'chevron-down'"
      />
    </h4>

    <div [@openCloseAnimation]="openGroup[idx] ? 'closed' : 'open'">
      <div class="group" *ngFor="let section of group.sections">
        <h5>{{ section.category }}</h5>
        <ul>
          <li *ngFor="let item of section.items" (click)="onClick(item)">
            <ng-container
              *ngTemplateOutlet="
                templates | diagnosticTemplate: group.def;
                context: { $implicit: item }
              "
            />
          </li>
        </ul>
      </div>
    </div>
  </section>
</ng-container>
