import { Directive, ElementRef } from '@angular/core';
@Directive({ selector: '[appOffsetTop]' })
export class OffsetTopDirective {
  constructor(private _el: ElementRef) {}
  get offsetTop(): number {
    return this._el.nativeElement.offsetTop;
  }
  get isSelected(): boolean {
    if (!this._el.nativeElement || !this._el.nativeElement.classList) {
      return false;
    }
    if (this._el.nativeElement.classList.contains('selected')) {
      return true;
    }
    return false;
  }
}
