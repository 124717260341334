import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { getDialogOptions } from '@configs';
import { NotificationService } from '@core/modules/snack-bar';
import { WorkspaceApiService, WorkspaceService } from '@core/services';
import {
  ComponentState,
  DialogComponent,
  UpdateWorkspaceResponse,
} from '@models';
import { Store } from '@ngrx/store';
import * as WorkspaceActions from '@store/workspace/actions';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import {
  exhaustMap,
  finalize,
  first,
  of,
  shareReplay,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throwError,
} from 'rxjs';

@Component({
  selector: 'app-upgrade-dialog',
  templateUrl: './upgrade-dialog.component.html',
  styleUrls: ['./upgrade-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradeDialogComponent implements DialogComponent, OnDestroy {
  constructor(
    private _store: Store,
    private _ws: WorkspaceService,
    private _wsApi: WorkspaceApiService,
    private _notify: NotificationService,
    private _dialog: MatDialog
  ) {}

  state = new ComponentState();
  destroySubject = new Subject<void>();

  workspaceInfo$ = this._store
    .select(WorkspaceSelectors.selectWorkspaceInfo)
    .pipe(
      first(isNotNull),
      tap(() => {
        this.state.next('loading');
      })
    );

  releaseNotes$ = this.workspaceInfo$.pipe(
    exhaustMap(info =>
      this._wsApi.getReleaseNotes(info.modelId, info.modelVersion).pipe(
        finalize(() => this.state.next('loaded')),
        takeUntil(this.destroySubject)
      )
    ),
    shareReplay(1)
  );

  static options(): MatDialogConfig {
    return getDialogOptions('md', {
      disableClose: true,
    });
  }

  ngOnDestroy() {
    this.destroySubject.next();
    this.destroySubject.complete();
  }

  upgrade() {
    this.state.next('loading');
    this.workspaceInfo$
      .pipe(
        switchMap(info => this._wsApi.updateWorkspace(info.canUpgrade)),
        switchMap(response =>
          response.success
            ? of(response)
            : throwError(() => new Error(response.message))
        ),
        finalize(() => this.state.next('loaded'))
      )
      .subscribe({
        next: response => this._successHandler(response),
        error: e => this._errorHandler(e),
      });
  }

  private _successHandler({ workspace }: UpdateWorkspaceResponse) {
    if (!workspace) {
      return;
    }
    this._ws.load(workspace.info.id);
    this._store.dispatch(WorkspaceActions.loadWorkspaces());
    this._notify.showSuccess({
      message: 'Workspace upgraded successfully!',
    });
    this.state.next('success');
    this._dialog.closeAll();
  }

  private _errorHandler(e: ErrorEvent) {
    this.state.next('error');
    this._notify.showError({
      message: e.message,
    });
  }
}
