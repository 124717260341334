import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FontsModule } from '@app/fonts/fonts.module';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Component({
  standalone: true,
  selector: 'app-action-panel',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './action-panel.component.html',
  styles: [
    `
      :host {
        display: flex;
        padding: 12px 0;

        > button {
          border: 1px solid;
          margin: 0 12px;
        }
      }

      .content {
        flex: 1;
        display: flex;
        align-items: center;
      }
    `,
  ],
  host: {
    class: 'theme-bg',
  },
  imports: [CommonModule, FontsModule, MatTooltipModule, MatButtonModule],
})
export class ActionPanelComponent {
  @Input() actionIcon!: IconProp;
  @Input() tooltip!: string;
  @Output() action = new EventEmitter<void>();
}
