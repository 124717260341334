import { Injectable } from '@angular/core';
import { Task, TaskNotification } from '@models';
import { Store } from '@ngrx/store';
import { WebsocketActions } from '@store/.';
import { TaskListeners } from '../task-listener.models';

@Injectable()
export class TranslateIngestRunListenerService implements TaskListeners {
  constructor(private _store: Store) {}

  action(task: TaskNotification) {
    if (task.task !== Task.TranslateIngestRun) {
      return;
    }

    if (task.status === 'STARTED') {
      this._store.dispatch(
        WebsocketActions.translateIngestionRunStarted({ payload: task.payload })
      );
    }

    if (task.status === 'FINISHED') {
      this._store.dispatch(
        WebsocketActions.translateIngestionRunFinished({
          payload: task.payload,
        })
      );
    }

    if (task.status === 'ERROR') {
      this._store.dispatch(
        WebsocketActions.translateIngestionRunErrored({ payload: task.payload })
      );
    }

    if (task.status === 'CANCELLED') {
      this._store.dispatch(WebsocketActions.allTasksCancelled());
    }
  }
}
