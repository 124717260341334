import { PipeTransform, Pipe } from '@angular/core';
import { sortBy } from 'lodash-es';

@Pipe({
  standalone: true,
  name: 'sortBy',
})
export class SortByPipe implements PipeTransform {
  transform<T>(collection: T[], ...iteratees: any) {
    return sortBy<T[]>(collection, iteratees) as T[];
  }
}
