import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Self,
  SimpleChanges,
} from '@angular/core';
import { NgScrollbar } from 'ngx-scrollbar';
import { ScrollToQueueService } from '@features/workspace/modules/design/regulation/services/scroll-to-queue.service';
import { SmoothScrollToElementOptions } from 'ngx-scrollbar/smooth-scroll';

@Directive({
  selector: 'ng-scrollbar[appScrollToElement]',
})
export class ScrollToElementDirective implements AfterViewInit, OnChanges {
  constructor(
    @Self() private _scrollbar: NgScrollbar,
    private _el: ElementRef,
    private _scrollQueueService: ScrollToQueueService
  ) {}

  @Input('appScrollToElement') scrollToQuerySelector?: string;
  @Input() scrollOptions?: SmoothScrollToElementOptions;
  @Input() skipScrollWhenInView = true;

  ngAfterViewInit(): void {
    if (this.scrollToQuerySelector) {
      this.scrollToElement();
    }
  }

  ngOnChanges({ scrollToQuerySelector }: SimpleChanges): void {
    if (scrollToQuerySelector) {
      this.scrollToElement();
    }
  }

  scrollToElement(): void {
    const element = this._el.nativeElement.querySelector(
      this.scrollToQuerySelector
    );
    this._enqueueScrollTo(element);
  }

  private _enqueueScrollTo(element: HTMLElement): void {
    if (this._scrollbar) {
      this._scrollQueueService.enqueue({
        scrollbar: this._scrollbar,
        element: element,
        scrollOptions: this.scrollOptions,
        skipScrollWhenInView: this.skipScrollWhenInView,
      });
    }
  }
}
