import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  TemplateRef,
} from '@angular/core';

@Component({
  selector: 'app-feature-toggle-wrapper',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `<div [matTooltip]="tooltip" matTooltipShowDelay="500">
    <ng-container [ngTemplateOutlet]="template" />
    <p class="badge" [innerHTML]="label"></p>
  </div>`,
  styles: [
    `
      @use 'partials/variables' as var;

      :host {
        --theme-color: #c4297d;

        display: block;
        position: relative;
        box-shadow: inset 0 0 0 1px var(--theme-color);

        &.full-height,
        > div {
          height: 100%;
        }
      }

      .badge {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.25rem;
        margin: 0;
        line-height: 0.75rem;
        font-size: 0.75rem;
        color: white;
        border-radius: 0 0 0 var.$border-radius-sm;
        background-color: var(--theme-color);
      }

      :host-context(.dark-theme) {
        --theme-color: #c9d646;

        .badge {
          background-color: var(--theme-color);
          color: rgba(black, 0.85);
        }
      }
    `,
  ],
  host: {},
})
export class FeatureToggleWrapperComponent {
  @Input() template!: TemplateRef<any>;
  @Input() inline: 'inline-block' | 'block' = 'block';
  @Input() label?: string;
  @Input() tooltip = '';
  @Input() hasBorder = true;
  @Input() fullHeight = false;

  @HostBinding('class.full-height') get class() {
    return this.fullHeight;
  }
}
