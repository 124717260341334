<ng-template #noDataView>
  <div class="warning-overlay">
    <div>
      <fa-icon icon="exclamation-triangle" size="2x" />
      <h3>
        Unable to display the Graphical Navigator due to an error in your model.
      </h3>
    </div>
  </div>
</ng-template>

<ng-container *appShowSpinner="workspaceReady$ | async">
  <ng-container *ngIf="canRenderTree$ | async; else noDataView">
    <p class="warning-banner" [@inOutAnimation] *ngIf="hasErrors$ | async">
      <fa-icon icon="exclamation-triangle" />
      <span>Limited functionality while your model has errors</span>
    </p>

    <app-collapsible-panel-group>
      <app-collapsible-panel ratio="2.5" [canClose]="false">
        <app-collapsible-panel-header contentAlign="end">
          <ng-container *ngIf="showToolbar$ | async">
            <app-rosetta-class-search
              [searchData]="loadClassList$ | async"
              (selectedItem)="buildTree($event)"
            />

            <mat-divider vertical="true" />

            <button
              mat-icon-button
              matTooltip="Reset"
              (click)="reset()"
              class="reset-button"
            >
              <fa-icon icon="redo" size="sm" />
            </button>

            <ng-container *ngIf="nameTypeToggleState$ | async as state">
              <span
                class="name-type-toggle"
                [class.selected]="state === nameTypeState.name"
                (click)="toggleNameType(false)"
                >Name</span
              >
              <mat-slide-toggle
                color="primary"
                [checked]="state === nameTypeState.type"
                (change)="toggleNameType($event.checked)"
              />
              <span
                class="name-type-toggle"
                [class.selected]="state === nameTypeState.type"
                (click)="toggleNameType(true)"
                >Type</span
              >
            </ng-container>
          </ng-container>
        </app-collapsible-panel-header>
        <app-collapsible-panel-body>
          <app-rosetta-tree
            [treeData]="rosettaTree | async"
            [nodeDecorators]="rosettaLegend"
            [nodeTextDecorator]="nameTypeToggleFunc$ | async"
            [highlighted]="[]"
            (nodeSelected)="classTreeSelected($event)"
          />
        </app-collapsible-panel-body>
      </app-collapsible-panel>
      <app-collapsible-panel [canClose]="false">
        <app-collapsible-panel-header />
        <app-collapsible-panel-body>
          <app-rosetta-tree-node-attribute
            [rosettaPath]="selectedRosettaPath"
            [classDetails]="classDetails | async"
            (hierarchyItem)="openHierarchyDialog($event)"
          />
        </app-collapsible-panel-body>
      </app-collapsible-panel>
    </app-collapsible-panel-group>
  </ng-container>
</ng-container>
