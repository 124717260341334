import { COLOR_DARK, COLOR_WHITE } from '@configs';
import { HEX } from '@models';

export function useDarkForeground(hexColor: HEX): boolean {
  hexColor = hexColor?.toUpperCase() as HEX;

  if (!hexColor || !/^#[0-9A-F]{6}$/.test(hexColor)) {
    return true;
  }

  const colorCode = hexColor.replace('#', '');
  const r = parseInt(colorCode.substr(0, 2), 16);
  const g = parseInt(colorCode.substr(2, 2), 16);
  const b = parseInt(colorCode.substr(4, 2), 16);
  const yiq = (r * 299 + g * 587 + b * 114) / 1000;
  return yiq >= 128;
}

export function contrast(hexColor: HEX): HEX {
  return useDarkForeground(hexColor) ? COLOR_DARK : COLOR_WHITE;
}

// https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
export function stringToColour(str = ''): HEX {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour.toUpperCase() as HEX;
}

export function lightenDarkenColor(hexColor: HEX, magnitude: number): HEX {
  if (hexColor.length !== 7) {
    return hexColor;
  }
  const hexColorValue = hexColor.replace(`#`, ``);
  const decimalColor = parseInt(hexColorValue, 16);
  let r = (decimalColor >> 16) + magnitude;
  r > 255 && (r = 255);
  r < 0 && (r = 0);
  let g = (decimalColor & 0x0000ff) + magnitude;
  g > 255 && (g = 255);
  g < 0 && (g = 0);
  let b = ((decimalColor >> 8) & 0x00ff) + magnitude;
  b > 255 && (b = 255);
  b < 0 && (b = 0);
  return `#${(g | (b << 8) | (r << 16)).toString(16)}`;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function escapeRegExp(input: string | String) {
  const source =
    typeof input === 'string' || input instanceof String ? input : '';
  return source.replace(/[-[/\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

export function isEmptyString(str: string | null | undefined): str is '' {
  return (str?.trim() ?? '') === '';
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function capitalizeFirstLetter(str: string) {
  if (isEmptyString(str)) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function snakeCaseToTitleCase(str: string | undefined) {
  if (!isString(str) || isEmptyString(str)) {
    return '';
  }
  return str
    .split(/_|\s/)
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export const normalize = (str: string | undefined) =>
  str ? str.trim().toLowerCase() : '';

// https://stackoverflow.com/questions/36140368/java-renaming-output-file-if-name-already-exists-with-an-increment-taking-int
export const addNumericSuffixToMakeUnique = (
  name: string,
  existsCheck: (name: string) => boolean
) => {
  if (existsCheck(name)) {
    const matcher = /^(.*?)(\((\d+)\))?(?:\.[^.]*)?$/.exec(name);

    if (matcher) {
      const prefix = matcher[1];
      const last = matcher[3];

      let count = last ? parseInt(last) : 0;

      do {
        count++;
        name = `${prefix.trim()} (${count})`;
      } while (existsCheck(name));
    }
  }

  return name;
};
