import { NgModule } from '@angular/core';
import { ReleaseNotesViewerDialogComponent } from '@core/modules/release-notes/release-notes-viewer-dialog/release-notes-viewer-dialog.component';
import { TextBadgeModule } from '@shared/modules';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [ReleaseNotesViewerDialogComponent],
  imports: [SharedModule, TextBadgeModule],
})
export class ReleaseNotesModule {}
