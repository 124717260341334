import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appScrollable]',
})
export class ScrollableDirective {
  constructor(private _el: ElementRef) {}
  set scrollTop(value: number) {
    this._el.nativeElement.scrollTop = value;
  }
  get scrollTop() {
    return this._el.nativeElement.scrollTop;
  }
  get offsetHeight() {
    return this._el.nativeElement.offsetHeight;
  }
  focus() {
    this._el.nativeElement.focus();
  }
}
