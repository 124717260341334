import {
  AgGridEvent,
  ColDef,
  RowNode,
  ValueGetterFunc,
} from '@ag-grid-community/core';
import { EN_DASH } from '@configs';
import * as transform from '@shared/modules/transform/models/data-viewer';
import { SampleCellState } from '@shared/modules/transform/models/sample-cell-state.enum';

export const getSummaryColDefs = (): ColDef<transform.DataViewerRow>[] => [
  summaryValidColDef(),
  summaryWarningColDef(),
  summaryErrorColDef(),
];

export const updateSummary = ({
  api,
  columnApi,
  context,
}: AgGridEvent<transform.DataViewerRow, transform.DataViewerContext>) => {
  const cells: transform.DataViewerCell[] = [];
  api.getModel().forEachNode((rowNode: RowNode<transform.DataViewerRow>) => {
    // Excludes rows which are filtered out
    if (rowNode.displayed) {
      const rowCells = [...(rowNode.data?.cellMap.values() || [])];
      cells.push(...rowCells);
    }
  });

  const shownMap = cells.reduce<transform.DataViewerSummary>((acc, cell) => {
    // Ignore all cells with no state or if they are in a hidden column
    if (cell.hidden) {
      return acc;
    }

    // When showChanges is active exclude cells which are in columns with no changes
    if (context.showChanges && !cell.hasChanges) {
      return acc;
    }

    switch (cell.cellState) {
      case SampleCellState.Valid:
      case SampleCellState.Updated:
        acc.valid++;
        break;
      case SampleCellState.New:
      case SampleCellState.Removed:
        acc.warning++;
        break;
      default:
        acc.error++;
        break;
    }

    if (acc.cellStates[cell.cellState] === undefined) {
      acc.cellStates[cell.cellState] = 0;
    }

    acc.cellStates[cell.cellState]++;
    return acc;
  }, transform.getDefaultDataViewerSummary());

  context.summarySubject.next({
    ...shownMap,
    rows: api.getDisplayedRowCount(),
    columns: columnApi.getDisplayedCenterColumns().length,
  });
};

const summaryValueGetter = (
  ...cellStates: SampleCellState[]
): ValueGetterFunc<transform.DataViewerRow> => {
  return (params): number | string => {
    // This is here for type safety
    const context = params.context as transform.DataViewerContext;
    return (
      params.data.cellList.filter(cell => {
        if (cell?.hidden || (context.showChanges && !cell?.hasChanges)) {
          return false;
        }
        return cellStates.includes(cell.cellState);
      }).length || EN_DASH
    );
  };
};

const summaryValidColDef = (): ColDef<transform.DataViewerRow> => ({
  colId: 'valid',
  type: 'summaryColumn',
  headerComponentParams: {
    iconProp: 'check-circle',
    iconClass: 'theme-color-success',
  },
  valueGetter: summaryValueGetter(
    SampleCellState.Valid,
    SampleCellState.Updated
  ),
});

const summaryWarningColDef = (): ColDef<transform.DataViewerRow> => ({
  colId: 'warning',
  type: 'summaryColumn',
  headerComponentParams: {
    iconProp: 'exclamation-circle',
    iconClass: 'theme-color-yellow-alt',
  },
  valueGetter: summaryValueGetter(SampleCellState.Removed, SampleCellState.New),
});

const summaryErrorColDef = (): ColDef<transform.DataViewerRow> => ({
  colId: 'error',
  type: 'summaryColumn',
  headerComponentParams: {
    iconProp: 'exclamation-triangle',
    iconClass: 'theme-color-warn',
  },
  valueGetter: summaryValueGetter(SampleCellState.Diff),
});
