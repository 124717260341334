import { TRANSLATE_1_5_ACCESS } from '@configs';
import { FeatureTab } from '@models';

export const Translate_1_5Tab: FeatureTab = {
  icon: 'exchange-alt',
  label: 'Ingest',
  name: 'translate_1_5',
  url: 'translate-1-5',
  groupId: 'pipeline',
  params: {
    labsFeature: 'alpha',
    ability: TRANSLATE_1_5_ACCESS,
  },
};
