import { inject, Pipe, PipeTransform } from '@angular/core';
import { WorkspaceInfo } from '@models';
import { Store } from '@ngrx/store';
import { WorkspaceSelectors } from '@store/workspace/selectors';
import { isNotNull } from '@utils';
import { filter, map, Observable } from 'rxjs';

@Pipe({
  standalone: true,
  name: 'getWorkspaceInfo',
})
export class GetWorkspaceInfoPipe implements PipeTransform {
  private _store = inject(Store);

  transform<TProp extends keyof WorkspaceInfo>(
    prop: TProp
  ): Observable<WorkspaceInfo[TProp]> {
    return this._store.select(WorkspaceSelectors.selectWorkspaceInfo).pipe(
      filter(isNotNull),
      map(info => info[prop])
    );
  }
}
