import { Injectable } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BaseWorkspaceService } from '@core/services';
import { TaskService } from '@core/services/task.service';
import { JsonValidateResult, JsonValidatorSubmit, Task } from '@models';
import { TaskActions } from '@store/.';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject } from 'rxjs';
import { JsonValidation } from './json-validation';

@Injectable({
  providedIn: 'root',
})
export class JsonValidatorService extends BaseWorkspaceService {
  constructor(private _taskService: TaskService) {
    super();
    this.initWorkspaceObserver();
  }

  private _jsonValidations = new BehaviorSubject<JsonValidation[] | null>([]);
  status = this._taskService.getTaskStatus(Task.JsonValidation);

  jsonValidations$ = this._jsonValidations.asObservable();
  uploadErrorMessage: string | null = null;
  jsonValidateSubmit: JsonValidatorSubmit | null = null;
  classControl = new UntypedFormControl();

  onJsonValidateFinished(jsonValidateResult: JsonValidateResult) {
    this._jsonValidations.value
      ?.filter(j => j.fileName === jsonValidateResult.fileName)
      .forEach(j => j.finished(jsonValidateResult));
  }

  runJsonValidation(
    jsonValidateSubmit: JsonValidatorSubmit[],
    taskService: TaskService
  ): void {
    this.clear();
    this.jsonValidateSubmit = jsonValidateSubmit[0];

    this._store.dispatch(
      TaskActions.submitTask({
        data: {
          task: Task.JsonValidation,
          payload: cloneDeep(jsonValidateSubmit),
        },
      })
    );

    const jsonVals = jsonValidateSubmit.map(({ name }) => {
      return new JsonValidation(
        name,
        taskService.getSubTaskStatus(Task.JsonValidation, name)
      );
    });

    this._jsonValidations.next(jsonVals);
  }

  select(JsonValidations: JsonValidation[] | null): void {
    this._jsonValidations.next(JsonValidations);
  }

  clear(): void {
    this.uploadErrorMessage = null;
    this.jsonValidateSubmit = null;
    this.select(null);
  }

  clearAll(): void {
    this.clear();
    this.classControl.setValue(null);
  }

  protected _onWorkspaceSwitch(): void {
    this.clearAll;
  }
}
