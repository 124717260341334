import {
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  booleanAttribute,
} from '@angular/core';
import { ALPHA_ACCESS, BETA_ACCESS, UserAbilities } from '@configs';
import { AuthService } from '@core/services';
import { RenderTemplateDirective } from '@shared/directives/abstract-render-template.directive';
import { FeatureToggleWrapperComponent } from '@shared/modules/feature-toggle/feature-toggle-wrapper.component';
import { BehaviorSubject, Subscription, of, switchMap, takeWhile } from 'rxjs';

export type LabsFeatureType = 'alpha' | 'beta';

@Directive({
  selector: '[appLabsFeature]',
})
export class LabsFeatureDirective
  extends RenderTemplateDirective
  implements OnInit, OnChanges, OnDestroy
{
  constructor(private _authService: AuthService) {
    super();
  }

  @Input('appLabsFeature') type!: LabsFeatureType;
  @Input({ alias: 'appLabsFeatureVisible', transform: booleanAttribute })
  visible = true;
  @Input({ alias: 'appLabsFeatureFullHeight', transform: booleanAttribute })
  fullHeight = false;

  private _sub = new Subscription();
  private _visible$ = new BehaviorSubject(true);
  private _abilityMap = new Map<LabsFeatureType, UserAbilities>([
    ['alpha', ALPHA_ACCESS],
    ['beta', BETA_ACCESS],
  ]);

  ngOnInit(): void {
    this._sub.add(
      of(this._authService.has(this._abilityMap.get(this.type)))
        .pipe(
          takeWhile(hasBetaAccess => hasBetaAccess),
          switchMap(() => this._visible$)
        )
        .subscribe(condition => this._updateFeature(condition, this.type))
    );
  }

  ngOnChanges({ visible }: SimpleChanges): void {
    this._visible$.next(visible?.currentValue ?? true);
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  private _updateFeature(condition: boolean, type: LabsFeatureType) {
    const label = type === 'alpha' ? '&#945;' : '&#946;';
    const tooltip =
      type === 'alpha'
        ? 'Alpha Feature - This is still being tested so it might break the application.'
        : 'Beta Feature - This is still being tested so it might break the application.';

    this.render(
      condition,
      componentRef => {
        componentRef.setInput('template', this.templateRef);
        componentRef.setInput('inline', 'block');
        componentRef.setInput('label', label);
        componentRef.setInput('fullHeight', this.fullHeight);
        componentRef.setInput('tooltip', tooltip);
      },
      FeatureToggleWrapperComponent
    );
  }
}
