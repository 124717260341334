import { createAction, props } from '@ngrx/store';
import {
  IngestRunResult,
  IngestRunStart,
  TranslateGenerateAndCompileResult,
  TranslateGenerateAndCompileStart,
  TranslateIngestRunResult,
  TranslateIngestRunStart,
} from '@models';

export const translateIngestionRunStarted = createAction(
  '[WebSocket] Translate Ingestion Run Started',
  props<{ payload: TranslateIngestRunStart }>()
);
export const translateIngestionRunFinished = createAction(
  '[WebSocket] Translate Ingestion Run Finished',
  props<{ payload: TranslateIngestRunResult }>()
);
export const translateIngestionRunErrored = createAction(
  '[WebSocket] Translate Ingestion Run Errored',
  props<{ payload: TranslateIngestRunResult }>()
);
export const translateGenerateAndCompileStarted = createAction(
  '[WebSocket] Translate Generate And Compile Started',
  props<{ payload: TranslateGenerateAndCompileStart }>()
);
export const translateGenerateAndCompileFinished = createAction(
  '[WebSocket] Translate Generate And Compile Finished',
  props<{ payload: TranslateGenerateAndCompileResult }>()
);
export const ingestionTestRunStarted = createAction(
  '[WebSocket] Ingestion Test Run Started',
  props<{ payload: IngestRunStart }>()
);
export const ingestionTestRunFinished = createAction(
  '[WebSocket] Ingestion Test Run Finished',
  props<{ payload: IngestRunResult }>()
);
export const ingestionTestRunResultsSaved = createAction(
  '[WebSocket] Ingestion Test Run Results Saved',
  props<{ payload: IngestRunResult }>()
);
export const allTasksCancelled = createAction('[WebSocket] Tasks Cancelled');
