import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FontsModule } from '@app/fonts/fonts.module';
import { InputValueDirective } from '@shared/directives/input-value.directive';
import { FilterFieldComponent } from '@shared/modules/filter-field/filter-field.component';
import { StopPropagationModule } from '../stop-propagation/stop-propagation.module';

@NgModule({
  declarations: [FilterFieldComponent],
  imports: [
    CommonModule,
    FontsModule,
    MatInputModule,
    MatButtonModule,
    StopPropagationModule,
    InputValueDirective,
  ],
  exports: [FilterFieldComponent],
})
export class FilterFieldModule {}
