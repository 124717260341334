import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  ICellActions,
  ICellActionsButton,
} from '@shared/modules/rosetta-table/models/rosetta-table.model';

@Component({
  selector: 'app-action-cell',
  templateUrl: './action-cell.component.html',
  styleUrls: ['./action-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionCellComponent implements ICellRendererAngularComp {
  actions!: ICellActionsButton[];
  rowData: unknown;
  context!: any;

  agInit({ actions, data, context }: ICellRendererParams & ICellActions) {
    this.actions = actions;
    this.rowData = data;
    this.context = context;
  }

  refresh(): boolean {
    return false;
  }

  onActionClick(action: ICellActionsButton<any>) {
    if (action?.action) {
      action.action(this.rowData, this.context);
    }
  }
}
