import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams, SortDirection } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
} from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

/**
 * MAIN COMPONENT USE CASE
 * Display a specific icon for the column with the ability to sort
 *
 * DON'T SUPPORTED
 * - Icon header doesn't support column menus
 * - Column name (displayName)
 */

@Component({
  selector: 'app-icon-header',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ` <fa-icon [icon]="params.iconProp" [ngClass]="params.iconClass" />
    <i
      *ngIf="params.enableSorting && params.column.isSorting()"
      class="ag-icon"
      [ngClass]="sortClass"
    ></i>`,
  styles: [
    `
      :host {
        position: relative;
        text-align: center;
        display: block;
        width: 100%;
      }

      i {
        position: absolute;
        top: 0;
        right: 0;
      }
    `,
  ],
})
export class IconHeaderComponent implements IHeaderAngularComp {
  params!: IHeaderParams & { iconProp: IconProp; iconClass: string };
  sortClass?: string;

  agInit(params: IHeaderParams & { iconProp: IconProp; iconClass: string }) {
    this.params = params;

    params.column.addEventListener(
      'sortChanged',
      this.onSortChanged.bind(this)
    );

    this.onSortChanged();
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const column = this.params.column;
    let sortDirection: SortDirection = null;

    if (column.isSortNone()) {
      sortDirection = 'asc';
    }

    if (column.isSortAscending()) {
      sortDirection = 'desc';
    }

    if (column.isSortDescending()) {
      sortDirection = null;
    }

    this.onSortRequested(sortDirection, event);
  }

  onSortChanged() {
    if (this.params.column.isSortAscending()) {
      this.sortClass = 'ag-icon-asc';
    } else if (this.params.column.isSortDescending()) {
      this.sortClass = 'ag-icon-desc';
    }
  }

  onSortRequested(order: SortDirection, event: MouseEvent) {
    this.params.setSort(order, event.shiftKey);
  }

  refresh() {
    return false;
  }
}
