import { FormControl } from '@angular/forms';
import { arraysContainSameValues, naturalSort } from '@utils';
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  first,
  map,
  Observable,
  of,
  share,
  startWith,
  Subject,
  switchMap,
  tap,
} from 'rxjs';

import { TransformSelectorConfig } from '../transform/models/transform-config.model';
import {
  IRosettaSelector,
  IRosettaSelectorData,
} from './rosetta-selector.model';

/*
 A service that is used with the Rosetta Selector component.

 The selector needs to define its own method for fetching data it needs to display and
 adaptors for mapping to a standardised internal object which the component can use.
 */
export abstract class RosettaSelectorBase<TData = any>
  implements IRosettaSelector<TData>
{
  constructor(protected _config: TransformSelectorConfig) {}

  /*
  Subject variables
   */
  private _optionsSubject = new BehaviorSubject<TData[]>([]);
  private _selectedSubject = new Subject<string | undefined>();
  private _dataLoadingSubject = new BehaviorSubject(false);
  private _refreshOnToggleSubject = new Subject<void>();
  private _refreshOnToggle$ = this._refreshOnToggleSubject.pipe(
    startWith(undefined)
  );

  /*
  Internal variables
  */
  private _control?: FormControl;

  /*
   Getters
   */
  get label(): string {
    return this._config?.label;
  }

  get placeholder(): string {
    return this._config?.placeholder;
  }

  get key(): string {
    return this._config?.key;
  }

  protected abstract get value(): string;
  protected abstract set value(value: string);

  /*
  Public API used by selector.component and other IRosettaSelector
   */
  selectedValue$ = this._selectedSubject.pipe(
    map(selectedItemId => this._getItemFromValue(selectedItemId))
  );
  dataLoading$ = this._dataLoadingSubject.pipe(
    // Debounce to prevent UI spinner from flashing
    debounceTime(500)
  );

  options$ = this._optionsSubject.pipe(
    map(data => {
      this._restoreSelection();
      data.length ? this._control?.enable() : this._control?.disable();
      return naturalSort(this._convertResponse(data), 'label');
    }),
    share()
  );

  registerControl(control: FormControl): void {
    this._control = control;
    this._control?.disable();
  }

  applyOnChange(selectedItemId: string | null): void {
    this._updateSelection(selectedItemId);
  }

  resetSelection(): void {
    this._updateSelection(undefined);
    this._optionsSubject.next([]);
    this._control?.reset();
  }

  fetchOptions(dataFromTrigger?: any): Observable<void> {
    return this._refreshOnToggle$.pipe(
      tap(() => this._dataLoadingSubject.next(true)),
      switchMap(() =>
        this.fetchData(dataFromTrigger).pipe(
          first(),
          catchError(() => of([] as TData[])),
          map(data => {
            this._updateOptionsIfNecessary(data);
            return undefined;
          })
        )
      ),
      tap(() => this._dataLoadingSubject.next(false))
    );
  }

  onToggle(isOpen: boolean): void {
    if (isOpen && this._config.refreshOnOpen) {
      this._refreshOnToggleSubject.next();
    }
  }

  getListItemValue = (item: TData) => item as string;
  getListItemLabel = (item: TData) => item as string;

  /*
   Abstract methods
   */
  /* Defines where the data is coming from and is used by the Rosetta select component */
  protected abstract fetchData(data: unknown): Observable<TData[]>;

  /*
  Protected Methods
   */

  /*
  Private Methods for internal use only
  */
  private _getItemFromValue(value: string | null): TData | undefined {
    const options = this._optionsSubject.getValue();
    return options.find(option => this.getListItemValue(option) === value);
  }

  private _restoreSelection(): void {
    this._updateSelection(this.value);
  }

  private _convertResponse(data: TData[]): IRosettaSelectorData[] {
    return data.map(item => ({
      value: this.getListItemValue(item),
      label: this.getListItemLabel(item),
    }));
  }

  private _updateSelection(selectedItemId: string): void {
    selectedItemId = this._getValidSelectedItemId(selectedItemId);
    this.value = selectedItemId;
    this._control?.setValue(selectedItemId);
    this._selectedSubject.next(selectedItemId);
  }

  private _getValidSelectedItemId(selectedItemId: string): string | undefined {
    if (
      selectedItemId &&
      this._optionsSubject
        .getValue()
        .find(option => this.getListItemValue(option) === selectedItemId)
    ) {
      return selectedItemId;
    }

    return undefined;
  }

  private _updateOptionsIfNecessary(options: TData[]): void {
    const newOptions = options?.map(item => this.getListItemValue(item));
    const currentOptions = this._optionsSubject
      .getValue()
      ?.map(item => this.getListItemValue(item));

    // To prevent loading spinner from appearing unnecessarily in UI, only update options if different
    if (!arraysContainSameValues(newOptions, currentOptions)) {
      this._optionsSubject.next(options);
    }
  }
}
