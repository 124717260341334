<ng-scrollbar>
  <app-diagnostic-panel
    *ngIf="data.get('validation')"
    [groups]="data.get('validation')"
    heading="Validations"
  >
    <ng-template appDiagnosticPanelGroupDef="failure" let-item>
      <h4 class="mb-0 font-weight-bold">{{ item.name }}</h4>
      <small class="opacity-50">{{
        item.inputPath?.value?.join(' -> ')
      }}</small>
      <ul>
        <li class="list-style-disc" *ngFor="let issue of item.issues">
          {{ issue }}
        </li>
      </ul>
    </ng-template>

    <ng-template appDiagnosticPanelGroupDef="success" let-item>
      <h4 class="mb-0 font-weight-bold">{{ item.name }}</h4>
      <small class="opacity-50">{{
        item.inputPath?.value?.join(' -> ')
      }}</small>
    </ng-template>
  </app-diagnostic-panel>

  <app-diagnostic-panel
    *ngIf="data.get('mapping')"
    [groups]="data.get('mapping')"
    heading="Mappings"
  >
    <ng-template #issues appDiagnosticPanelGroupDef="failure" let-item>
      <h4 class="mb-0 font-weight-bold">{{ item.name }}</h4>
      <small class="opacity-50">{{
        item.inputPath?.value?.join(' -> ')
      }}</small>
      <ul>
        <li class="list-style-disc" *ngFor="let issue of item.issues">
          {{ issue }}
        </li>
      </ul>
    </ng-template>

    <ng-template appDiagnosticPanelGroupDef="success" let-item>
      <h4 class="mb-0 font-weight-bold">{{ item.name }}</h4>
      <small class="opacity-50">{{ item.inputPath?.value.join(' -> ') }}</small>
      <div class="mb-8"></div>
      <small class="font-weight-bold">Mapped to: </small>
      <ul *ngIf="item.outputPaths?.length > 1; else outputPathsMapped">
        <li *ngFor="let path of item.outputPaths">
          <small class="opacity-50">{{ path.value.join(' -> ') }}</small>
        </li>
      </ul>
      <ng-template #outputPathsMapped>
        <small class="opacity-50">{{
          item.outputPaths?.length === 1
            ? item.outputPaths[0].value.join(' -> ')
            : 'Used in conditional mapping expression'
        }}</small>
      </ng-template>
    </ng-template>

    <ng-template #issues appDiagnosticPanelGroupDef="excluded" let-item>
      <small class="opacity-50">{{
        item.inputPath?.value?.join(' -> ')
      }}</small>
    </ng-template>
  </app-diagnostic-panel>
</ng-scrollbar>
