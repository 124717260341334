import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SymbolIdentifier } from '@models';
import { Store } from '@ngrx/store';
import * as WorkspaceActions from '@store/workspace/actions';
import { map, Subscription } from 'rxjs';
import { BODY_CORPUS_GROUP_PARAM, DocRefId } from '../../models';
import { SelectedElementIdEvent } from '../../models/scroll-to.model';
import { RegulationService } from '../../services/regulation.service';
import { ScrollToQueueService } from '../../services/scroll-to-queue.service';

@Component({
  selector: 'app-regulation',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './regulation.component.html',
  styleUrls: ['./regulation.component.scss'],
  providers: [RegulationService, ScrollToQueueService],
})
export class RegulationComponent implements OnInit, OnDestroy {
  constructor(
    private _store: Store,
    private _service: RegulationService,
    private _activatedRoute: ActivatedRoute,
    private _scrollQueueService: ScrollToQueueService
  ) {}

  bodyCorpus$ = this._service.bodyCorpus$;
  segmentTree$ = this._service.bodyCorpusTree$;
  provisionDetails$ = this._service.bodyCorpusProvisionDetails$;
  showBetaToggle$ = this._service.newRegViewSupported$;
  selectedSegmentEvent$ = this._service.selectedSegmentEvent$;
  selectedSegmentId$ = this.selectedSegmentEvent$.pipe(map(event => event?.id));
  selectedBodyCorpusGroupId$ = this._service.selectedBodyCorpusGroupId$;

  private _sub = new Subscription();

  ngOnInit(): void {
    this._sub.add(this._scrollQueueService.processQueue$.subscribe());
    this._initialiseRouteSubscriptions();
  }

  ngOnDestroy(): void {
    this._sub.unsubscribe();
  }

  goToSymbol(classIdentification: SymbolIdentifier): void {
    this._store.dispatch(WorkspaceActions.goToSymbol({ classIdentification }));
  }

  bodyCorpusChanged(bodyCorpusGroupId: string): void {
    this._service.selectBodyCorpusGroup(bodyCorpusGroupId);
  }

  goToDocRef(docRef: DocRefId): void {
    this._service.selectSegment(docRef);
  }

  onFragmentChanged(event: SelectedElementIdEvent) {
    this._service.updateSelectedSegmentId(event);
  }

  private _initialiseRouteSubscriptions(): void {
    this._sub.add(
      this._activatedRoute.fragment.subscribe(fragment => {
        if (fragment) {
          this.onFragmentChanged({ type: 'click', id: fragment });
        }
      })
    );

    this._sub.add(
      this._activatedRoute.queryParamMap
        .pipe(map(paramMap => paramMap.get(BODY_CORPUS_GROUP_PARAM)))
        .subscribe(bodCorpusGroupId =>
          this._service.updateSelectedBodyCorpusGroupId(bodCorpusGroupId)
        )
    );
  }
}
