import { Injectable } from '@angular/core';
import { Task, TaskStatus } from '@models';
import { BehaviorSubject, mergeMap, Observable, of } from 'rxjs';
import { BaseWorkspaceService } from './base-workspace.service';

@Injectable({
  providedIn: 'root',
})
export class TaskService extends BaseWorkspaceService {
  constructor() {
    super();
    this.initWorkspaceObserver();
  }

  private _taskStatusAsSubjectMap: {
    [task: string]: { [subTask: string]: BehaviorSubject<TaskStatus> };
  } = {};
  private _taskStatusMapSubject = new BehaviorSubject<{
    [task: string]: { [subTask: string]: Observable<TaskStatus> };
  }>({});

  taskStatusMap = this._taskStatusMapSubject.asObservable();

  updateTaskStatus(
    task: Task,
    subTask: string | undefined,
    status: TaskStatus
  ): void {
    subTask = subTask || '';
    if (this._hasTaskStatus(task, subTask)) {
      // Publish task status only (no need to add new task / subTask)
      this._taskStatusAsSubjectMap[task][subTask].next(status);
    } else {
      const taskStatusSubject = new BehaviorSubject<TaskStatus>(status);
      // Keep map containing task status subject that we can publish updated to
      if (!this._taskStatusAsSubjectMap[task]) {
        this._taskStatusAsSubjectMap[task] = {};
      }
      this._taskStatusAsSubjectMap[task][subTask] = taskStatusSubject;
      // Publish list of observable task statuses that components can subscribe to
      const taskStatusAsObservableMap = this._taskStatusMapSubject.getValue();
      if (!taskStatusAsObservableMap[task]) {
        taskStatusAsObservableMap[task] = {};
      }
      taskStatusAsObservableMap[task][subTask] =
        taskStatusSubject.asObservable();
      this._taskStatusMapSubject.next(taskStatusAsObservableMap);
    }
  }

  updateAllSubTaskStatus(task: Task, taskStatus: TaskStatus): void {
    const subTaskMap = this._taskStatusAsSubjectMap[task];
    if (subTaskMap) {
      Object.values(subTaskMap).forEach(
        (subject: BehaviorSubject<TaskStatus>) => subject.next(taskStatus)
      );
    }
  }

  getTaskStatus(task: Task): Observable<TaskStatus> {
    return this.taskStatusMap.pipe(
      mergeMap(x => {
        return this._hasTaskStatus(task, '')
          ? x[task]['']
          : of(TaskStatus.Pending);
      })
    );
  }

  getSubTaskStatus(task: Task, subTask: string): Observable<TaskStatus> {
    return this.taskStatusMap.pipe(
      mergeMap(x => {
        return this._hasTaskStatus(task, subTask)
          ? x[task][subTask]
          : of(TaskStatus.Pending);
      })
    );
  }

  protected _onWorkspaceSwitch(): void {
    this._taskStatusAsSubjectMap = {};
    this._taskStatusMapSubject.next({});
  }

  private _hasTaskStatus(task: Task, subTask: string): boolean {
    return (
      task in this._taskStatusAsSubjectMap &&
      subTask in this._taskStatusAsSubjectMap[task]
    );
  }
}
