import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { BodyCorpus } from '@features/workspace/modules/design/regulation/models';

@Component({
  selector: 'app-body-corpus-list',
  templateUrl: './body-corpus-list.component.html',
  styleUrls: ['./body-corpus-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'body-corpus-list height-full' },
})
export class BodyCorpusListComponent {
  scrollToElementSelector?: string;
  scrollOptions = {
    top: -8,
  };

  @Input() bodyCorpus?: BodyCorpus;

  @Input()
  get selectedBodyCorpusGroupId(): string | undefined {
    return this._selectedBodyCorpusGroupId;
  }
  set selectedBodyCorpusGroupId(value: string | undefined) {
    this._selectedBodyCorpusGroupId = value;
    this.scrollToElementSelector = `#${value}`;
  }

  private _selectedBodyCorpusGroupId?: string;

  @Output() changed = new EventEmitter<string>();

  onClick(bodyCorpusGroupId: string) {
    this.changed.emit(bodyCorpusGroupId);
  }
}
