import { UPLOAD_DIR } from '@configs';
import { keyWordSort } from '@utils';
import {
  ResponseSampleFileNode,
  ResponseSampleFileNodeFlattened,
  TestType,
} from '../../models';

export const normalizeSampleFileFactory = (
  payload: ResponseSampleFileNode[]
): ResponseSampleFileNodeFlattened => {
  const uniqueIds = new Set<string>();
  const defaultValue: ResponseSampleFileNodeFlattened = {
    allIds: [],
    allLeafIds: [],
    allNodeIds: [],
    allUploadedIds: [],
    file: {},
    expectations: {},
  };

  keyWordSort(payload, UPLOAD_DIR, 'id').forEach(node => {
    if (uniqueIds.has(node.id)) {
      // Skip duplicates in the list
      return;
    }

    uniqueIds.add(node.id);
    defaultValue.allIds.push(node.id);
    const isLeaf = !!node.expectations;
    const isUpload = node.testType !== TestType.INTEGRATION_TEST;

    if (isLeaf) {
      defaultValue.allLeafIds.push(node.id);
      defaultValue.expectations[node.id] = node.expectations;

      if (isUpload) {
        defaultValue.allUploadedIds.push(node.id);
      }
    } else {
      defaultValue.allNodeIds.push(node.id);
    }

    defaultValue.file[node.id] = {
      id: node.id,
      testType: node.testType,
      parent: node.parent,
      name: node.name,
      environments: node.environments,
      synonyms: node.synonyms.slice(-1),
      isLeaf,
      isVisible: true,
      content: node.content,
      ...(isLeaf ? {} : { childIds: [] }),
    };
  });

  return Array.from(uniqueIds).reduce((acc, nodeId) => {
    let node = defaultValue.file[nodeId];
    let parentNode = node.parent && acc.file[node.parent];

    while (parentNode) {
      const childIds = parentNode.childIds || [];
      if (!childIds.includes(node.id)) {
        childIds.push(node.id);
        parentNode.childIds = childIds;
      }

      node = parentNode;
      parentNode = parentNode.parent && acc.file[parentNode.parent];
    }

    return acc;
  }, defaultValue);
};
