export enum Task {
  PojoCompilation = 'PojoCompilation',
  StaticJavaCompilation = 'StaticJavaCompilation',
  PojoGeneration = 'PojoGeneration',

  IngestionExport = 'IngestionExport',
  IngestionTestRun = 'IngestionTestRun',
  TranslateGenerateAndCompile = 'TranslateGenerateAndCompile',

  TranslateIngestSubmission = 'TranslateIngestSubmission',
  TranslateIngestRun = 'TranslateIngestRun',

  JsonValidation = 'JsonValidation',
  DamlGenerate = 'DamlGenerate',
  GraphGeneration = 'GraphGeneration',
  WorkflowExecution = 'WorkflowExecution',
  GridViewGeneration = 'GridViewGeneration',
  RootTypeCache = 'RootTypeCache',
  MultipleVisualisationExecution = 'MultipleVisualisationExecution',
  ExecutionEngineInitialisation = 'ExecutionEngineInitialisation',
}
