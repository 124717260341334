/* The purpose of this queue service is to handle scolling when there are multiple usages of the ng-scrollbar component on the same page.
  There is a bug with the library which causes weird behaviours when multiple scrollbars are scrolling at the same time.
  This queue processes the scroll events one at a time to avoid this issue.
*/

import { Injectable } from '@angular/core';
import { isElementVerticallyInView } from '@utils';
import {
  catchError,
  concatMap,
  finalize,
  from,
  Observable,
  of,
  Subject,
  timeout,
} from 'rxjs';
import { ScrollToModel } from '../models/scroll-to.model';

@Injectable()
export class ScrollToQueueService {
  private _isProcessing = false;
  private _queueSubject = new Subject<ScrollToModel>();

  processQueue$ = this._queueSubject.pipe(
    concatMap(item => {
      this._isProcessing = true;
      return this._processItem(item).pipe(
        timeout(500),
        catchError(() => {
          return of();
        }),
        finalize(() => {
          this._isProcessing = false;
        })
      );
    })
  );
  get processing(): boolean {
    return this._isProcessing;
  }

  enqueue(item: ScrollToModel): void {
    this._queueSubject.next(item);
  }

  private _processItem(item: ScrollToModel): Observable<void> {
    if (!item?.scrollbar) {
      return of();
    }

    if (!item.element) {
      // Scroll to top if element is not provided
      return from(item.scrollbar.scrollTo(item.scrollOptions ?? {}));
    }

    if (
      item.skipScrollWhenInView &&
      isElementVerticallyInView(
        item.element,
        item.scrollbar.viewport.nativeElement
      )
    ) {
      return of();
    }

    return from(
      item.scrollbar.scrollToElement(item.element, item.scrollOptions)
    );
  }
}
