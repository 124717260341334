import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { COLOR_DARK, COLOR_WHITE } from '@configs';
import { Color } from '@models';
import { WA_WINDOW } from '@ng-web-apis/common';
import { ResizeObserverService } from '@ng-web-apis/resize-observer';
import { Store } from '@ngrx/store';
import { AppSelectors } from '@store/selectors';
import { debounce } from '@utils';
import * as cytoscape from 'cytoscape';
import * as cytoscapeDagre from 'cytoscape-dagre';
import * as popper from 'cytoscape-popper';
import { Subject, distinctUntilChanged, merge, takeUntil, tap } from 'rxjs';
import tippy, { Instance } from 'tippy.js';
import { postProcessNodeName } from '../../services/cytoscape-graph.util';

cytoscape.use(cytoscapeDagre);
cytoscape.use(popper);

@Component({
  selector: 'app-cytoscape-graph',
  templateUrl: './cytoscape-graph.component.html',
  styleUrls: ['./cytoscape-graph.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ResizeObserverService],
})
export class CytoscapeGraphComponent implements OnChanges, OnInit, OnDestroy {
  constructor(
    private _store: Store,
    private _route: ActivatedRoute,
    @Inject(WA_WINDOW) private _window: Window
  ) {}

  static readonly ROSETTA_CLASS_REGEX = /(.*)\.(.*)/;
  private _unsubscribe$ = new Subject<void>();
  private _cy: any;
  private _graph: any;
  private _textColor?: Color;
  private _textOpacity?: string;
  private _isDark = false;

  @Input()
  set graph(graph: any) {
    this._graph = graph;
  }

  @Output() nodeClick: EventEmitter<{
    graphPath: string;
    rosettaClass: string;
    rosettaNamespace: string;
  }> = new EventEmitter();

  ngOnChanges() {
    this._render();
  }

  ngOnInit() {
    merge(
      this._store
        .select(AppSelectors.isDarkTheme)
        .pipe(tap(isDark => this._setTheme(isDark))),
      this._route.queryParams.pipe(distinctUntilChanged())
    )
      .pipe(
        takeUntil(this._unsubscribe$),
        tap(() => this._render())
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  onResize() {
    this._render();
  }

  select(rosettaKey: string) {
    this._cy.elements('node[rosettaKey = "' + rosettaKey + '"]').select();
  }

  deselect() {
    this._cy.elements().unselect();
  }

  getVizElement() {
    return document.getElementById('viz');
  }

  private _setTheme(isDark: boolean) {
    this._textColor = isDark ? COLOR_WHITE : COLOR_DARK;
    this._textOpacity = isDark ? '0.9' : '0.54';
    this._isDark = isDark;
  }

  @debounce(500)
  private _render() {
    this._window.requestAnimationFrame(() => {
      // Ensure that the graph is in the DOM before rendering it
      if (!this.getVizElement()) {
        return;
      }
      const graph = this._graph;
      const roots =
        graph.roots.length === 0
          ? graph.nodes
              .filter((n: any, i: number) => i === 0 && n.data.id)
              .map((n: any) => n.data.id)
          : graph.roots;

      if (graph !== undefined) {
        this._cy = cytoscape({
          container: this.getVizElement(),
          layout: {
            name: 'breadthfirst',
            spacingFactor: 2,
            roots: roots,
          },
          style: this._getStyle(),
          elements: graph,
          minZoom: 0.25,
          maxZoom: 2,
          userZoomingEnabled: true,
        });

        this._cy.nodes().forEach((node: any) => {
          this._addPopups(node);
          this._addClickHandler(node);
          this._processName(node);
        });
      }
    });
  }

  private _addClickHandler(node: any) {
    const nodeData = node.data().node;
    const graphPath = nodeData.graphPath;
    const [, rosettaNamespace, rosettaClass] = nodeData.clazz.match(
      CytoscapeGraphComponent.ROSETTA_CLASS_REGEX
    );
    node.on('click', () => {
      this.nodeClick.emit({ graphPath, rosettaClass, rosettaNamespace });
    });
  }

  private _addPopups(node: any) {
    const nodeData = node.data().node;
    if (nodeData && nodeData.action) {
      const instance = tippy(node.popperRef(), {
        content: 'Action: ' + nodeData.action.toLowerCase(),
        trigger: 'manual',
        hideOnClick: false,
        arrow: true,
        sticky: true,
        interactive: true,
      });

      // A 'type guard' that safely casts i to Instance and restricts the type scope
      const isInstance = (i: Instance | Instance[]): i is Instance =>
        (i as Instance).show !== undefined;

      if (isInstance(instance)) {
        node.on('mouseover', () => instance.show());
        node.on('mouseout', () => instance.hide());
      }
    }
  }

  private _processName(node: any) {
    const nodeData = node.data().node;
    const [, , rosettaClass] = nodeData.clazz.match(
      CytoscapeGraphComponent.ROSETTA_CLASS_REGEX
    );

    if (nodeData.name) {
      if (rosettaClass === 'ContractualProduct') {
        const productName = nodeData.name.split('_').pop();
        nodeData.name = postProcessNodeName(productName);
      } else {
        nodeData.name = postProcessNodeName(nodeData.name);
      }
    }
  }

  private _getStyle(): any[] {
    return [
      {
        selector: 'node',
        style: {
          label: 'data(node.name)',
          'font-size': 25,
          'text-valign': 'center',
          color: this._textColor,
          'text-opacity': this._textOpacity,
          'text-wrap': 'wrap',
          'background-color': '#888',
          width: 100,
          height: 100,
          'border-width': 2,
        },
      },
      {
        selector: 'node.party',
        style: {
          'background-color': '#8DCC93',
          'border-color': '#5db665',
        },
      },
      {
        selector: 'node.workflow-step',
        style: {
          'background-color': '#FFD86E',
          'border-color': '#EDBA39',
          'text-outline-width': 2,
          'text-outline-color': this._isDark ? '#8A7D07' : '#FFD86E',
        },
      },
      {
        selector: 'node.business-event',
        style: {
          'background-color': '#816888',
          'border-color': '#7b5186',
          'text-outline-width': 2,
          'text-outline-color': '#816888',
        },
      },
      {
        selector: 'node.trade-state',
        style: {
          'background-color': '#C990C0',
          'border-color': '#b261a5',
          'text-outline-width': 2,
          'text-outline-color': '#C990C0',
        },
      },
      {
        selector: 'node.primitive-event',
        style: {
          'background-color': '#4C8EDA',
          'border-color': '#23b3d7',
          'text-outline-width': 2,
          'text-outline-color': '#4C8EDA',
        },
      },
      {
        selector: 'node.legal-agreement',
        style: {
          'background-color': '#F79767',
          'border-color': '#f36924',
          'text-outline-width': 2,
          'text-outline-color': '#F79767',
        },
      },
      {
        selector: 'node.contractual-product',
        style: {
          'background-color': '#4459bd',
          'border-color': '#3c4fad',
          'text-outline-width': 2,
          'text-outline-color': '#4459bd',
        },
      },
      {
        selector: 'node.party-reference-payer-receiver',
        style: {
          'background-color': '#C69E38',
          'border-color': '#775F21',
        },
      },
      {
        selector: 'node.transfer',
        style: {
          'background-color': '#C63860',
          'border-color': '#772139',
        },
      },
      {
        selector: 'node:selected',
        style: {
          'border-width': 30,
          'border-color': '#AAD8FF',
          'border-opacity': '0.5',
        },
      },
      {
        selector: 'edge',
        style: {
          label: 'data(label)',
          'font-size': 25,
          color: this._textColor,
          'edge-text-rotation': 'autorotate',

          'curve-style': 'bezier',
          width: 2,
          'target-arrow-shape': 'triangle',
          'target-arrow-color': '#A5ABB6',
          'line-color': '#A5ABB6',
          'arrow-scale': 2,
        },
      },
      {
        selector: '.lineage',
        style: {
          label: 'data(reference)',
          'curve-style': 'unbundled-bezier',
          'control-point-distance': [150],
          'control-point-weight': [0.5],
          'target-arrow-color': '#5CA8DB',
          'line-color': '#5CA8DB',
        },
      },
    ];
  }
}
