<button
  mat-icon-button
  matTooltip="Search All Files"
  [appRosettaOverlayTarget]="overlay"
  [disabled]="isLoading$ | async"
  (click)="searchVal.focus()"
>
  <fa-icon icon="search" />
</button>

<app-rosetta-overlay #overlay>
  <app-rosetta-menu class="search-all-container">
    <mat-form-field
      class="search-query-content width-full"
      appearance="outline"
    >
      <input
        matInput
        #searchVal
        id="workspace-search"
        name="findAll"
        appStopPropagation
        (keydown.enter)="applyFilter(searchVal.value)"
        (keydown.arrowup)="moveResult('UP')"
        (keydown.arrowdown)="moveResult('DOWN')"
        dividerColor="primary"
        autofocus
        autocorrect="off"
        autocapitalize="off"
        spellcheck="false"
        wrap="off"
        aria-label="Find All"
        placeholder="Find All"
        title="Find All"
        maxlength="200"
      />
      <div class="small-button-wrapper mr-8" matIconSuffix>
        <button
          mat-icon-button
          (click)="moveResult('DOWN')"
          [disabled]="!resultList?.length"
        >
          <fa-icon icon="arrow-down" [fixedWidth]="true" />
        </button>
        <button
          mat-icon-button
          (click)="moveResult('UP')"
          [disabled]="!resultList?.length"
        >
          <fa-icon icon="arrow-up" [fixedWidth]="true" />
        </button>
        <button
          mat-icon-button
          (click)="applyFilter('')"
          [disabled]="!searchValue?.length"
        >
          <fa-icon icon="times" [fixedWidth]="true" />
        </button>
      </div>
    </mat-form-field>

    <div
      tabindex="1"
      appScrollable
      appStopPropagation
      class="search-results-list"
      (keydown.arrowup)="moveResult('UP')"
      (keydown.arrowdown)="moveResult('DOWN')"
      (keydown.enter)="
        goToRange(
          resultList[currentResultId.fileId].model.uri,
          resultList[currentResultId.fileId].ranges[currentResultId.rowId].range
        )
      "
    >
      <ng-template #noResults>
        <p class="no-results">No Results found.</p>
      </ng-template>

      <ng-container *ngIf="resultList !== null; else noResults">
        <div
          class="file-group"
          *ngFor="let result of resultList; index as fileId"
        >
          <h3 class="file-group-info mb-0 font-weight-bold">
            {{ result.model.fileName }}
          </h3>

          <section class="file-group-content">
            <div
              class="file-group-row flex-row-center"
              *ngFor="let row of result.ranges; index as rowId"
              appOffsetTop
              (click)="
                goToRange(result.model.uri, row.range, {
                  fileId: fileId,
                  rowId: rowId,
                });
                overlay.close()
              "
              [class.selected]="
                fileId === currentResultId.fileId &&
                rowId === currentResultId.rowId
              "
            >
              <span class="mr-4">{{ row.range.startLineNumber }}:</span>
              <p class="mb-0 text-ellipsis" [innerHTML]="row.rangeText"></p>
            </div>
          </section>
        </div>
      </ng-container>
    </div>
  </app-rosetta-menu>
</app-rosetta-overlay>
