<form [formGroup]="searchForm">
  <button
    class="small-button-icon"
    mat-icon-button
    (click)="itemSelected(searchForm.controls['searchInputControl'].value)"
  >
    <fa-icon icon="search" />
  </button>
  <input
    class="theme-color"
    matInput
    placeholder="Class"
    formControlName="searchInputControl"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="itemSelected($event.option.viewValue)"
  >
    <mat-option
      *ngFor="let option of filteredSearchData | async"
      [value]="option"
      id="search-{{ option }}"
    >
      {{ option }}
    </mat-option>
  </mat-autocomplete>
</form>
