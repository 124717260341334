import { IngestRunResult, TaskStatus } from '@models';
import { State } from '.';
import { UPLOAD_DIR } from '@configs';
import { addUnique, keyWordSort } from '@utils';
import { Draft, produce } from 'immer';

export const startSampleFileIngestion = produce(
  (draft: Draft<State>, ids: string[]) => {
    ids
      .filter(id => draft.fileData.statuses[id] !== TaskStatus.Unable_To_Run)
      .forEach(id => {
        draft.fileData.statuses[id] = TaskStatus.Starting;
      });
  }
);

export const aggregateParentStatuses = produce(
  (draft: Draft<State>, modifiedNodeId: string | string[] | undefined) => {
    if (!modifiedNodeId) {
      return;
    }

    if (Array.isArray(modifiedNodeId)) {
      modifiedNodeId.forEach(recursiveCall);
    } else {
      recursiveCall(modifiedNodeId);
    }

    function recursiveCall(nodeId: string) {
      const file = draft.fileMap[nodeId];

      if (file.parent) {
        const parentId = file.parent;
        const parent = draft.fileMap[parentId];
        const childIds = parent.childIds || [];
        const statuses = addUnique(
          [],
          ...childIds.map(
            id => draft.fileData.statuses[id] || TaskStatus.Pending
          )
        );

        if (statuses.length === 1) {
          const status = statuses[0];
          draft.fileData.statuses[parentId] =
            status === TaskStatus.Starting ? TaskStatus.Started : status;
          recursiveCall(parentId);
          return;
        }

        if (statuses.some(status => status === TaskStatus.Error)) {
          draft.fileData.statuses[parentId] = TaskStatus.Error;
          recursiveCall(parentId);
          return;
        }

        if (
          statuses.some(
            status =>
              status === TaskStatus.Started || status === TaskStatus.Starting
          )
        ) {
          draft.fileData.statuses[parentId] = TaskStatus.Started;
          recursiveCall(parentId);
          return;
        }

        if (statuses.some(status => status === TaskStatus.Finished)) {
          draft.fileData.statuses[parentId] = TaskStatus.Finished;
          recursiveCall(parentId);
          return;
        }

        draft.fileData.statuses[parentId] = TaskStatus.Pending;
        recursiveCall(parentId);
      }
    }
  }
);

export const leafNodeResults = produce(
  (draft: Draft<State>, payload: IngestRunResult) => {
    const file = draft.fileMap[payload.id];

    if (payload.errors) {
      draft.fileData.statuses[file.id] = TaskStatus.Error;
      draft.fileData.errors[file.id] = payload.errors;
      return;
    }

    draft.fileData.statuses[file.id] = payload.success
      ? TaskStatus.Finished
      : TaskStatus.Finished_Error;

    const validationTotal =
      payload.report.validationReport.validationResults.length;

    const validationSuccess =
      payload.report.validationReport.validationResults.filter(
        v => !!v.success
      ).length;

    const validationFailure = validationTotal - validationSuccess;

    const { uniquelyQualifiedObjectsCount, qualifiableObjectsCount } =
      payload.report.qualificationReport;

    draft.fileData.results[file.id] = {
      success: payload.success,
      mapping: {
        success: payload.report.mappingReport.successCount,
        failure: payload.report.mappingReport.failures.length,
        excluded: payload.report.mappingReport.excludedPaths.length,
        completeness: payload.report.mappingReport.completeness,
      },

      validation: {
        success: validationSuccess,
        failure: validationFailure,
        completeness: (validationSuccess / validationTotal) * 100,
      },

      qualifications: {
        count: uniquelyQualifiedObjectsCount,
        completeness:
          (uniquelyQualifiedObjectsCount / qualifiableObjectsCount) * 100,
      },

      lastModified: Date.now(),
    };
  }
);

export const setFilteredFileIds = produce((draft: State) => {
  draft.fileIds.filtered = keyWordSort(
    draft.fileIds.leaf.filter(fileId => {
      const file = draft.fileMap[fileId];
      const searchMatch = file.name
        .toLowerCase()
        .includes(draft.filter.search.toLowerCase());

      const hasReport = !draft.filter.lastRun
        ? true
        : draft.fileData.results[fileId] !== undefined;
      const filterMatch =
        draft.filter.filters.length < 1
          ? true
          : draft.filter.filters.filter(env => file.environments.includes(env))
              .length > 0;
      return (file.isVisible = searchMatch && filterMatch && hasReport);
    }),
    UPLOAD_DIR
  );

  draft.fileIds.node.forEach(fileId => {
    const file = draft.fileMap[fileId];

    if (!file.childIds) {
      return;
    }

    const findVisibleChild = function (ids: string[]): boolean {
      return ids.some(id => {
        const child = draft.fileMap[id];
        if (!child) {
          return false;
        }

        if (child.isLeaf && child.isVisible) {
          return true;
        }

        if (child.childIds && child.childIds.length > 0) {
          return findVisibleChild(child.childIds);
        }

        return false;
      });
    };

    file.isVisible = findVisibleChild(file.childIds);
  });
});
