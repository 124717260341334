import { Expectation, ServerError, TaskStatus } from '@models';
import { ExpectationResult, ExpectationsMap, FileFullMap, FileMap } from '.';
import { NodeCompleteness } from './expectation-result.model';

export interface FileNode {
  id: string;
  testType: TestType;
  parent: string | null;
  name: string;
  environments: string[];
  synonyms: string[];
  content?: string;
}

export interface ResponseSampleFileNode extends FileNode {
  expectations?: Expectation;
}

export interface SampleFileNode extends FileNode {
  childIds?: string[] | undefined;
  isLeaf: boolean;
  isVisible: boolean;
}

export interface ResponseSampleFileNodeFlattened {
  allIds: string[];
  allLeafIds: string[];
  allNodeIds: string[];
  allUploadedIds: string[];
  file: FileMap;
  expectations: ExpectationsMap;
}

export enum TestType {
  UPLOADED_TO_SERVER = 'UPLOADED_TO_SERVER',
  UPLOADED_TO_CLIENT = 'UPLOADED_TO_CLIENT',
  INTEGRATION_TEST = 'INTEGRATION_TEST',
}

export interface SampleFileNodeFull extends Omit<SampleFileNode, 'childIds'> {
  state: TaskStatus;
  expectations?: Expectation;
  results?: ExpectationResult;
  completeness?: NodeCompleteness;
  children?: SampleFileNodeFull[];
  errors?: ServerError;
}

export interface FileMapTree {
  fileMap: FileFullMap;
  tree: SampleFileNodeFull[];
}
