import { inject, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { currentWorkspaceIdObserver } from '@utils';
import { Subscription } from 'rxjs';

@Injectable()
export abstract class BaseWorkspaceService implements OnDestroy {
  protected _store = inject(Store);
  private _workspaceObserver: Subscription;

  initWorkspaceObserver(): void {
    if (!this._workspaceObserver || this._workspaceObserver.closed) {
      this._workspaceObserver = currentWorkspaceIdObserver(this._store, {
        skipReadyCheck: true,
      }).subscribe(() => this._onWorkspaceSwitch());
    }
  }

  ngOnDestroy(): void {
    this._workspaceObserver.unsubscribe();
  }

  protected abstract _onWorkspaceSwitch(): void;
}
