import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MonacoThemes, StorageKey } from '@configs';
import { LocalStorageService } from '@core/services';
import { LanguageServerService } from '@core/services/language-server.service';
import { isNotNull } from '@utils';
import { first, map } from 'rxjs';

@Component({
  selector: 'app-editor-action-dropdown',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'editor-action-dropdown',
  },
  template: `
    <button
      tourAnchor="rosetta-editor.dropdown"
      mat-icon-button
      [matMenuTriggerFor]="editorMenu"
    >
      <fa-icon icon="ellipsis-vertical" size="lg" />
    </button>
    <mat-menu #editorMenu="matMenu" xPosition="before" class="editor-menu">
      <ng-template matMenuContent>
        <div
          *ngFor="let item of items | keyvalue | sortBy: 'value.name'"
          mat-menu-item
          role="menuitemcheckbox"
          appStopPropagation
        >
          <mat-checkbox
            [(ngModel)]="item.value.checked"
            (ngModelChange)="updateAllComplete()"
            >{{ item.value.name }}</mat-checkbox
          >
        </div>
        <div mat-menu-item role="menuitemcheckbox" appStopPropagation>
          <mat-checkbox
            [checked]="checked$ | async"
            (change)="changeTheme($event)"
            >Legacy theme</mat-checkbox
          >
        </div>
      </ng-template>
    </mat-menu>
  `,
})
export class EditorActionDropdownComponent implements OnChanges {
  constructor(
    private _storage: LocalStorageService,
    private _languageService: LanguageServerService
  ) {}

  @Input() editor!: monaco.editor.IStandaloneCodeEditor;

  private readonly _defaultEditorOptions = {
    inlayHints: {
      name: 'Code hints',
      checked: true,
    },
    wordWrap: { name: 'Word wrap', checked: true },
    miniMap: { name: 'Mini map', checked: false },
  };

  items: typeof this._defaultEditorOptions = {
    ...this._defaultEditorOptions,
    ...(this._storage.getItem(StorageKey.EditorOptions) || {}),
  };

  checked$ = this._languageService.editorSubject$.pipe(
    first(isNotNull),
    map(editor =>
      (editor as any)['_themeService']._theme.themeName.startsWith(
        MonacoThemes.LEGACY
      )
    )
  );

  ngOnChanges({ editor }: SimpleChanges) {
    if (editor) {
      this._updateEditorOptions();
    }
  }

  updateAllComplete() {
    this._storage.setItem(StorageKey.EditorOptions, this.items);
    this._updateEditorOptions();
  }

  changeTheme({ checked }: MatCheckboxChange) {
    this._languageService.setTheme({
      themeName: checked ? MonacoThemes.LEGACY : MonacoThemes.ROSETTA,
    });
  }

  private _updateEditorOptions() {
    const { inlayHints, wordWrap, miniMap } = this.items;
    this.editor.focus();
    this.editor.updateOptions({
      inlayHints: { enabled: inlayHints.checked, fontSize: 12 },
      wordWrap: wordWrap.checked ? 'on' : 'off',
      minimap: {
        enabled: miniMap.checked,
        size: 'fit',
        showSlider: 'always',
      },
    });
  }
}
